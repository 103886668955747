import Cookies from 'js-cookie';

export const convertCurrency = (price) => {
  const currencyRate = Cookies.get('c_r');
  const amountInSelectedCurrency = currencyRate ? currencyRate * price : price;
  const roundedAmount = amountInSelectedCurrency.toFixed(2);
  return roundedAmount;
};

export const calculateCommission = (price) => {
  const rate = Cookies.get('rate');
  const commissionRate = rate; // 2% commission rate
  let listedAmount = (price * commissionRate) / 100;
  const earningAmount = price - listedAmount;
  return {
    listedAmount: listedAmount.toFixed(2),
    earningAmount: Number(earningAmount).toFixed(2)
  };
};

export const itemNameConverter = (text) => {
  let inventoryName;
  const slashIndices = [];
  let match;
  const slashRegex = /\|/g;

  while ((match = slashRegex.exec(text)) !== null) {
    slashIndices.push(match.index);
  }
  if (slashIndices.length >= 2) {
    const startIndex = slashIndices[0] + 1; // index after the first '|'
    const endIndex = slashIndices[1]; // index of the second '|'
    const convertedText = text.substring(startIndex, endIndex).trim();
    inventoryName = convertedText.replace(/ *\([^)]*\) */g, '');
  } else {
    const match = text.match(/\|([^()|]+)\(/);
    if (match && match.length > 1) {
      inventoryName = match[1].trim(); // Extract the matched word and remove leading/trailing spaces
    } else {
      const indexOfSlash = text.indexOf('|');
      if (indexOfSlash !== -1) {
        // Extract the text before the first occurrence of '|'
        inventoryName = text.substring(indexOfSlash + 1).trim();
      } else {
        inventoryName = text;
      }
    }
  }

  return inventoryName;
};

export const wearNameConverter = (wear) => {
  return wear === 'N/A'
    ? wear
    : wear
        .split(/[\s-]/)
        .map((word) => word.charAt(0))
        .join('');
};

export const RoundedPrice = (value) => {
  // Round the price to two decimal places
  const roundedPrice = Math.round(value * 100) / 100;

  return roundedPrice;
};

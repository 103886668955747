import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

import { Button } from 'components';

export const SecureTrading = ({ data }) => {
  const sectionArray = data?.item;
  const section = sectionArray?.find((section) => Number(section.section_name) === 2);
  if (!section) {
    return null;
  }
  const handleClick = (button_url) => {
    if (button_url) {
      window.open(button_url, '_blank');
    }
  };
  return (
    <>
      {section ? (
        <div className="second_section section_padding">
          <div className="container-fluid">
            <div className="container">
              <div className="row align-items-center custome-row-gap">
                {section?.image && (
                  <div className="col-md-6 col order-md-1 order-2">
                    <div className="common_image_div common_left_div position-relative">
                      <img src={section?.image} alt="Zipit" className="img-fluid" />
                      <span className="second_section_shadow"></span>
                    </div>
                  </div>
                )}
                <div className="col-md-6 col order-md-2 order-1">
                  <div className="common_text_div common_right_div text-white">
                    {section?.title && <h2 className="main_sub_heading">{section?.title}</h2>}
                    {section?.description && (
                      <div className="description">
                        <p>{section?.description}</p>
                      </div>
                    )}
                    {section?.button_name && (
                      <Button className="common_button mt-50" handleClick={() => handleClick(section?.button_url)}>
                        {section?.button_name}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="second_section section_padding second_section_loading"></div>
      )}
    </>
  );
};

SecureTrading.propTypes = {
  data: PropTypes.any
};

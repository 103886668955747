import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { inventory_sell_price_update } from 'api';
import { useApi } from 'hooks/useApi';
import { toast } from 'react-toastify';
import { RoundedPrice, calculateCommission, convertCurrency } from 'lib';

export const EditListing = ({
  closeModal,
  item,
  setDeleteModal,
  deleteModal,
  onDelete,
  setEditListingModal,
  fetchData,
  id,
  deleteModalClose
}) => {
  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const showSecondPrice = currency && currency.code !== defaultCurrency.code;

  const hasNow = item?.hasBuyNow === '1' ? true : false;
  const hascheckout = item?.hascheckout === '1' ? true : false;
  const [checkOutPrice, setCheckOutPrice] = useState(item.checkoutPrice || null);
  const [hasCheckOut, setHasCheckOut] = useState(hascheckout || false);
  const [hasBuyNow, setHasBuyNow] = useState(hasNow || false);
  const [buyNowPrice, setBuyNowPrice] = useState(item?.buyNowPrice || null);
  const [price, setPrice] = useState(item?.startBid || '');
  const [errors, setErrors] = useState({
    checkOutPrice: '',
    buyNowPrice: '',
    price: ''
  });

  const [buyEarningPrice, setBuyEarningPrice] = useState(0);

  useEffect(() => {
    if (item?.buyNowPrice) {
      const { earningAmount } = calculateCommission(item?.buyNowPrice);
      // setBuyListedPrice(listedAmount);
      setBuyEarningPrice(earningAmount);
    }
  }, [item]);

  const onDeleteModal = () => {
    setDeleteModal(true);
  };

  const { apiCaller: onSellPriceUpdate } = useApi({
    dataGetter: inventory_sell_price_update
  });

  const onSavePrice = () => {
    const minAllowedBargainPrice = (parseFloat(price) / 100) * 20;
    if (parseFloat(buyNowPrice) <= minAllowedBargainPrice) {
      return setErrors({ ...errors, buyNowPrice: 'Bargain price cannot be less than 20% off the original price.' });
    }
    if (parseFloat(price) > parseFloat(checkOutPrice)) {
      return setErrors({ ...errors, checkOutPrice: 'Price must be equal to or less than the estimated earnings.' });
    }
    if (Object.values(errors).some((error) => error !== '')) {
      return; // If there are errors, do not proceed
    }

    setErrors({});
    document.body.classList.remove('modal-open');
    const params = {
      market_id: item.m_id,
      item_id: item.item_id,
      price,
      buyNowPrice: hasBuyNow ? buyNowPrice : null,
      hasBuyNow: buyNowPrice ? hasBuyNow : null,
      checkOutPrice: hasCheckOut ? checkOutPrice : null,
      hasCheckOut: checkOutPrice ? hasCheckOut : null
    };
    onSellPriceUpdate(params).then((res) => {
      if (res.data.status === 'success') {
        setEditListingModal(false);
        toast.success(res.data.msg);
        fetchData(id);
      }
    });
  };

  const { earningAmount } = calculateCommission(price);

  const onBargainPrice = (e) => {
    setBuyNowPrice(e.target.value);
    handleApplyBargainPrice(e.target.value);
  };

  const handleApplyBargainPrice = (bprice) => {
    // Convert bargain price input to a number
    const enteredBargainPrice = parseFloat(bprice);
    // Calculate the minimum allowed bargain price
    const minAllowedBargainPrice = (price / 100) * 20;
    const decimalRegex = /^\d{0,7}(\.\d{0,2})?$/;

    if (bprice.startsWith('0')) {
      setErrors({ ...errors, buyNowPrice: 'The minimum price is 1€' });
    } else if (enteredBargainPrice <= minAllowedBargainPrice) {
      setErrors({ ...errors, buyNowPrice: 'Bargain price cannot be less than 20% off the original price.' });
    } else if (!decimalRegex.test(bprice)) {
      setErrors({ ...errors, buyNowPrice: 'Price can have a maximum of 7 figures and 2 decimal places' });
    } else {
      setErrors({ ...errors, buyNowPrice: '' });
    }
  };

  const auctionBuyPrice = (e) => {
    setCheckOutPrice(e.target.value);
    const { earningAmount } = calculateCommission(e.target.value);
    const decimalRegex = /^\d{0,7}(\.\d{0,2})?$/;

    // setBuyListedPrice(listedAmount);
    setBuyEarningPrice(earningAmount);
    if (e.target.value.startsWith('0')) {
      setErrors({ ...errors, checkOutPrice: 'The minimum price is 1€' });
    } else if (parseFloat(price) > parseFloat(e.target.value)) {
      setErrors({ ...errors, checkOutPrice: 'Must be equal to or greater than the start price.' });
    } else if (!decimalRegex.test(e.target.value)) {
      setErrors({ ...errors, checkOutPrice: 'Price can have a maximum of 7 figures and 2 decimal places' });
    } else {
      setErrors({ ...errors, checkOutPrice: '' });
    }
  };

  const onPriceSave = (e) => {
    const newPrice = e.target.value;
    const decimalRegex = /^\d{0,7}(\.\d{0,2})?$/;

    setPrice(newPrice);
    if (newPrice === '') {
      setErrors({ ...errors, price: 'Price is required' });
    } else if (newPrice.startsWith('0')) {
      setErrors({ ...errors, price: 'The minimum price is 1€' });
    } else if (parseFloat(newPrice) < 1) {
      setErrors({ ...errors, price: 'The minimum price is 1€' });
    } else if (!decimalRegex.test(e.target.value)) {
      setErrors({ ...errors, price: 'Price can have a maximum of 7 figures and 2 decimal places' });
    } else {
      setErrors({ ...errors, price: '' });
    }
    // handleApplyBargainPrice(e.target.value);
  };
  return (
    <>
      <aside role="dialog" tabIndex="-1" aria-modal="true" className="edit-price-modal sell-model">
        <div className="sell-modal-area edit-listing-modal">
          <div className="edit-listing-popup position-relative">
            <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
              <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
            </button>
            <p className="offer-heading">Edit listing</p>
            <div className="d-flex justify-content-between amount-offer-div">
              <p className="pt-10">Starting bid</p>
              <div className="input-outer-div">
                <div className={`grey-background input-main-div ${errors.price ? 'invalid-input' : 'valid-input'}`}>
                  <label className="input-lable">{defaultCurrency && defaultCurrency.symbol}</label>
                  <input
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="price"
                    placeholder="00.00"
                    className={`w-100 `}
                    value={price}
                    onChange={(e) => onPriceSave(e)}
                    onKeyDown={(e) => {
                      if (
                        (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                          e.key !== 'Backspace' &&
                          e.key !== 'Delete' &&
                          e.key !== 'ArrowLeft' &&
                          e.key !== 'ArrowRight' &&
                          e.key !== '.') || // Allow dot key
                        (e.key === '.' && price.includes('.')) // Ensure only one dot is entered
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {errors.price && <p className="color-red">{errors.price}</p>}
                {showSecondPrice && (
                  <p className="opacity-white font-12 mt-5 letter-space">
                    {currency.symbol}
                    {convertCurrency(RoundedPrice(price))}
                  </p>
                )}
              </div>
            </div>
            <div className="mt-20">
              <p className="">
                <span className="opacity-white">Listed price {''}</span>
                <span>
                  {defaultCurrency && defaultCurrency.symbol}
                  {RoundedPrice(price)}
                </span>
              </p>
              <p className="mt-5">
                <span className="opacity-white">Estimated Earnings {''}</span>
                <span>
                  {defaultCurrency && defaultCurrency.symbol}
                  {RoundedPrice(earningAmount)}
                </span>
              </p>
            </div>

            {item && item.selltype === 'market' && (
              <>
                <div className="edit-list-text mt-20">
                  <p className="opacity-white">
                    {
                      'You can set a custom minimum bargain reserve price for your listing. We are not going to show it to potential buyers, instead we will show the standard 20% of the sell price. The custom amount has to be more than 20% of the sell price. '
                    }
                  </p>
                </div>
                <label className="sell-label mt-20">
                  Set custom minimum bargain price
                  <input type="checkbox" onChange={(e) => setHasBuyNow(e.target.checked)} checked={hasBuyNow} id="" name="haseByNow" />
                  <span className="checkmark l-0"></span>
                </label>
                {hasBuyNow && (
                  <div className="form-control">
                    <input
                      type="text"
                      name="price"
                      inputMode="numeric"
                      value={buyNowPrice || ''}
                      placeholder="0.00"
                      onChange={onBargainPrice}
                      className={`w-100 ${errors.buyNowPrice ? 'invalid-input' : 'valid-input'}`}
                      onKeyDown={(e) => {
                        if (
                          (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                            e.key !== 'Backspace' &&
                            e.key !== 'Delete' &&
                            e.key !== 'ArrowLeft' &&
                            e.key !== 'ArrowRight' &&
                            e.key !== '.') || // Allow dot key
                          (e.key === '.' && buyNowPrice.includes('.')) // Ensure only one dot is entered
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.buyNowPrice && <p className="color-red mt-5 font-14 pb-0">{errors.buyNowPrice}</p>}
                  </div>
                )}
              </>
            )}
            {item && item.selltype === 'auction' && (
              <>
                <label className="sell-label mt-10">
                  {`Has "buy now" option`}
                  <input type="checkbox" onChange={(e) => setHasCheckOut(e.target.checked)} checked={hasCheckOut} id="" name="haseByNow" />
                  <span className="checkmark l-0"></span>
                </label>
                {hasCheckOut && (
                  <>
                    <div className="form-control mt-10">
                      <p className="font-16 font-700 pb-5">Buy Out Price </p>
                      <input
                        type="text"
                        name="price"
                        placeholder="00.00"
                        inputMode="numeric"
                        className={`w-100 ${errors.checkOutPrice ? 'invalid-input' : 'valid-input'}`}
                        value={checkOutPrice || ''}
                        onChange={auctionBuyPrice}
                        onKeyDown={(e) => {
                          if (
                            (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                              e.key !== 'Backspace' &&
                              e.key !== 'Delete' &&
                              e.key !== 'ArrowLeft' &&
                              e.key !== 'ArrowRight' &&
                              e.key !== '.') || // Allow dot key
                            (e.key === '.' && checkOutPrice.includes('.')) // Ensure only one dot is entered
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.checkOutPrice && <p className="color-red mt-5 mb-5 font-14 pb-0">{errors.checkOutPrice}</p>}

                      {showSecondPrice && (
                        <p className="opacity-white font-12 mt-5 letter-space">
                          {currency.symbol}
                          {convertCurrency(RoundedPrice(checkOutPrice))}
                        </p>
                      )}
                      <div className="text-div">
                        <p className="font-500 font-14">
                          Listed price {defaultCurrency && defaultCurrency.symbol}
                          {checkOutPrice ? RoundedPrice(checkOutPrice) : '0'}
                        </p>
                        <p className="mt-4 font-500 font-14">
                          Your earnings {defaultCurrency && defaultCurrency.symbol}
                          {RoundedPrice(buyEarningPrice)}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            <div className="offer-button-div">
              <button
                aria-label="Close Modal"
                aria-labelledby="close-modal"
                className="green-button"
                onClick={onSavePrice}
                disabled={Object.values(errors).some((error) => error !== '')}
              >
                Save
              </button>
              <button aria-label="Close Modal" aria-labelledby="close-modal" className="grey-button" onClick={closeModal}>
                Cancel
              </button>
              <button aria-label="Close Modal" aria-labelledby="close-modal" className="delete-button" onClick={onDeleteModal}>
                Delete
              </button>
            </div>
          </div>
        </div>

        {deleteModal && (
          <div className="sell-modal-area inner-listing-modal">
            <div className="edit-listing-popup position-relative">
              <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={deleteModalClose}>
                <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
              </button>
              <p className="offer-heading">Delete listing</p>
              <div className="mt-20 border-top">
                <p className="opacity-white pt-20">Are you sure you want to delete this listing? This action cannot be undone.</p>
              </div>
              <div className="offer-button-div">
                <button
                  aria-label="Close Modal"
                  aria-labelledby="close-modal"
                  className="delete-button"
                  onClick={() => onDelete(item?.m_id)}
                >
                  Delete
                </button>
                <button aria-label="Close Modal" aria-labelledby="close-modal" className="grey-button" onClick={deleteModalClose}>
                  cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </aside>
    </>
  );
};

EditListing.propTypes = {
  closeModal: PropTypes.func,
  item: PropTypes.object,
  deleteModal: PropTypes.bool,
  setDeleteModal: PropTypes.func,
  onDelete: PropTypes.func,
  setEditListingModal: PropTypes.func,
  fetchData: PropTypes.func,
  id: PropTypes.any,
  deleteModalClose: PropTypes.func
};

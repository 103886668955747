import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import {
  setBearerToken,
  setCommissionRate,
  setCurrencyRate,
  setNotificationCount,
  setUserBlockDetails,
  setUserData
} from 'reduxs/userSlice';

import { useApi } from 'hooks/useApi';
import {
  axiosAuthInstance,
  buy_sell_count,
  commissionRate,
  currency_rate,
  ischeck_chkout_intry,
  login,
  notification_list,
  token
} from 'api';
import { useLocation, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import { setBuyFilterCount } from 'reduxs/BuySlice';
import Cookies from 'js-cookie';

export const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const steamToken = urlSearchParams.get('steam_token') || Cookies.get('Bearer_Token');

  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));

  useEffect(() => {
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${steamToken}`;
  }, [steamToken]);

  const { apiCaller: onLogin } = useApi({
    dataGetter: login
  });

  const { apiCaller: onToken } = useApi({
    dataGetter: token
  });

  const { data } = useApi({
    dataGetter: commissionRate,
    autoRetrieve: true
  });

  const { data: currenciesData } = useApi({
    dataGetter: currency_rate,
    autoRetrieve: true
  });

  const { data: notificationData } = useApi({
    dataGetter: notification_list,
    autoRetrieve: true
  });

  const { apiCaller: isCheckChkoutIntry } = useApi({
    dataGetter: ischeck_chkout_intry
  });

  const { apiCaller: BuySellAllFilterCount } = useApi({
    dataGetter: buy_sell_count
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await BuySellAllFilterCount();

  //       if (res?.data?.data?.token_check?.token === false) {
  //         Cookies.remove('Bearer_Token', { secure: true, sameSite: 'strict' });
  //         Cookies.remove('ustid', { secure: true, sameSite: 'strict' });
  //         Cookies.remove('ukey', { secure: true, sameSite: 'strict' });
  //         Cookies.remove('login_time', { secure: true, sameSite: 'strict' });
  //         dispatch(setBearerToken(null));
  //         navigate('/');
  //         axiosAuthInstance.defaults.headers.common.Authorization = `Bearer null`;
  //       }
  //       dispatch(setBuyFilterCount(res.data.data));
  //       dispatch(setUserBlockDetails(res.data.data.user_block_details));
  //     } catch (error) {
  //       console.error(error.message);
  //     }
  //   };
  //   // Call the API immediately when the component mounts
  //   if (steamToken !== null || steamToken !== undefined || steamToken !== '') {
  //     console.log(steamToken, 'steamToken');
  //     fetchData();
  //   }
  //   // Set up an interval to call the API every 1 minute
  //   const intervalId = setInterval(fetchData, 60000); // 60000 milliseconds = 1 minute
  //   // Clean up the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, [isCheckChkoutIntry, BuySellAllFilterCount, dispatch, steamToken]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if user is logged in
        const isLoggedIn = Cookies.get('Bearer_Token') !== undefined;
        if (!isLoggedIn) {
          // Redirect to login page or perform any other action
          return;
        }
        const res = await BuySellAllFilterCount();
        dispatch(setBuyFilterCount(res.data.data));
        dispatch(setUserBlockDetails(res.data.data.user_block_details));
      } catch (error) {
        console.error(error.message);
      }
    };
    // Call the API immediately when the component mounts
    if (steamToken !== null || steamToken !== undefined || steamToken !== '') {
      fetchData();
    }
    // Set up an interval to call the API every 1 minute
    const intervalId = setInterval(fetchData, 60000); // 60000 milliseconds = 1 minute
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [isCheckChkoutIntry, BuySellAllFilterCount, dispatch, steamToken]);

  useEffect(() => {
    if (notificationData) {
      const notifictionCount = notificationData?.not_seen;
      dispatch(setNotificationCount(notifictionCount));
    }
  }, [notificationData, dispatch]);

  useEffect(() => {
    if (currenciesData && currenciesData.rates) {
      const exchangeRates = currenciesData.rates;
      let rate;
      if (currency && currency.code && exchangeRates[currency.code]) {
        rate = exchangeRates[currency.code];
      } else if (defaultCurrency && defaultCurrency.code && exchangeRates[defaultCurrency.code]) {
        rate = exchangeRates[defaultCurrency.code];
      }
      if (rate !== undefined) {
        dispatch(setCurrencyRate(exchangeRates));
        Cookies.set('c_r', rate);
      }
    }
  }, [currenciesData, defaultCurrency, currency, dispatch]);

  useEffect(() => {
    if (data && data.rate) {
      dispatch(setCommissionRate(data.rate));
      Cookies.set('rate', data.rate, { expires: 365, secure: true, sameSite: 'none' });
    }
  }, [data, dispatch]);

  useEffect(() => {
    const checkToken = async () => {
      try {
        if (steamToken !== null && steamToken !== undefined && steamToken !== '') {
          const res = await onToken();
          if (res?.data?.token === false) {
            navigate('/');
            Cookies.remove('Bearer_Token', { secure: true, sameSite: 'strict' });
            Cookies.remove('ustid', { secure: true, sameSite: 'strict' });
            Cookies.remove('ukey', { secure: true, sameSite: 'strict' });
            Cookies.remove('login_time', { secure: true, sameSite: 'strict' });
            dispatch(setBearerToken(null));
            // axiosAuthInstance.defaults.headers.common.Authorization = `Bearer null`;
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    if (steamToken !== null && steamToken !== undefined && steamToken !== '') {
      checkToken();
    }
    const intervalId = setInterval(checkToken, 60000); // 60000 milliseconds = 1 minute
    return () => clearInterval(intervalId);
  }, [steamToken, dispatch, navigate, onToken]);

  useEffect(() => {
    const handleLogin = async () => {
      try {
        if (steamToken !== null && steamToken !== undefined && steamToken !== '') {
          const res = await onLogin();
          if (res.data.status === 'success') {
            if (window.location.pathname === '/inventory') {
              const newSearchParams = new URLSearchParams(search);
              newSearchParams.delete('steam_token');
              navigate(`?${newSearchParams.toString()}`);
            }
            dispatch(setBearerToken(res.data.token));
            dispatch(setUserData(res.data));
            const expires = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            const loginTime = new Date().toISOString();
            Cookies.set('Bearer_Token', res.data.token, { expires, secure: true, sameSite: 'strict' });
            Cookies.set('ustid', res.data.user?.steamid, { expires, secure: true, sameSite: 'strict' });
            Cookies.set('ukey', res.data.api_key, { expires, secure: true, sameSite: 'strict' });
            Cookies.set('login_time', loginTime, { expires, secure: true, sameSite: 'strict' });
            // Cookies.set('Bearer_Token', res.data.token, { expires });
            // Cookies.set('ustid', res.data.user?.steamid, { expires });
            // Cookies.set('ukey', res.data.api_key, { expires });
            // Cookies.set('login_time', loginTime, { expires });
          } else {
            // console.error(res.data.message);
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    handleLogin();
  }, [onLogin, dispatch, steamToken]);
  return <> {children} </>;
};

Auth.propTypes = {
  children: PropTypes.node.isRequired
};

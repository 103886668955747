import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sellCount: 0,
  sellFilterCount: {
    'Offers received': 0,
    'Private listings': 0,
    Selling: 0
  }
};

const SellSlice = createSlice({
  name: 'sell',
  initialState,
  reducers: {
    setSellCount(state, action) {
      state.sellCount = action.payload;
    },
    setSellFilterCountt(state, action) {
      state.sellFilterCount = action.payload;
    }
  }
});

export const { setSellCount, setSellFilterCountt } = SellSlice.actions;
export default SellSlice.reducer;

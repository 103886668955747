import React from 'react';
import PropTypes from 'prop-types';

export const Button = ({ className, children, handleClick }) => {
  return (
    <>
      <button onClick={handleClick} className={className}>
        {children}
      </button>
    </>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string.isRequired,
  handleClick: PropTypes.func
};

import moment from 'moment';

export const notificationTimeAgo = (timestamp) => {
  if (!moment(timestamp, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
    return 'Invalid timestamp';
  }

  const currentTime = moment.utc();
  const previousTime = moment.utc(timestamp, 'YYYY-MM-DD HH:mm:ss');

  const diffDuration = moment.duration(currentTime.diff(previousTime));

  if (diffDuration.asYears() >= 1) {
    return `${Math.floor(diffDuration.asYears())} years ago`;
  } else if (diffDuration.asMonths() >= 1) {
    return `${Math.floor(diffDuration.asMonths())} months ago`;
  } else if (diffDuration.asWeeks() >= 1) {
    return `${Math.floor(diffDuration.asWeeks())} weeks ago`;
  } else if (diffDuration.asDays() >= 1) {
    return `${Math.floor(diffDuration.asDays())} days ago`;
  } else if (diffDuration.asHours() >= 1) {
    return `${Math.floor(diffDuration.asHours())} hours ago`;
  } else if (diffDuration.asMinutes() >= 1) {
    return `${Math.floor(diffDuration.asMinutes())} minutes ago`;
  } else {
    return `${Math.floor(diffDuration.asSeconds())} seconds ago`;
  }
};

export const formatedTime = (timestamp) => {
  if (!moment(timestamp, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
    return 'Invalid timestamp';
  }

  const currentTime = moment.utc();
  const previousTime = moment.utc(timestamp, 'YYYY-MM-DD HH:mm:ss');

  const diffDuration = moment.duration(currentTime.diff(previousTime));

  if (diffDuration.asDays() >= 1) {
    return `${Math.floor(diffDuration.asDays())}d ${Math.floor(diffDuration.asHours() % 24)}h`;
  } else if (diffDuration.asHours() >= 1) {
    return `${Math.floor(diffDuration.asHours())}h ${Math.floor(diffDuration.asMinutes() % 60)}m`;
  } else {
    return `${Math.floor(diffDuration.asMinutes())}m`;
  }
};

export const calculateRemoveOfferTimeRemaining = (created) => {
  const createdDateTime = moment.utc(created);
  const endTime = createdDateTime.add(29, 'minutes').valueOf(); // Calculate 29 minutes after the created date

  const currentTime = moment.utc().valueOf(); // Get current time in UTC
  const timeDiff = endTime - currentTime;

  if (timeDiff > 0) {
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return { minutes, seconds };
  } else {
    return { minutes: 0, seconds: 0 };
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
export const WarningModal = ({ onWarningModalClose, setAuctionTerms, AuctionTerms, onwarrning }) => {
  return (
    <>
      <div className="zipit-main-dialog">
        <div className="zipit-dialog offer-popup position-relative">
          <div className="offer-heading">Read carefully</div>
          <div type="button">
            <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={onWarningModalClose}>
              <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
            </button>
          </div>
          <div className="zipit-dialog">
            <div className="alert-box">
              <p>Read this before you continue</p>
              <div className="rbyb-points">
                <ul>
                  <li>Auctions with bids cannot be unlisted</li>
                  <li>
                    You are responsible for having the item in your inventory when the auction ends and it has bids. It is recommended not
                    to list it on multiple platforms because of this
                  </li>
                  <li> Not trading sold items will result in a ban</li>
                  <li> Scraping stickers on listed items will result in a ban</li>
                </ul>
                <label className="sell-label">
                  I have read and understand
                  <input type="checkbox" name="term" onChange={(e) => setAuctionTerms(e.target.checked)} />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="offer-button-div">
              <button className="green-button" disabled={!AuctionTerms} onClick={onwarrning}>
                Continue
              </button>
              <button className="grey-button" onClick={onWarningModalClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

WarningModal.propTypes = {
  onWarningModalClose: PropTypes.func,
  AuctionTerms: PropTypes.bool,
  setAuctionTerms: PropTypes.func,
  onwarrning: PropTypes.func
};

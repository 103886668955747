import React, { useEffect, useState } from 'react';
import { page_template } from 'api';
import { useApi } from 'hooks/useApi';
import { SettingSideBar } from '../components/SettingSideBar';
import Cookies from 'js-cookie';

export const Support = () => {
  document.title = 'Zipit - Support';
  const Bearer_Token = Cookies.get('Bearer_Token');
  const [data, setData] = useState();
  const { apiCaller: onTermsDataGet } = useApi({
    dataGetter: page_template
  });

  const fetchData = () => {
    let params = {
      pages: 'Support'
    };
    onTermsDataGet(params)
      .then((res) => {
        setData(res.data.item);
      })
      .catch((error) => console.error(error.message));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={Bearer_Token ? 'profile-page-main-div' : 'support_page'}>
        <div className="container-fluid">
          <div className="container">
            <div className="profile-page-inner-div">
              {Bearer_Token && <SettingSideBar />}
              <div className="support-main-div profile-page-common-div">
                {Bearer_Token && (
                  <div className="profile-page-heading">
                    <p>Support</p>
                  </div>
                )}
                <div className={'common-page-inner-div'}>
                  <div className="support-div ">
                    <div className=" text-center border-0">
                      <p className="main_heading">{data?.label ? data?.label : 'Support'}</p>
                      <div className="description">
                        <div
                          className="static-page-content green-text-support"
                          dangerouslySetInnerHTML={{ __html: data?.short_description }}
                        />
                      </div>
                    </div>
                    <div className="content-support-div description-text-div max-900">
                      <div dangerouslySetInnerHTML={{ __html: data?.short_title }} />
                      <div className="info-box-main-div">
                        <div className="website-div">
                          <div className="website-image-div">
                            <img src="/images/common/mail.svg" alt="Zipit" height="40" width="40" />
                          </div>
                          <div className="website-text-div">
                            <a href={`mailto:${data?.email}`}>
                              <div className="discord-text">
                                <p>{data?.email}</p>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="website-div">
                          <div className="website-image-div">
                            <img src="/images/discord.svg" alt="Zipit" height="40" width="40" />
                          </div>
                          <div className="website-text-div">
                            <a href={`${data?.descord_link}`} target="blank">
                              <div className="discord-text">
                                <p>{data?.descord_title}</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="static-page-content" dangerouslySetInnerHTML={{ __html: data?.content }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
export const UserBanModal = ({ userBan, setIsUserBanModalOpen, userBanModalClose }) => {
  const formattedReleaseDate = userBan.block_release_date
    ? moment.utc(userBan.block_release_date).local().format('DD/MM/yyyy h:mm:ss A')
    : '';
  // Format user date using moment
  const formattedUserDate = userBan.block_user_date ? moment.utc(userBan.block_user_date).local().format('DD/MM/yyyy h:mm:ss A') : '';
  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal">
      <div className="sell-modal-area offer-modal">
        <div className="position-relative steam-account-popup banned-popup">
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={userBanModalClose}>
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>
          {Number(userBan.block_count) === 5 ? (
            <>
              <p className="pb-20 mb-20 font-700 offer-heading d-flex align-item-center gap-5">
                <img src="/images/common/danger.svg" alt="modal-close" className="img-fluid" />
                {userBan.block_hour !== null && `Banned for ${userBan?.block_hour} `}{' '}
              </p>
              <div className="secure-text border-top pt-20">
                <p className="">
                  You have been banned for {formattedUserDate} You failed to pay your purchase within the required 18 hours. This has
                  resulted in a ban from using the Zipit platform. If you believe this is a mistake, please contact Support immediately.
                  <Link to="/terms" className="blue-color font-700 text-underline">
                    Terms
                  </Link>
                </p>
              </div>
            </>
          ) : (
            <>
              <p className="pb-20 mb-20 font-700 offer-heading d-flex align-item-center gap-5">
                <img src="/images/common/danger.svg" alt="modal-close" className="img-fluid" />
                {userBan.block_hour !== null && `Banned for ${userBan?.block_hour} hours `}{' '}
              </p>
              <div className="secure-text border-top pt-20 banned-popup-text">
                <p className="">
                  You have been banned for {userBan?.block_hour ? userBan?.block_hour : ''} hours, {formattedUserDate} (CT) You failed to
                  pay your purchase within the required 18 hours. This has resulted in a ban from using the Zipit platform. If you believe
                  this is a mistake, please contact Support immediately.{' '}
                  <Link to="/terms" className="blue-color font-700 text-underline">
                    Terms
                  </Link>
                </p>
                <p className="pt-10">
                  <span className="yellow-text">You have {5 - userBan?.block_count} strikes </span> left before your account will be
                  permanently suspended.
                </p>
                <p className="pb-10 pt-10">{userBan.block_date !== null ? 'Reviewed: ' + formattedUserDate : ''}</p>
                <p className=" pt-10">
                  Your account has been banned. The suspension will automatically be lifted on {formattedUserDate} (CT)
                </p>
                <p className="pb-0">
                  {userBan.block_date !== null
                    ? `Your account has been banned. You may re-activate it after ${formattedReleaseDate} (CT)`
                    : ''}
                </p>
              </div>
            </>
          )}

          <div className="support-button-div mt-20">
            <Link to="/support" className="blue-button support-button width-100">
              support
            </Link>
          </div>
        </div>
      </div>
    </aside>
  );
};

UserBanModal.propTypes = {
  setIsUserBanModalOpen: PropTypes.func,
  userBan: PropTypes.object,
  userBanModalClose: PropTypes.func
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export const AlertModal = ({
  alertData,
  closeModal,
  onSaveAlterTime,
  setAlertPrice,
  setDays,
  setHours,
  setMinutes,
  days,
  hours,
  minutes,
  alertPrice
}) => {
  const [timeLeft, setTimeLeft] = useState(false);
  const [error, setError] = useState({});

  const [timeConvertedDays, setTimeConvertedDays] = useState();
  const [timeConvertedHours, setTimeConvertedHours] = useState();
  const [timeConvertedMin, setTimeConvertedMin] = useState();

  const [priceCheckBox, setPriceCheckBox] = useState(false);

  const maxBid = alertData?.max_bid[0];

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (!alertData || !alertData.runtime || !alertData.cread_date) return;

      const daysCount = parseInt(alertData.runtime);
      const createdDate = alertData.cread_date;
      const targetDate = moment.utc(createdDate).add(daysCount, 'days');
      const difference = targetDate.diff(moment.utc(), 'milliseconds');

      if (difference > 0) {
        const duration = moment.duration(difference);
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        setTimeConvertedDays(days);
        setTimeConvertedHours(hours);
        setTimeConvertedMin(minutes);
      }
    };

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    calculateTimeLeft();

    return () => clearInterval(timer);
  }, [alertData]);

  const handleDaysChange = (event) => {
    let value = parseInt(event.target.value);
    if (value < 0 || value > timeConvertedDays) {
      setError({ time: `Days must be between 0 and ${timeConvertedDays}` });
    } else {
      setError({ time: '' });
    }
    setDays(value);
  };
  const handleHoursChange = (event) => {
    const value = parseInt(event.target.value);
    if (value < 0 || value > timeConvertedHours) {
      setError({ time: `Hours must be between 0 and ${hours}` });
    } else {
      setError({ time: '' });
    }
    setHours(value);
  };

  const handleMinutesChange = (event) => {
    const value = parseInt(event.target.value);

    if (value < 0 || timeConvertedMin <= value) {
      setError({ time: `Minutes must be between 0 and ${minutes}` });
    } else {
      setError({ time: '' });
    }
    setMinutes(value);
  };

  const onPriceSetForAlert = (e) => {
    const { value } = e.target;
    if (maxBid !== undefined && maxBid !== null) {
      if (Number(maxBid.max_bidprice) > Number(value)) {
        setError({ price: `Price cannot be more than ${maxBid.max_bidprice}` });
      } else {
        setError({ price: '' });
      }
    }
    setAlertPrice(Number(value));
  };
  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="edit-price-modal sell-model">
      <div className="sell-modal-area edit-listing-modal">
        <div className="edit-listing-popup position-relative">
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>
          <p className="offer-heading">Edit Alerts</p>
          <div className="amount-offer-div edit-alert-div">
            <label className="sell-label ">
              <input type="checkbox" onChange={(e) => setTimeLeft(e.target.checked)} checked={timeLeft} />
              <span className="checkmark l-0"></span>Time Left
            </label>
            {timeLeft && (
              <div className="input-outer-div">
                <>
                  <div className="alter-time-div">
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={days}
                      onChange={handleDaysChange}
                      className={error.time ? 'invalid-input' : 'valid-input'}
                      min={0}
                      max={7}
                      onKeyDown={(e) => {
                        if (
                          (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                            e.key !== 'Backspace' &&
                            e.key !== 'Delete' &&
                            e.key !== 'ArrowLeft' &&
                            e.key !== 'ArrowRight' &&
                            e.key !== '.') || // Allow dot key
                          (e.key === '.' && days.includes('.')) // Ensure only one dot is entered
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <label>Days:</label>
                  </div>
                  <div className="alter-time-dot">:</div>
                  <div className="alter-time-div">
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={hours}
                      onChange={handleHoursChange}
                      className={error.time ? 'invalid-input' : 'valid-input'}
                      min={0}
                      max={23}
                      onKeyDown={(e) => {
                        if (
                          (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                            e.key !== 'Backspace' &&
                            e.key !== 'Delete' &&
                            e.key !== 'ArrowLeft' &&
                            e.key !== 'ArrowRight' &&
                            e.key !== '.') || // Allow dot key
                          (e.key === '.' && hours.includes('.')) // Ensure only one dot is entered
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <label>Hours:</label>
                  </div>
                  <div className="alter-time-dot">:</div>
                  <div className="alter-time-div">
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={minutes}
                      onChange={handleMinutesChange}
                      className={error.time ? 'invalid-input' : 'valid-input'}
                      min={0}
                      max={59}
                      onKeyDown={(e) => {
                        if (
                          (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                            e.key !== 'Backspace' &&
                            e.key !== 'Delete' &&
                            e.key !== 'ArrowLeft' &&
                            e.key !== 'ArrowRight' &&
                            e.key !== '.') || // Allow dot key
                          (e.key === '.' && minutes.includes('.')) // Ensure only one dot is entered
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <label>Minutes:</label>
                  </div>
                </>
              </div>
            )}
            <div className="border-top price-alert-div">
              <label className="sell-label ">
                <input
                  type="checkbox"
                  onChange={(e) => setPriceCheckBox(e.target.checked)}
                  checked={priceCheckBox}
                  disabled={(days === 0 && hours === 0 && minutes === 0) || error.time}
                />
                <span className="checkmark l-0"></span>Price
              </label>

              {priceCheckBox && (
                <>
                  <div className="price-alert-input">
                    <p className="text-center pb-5">Does not reach</p>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onChange={(e) => onPriceSetForAlert(e)}
                      className={error.price ? 'invalid-input' : 'valid-input'}
                      onKeyDown={(e) => {
                        if (
                          (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                            e.key !== 'Backspace' &&
                            e.key !== 'Delete' &&
                            e.key !== 'ArrowLeft' &&
                            e.key !== 'ArrowRight' &&
                            e.key !== '.') || // Allow dot key
                          (e.key === '.' && alertPrice.includes('.')) // Ensure only one dot is entered
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="offer-button-div">
            <button className="grey-button" onClick={closeModal}>
              cancel
            </button>
            <button className="green-button" onClick={onSaveAlterTime} disabled={error.time || error.price}>
              save
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};

AlertModal.propTypes = {
  alertData: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveAlterTime: PropTypes.func.isRequired,
  setAlertPrice: PropTypes.any,
  alertPrice: PropTypes.number,
  setDays: PropTypes.any,
  setHours: PropTypes.any,
  setMinutes: PropTypes.any,
  days: PropTypes.any,
  hours: PropTypes.any,
  minutes: PropTypes.any
};

import React from 'react';
import Slider from 'react-slick';

import './style.css';
import { SkeletonLoader, WishlistIcon } from 'components';
import PropTypes from 'prop-types';
import { convertCurrency, itemNameConverter, wearNameConverter } from 'lib';
import { useNavigate } from 'react-router-dom';

export const SimilarProduct = ({
  similarProduct,
  onCheckoutModal,
  isLoading,
  onBidAdd,
  loadingIndex,
  isLoadingBid,
  followHandle,
  follow,
  successMes,
  isLoadingProduct
}) => {
  // if (similarProduct.length <= 3) {
  //   settings.swipeToSlide = false;
  //   settings.draggable = false;
  //   settings.slidesToScroll = similarProduct.length;
  // } else {
  //   settings.swipeToSlide = true;
  //   settings.draggable = true;
  //   settings.slidesToScroll = 1;
  // }

  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: similarProduct.length > 4 ? 1 : 0,
    swipeToSlide: similarProduct.length > 4 ? true : false,
    draggable: similarProduct.length > 4 ? true : false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1, // Scroll 3 slides at a time on screens >= 1024px
          swipeToSlide: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1, // Scroll 2 slides at a time on screens between 768px and 1023px
          swipeToSlide: true
        }
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1 // Scroll 1 slide at a time on screens <= 767px
        }
      }
    ]
  };

  const navigate = useNavigate();

  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
  const showSecondPrice = currency && currency.code !== defaultCurrency.code;

  return (
    <>
      {similarProduct.length > 0 && (
        <div className={`similar-product-div`}>
          <div className="container-fluid">
            <div className="container">
              <div className="similar-product-inner-div">
                <div className="similar-product-heading text-center">
                  <p className="sub-heading text-uppercase text-white pb-0">Similar skins</p>
                </div>
                <div
                  className={`similar-product-slider slider-container
                    ${similarProduct.length > 3 ? '' : 'similar-product-slider-container'}`}
                >
                  {isLoadingProduct ? (
                    <Slider {...settings}>
                      {[...Array(4)].map((_, index) => (
                        <div className="single-similar-product" key={index}>
                          <div className="similar-product-image-div">
                            <div className="similar-product-image skeleton-product-image">
                              <SkeletonLoader
                                width="106%"
                                height={200}
                                count={1}
                                color="#0b051b"
                                highlightColor="#242347"
                                borderRadius={0}
                              />
                            </div>
                          </div>
                          <div className="similar-product-text-div pt-10">
                            <h3 className="pb-10 mb-10 text-uppercase text-white">
                              <SkeletonLoader width="99%" height={20} count={3} color="#0b051b" highlightColor="#242347" borderRadius={0} />
                            </h3>
                          </div>
                          <div className="product-purchase-div">
                            <div className={`wishlist-icon tooltip-image ml-0`}>
                              <SkeletonLoader width={35} height={30} count={1} color="#0b051b" highlightColor="#242347" borderRadius={0} />
                            </div>
                            <div className={`button-link p-0 skeleton-button`}>
                              <SkeletonLoader width="99%" height={30} count={1} color="#0b051b" highlightColor="#242347" borderRadius={0} />
                            </div>
                            <div className={`button-link p-0 skeleton-button`}>
                              <SkeletonLoader width="99%" height={30} count={1} color="#0b051b" highlightColor="#242347" borderRadius={0} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : similarProduct.length > 0 ? (
                    <Slider {...settings}>
                      {similarProduct?.map((item, index) => {
                        const isFollowed = follow.find((f) => {
                          return f.market_id === item?.m_id && f.status;
                        });
                        // const following_status = isFollowed ? true : item?.following_status;
                        return (
                          <React.Fragment key={index}>
                            <div className="single-similar-product" key={index}>
                              <div
                                className="similar-product-image-div"
                                style={{
                                  borderBottom: `5px solid ${item?.item?.r_color}`
                                }}
                              >
                                <div className="product-wear-name">
                                  <p className="font-14">{wearNameConverter(item.wear)}</p>
                                  <p className="font-14">{item && item?.wear !== 'N/A' ? `/ ${Number(item.float_id).toFixed(4)} ` : ''}</p>
                                </div>
                                <div
                                  className="similar-product-image"
                                  // onClick={() => navigate(`/market/${item.m_id}`)}
                                  // onClick={isSwiping ? null : () => navigate(`/market/${item.m_id}`)}
                                >
                                  <img
                                    src={`${item && process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${item && item.item.imageUrl}`}
                                    alt="zipit"
                                    className="img-fluid similar-product-img"
                                  />
                                </div>
                                {item?.item?.stickers && item?.item?.stickers.length > 0 && (
                                  <div className="similar-product-sticker">
                                    {item.item &&
                                      item?.item?.stickers.map((item, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            {/* <div dangerouslySetInnerHTML={{ __html: item.value }} /> */}
                                            {/* <img src={item.value} alt="sticker" title={item.key} /> */}
                                            <div className="single-sticker-div">
                                              <img src={item.value} alt="sticker" />
                                              {item.key && (
                                                <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                                  <span>{item.key}</span>
                                                </div>
                                              )}
                                            </div>
                                          </React.Fragment>
                                        );
                                      })}
                                  </div>
                                )}
                              </div>
                              <div className="similar-product-text-div">
                                <h3
                                  className="sub-heading text-uppercase text-white"
                                  // onClick={() => navigate(`/market/${item.m_id}`)}
                                  // onClick={isSwiping ? null : () => navigate(`/market/${item.m_id}`)}
                                >
                                  {itemNameConverter(item?.item?.fullname)}
                                </h3>
                                <p className="font-14 opacity-white pb-0">
                                  {item.category} • {item?.item?.subCategory}
                                </p>
                                <div className="font-18 product-price">
                                  {showSecondPrice ? (
                                    item.selltype === 'auction' ? (
                                      <>
                                        {currency && currency.symbol} {convertCurrency(Number(item.nextbid))}
                                      </>
                                    ) : item.selltype === 'market' ? (
                                      <>
                                        {currency && currency.symbol} {convertCurrency(Number(item.startBid))}
                                      </>
                                    ) : null
                                  ) : item.selltype === 'market' ? (
                                    <>
                                      {defaultCurrency && defaultCurrency.symbol} {item.startBid}
                                    </>
                                  ) : item.selltype === 'auction' ? (
                                    <>
                                      {defaultCurrency && defaultCurrency.symbol} {convertCurrency(Number(item.nextbid))}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="product-purchase-div">
                              <div
                                className={`wishlist-icon tooltip-image ml-0 ${isFollowed ? 'follow-active' : 'unfollow'}`}
                                onClick={() => followHandle(item.m_id)}
                              >
                                <WishlistIcon />
                                <div className="tooltiptext">
                                  {isFollowed ? (
                                    <>
                                      <p className="font-14 pb-0">Unfollow</p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="font-14 pb-0">Follow</p>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="white-border-button button-link " onClick={() => navigate(`/market/${item.m_id}`)}>
                                View
                              </div>

                              {item && item.selltype === 'market' ? (
                                <>
                                  <div className="buy-now-main-button">
                                    {isLoading && loadingIndex === index && (
                                      <img src="/images/common/loader.gif" alt="loader" width={20} height={20} className="buy-now-loader" />
                                    )}
                                    <div
                                      className={`buy-now-button button-link
                                        ${isLoading && loadingIndex === index && 'button-disabled'}`}
                                      onClick={() => onCheckoutModal(item, index)}
                                    >
                                      Buy now
                                    </div>
                                  </div>
                                </>
                              ) : loadingIndex === index && successMes?.type === 'success' ? (
                                <div className="success-button buy-now-button button-link">
                                  <img src="/images/common/success.svg" alt="Success" className="success-image" width={30} height={30} />
                                </div>
                              ) : (
                                <>
                                  <div className="buy-now-main-button">
                                    {isLoadingBid && loadingIndex === index && (
                                      <img src="/images/common/loader.gif" alt="loader" width={20} height={20} className="buy-now-loader" />
                                      // <img src="/images/common/loader.gif" alt="loader" width={20} height={20} />
                                    )}
                                    <div
                                      className={`buy-now-button button-link
                                        ${isLoadingBid && loadingIndex === index && 'button-disabled'}`}
                                      onClick={() => onBidAdd(item, index)}
                                    >
                                      Bid
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </Slider>
                  ) : (
                    <div className="no-similar-product opacity-white">No Have Similar </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SimilarProduct.propTypes = {
  similarProduct: PropTypes.array,
  onCheckoutModal: PropTypes.func,
  isLoading: PropTypes.bool,
  onBidAdd: PropTypes.func,
  loadingIndex: PropTypes.any,
  isLoadingBid: PropTypes.bool,
  followHandle: PropTypes.func,
  follow: PropTypes.any,
  successMes: PropTypes.object,
  isLoadingProduct: PropTypes.bool
};

import { transection_list_view } from 'api';
import { SettingSideBar } from 'components';
import { TransctionModal } from 'components';
import { useApi } from 'hooks/useApi';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export const Transactions = () => {
  document.title = 'Zipit - Transactions';
  const [transction, setTransction] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [visible, setVisible] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [historyTransaction, setHistoryTransaction] = useState();

  const closeModalRef = useRef(null);
  const location = useLocation();
  const defaultCurrency = localStorage.getItem('defaultCurrency');

  const onTransction = () => {
    document.body.classList.add('modal-open');
    setTransction(true);
  };

  const closeModal = () => {
    document.body.classList.remove('modal-open');
    setTransction(false);
  };

  const { apiCaller: transactionHistory } = useApi({
    dataGetter: transection_list_view
  });

  const fetchData = useCallback(
    async (page) => {
      try {
        // setIsLoading(true);
        let params = {
          page
        };

        const res = await transactionHistory(params);
        if (res.data.data.transection_data.last_page > page) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        setHistoryTransaction(res.data.data);
        const newData = res.data.data.transection_data.data.filter(
          (item, index, self) => index === self.findIndex((i) => i.transection_id === item.transection_id)
        );

        setTransactionData((prev) => {
          const updatedData = page === 1 ? newData : [...prev, ...newData];
          const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

          const newVisible = uniqueArray.length;
          setVisible(newVisible);
          return uniqueArray;
        });
        setRowCount(res.data.data?.transection_data?.total);
      } catch (error) {
        toast.error(error.message);
      } finally {
        // setTimeout(() => {
        //   setIsLoading(false);
        // }, 1000);
      }
    },
    [transactionHistory]
  );

  // on page reload to fetch data using useEffect
  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, location.search, currentPage]);

  const fetchMoreOnBottomReached = () => {
    if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
      if (visible < rowCount) {
        setCurrentPage(currentPage + 1);
        // fetchData(selectedCategory, currentPage + 1, search);
      }
    }
  };

  useEffect(() => {
    visible < rowCount && rowCount > 0
      ? window.addEventListener('scroll', fetchMoreOnBottomReached)
      : window.removeEventListener('scroll', fetchMoreOnBottomReached);
    // Attach scroll event listener when the component mounts
    window.addEventListener('scroll', fetchMoreOnBottomReached);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', fetchMoreOnBottomReached);
    };
  }, [visible]);

  function formatDate(dateString) {
    const options = {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric'
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }
  return (
    <>
      <div className="profile-page-main-div">
        <div className="container-fluid">
          <div className="container">
            <div className="profile-page-inner-div">
              <SettingSideBar />
              <div className="deposit-main-div profile-page-common-div position-relative">
                <div className="profile-page-heading history-heading p-0">
                  <p>Transactions</p>
                </div>
                <div className="transction-box">
                  <div className="single-tranction-box">
                    <p className="opacity-white pb-10">Ready to Use</p>
                    <p className="font-20 pb-0">
                      {defaultCurrency && defaultCurrency.symbol}
                      {historyTransaction && Number(historyTransaction.ready_to_use).toFixed(2)}
                    </p>
                  </div>
                  <div className="single-tranction-box">
                    <p className="opacity-white pb-10">Pending balance</p>
                    <p className="font-20 pb-0">
                      {defaultCurrency && defaultCurrency.symbol}
                      {historyTransaction && Number(historyTransaction.pending_balance).toFixed(2)}
                    </p>
                  </div>
                  <div className="single-tranction-box">
                    <p className="opacity-white pb-10">Total sales</p>
                    <p className="font-20 pb-0">
                      {defaultCurrency && defaultCurrency.symbol}
                      {historyTransaction && Number(historyTransaction.total_sales).toFixed(2)}
                    </p>
                  </div>
                  <div className="single-tranction-box">
                    <p className="opacity-white pb-10">Total purchases</p>
                    <p className="font-20 pb-0">
                      {defaultCurrency && defaultCurrency.symbol}
                      {historyTransaction && Number(historyTransaction.total_purchases).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="transction-history-div">
                  <div className="transction-left-div">
                    <div className="transction-left-inner-div">
                      <div className="history-heading">
                        <p className="font-20">Transactions History</p>
                        <div className="view-policy" onClick={onTransction}>
                          View policies
                        </div>
                      </div>
                      <div className="transction-table-main-div">
                        <div className="table-head transction-row">
                          <div className="transction-data-left-div">
                            <div className="single-transction-div">
                              <p>Item</p>
                            </div>
                            <div className="single-transction-div common-sell">
                              <p>Date/time</p>
                            </div>
                            <div className="single-transction-div common-sell">
                              <p>Amount</p>
                            </div>
                          </div>
                          <div className="transction-data-right-div">
                            <div className="single-transction-div">
                              <p>Status</p>
                            </div>
                          </div>
                        </div>
                        {transactionData &&
                          transactionData?.length > 0 &&
                          transactionData?.map((item, index) => {
                            const items = item?.transection_name?.split(',');
                            return (
                              <div className="table-body transction-row" key={index}>
                                <div className="transction-data-left-div">
                                  <div className="single-transction-div">
                                    <p className="transction-product-name">
                                      {item && item !== undefined && items && items.length > 0 && items[0]}
                                    </p>
                                    {item && item.status !== 'deposit' && item && items[1] && (
                                      <p className="opacity-white font-12">
                                        {item && items[1]} * {item && items[2]}
                                      </p>
                                    )}
                                  </div>
                                  <div className="single-transction-div">
                                    <p>{item && formatDate(item.transection_date)}</p>
                                  </div>
                                  <div className="single-transction-div">
                                    <p>
                                      {defaultCurrency && defaultCurrency.symbol}
                                      {item && item.amount}
                                    </p>
                                  </div>
                                </div>
                                <div className="transction-data-right-div">
                                  <div className={`single-transction-div gap-5`}>
                                    <div className={`status gap-5 d-flex ${item.status === 'deposit' ? 'text-red ' : 'text-green'}`}>
                                      {item.status === 'debited' ? (
                                        <img src="/images/common/red-minus.svg" alt="Zipit" height="20" width="20" />
                                      ) : (
                                        <img src="/images/common/green-plus.svg" alt="Zipit" height="20" width="20" />
                                      )}
                                      {item && item.status}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                {transction && <TransctionModal closeModal={closeModal} closeModalRef={closeModalRef} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

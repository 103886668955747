import React, { useCallback, useEffect, useRef, useState } from 'react';
import { goods_market, market_sidebar, mrkt_goods_category } from 'api/market';
import { SideFilter, MarketSkin, TopFilter, MobileTopFilter } from 'components/Market';
import { useApi } from 'hooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSideBarFilterFade, setSideBarFilterPrice } from 'reduxs/marketSlice';

export const Market = () => {
  document.title = 'Zipit - Market';

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const price = useSelector((state) => state.market.filterPrice);
  const fadeArray = useSelector((state) => state.market.filterFade);
  const itemsPerPage = 25;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const mobileRef = useRef(null);
  const [isMobileCateOpen, setIsMobileCateOpen] = useState(false);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState();
  const [subSelectedCategory, setSubSelectedCategory] = useState();
  const [sellType, setSellType] = useState('all');
  const [priceRange, setPriceRange] = useState([0, 0]);
  const [selectedWears, setSelectedWears] = useState([]);
  const [selectedRarePattern, setSelectedRarerPattern] = useState([]);
  const [fadeRange, setFadeRange] = useState([0, 0]);

  const [sortOrderFilter, setSortOrderFilter] = useState('date_desc');
  const [popular, setPopular] = useState(false);
  const [patternIds, setPatternIds] = useState('');
  const [patternIdsArray, setPatternIdsArray] = useState([]);
  const [selectedPhase, setSelecedPhase] = useState([]);
  const [selectedChildCategory, setSelectedChildCategory] = useState([]);
  const [searchStickers, setSearchStickers] = useState('');

  const [status, setStatus] = useState(false);

  const [visible, setVisible] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [market, setMarket] = useState([]);
  const [itemTotal, setItemTotal] = useState(1);

  const [filterWear, setFilterWear] = useState([]);
  const [filterPrice, setFilterPrice] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [filterPopular, setFilterPopular] = useState(false);
  const [filterPatternId, setFilterPatternId] = useState([]);
  const [filterPhase, setFilterPhase] = useState([]);
  const [filterChildCategory, setFilterChildCategory] = useState([]);
  const [filterRarePattern, setFilterRarePattern] = useState([]);
  const [filterStickers, setFilterStickers] = useState('');
  const [filterFade, setFilterFade] = useState([]);
  const [selectedFade, setSelectedFade] = useState([]);

  const [resetFilters, setResetFilters] = useState(0);

  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [menuText, setMenuText] = useState();

  const marketSortOrder = [
    // { value: 'popularity', label: 'Highest Discount' },
    { value: 'price_asc', label: 'Lowest Price' },
    { value: 'price_desc', label: 'Highest Price' },
    { value: 'date_desc', label: 'Newest' },
    { value: 'date_asc', label: 'Oldest' },
    { value: 'wear_asc', label: 'Lowest Wear' },
    { value: 'wear_desc', label: 'Highest Wear' }
  ];

  const RarePattern = [
    { value: 'low-float', name: 'Low Float' },
    { value: 'high-fade', name: 'High Fade' },
    { value: 'fire-ice', name: 'Fire & Ice' },
    { value: 'blue-gem', name: 'Blue Gem' }
  ];

  const AuctionsortOrder = [
    { value: 'time_left', label: 'Ending Soon' },
    { value: 'bid_desc', label: 'Most Bids' },
    { value: 'price_desc', label: 'Highest Price' },
    { value: 'price_asc', label: 'Lowest Price' },
    { value: 'date_desc', label: 'Newest' },
    { value: 'wear_asc', label: 'Lowest Wear' },
    { value: 'wear_desc', label: 'Highest Wear' }
  ];

  const PhaseArray = ['Phase 1', 'Phase 2', 'Phase 3', 'Phase 4', 'Emerald', 'Sapphire', 'Ruby', 'Black Pearl'];

  const { loading: isCateLoading, data: categories } = useApi({
    dataGetter: mrkt_goods_category,
    autoRetrieve: true
  });

  const { data: sideBarOptions, apiCaller: onSideBarOptions } = useApi({
    dataGetter: market_sidebar
    // autoRetrieve: true
  });

  const { loading, apiCaller: goodMarket } = useApi({
    dataGetter: goods_market
  });

  useEffect(() => {
    const fetchData = async () => {
      const res = await onSideBarOptions();
      const price = res.data.price;
      setPriceRange([0, price?.max_price]);
      dispatch(setSideBarFilterPrice([0, price?.max_price]));

      const fade = res.data.fade;
      setFadeRange([fade?.fadestart, fade?.fadeend]);
      dispatch(setSideBarFilterFade([fade?.fadestart, fade?.fadeend]));
    };
    fetchData();
  }, []);

  const handleWearCheck = (tag) => {
    const updatedWears = selectedWears.includes(tag) ? selectedWears.filter((wear) => wear !== tag) : [...selectedWears, tag];
    setSelectedWears(updatedWears);
  };

  const handleCategoryCheck = (categoryName, cateid) => {
    const updatedCategory = selectedChildCategory.includes(cateid)
      ? selectedChildCategory.filter((category) => category !== cateid)
      : [...selectedChildCategory, cateid];
    setSelectedChildCategory(updatedCategory);
  };

  const handleRarePatternCheck = (rareName) => {
    const updatedRarerPattern = selectedRarePattern.includes(rareName)
      ? selectedRarePattern.filter((rare) => rare !== rareName)
      : [...selectedRarePattern, rareName];
    setSelectedRarerPattern(updatedRarerPattern);
  };

  const onCategoryFilterRemoveSideFilter = () => {
    setFilterPrice([]);
    setFilterWear([]);
    setSelectedWears([]);
    setPriceRange([0, price[1]]);
    setFilterPatternId([]);
    setPatternIdsArray([]);
    setSortOrderFilter('date_desc');
    setPopular(false);
    setSelectedChildCategory([]);
    setFilterChildCategory([]);
    setFilterStickers('');
    setSearchStickers('');
    setSelectedRarerPattern([]);
    setFilterFade([]);
    setFadeRange([fadeArray[0], fadeArray[1]]);
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(mobileRef.current.clientWidth <= 992);
  //   };

  //   const resizeObserver = new ResizeObserver(handleResize);

  //   if (mobileRef.current) {
  //     resizeObserver.observe(mobileRef.current);
  //   }

  //   handleResize(); // Initial check

  //   return () => {
  //     resizeObserver.disconnect();
  //   };
  // }, [isMobile]);

  useEffect(() => {
    const handleResize = () => {
      if (mobileRef.current) {
        setIsMobile(mobileRef.current.clientWidth <= 992);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (mobileRef.current) {
      resizeObserver.observe(mobileRef.current);
    }
    handleResize(); // Initial check

    return () => {
      resizeObserver.disconnect();
    };
  }, [isMobile]);

  const onToggleOpenMenu = (text) => {
    setMenuText(text);
    setSubMenuOpen(!subMenuOpen);
  };

  const toggleCategoryFilter = () => {
    if (!isMobileCateOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    setIsMobileCateOpen(!isMobileCateOpen);
  };

  const toggleMarketFilter = () => {
    if (!isMobileFilterOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    setIsMobileFilterOpen(!isMobileFilterOpen);
  };

  const onApplyFiltersParam = (sellType = '', sortOrderFilter = '') => {
    let baseUrl = `/market?`;
    let sorting;
    if (sellType === 'auction') {
      sorting = sortOrderFilter === null ? 'runTime' : sortOrderFilter;
    } else {
      sorting = sortOrderFilter === null ? 'date_desc' : sortOrderFilter;
    }
    baseUrl += `sellType=${sellType}`;
    baseUrl += sorting ? `&sorting=${sorting}` : '';
    baseUrl += popular ? `&popular=${popular}` : '';
    baseUrl += selectedCategory ? `&cat=${selectedCategory}` : '';
    baseUrl += subSelectedCategory ? `&catType=${subSelectedCategory}` : '';
    baseUrl += selectedWears.length > 0 ? `&wear=${selectedWears.join(',')}` : '';
    baseUrl += selectedPrice.length > 0 ? `&minPrice=${priceRange[0]}&maxPrice=${priceRange[1]}` : '';
    baseUrl += patternIdsArray.length > 0 ? `&patternId=${patternIdsArray.join(',')}` : '';
    baseUrl += selectedChildCategory.length > 0 ? `&category=${selectedChildCategory.join(',')}` : '';
    baseUrl += searchStickers ? `&stickers=${searchStickers}` : '';
    baseUrl += selectedRarePattern.length > 0 ? `&rarePattern=${selectedRarePattern}` : '';
    baseUrl += selectedFade.length > 0 ? `&fade=${selectedFade.join(',')}` : '';
    baseUrl += selectedPhase.length > 0 ? `&phase=${selectedPhase.join(',')}` : '';
    navigate(baseUrl);
  };

  const onSellType = (type) => {
    const params = new URLSearchParams(location.search);
    const sellTypeParam = params.get('sellType');
    if (sellTypeParam !== type) {
      setStatus(true);
    }
    if (isMobile) {
      toggleCategoryFilter();
    }
    onCategoryFilterRemoveSideFilter();
    setSellType(type);
    setCurrentPage(1);
    const sellType = type === 'auction' ? 'runTime' : 'date_desc';
    setSortOrderFilter(sellType);
    // let baseUrl = '/market?';
    onApplyFiltersParam(type, sortOrderFilter);

    // navigate(baseUrl);
  };

  const onCategory = (categoryName, subCategoryName) => {
    const params = new URLSearchParams(location.search);
    const categoryNameParam = params.get('cat');
    if (categoryNameParam !== categoryName) {
      setStatus(true);
      setMarket([]);
    }
    setCurrentPage(1);
    if (isMobile) {
      toggleCategoryFilter();
    }
    onCategoryFilterRemoveSideFilter();
    setSelectedCategory(categoryName);
    setSubSelectedCategory(subCategoryName);
    let queryParams = `/market/?`;
    if (subCategoryName && subCategoryName !== 'All' && subCategoryName !== 'all') {
      queryParams += `sellType=${sellType}&cat=${categoryName}&catType=${subCategoryName}`;
    } else {
      queryParams += `sellType=${sellType}&cat=${categoryName}`;
    }

    navigate(queryParams);
  };

  const handlePriceChange = (newPriceRange) => {
    setPriceRange(newPriceRange);
    setSelectedPrice(newPriceRange);
  };

  const handleFadeChange = (newFade) => {
    setFadeRange(newFade);
    setSelectedFade(newFade);
  };

  const onPriceChange = (e) => {
    const { name, value } = e.target;
    setPriceRange((prevPriceRange) => {
      const newValue = parseInt(value) || 0;
      return name === 'minPrice' ? [newValue, prevPriceRange[1]] : [prevPriceRange[0], newValue];
    });
    setSelectedPrice((prevPriceRange) => {
      const newValue = parseInt(value) || 0;
      return name === 'minPrice' ? [newValue, prevPriceRange[1]] : [prevPriceRange[0], newValue];
    });
  };

  const onPhase = (phase) => {
    const updatedWears = selectedPhase.includes(phase) ? selectedPhase.filter((tag) => phase !== tag) : [...selectedPhase, phase];
    setSelecedPhase(updatedWears);
  };

  const onSortOrder = (sorting) => {
    const params = new URLSearchParams(location.search);
    const sortingParam = params.get('sorting');
    if (sortingParam !== sorting) {
      setStatus(true);
      setMarket([]);
    }
    if (isMobile) {
      toggleMarketFilter();
    }
    setCurrentPage(1);
    setSortOrderFilter(sorting);
    onApplyFiltersParam(sellType, sorting);
  };

  const onPatternIdAdd = (e) => {
    e.preventDefault();
    setPatternIds('');
    setPatternIdsArray([...patternIdsArray, patternIds]);
  };

  const onPatternId = (value) => {
    setPatternIds(value);
  };

  const removePatternId = (item, index) => {
    const updatedPatternIds = patternIdsArray.filter((item, i) => i !== index);
    setPatternIdsArray(updatedPatternIds);
  };

  const onPopular = (value) => {
    setPopular(value);
  };

  const onStickersSearch = (e) => {
    setCurrentPage(1);
    setSearchStickers(e.target.value);
  };

  const applyFilters = (applyName) => {
    setStatus(true);
    setMarket([]);

    if (isMobile) {
      toggleMarketFilter();
      onToggleOpenMenu();
    }
    onApplyFiltersParam(sellType, sortOrderFilter);

    switch (applyName) {
      case 'wear':
        setCurrentPage(1);
        setFilterWear(selectedWears);
        break;
      case 'price':
        setCurrentPage(1);
        setFilterPrice(priceRange);
        break;
      case 'popular':
        setCurrentPage(1);
        setFilterPopular(popular);
        break;
      case 'patternId':
        setCurrentPage(1);
        setFilterPatternId(patternIdsArray);
        break;
      case 'Phase':
        setCurrentPage(1);
        setFilterPhase(selectedPhase);
        break;
      case 'category':
        setCurrentPage(1);
        setFilterChildCategory(selectedChildCategory);
        break;
      case 'stickers':
        setCurrentPage(1);
        setFilterStickers(searchStickers);
        break;
      case 'rare':
        setCurrentPage(1);
        setFilterRarePattern(selectedRarePattern);
        break;
      case 'fade':
        setCurrentPage(1);
        setFilterFade(selectedFade);
        break;
      default:
        break;
    }
  };

  const clearFilters = (name) => {
    const searchParams = new URLSearchParams(location.search);
    setCurrentPage(1);
    switch (name) {
      case 'wear':
        setSelectedWears([]);
        setFilterWear([]);
        searchParams.delete('wear');
        break;
      case 'price':
        setPriceRange([0, price[1]]);
        setFilterPrice([]);
        setSelectedPrice([]);
        searchParams.delete('minPrice');
        searchParams.delete('maxPrice');
        break;
      case 'popular':
        setFilterPopular(false);
        setPopular(false);
        searchParams.delete('popular');
        break;
      case 'patternId':
        setFilterPatternId([]);
        setPatternIds('');
        setPatternIdsArray([]);
        searchParams.delete('patternId');
        break;
      case 'category':
        setSelectedChildCategory([]);
        setFilterChildCategory([]);
        searchParams.delete('category');
        break;
      case 'stickers':
        setSearchStickers('');
        setFilterStickers('');
        searchParams.delete('stickers');
        break;
      case 'resetAllFilter':
        resetAllFilters(searchParams);
        break;
      case 'rare':
        setFilterRarePattern([]);
        setSelectedRarerPattern([]);
        searchParams.delete('rarePattern');
        break;
      case 'fade':
        setFilterFade([]);
        setSelectedFade([]);
        setFadeRange([fadeArray[0], fadeArray[1]]);
        searchParams.delete('fade');
        break;
      case 'Phase':
        setSelecedPhase([]);
        setFilterPhase([]);
        searchParams.delete('phase');
        break;
      default:
        break;
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const fetchData = useCallback(
    async (
      page,
      limit,
      selltype,
      category,
      subcategory,
      wears,
      sorting,
      price,
      popularParam,
      patternId,
      childCategory,
      stickers,
      rarePattern,
      fadeValue,
      phase,
      globalSearch,
      globalItemName
    ) => {
      try {
        setIsLoading(true);
        let params = {
          page,
          limit
        };

        if (selltype !== 'all' && (globalSearch === null || globalItemName === null)) {
          params.sellType = selltype;
        }

        if (category) {
          params.cat = category;
        }

        if (subcategory !== 'all') {
          params.catType = subcategory;
        }

        if (wears.length > 0) {
          params.wear = wears.join(',');
        }

        if (sorting !== 'popularity' && sorting !== 'time_left') {
          params.sorting = sorting;
        }

        if (price) {
          params.minPrice = price[0];
          params.maxPrice = price[1];
        }

        if (popularParam === 'true') {
          params.popularity = popularParam === 'true' ? 1 : 0;
        }

        if (patternId.length > 0) {
          params.patternId = patternId.join(',');
        }

        if (childCategory.length > 0) {
          params.category = childCategory.join(',');
        }

        if (stickers) {
          params.stickers = stickers;
        }

        if (globalSearch) {
          params.search = globalSearch;
        }

        if (globalItemName) {
          params.name = globalItemName;
        }

        if (rarePattern.length > 0) {
          params.rarePattern = rarePattern.join(',');
        }

        if (fadeValue.length > 0) {
          params.fade = fadeValue.join(',');
        }

        if (phase.length > 0) {
          params.phase = phase.join(',');
        }

        const response = await goodMarket(params);
        if (response.data.item.last_page > page) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        const newData = response.data.item.data.filter((item, index, self) => index === self.findIndex((i) => i.m_id === item.m_id));

        setMarket((prev) => {
          const updatedData = page === 1 ? newData : [...prev, ...newData];
          const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

          const newVisible = uniqueArray.length;
          setVisible(newVisible);
          return uniqueArray;
        });
        setItemTotal(response.data.item.total);
        setTimeout(() => {
          setIsLoading(false);
          setStatus(false);
        }, 1000);
      } catch (error) {
        console.error(error.message);
        // Handle error
      }
    },
    [goodMarket]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.toString()) {
      fetchData(
        currentPage,
        itemsPerPage,
        sellType,
        selectedCategory,
        subSelectedCategory,
        filterWear,
        sortOrderFilter,
        filterPrice,
        filterPopular,
        filterPatternId,
        filterChildCategory,
        filterStickers,
        filterRarePattern,
        filterFade,
        filterPhase
      );
    }
  }, [
    fetchData,
    location.search,
    currentPage,
    itemsPerPage,
    sellType,
    selectedCategory,
    subSelectedCategory,
    filterWear,
    sortOrderFilter,
    filterPrice,
    filterPopular,
    filterPatternId,
    filterChildCategory,
    filterStickers,
    filterRarePattern,
    filterFade,
    filterPhase
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.size > 0) {
      setResetFilters(Number(params.size));
      const categoryNameParam = params.get('cat');
      const subCategoryParam = params.get('catType');
      const sellTypeParam = params.get('sellType');
      const wearParam = params.get('wear');
      const GlobalSearch = params.get('search');
      const GlobalName = params.get('name');
      const sorting = params.get('sorting');
      const minPrice = params.get('minPrice');
      const maxPrice = params.get('maxPrice');
      const popularParam = params.get('popular');
      const filterPatternIdParam = params.get('patternId');
      const childCategoryParam = params.get('category');
      const stickersParam = params.get('stickers');
      const rarePatternParam = params.get('rarePattern');
      const fadeParam = params.get('fade');
      const phaseParam = params.get('phase');

      if (GlobalName || GlobalSearch) {
        setCurrentPage(1);
      }

      setSelectedCategory(categoryNameParam);
      setSubSelectedCategory(subCategoryParam);
      setSellType(sellTypeParam);

      if (stickersParam) {
        setFilterStickers(stickersParam);
        setSearchStickers(stickersParam);
      }

      setSortOrderFilter(sorting);
      if (popularParam) {
        setPopular(popularParam);
        setFilterPopular(popularParam);
      }

      var patternIdArray = [];
      if (filterPatternIdParam) {
        patternIdArray = filterPatternIdParam.split(',').map((item) => item);
        setFilterPatternId(patternIdArray);
        setPatternIdsArray(patternIdArray);
      }

      let wearArray = [];
      if (wearParam) {
        wearArray = wearParam.split(',').map((wear) => wear);
        setSelectedWears(wearArray);
        setFilterWear(wearArray);
      }

      let rarePatternArray = [];
      if (rarePatternParam) {
        rarePatternArray = rarePatternParam.split(',').map((rare) => rare.trim());
        setFilterRarePattern(rarePatternArray);
        setSelectedRarerPattern(rarePatternArray);
      }

      let childCategoryArray = [];
      if (childCategoryParam) {
        childCategoryArray = childCategoryParam.split(',').map((cate) => Number(cate));
        setSelectedChildCategory(childCategoryArray);
        setFilterChildCategory(childCategoryArray);
      }

      let priceParam = [];
      if (minPrice !== null || maxPrice !== null) {
        priceParam = [minPrice, maxPrice];
        setFilterPrice([minPrice, maxPrice]);
        setPriceRange([minPrice, maxPrice]);
        setSelectedPrice([minPrice, maxPrice]);
      }

      let fadeArray = [];
      if (fadeParam) {
        fadeArray = fadeParam.split(',').map((fade) => Number(fade));
        setFilterFade(fadeArray);
        setFadeRange(fadeArray);
      }

      let phaseArray = [];
      if (phaseParam) {
        phaseArray = phaseParam.split(',').map((wear) => wear);
        setSelecedPhase(phaseArray);
        setFilterPhase(phaseArray);
      }

      // Only make the API call if category parameters have changed
      fetchData(
        currentPage,
        itemsPerPage,
        sellTypeParam,
        categoryNameParam,
        subCategoryParam,
        wearArray,
        sorting,
        priceParam,
        popularParam,
        patternIdArray,
        childCategoryArray,
        stickersParam,
        rarePatternArray,
        fadeArray,
        phaseArray,
        GlobalSearch,
        GlobalName
      );
    }
  }, [currentPage, fetchData, location.search]);

  const fetchMoreOnBottomReached = () => {
    if (window.scrollY + window.innerHeight + 200 >= document.documentElement.scrollHeight) {
      if (visible < itemTotal) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  useEffect(() => {
    visible < itemTotal && itemTotal > 0
      ? window.addEventListener('scroll', fetchMoreOnBottomReached)
      : window.removeEventListener('scroll', fetchMoreOnBottomReached);
    // Attach scroll event listener when the component mounts
    window.addEventListener('scroll', fetchMoreOnBottomReached);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', fetchMoreOnBottomReached);
    };
  }, [visible]);

  const clearSearch = () => {
    setSearchStickers('');
  };

  const resetAllFilters = (searchParams) => {
    setStatus(true);
    setMarket([]);
    setSelectedWears([]);
    setFilterWear([]);
    setPriceRange([0, price[1]]);
    setFilterPrice([]);
    setSelectedPrice([]);
    setFilterPopular(false);
    setPopular(false);
    setFilterPatternId([]);
    setPatternIds('');
    setPatternIdsArray([]);
    setSelectedChildCategory([]);
    setFilterChildCategory([]);
    setSearchStickers('');
    setFilterStickers('');
    setSortOrderFilter('date_desc');
    setSellType('all');
    setSelectedCategory();
    setSubSelectedCategory();
    setSelecedPhase([]);
    setFilterPhase([]);
    setFilterRarePattern([]);
    setSelectedRarerPattern([]);
    setFilterFade([]);
    setFadeRange([fadeArray[0], fadeArray[1]]);
    // Remove all relevant search parameters
    searchParams.delete('wear');
    searchParams.delete('minPrice');
    searchParams.delete('maxPrice');
    searchParams.delete('popular');
    searchParams.delete('patternId');
    searchParams.delete('category');
    searchParams.delete('stickers');
    searchParams.delete('sorting');
    searchParams.delete('sellType');
    searchParams.delete('cat');
    searchParams.delete('catType');
    searchParams.delete('search');
    searchParams.delete('name');
    searchParams.delete('rarePattern');
    searchParams.delete('fade');
    searchParams.delete('phase');
    setResetFilters(0);
  };

  return (
    <>
      <div className="inventory-main-div position-relative pb-60" ref={mobileRef}>
        <div className="container-fluid">
          <div className="container">
            <div className="inventory-inner-div position-relative market-page MarketSkin">
              <div className="inventory-main-filter d-flex mobile-filter-main-div">
                {isMobile && (
                  <>
                    <div className="mobile-category mobile-filter-div">
                      <div onClick={toggleMarketFilter} className="category-heading">
                        Filter <img src="/images/common/right-arrow.svg" alt="left-arrow" />
                      </div>
                    </div>

                    <div className="mobile-category mobile-top-category">
                      <div onClick={toggleCategoryFilter} className="category-heading">
                        Category <img src="/images/common/right-arrow.svg" alt="left-arrow" />
                      </div>
                      <MobileTopFilter
                        categories={categories}
                        onCategory={onCategory}
                        selectedCategory={selectedCategory}
                        onSellType={onSellType}
                        sellType={sellType}
                        isCateLoading={isCateLoading}
                        isMobileCateOpen={isMobileCateOpen}
                        subSelectedCategory={subSelectedCategory}
                        toggleCategoryFilter={toggleCategoryFilter}
                      />
                    </div>

                    <div className="mobile-reset-main-div">
                      <div className="text-white font-16 mobile-reset-div border-0" style={{ textTransform: 'capitalize' }}>
                        {sellType}
                      </div>
                      <p className="item-count mobile-reset-div">
                        ({itemTotal} <span>items</span>)
                      </p>
                      {resetFilters > 0 && (
                        <div className="text-white font-16 mobile-reset-div" onClick={() => clearFilters('resetAllFilter')}>
                          <p className="reset-button">Reset</p>
                        </div>
                      )}
                    </div>
                  </>
                )}

                <SideFilter
                  handlePriceChange={handlePriceChange}
                  sideBarOptions={sideBarOptions}
                  handleWearCheck={handleWearCheck}
                  applyFilters={applyFilters}
                  clearFilters={clearFilters}
                  selectedWears={selectedWears}
                  itemCount={itemTotal}
                  marketSortOrder={marketSortOrder}
                  AuctionsortOrder={AuctionsortOrder}
                  onPriceChange={onPriceChange}
                  priceRange={[...priceRange]}
                  onSortOrder={onSortOrder}
                  sortOrderFilter={sortOrderFilter}
                  selectedPrice={selectedPrice}
                  filterWear={filterWear}
                  popular={popular}
                  filterPopular={filterPopular}
                  onPopular={onPopular}
                  onPatternIdAdd={onPatternIdAdd}
                  onPatternId={onPatternId}
                  patternIds={patternIds}
                  patternIdsArray={patternIdsArray}
                  filterPatternId={filterPatternId}
                  removePatternId={removePatternId}
                  onStickersSearch={onStickersSearch}
                  filterPrice={filterPrice}
                  setSortOrderFilter={setSortOrderFilter}
                  PhaseArray={PhaseArray}
                  onPhase={onPhase}
                  filterPhase={filterPhase}
                  selectedPhase={selectedPhase}
                  handleCategoryCheck={handleCategoryCheck}
                  filterChildCategory={filterChildCategory}
                  selectedChildCategory={selectedChildCategory}
                  searchStickers={searchStickers}
                  filterStickers={filterStickers}
                  clearSearch={clearSearch}
                  resetFilters={resetFilters}
                  sellType={sellType}
                  isMobileFilter={isMobile}
                  toggleMarketFilter={toggleMarketFilter}
                  isMobileFilterOpen={isMobileFilterOpen}
                  subMenuOpen={subMenuOpen}
                  menuText={menuText}
                  onToggleOpenMenu={onToggleOpenMenu}
                  isMobile={isMobile}
                  RarePattern={RarePattern}
                  selectedRarePattern={selectedRarePattern}
                  handleRarePatternCheck={handleRarePatternCheck}
                  filterRarePattern={filterRarePattern}
                  fadeRange={fadeRange}
                  filterFade={filterFade}
                  handleFadeChange={handleFadeChange}
                  selectedFade={selectedFade}
                  isCateLoading={isCateLoading}
                />

                <div className="market-product-main--list">
                  {!isMobile && (
                    <>
                      <TopFilter
                        categories={categories}
                        onCategory={onCategory}
                        selectedCategory={selectedCategory}
                        onSellType={onSellType}
                        sellType={sellType}
                        isCateLoading={isCateLoading}
                        subSelectedCategory={subSelectedCategory}
                      />
                    </>
                  )}
                  <MarketSkin
                    market={market}
                    // getMarketData={handleNextPage}
                    isLoading={isLoading}
                    hasMore={hasMore}
                    itemsPerPage={itemsPerPage}
                    loading={loading}
                    itemTotal={itemTotal}
                    status={status}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

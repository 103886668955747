import PropTypes from 'prop-types';
import React from 'react';

export const CheckBox = ({ option, handleCheckbox, checked }) => {
  return (
    <>
      <label className="sell-label">
        {option.name}
        <input type="checkbox" name={option.name} onChange={() => handleCheckbox(option.name, option.id)} checked={checked} />
        <span className="checkmark l-0"></span>
      </label>
    </>
  );
};

CheckBox.propTypes = {
  option: PropTypes.object,
  handleCheckbox: PropTypes.func,
  checked: PropTypes.bool
};

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { SkeletonLoader } from 'components';

export const TopFilter = ({ categories, onCategory, selectedCategory, onSellType, sellType, isCateLoading, subSelectedCategory }) => {
  // const isCateLoading = false;
  const scrollerRef = useRef(null);
  const dropDownRefs = useRef([]);

  const scrollMenuRef = useRef(null);
  const menuHoverRef = useRef([]);

  useEffect(() => {
    // Add the logic for dropdown scrolling here
    function setupDropdownScrolling() {
      const scroller = scrollerRef.current;
      const dropDown = document.querySelectorAll('.dropdown');

      function checkScroll() {
        document.activeElement.blur();
        scroller.classList.add('isScrolling');
        dropDown.forEach((dropdown) => {
          dropdown.style.transform = `translateX(-${scroller.scrollLeft}px)`;
          // setScrollLeft(scroller.scrollLeft);
        });
        scroller.classList.remove('isScrolling');
      }

      if (scroller) {
        scroller.addEventListener('scroll', checkScroll);
      }

      return () => {
        if (scroller) {
          scroller.removeEventListener('scroll', checkScroll);
        }
      };
    }

    setupDropdownScrolling();
  }, [categories]); // Re-run when categories change

  // useEffect(() => {
  //   // Add the logic for dropdown scrolling here
  //   function setupDropdownScrolling() {
  //     const scrollMenu = scrollMenuRef.current;
  //     const menuTooltip = document.querySelectorAll('.menu-tooltip');
  //     function checkScroll() {
  //       document.activeElement.blur();
  //       menuTooltip.forEach((menuTooltip) => {
  //         menuTooltip.style.transform = `translateY(-${scrollMenu.scrollLeft}px)`;
  //       });
  //     }

  //     if (scrollMenu) {
  //       scrollMenu.addEventListener('scroll', checkScroll);
  //     }

  //     return () => {
  //       if (scrollMenu) {
  //         scrollMenu.removeEventListener('scroll', checkScroll);
  //       }
  //     };
  //   }

  //   setupDropdownScrolling();
  // }, [categories]); // Re-run when categories change

  return (
    <div className="market-category-div">
      <div className="market-category-inner-div navwrap" ref={scrollerRef}>
        <ul id="nav" className="nav">
          {isCateLoading ? (
            <SkeletonLoader
              count={15}
              color="#0b051b"
              highlightColor="#242347"
              height={60}
              width="100%"
              className="skeleton-list"
              containerClassName="skeleton-container"
            />
          ) : (
            categories &&
            categories.map((mainCategory, index) => {
              return (
                <React.Fragment key={index}>
                  {mainCategory.sub_categories.length !== 0 ? (
                    <li
                      key={index}
                      className={`category-list ${selectedCategory === mainCategory.category ? 'active' : ''}`}
                      data-title={mainCategory.category ? mainCategory.category : ''}
                    >
                      {/* <p className={`menu-tooltip`}>{mainCategory.category && mainCategory.category}</p> */}
                      <div dangerouslySetInnerHTML={{ __html: mainCategory.img }} />

                      {/* <div className="list-options-main"> */}
                      <ul className={`list-options dropdown`}>
                        {mainCategory.sub_categories.map((subCategory, subIndex) => {
                          let imgSrc = subCategory.img; // Default image source

                          if (subIndex === 2 && subCategory.img === '') {
                            imgSrc = subCategory.img;
                          }
                          return (
                            <li
                              className={`list-option ${subSelectedCategory === subCategory.subcategory ? 'active' : ''}`}
                              key={subIndex}
                              onClick={() => onCategory(mainCategory.category, subCategory.subcategory)}
                              ref={(element) => (dropDownRefs.current[subIndex] = element)}
                            >
                              {subCategory.img !== '' && (
                                <img src={imgSrc} alt={subCategory.subcategory} className="img-fluid" title={subCategory.subcategory} />
                              )}

                              <span>{subCategory.subcategory && subCategory.subcategory}</span>
                            </li>
                          );
                        })}
                      </ul>
                      {/* </div> */}
                    </li>
                  ) : (
                    <li
                      onClick={(e) => onCategory(mainCategory.category)}
                      className={`category-list
                      ${selectedCategory === mainCategory.category ? 'active' : ''}`}
                      data-title={mainCategory.category ? mainCategory.category : ''}
                    >
                      <div className="tooltiptext dropdown">
                        <p>{mainCategory.category && mainCategory.category}</p>
                      </div>
                      {/* <p className={`menu-tooltip`}>{mainCategory.category && mainCategory.category}</p> */}
                      <div dangerouslySetInnerHTML={{ __html: mainCategory.img }} />
                      {/* <p> {mainCategory.category && mainCategory.category}</p> */}
                    </li>
                  )}
                </React.Fragment>
              );
            })
          )}
        </ul>
      </div>

      <ul className="auction-menu">
        <li onClick={() => onSellType('all')} className={`category-list ${sellType === 'all' ? 'active' : ''}`}>
          <img src={`/images/market/${sellType === 'all' ? 'active/list.svg' : 'deactive/list.svg'}`} alt="knife" className="gun-fluid" />
          <span>All items</span>
        </li>
        <li onClick={() => onSellType('market')} className={`category-list ${sellType === 'market' ? 'active' : ''}`}>
          <img
            src={`/images/market/${sellType === 'market' ? 'active/shopping-bag.svg' : 'deactive/shopping-bag.svg'}`}
            alt="knife"
            className="gun-fluid"
          />
          <span>Buy Now</span>
        </li>
        <li onClick={() => onSellType('auction')} className={`category-list ${sellType === 'auction' ? 'active' : ''}`}>
          <img
            src={`/images/market/${sellType === 'auction' ? 'active/auction.svg' : 'deactive/auction.svg'}`}
            alt="knife"
            className="gun-fluid"
          />
          <span>Auction</span>
        </li>
      </ul>
    </div>
  );
};

TopFilter.propTypes = {
  categories: PropTypes.array,
  onCategory: PropTypes.func,
  selectedCategory: PropTypes.string,
  subSelectedCategory: PropTypes.string,
  sellType: PropTypes.string,
  onSellType: PropTypes.func,
  isCateLoading: PropTypes.bool
};

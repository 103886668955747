import { singleSkinRoutes } from './apiRoutes';
import { axiosAuthInstance } from './auth';

export const single_market_inventory = (queryParams) =>
  axiosAuthInstance.get(singleSkinRoutes.single_market_inventory, { params: queryParams });

export const get_similar_inventory = (body) => axiosAuthInstance.post(singleSkinRoutes.get_similar_inventory, body);

export const user_bid_add = (body) => axiosAuthInstance.post(singleSkinRoutes.user_bid, body);
export const user_offer_add = (body) => axiosAuthInstance.post(singleSkinRoutes.user_offer, body);
export const steam_pricehistory = (queryParams) => axiosAuthInstance.get(singleSkinRoutes.steam_pricehistory, { params: queryParams });

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { UpArrow, DownArrow } from 'components';

import './style.css';
import PropTypes from 'prop-types';

export const Footer = ({ options }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const [hovered, setHovered] = useState(false);
  const buttonRef = useRef(null);

  localStorage.setItem('default', JSON.stringify({ code: 'EUR', symbol: '€' }));

  useEffect(() => {
    const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
    setSelectedOption(currency || { code: 'EUR', symbol: '€' });
    const defaultCurrency = JSON.parse(localStorage.getItem('default'));
    setDefaultCurrency(defaultCurrency);
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
    const currency = { ...option };
    delete currency.currency;
    localStorage.setItem('tf2-currencies', JSON.stringify(currency));
    window.location.reload();
  };

  const handleOutsideClick = (event) => {
    if (buttonRef.current && buttonRef.current.contains(event.target)) return;
    setShowOptions(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isMobile = screenWidth <= 992;
  // const handleHoverLeave = () => {
  //   setHovered(false);
  //   // setTimeout(() => {
  //   setShowOptions(false);
  //   // }, 200); // Delay hiding options to handle mouse moving between button and options
  // };

  // const handleHoverEnter = () => {
  //   setShowOptions(true);
  //   setHovered(true);
  // };
  return (
    <>
      <footer>
        <div className="container-fluid">
          <div className="container">
            <div className="footer-main-div">
              <div className="footer-logo text-center">
                <Link href="/">
                  <img src="/images/header/new-zipit-logo.svg" alt="Zipit" className="img-fluid" />
                </Link>
              </div>
              <div className="footer_main_menu">
                <div className="footer_menu">
                  <ul>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/faqs">FAQs</Link>
                    </li>
                    <li>
                      <Link to="/support">Support</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms of Service</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy and cookie policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_media text-center">
                <ul>
                  <li>
                    <Link to="/" target="_blank">
                      <img src="/images/footer/instagram.svg" alt="Zipit" className="img-fluid" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" target="_blank">
                      <img src="/images/footer/facebook.svg" alt="Zipit" className="img-fluid" />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://discord.com/invite/BcqWyPxMkQ" target="_blank">
                      <img src="/images/footer/footer-discord.svg" alt="Zipit" className="img-fluid" />
                    </Link>
                  </li>
                </ul>
              </div>
              {isMobile && (
                <div className="payment-card text-center">
                  <img src="/images/footer/payment-card.png" alt="Zipit" className="img-fluid" />
                </div>
              )}
            </div>
            <div className="footer_copyright text-white">
              <div className="copyright-text">
                <p>© Copyright Zipit 2024. All rights reserved</p>
              </div>
              <div
                className="footer-currency-div d-flex gap-10 align-items-center custom-select-container"
                ref={buttonRef}
                // onMouseEnter={handleHoverEnter}
                // onMouseLeave={handleHoverLeave}
              >
                <div className="position-relative">
                  <img src="/images/footer/info.svg" alt="Zipit" className="img-fluid tooltip-image"></img>
                  <div className="tooltiptext">
                    <p>Preferred display currency</p>
                  </div>
                </div>
                <div className="selected-option currency-div" onClick={() => setShowOptions(!showOptions)}>
                  <div className="d-flex gap-10">
                    {selectedOption ? (
                      <>
                        <span className="currency-symbol">{selectedOption.symbol}</span>
                        <span>{selectedOption.code}</span>
                      </>
                    ) : (
                      <>
                        <span className="currency-symbol">{defaultCurrency?.symbol}</span>
                        <span>{defaultCurrency?.currency}</span>
                      </>
                    )}
                  </div>

                  {!showOptions ? <DownArrow /> : <UpArrow />}
                  {/* <img src="/images/header/down-arrow.svg" alt="Zipit" className="img-fluid"></img> */}
                </div>

                {showOptions && (
                  <ul className="options">
                    {options.map((option) => (
                      <li key={option.code} className="option" onClick={() => handleSelect(option)}>
                        <span className="currency-symbol">{option.symbol}</span>
                        <span className="currency-code">{option.currency}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {!isMobile && (
                <div className="payment-card">
                  <img src="/images/footer/payment-card.png" alt="Zipit" className="img-fluid" />
                </div>
              )}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  options: PropTypes.array
};

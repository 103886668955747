import inventorySlice from './inventorySlice';
import userSlice from './userSlice';
import toastSlice from './toastSlice';
import marketSlice from './marketSlice';
import BuySlice from './BuySlice';
import SellSlice from './SellSlice';

export const rootReducer = {
  user: userSlice,
  inventory: inventorySlice,
  toast: toastSlice,
  market: marketSlice,
  buy: BuySlice,
  sell: SellSlice
};

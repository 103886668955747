import { add_wallet } from 'api';
import { SettingSideBar, UserBanModal } from 'components';
import { useApi } from 'hooks/useApi';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export const Deposit = () => {
  document.title = 'Zipit - Deposit';

  const amountArray = ['25.00', '50.00', '100.00', '250.00', '500.00'];
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));

  const [selectedPrice, setSelectedPrice] = useState('');
  const [error, setError] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);

  const userBan = useSelector((state) => state.user.userBlockDetails);
  const [isUserBanModalOpen, setIsUserBanModalOpen] = useState(false);

  const onSelectPrice = (amount) => {
    setError('');
    setSelectedPrice(amount);
  };

  const onChangePrice = (e) => {
    const { value } = e.target;

    if (value < 5) {
      setError('Please select an amount between 5 and 10000€');
    } else {
      setError('');
    }
    setSelectedPrice(e.target.value);
  };

  const { apiCaller: onAmountAddInWallet } = useApi({
    dataGetter: add_wallet
  });

  const handleDeposit = () => {
    if (userBan.status === 'block' && Number(userBan.block_count) === 5) {
      setIsUserBanModalOpen(true);
    } else {
      onAmountAddInWallet({ amount: selectedPrice }).then((res) => {
        if (res.data.status === 'success') {
          setSelectedPrice('');
          toast.success(res.data.message);
        }
      });
    }
  };

  const userBanModalClose = () => {
    document.body.classList.remove('modal-open');
    setIsUserBanModalOpen(false);
  };
  return (
    <div className="profile-page-main-div">
      <div className="container-fluid">
        <div className="container">
          <div className="profile-page-inner-div">
            {isUserBanModalOpen && (
              <UserBanModal setIsUserBanModalOpen={setIsUserBanModalOpen} userBan={userBan} userBanModalClose={userBanModalClose} />
            )}
            <SettingSideBar />
            <div className="deposit-main-div pb-60 profile-page-common-div deposite-page">
              <div className="profile-page-heading">
                <p>Deposit</p>
              </div>
              <div className="deposit-outer-div ">
                <div className="deposit-inner-div">
                  <div className="input-outer-div mb-10">
                    <label className="font-14 opacity-white pb-5 d-block">price</label>
                    <div className={`grey-background input-main-div ${selectedPrice !== '' && selectedPrice < 5 ? 'invalid-input' : ''}`}>
                      <div className="position-relative prefix-div">
                        <p className="prefix l-0">{defaultCurrency && defaultCurrency.symbol}</p>
                        <input
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          placeholder="0.00"
                          value={selectedPrice}
                          onChange={onChangePrice}
                          className="error-msg ml-3"
                          onKeyDown={(e) => {
                            if (
                              (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                                e.key !== 'Backspace' &&
                                e.key !== 'Delete' &&
                                e.key !== 'ArrowLeft' &&
                                e.key !== 'ArrowRight' &&
                                e.key !== '.') || // Allow dot key
                              (e.key === '.' && selectedPrice.includes('.')) // Ensure only one dot is entered
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    {error && <p className="color-red font-14 pb-0">{error}</p>}
                  </div>
                  <div className="auction-time ">
                    <label className="font-14 opacity-white pb-5 d-block">Suggested amounts</label>
                    <div className="auction-type-main mb-10 ">
                      {amountArray.map((item, index) => (
                        <div
                          className={`grey-background auction-time-box ${selectedPrice === Number(item) ? 'selected' : ''}`}
                          key={index}
                          onClick={() => onSelectPrice(Number(item))}
                        >
                          {defaultCurrency && defaultCurrency.symbol} {''}
                          {Number(item)}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="condition-label">
                    <label className="sell-label">
                      I accept the
                      <Link to="/" rel="noreferrer" target="_blank" className="text-white text-underline">
                        terms
                      </Link>
                      and confirm that I want to sell this item.
                      <input type="checkbox" name="term" onChange={(e) => setAcceptTerms(e.target.checked)} />
                      <span className="checkmark l-0"></span>
                    </label>
                  </div>
                  <div className="offer-button-div">
                    <button
                      aria-label="Close Modal"
                      aria-labelledby="close-modal"
                      className="green-button"
                      disabled={!acceptTerms || selectedPrice === '' || error !== ''}
                      onClick={handleDeposit}
                    >
                      Deposit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { marketRoutes } from './apiRoutes';
import { axiosAuthInstance } from './auth';

export const mrkt_goods_category = () => axiosAuthInstance.get(marketRoutes.mrkt_goods_ct);

export const goods_market = (queryParams) => axiosAuthInstance.get(marketRoutes.goods_market, { params: queryParams });

export const market_sidebar = () => axiosAuthInstance.get(marketRoutes.market_sidebar);

export const search_mrktet = (queryParams) => axiosAuthInstance.get(marketRoutes.search_mrktet, { params: queryParams });

import { BrowserRouter as Router } from 'react-router-dom';
import './assets/Fonts/fonts.css';
import './assets/css/bootstrap.css';
import './assets/css/style.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-loading-skeleton/dist/skeleton.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Main } from './layout';
import { Provider } from 'react-redux';
import { store } from 'reduxs/store';
import ScrollToTop from 'ScrollToTop';

function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <Main />
        </Router>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Provider>
    </>
  );
}

export default App;

import React from 'react';
import PropTypes from 'prop-types';

export const SellerDeclineOfferModal = ({ handleSingleOfferDecline, offerData, closeOfferModal, OfferDecline, isDeclineAllOffer }) => {
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  return (
    <div>
      <aside role="dialog" tabIndex="-1" aria-modal="true" className="edit-price-modal sell-model">
        <div className="sell-modal-area edit-listing-modal">
          <div className="position-relative steam-account-popup">
            <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeOfferModal}>
              <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
            </button>
            <p className="offer-heading">Decline</p>
            <div className="amount-offer-div border-bottom-0 pb-10">
              <div className="input-outer-div">
                <div className="grey-background input-main-div d-flex align-items-center gap-10">
                  {/* <p>
                    Decline {defaultCurrency && defaultCurrency.symbol}
                    {offerData && offerData.offer} Offer
                  </p> */}
                  <img src="/images/sell-modal/cross.svg" alt="check" />
                  {isDeclineAllOffer ? (
                    <>
                      <p>Are sure Decline all offers </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Decline {defaultCurrency && defaultCurrency.symbol}
                        {offerData && offerData.offer} Offer
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="offer-button-div">
              <button
                aria-label="Close Modal"
                aria-labelledby="close-modal"
                className="delete-button"
                onClick={() => {
                  if (isDeclineAllOffer) {
                    OfferDecline(offerData?.m_id);
                  } else {
                    handleSingleOfferDecline(offerData?.id, offerData?.m_id, offerData?.offer);
                  }
                }}
              >
                Decline
              </button>
              <button aria-label="Close Modal" aria-labelledby="close-modal" className="grey-button" onClick={closeOfferModal}>
                cancel
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

SellerDeclineOfferModal.propTypes = {
  handleSingleOfferDecline: PropTypes.func,
  offerData: PropTypes.any,
  closeOfferModal: PropTypes.func,
  isDeclineAllOffer: PropTypes.bool,
  OfferDecline: PropTypes.func
};

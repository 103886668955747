import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './style.css';
import { Link, useLocation } from 'react-router-dom';
import { Deposit, Following, Notification, Setting, Shop, Support, Transactions, Withdraw } from 'components';

export const SettingSideBar = () => {
  const location = useLocation();
  const { pathname } = location;
  const user_Data = useSelector((state) => state.user.userData);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const mobileRef = useRef(null);
  const [activeMenuName, setActiveMenuName] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    if (!isMenuOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    // Function to set active menu name based on pathname
    const setActiveMenu = () => {
      switch (pathname) {
        case '/deposit':
          setActiveMenuName('Deposit');
          break;
        case '/withdraw':
          setActiveMenuName('Withdraw');
          break;
        case '/transactions':
          setActiveMenuName('Transactions');
          break;
        case '/settings':
          setActiveMenuName('Settings');
          break;
        case '/notifications':
          setActiveMenuName('Notifications');
          break;
        case `/shop/${user_Data?.hash_id}`:
          setActiveMenuName('My Shop');
          break;
        case '/following':
          setActiveMenuName('Following');
          break;
        case '/support':
          setActiveMenuName('Support');
          break;
        default:
          setActiveMenuName('');
          break;
      }
    };

    setActiveMenu(); // Call the function to set active menu initially
    document.body.classList.remove('modal-open');
  }, [pathname, user_Data]);
  return (
    <>
      {isMobile ? (
        <div onClick={() => toggleMenu()} className="sell-buy-filter-heading">
          <p>{activeMenuName || 'Filter'}</p>
          <img src="/images/common/right-arrow.svg" alt="left-aarow" />
        </div>
      ) : (
        ''
      )}
      <div
        className={`profile-page-sidebar mobile-filter
          ${isMenuOpen && 'side-filter-menu-show'}`}
        ref={mobileRef}
      >
        {isMenuOpen && (
          <div className="back-text" onClick={() => toggleMenu()}>
            <img src="/images/common/left-arrow.svg" alt="left-aarow" /> Back
          </div>
        )}
        <div className="sidebar-inner-div">
          <div className="category-main-heading-div">
            <div className="category-heading text-white font-14 opacity-white">Wallet</div>
          </div>
          <Link to={'/deposit'}>
            <div
              className={`category-item d-flex gap-10
                ${pathname === '/deposit' ? 'active' : ''}`}
            >
              <Deposit />
              <div className="text-white font-16">Deposit</div>
            </div>
          </Link>
          <Link to={'/withdraw'}>
            <div
              className={`category-item d-flex gap-10
                ${pathname === '/withdraw' ? 'active' : ''}`}
            >
              <Withdraw />
              <div className="text-white font-16">Withdraw</div>
            </div>
          </Link>
          <Link to={'/transactions'}>
            <div
              className={`category-item d-flex gap-10
                ${pathname === '/transactions' ? 'active' : ''}`}
            >
              <Transactions />
              <div className="text-white font-16">Transactions</div>
            </div>
          </Link>
          <div className="category-main-heading-div">
            <div className="category-heading text-white font-14 opacity-white">Menu</div>
          </div>
          <Link to={'/settings'}>
            <div
              className={`category-item d-flex gap-10
                ${pathname === '/settings' ? 'active' : ''}`}
            >
              <Setting />
              <div className="text-white font-16">Settings</div>
            </div>
          </Link>
          <Link to={'/notifications'}>
            <div
              className={`category-item d-flex gap-10
                ${pathname === '/notifications' ? 'active' : ''}`}
            >
              <Notification />
              <div className="text-white font-16">Notifications</div>
            </div>
          </Link>
          <Link to={`/shop/${user_Data?.hash_id}`}>
            <div
              className={`category-item d-flex gap-10
                ${pathname === `/shop/${user_Data?.hash_id}` ? 'active' : ''}`}
            >
              <Shop />
              <div className="text-white font-16">My Shop</div>
            </div>
          </Link>
          <Link to={'/following'}>
            <div
              className={`category-item d-flex gap-10
                ${pathname === '/following' ? 'active' : ''}`}
            >
              <Following />
              <div className="text-white font-16">Following</div>
            </div>
          </Link>
          <Link to={'/support'}>
            <div
              className={`category-item d-flex gap-10
                ${pathname === '/support' ? 'active' : ''}`}
            >
              <Support />
              <div className="text-white font-16">Support</div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

import React from 'react';
import { Route, Navigate } from 'react-router-dom';

import { NavBar } from 'components';
import {
  About,
  Buy,
  Faqs,
  Inventory,
  Market,
  Notifications,
  Privacy,
  SellerShop,
  Setting,
  Skin,
  Support,
  Terms,
  SingleInventoryView,
  Following,
  Sell,
  Transactions,
  Withdraw,
  Deposit,
  MarketPrivateView
} from 'view';
import Cookies from 'js-cookie';
export const useAppRoutes = () => {
  const token = Cookies.get('Bearer_Token');
  const isAuthenticated = !!token;
  const appRoutes = [
    { route: 'market', component: Market, isPrivate: false },
    { route: 'market/:market_id', component: Skin, isPrivate: false },
    { route: 'shop/:hash_id', component: SellerShop, isPrivate: false },
    { route: 'faqs', component: Faqs, isPrivate: false },
    { route: 'about', component: About, isPrivate: false },
    { route: 'terms', component: Terms, isPrivate: false },
    { route: 'privacy', component: Privacy, isPrivate: false },
    { route: 'support', component: Support, isPrivate: false },
    { route: 'inventory', component: Inventory, isPrivate: true },
    { route: 'listing/:item_id', component: SingleInventoryView, isPrivate: true },
    { route: 'settings', component: Setting, isPrivate: true },
    { route: 'notifications', component: Notifications, isPrivate: true },
    { route: 'buy', component: Buy, isPrivate: true },
    { route: 'following', component: Following, isPrivate: true },
    { route: 'sell', component: Sell, isPrivate: true },
    { route: 'transactions', component: Transactions, isPrivate: true },
    { route: 'withdraw', component: Withdraw, isPrivate: true },
    { route: 'deposit', component: Deposit, isPrivate: true },
    { route: 'view/private/:market_hash_id', component: MarketPrivateView, isPrivate: true }
    // { route: 'thankyou', component: Thankyou, isPrivate: true }
    // { route: 'stripe', component: Stripe, isPrivate: false },
  ];

  const routes = appRoutes.map(({ route, component: Component, isPrivate }) => {
    return isPrivate ? (
      <Route
        key={route}
        path={`/${route}`}
        element={
          isPrivate && !isAuthenticated ? (
            <Navigate key={route} to="/" />
          ) : (
            <React.Fragment>
              {isPrivate && isAuthenticated && <NavBar />}
              <Component />
            </React.Fragment>
          )
        }
      />
    ) : (
      <Route
        key={route}
        path={`/${route}`}
        element={
          !isPrivate && !isAuthenticated ? (
            <Component />
          ) : (
            <React.Fragment>
              {!isPrivate && isAuthenticated && <NavBar />}
              <Component />
            </React.Fragment>
          )
        }
      />
    );
  });

  return { routes, isAuthenticated };
};

import React from 'react';
import PropTypes from 'prop-types';

export const SellerOfferDisabledModal = ({ market_data, OfferDisable, OfferDisableModal }) => {
  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal">
      <div className="sell-modal-area offer-modal">
        <div className="position-relative steam-account-popup">
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={OfferDisableModal}>
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>
          <p className="pb-20 mb-20 font-700 offer-heading">Disable offers for this listing</p>
          <div className="border-top pt-20">
            <div className="secure-text">
              <p className="">
                Are you sure offers are disabled for this listing? Users cannot see the offer button and cannot add an offer.{' '}
              </p>
            </div>
          </div>
          <div className="offer-button-div" onClick={OfferDisableModal}>
            <button className="green-button" onClick={() => OfferDisable(market_data?.m_id)}>
              Confirm
            </button>
            <button className="grey-button">Cancel</button>
          </div>
        </div>
      </div>
    </aside>
  );
};

SellerOfferDisabledModal.propTypes = {
  market_data: PropTypes.any,
  OfferDisable: PropTypes.func,
  OfferDisableModal: PropTypes.func
};

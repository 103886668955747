import React, { useEffect, useRef, useState } from 'react';
// import { PreviousSales } from '../PreviousSales';
import PropTypes from 'prop-types';
import { DownArrow, SkeletonLoader, UpArrow } from 'components';

// Import necessary chart types and elements for 'area' chart
import 'chart.js/auto';
import './style.css';
import { Line } from 'react-chartjs-2';
// import 'chart.js/dist/chart.esm';

export const Graph = ({
  isLoadingProduct,
  priceHistory,
  graphDays,
  setShowOptions,
  handleGraphChangeOnDays,
  showOptions,
  defaultCurrency
}) => {
  const buttonRef = useRef(null);
  // const [state, setState] = useState('priceTrend');
  const [priceArray, setPriceArray] = useState([]);
  useEffect(() => {
    setPriceArray(priceHistory?.prices);
  }, [priceHistory]);
  // const priceArray = priceHistory?.prices;
  const hasPriceStartingWithZero = priceArray?.some((item) => item?.steam_price.toString().startsWith('0'));
  const calculateStepSize = () => {
    const priceArray = priceHistory?.prices ? priceHistory.prices : [];
    const minPrice = Math.min(...priceArray.map((item) => item.steam_price));
    const maxPrice = Math.max(...priceArray.map((item) => item.steam_price));
    const priceRange = maxPrice - minPrice;
    const suggestedStepSize = priceRange / 10;
    const exponent = Math.ceil(Math.log10(suggestedStepSize));
    const stepSize = Math.pow(10, exponent);
    return !isNaN(stepSize) ? stepSize : 10;
  };

  const stepSize = calculateStepSize();

  const graphTimeArray = [
    { labels: 'Last 2 weeks', days: '15' },
    { labels: 'Last month', days: '30' },
    { labels: 'Last 2 months', days: '60' },
    { labels: 'Last 3 months', days: '90' },
    { labels: 'Last 6 months', days: '180' }
  ];

  const handleOutsideClick = (event) => {
    if (buttonRef.current && buttonRef.current.contains(event.target)) return;
    setShowOptions(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const formatDate = (dateString) => {
    const dateArray = dateString.split('-');
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];
    const date = new Date(year, month - 1, day);
    const dayOfMonth = date.getDate();
    const monthName = date.toLocaleString('default', { month: 'short' });
    return `${dayOfMonth} ${monthName}`;
  };

  const RoundedPrice = (price) => {
    // Round the price to two decimal places
    const roundedPrice = Math.round(price * 100) / 100;

    return roundedPrice;
  };

  const chartData = {
    labels: priceArray?.map((item) => formatDate(item.date)),
    // labels2: data.prices.map((item) => formatDate(item.seen)),
    datasets: [
      {
        // label: 'My Dataset',
        data: priceArray?.map((item) => RoundedPrice(item.steam_price)),
        backgroundColor: ({ chart: { ctx } }) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 500);
          gradient.addColorStop(0, 'rgba(255, 255, 255, 0.7)');
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0.06)');
          return gradient;
        },
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#fff',
        borderColor: '#7000ff',
        borderWidth: 3,
        fill: true
      }
    ]
  };

  const chartOptions = {
    plugins: {
      legend: { display: false }
    },
    elements: { point: { radius: 0 } },
    responsive: true,
    maintainAspectRatio: false,
    hover: { mode: 'nearest', intersect: false },
    scales: {
      x: {
        display: true,
        scaleLabel: { display: true },
        ticks: {
          color: '#fff',
          padding: 10,
          callback: function (val, index) {
            return index % 3 === 0 ? this.getLabelForValue(val) : '';
          }
        },
        grid: {
          display: true,
          color: function (value, index, context) {
            return value.index % 3 === 0 ? '#ffffff50' : '#ffffff1a';
          }
        }
      },
      y: {
        display: true,
        ticks: {
          stepSize: hasPriceStartingWithZero ? '0.20' : stepSize,
          maxTicksLimit: 10,
          color: '#fff',
          padding: 10,
          callback: function (value) {
            const currency = priceHistory?.price_suffix ? priceHistory?.price_suffix : defaultCurrency?.symbol;
            return currency + ' ' + value.toFixed(2); // Prepend currency symbol to each tick value
          }
        },
        grid: { display: true, color: '#ffffff50' }
      },
      x2: {
        display: true,
        border: {
          color: '#ffffff1a'
        },
        ticks: {
          color: '#fff'
        },
        grid: { display: true, color: '#ffffff1a' }
      },
      y2: {
        border: {
          color: '#ffffff1a'
        },
        ticks: {
          color: '#fff',
          padding: 0
        },
        grid: { display: true, color: '#ffffff1a' }
      }
    },
    animation: true
  };

  // const onState = (name) => {
  //   setState(name);
  // };
  return (
    <>
      <div className="graph-div">
        <div className="container-fluid">
          <div className="container">
            <div className="graph-inner-div position-relative">
              {/* <img src="/images/market/graph-back-img.png" alt="Zipit" className="img-fluid graph-back-img" /> */}
              {!isLoadingProduct && <img src="/images/market/graph-back-img.png" alt="Zipit" className="img-fluid graph-back-img" />}
              <div className="similar-product-heading text-center border-0">
                <p className="font-20 text-uppercase text-white pb-0">Stats</p>
              </div>
              <div className="graph-tab-div">
                <div
                  // onClick={() => onState('priceTrend')}
                  className="graph-tab"
                >
                  <span>Price Trend </span>
                </div>

                {/* <div
                  onClick={() => onState('previousSales')}
                  className={`graph-tab ${state === 'previousSales' ? 'active' : ''}`}
                >
                  <span>Previous Sales</span>
                </div> */}
              </div>
              {/* {isLoadingProduct ? (
                <SkeletonLoader
                  width={1334}
                  height={400}
                  color="#0b051b"
                  highlightColor="#242347"
                  borderRadius={0}
                  className="stats-tab-desc-div"
                />
              ) : state === 'priceTrend' ? (
                <div className="graph stats-tab-desc-div">
                  <canvas ref={chartRef} />
                </div>
              ) : (
                <div className="previous-sales stats-tab-desc-div">
                  <PreviousSales />
                </div>
              )} */}
              {/* {state === 'priceTrend' ? ( */}
              {
                isLoadingProduct ? (
                  <SkeletonLoader
                    width="99%"
                    height={400}
                    color="#0b051b"
                    highlightColor="#242347"
                    borderRadius={0}
                    className="stats-tab-desc-div"
                  />
                ) : (
                  <div className="graph-main-div">
                    <div className="graph-select-box-main-div">
                      <div className="graph-select-box" ref={buttonRef}>
                        <div className={`currency-div ${showOptions ? 'active' : ''}`} onClick={() => setShowOptions(!showOptions)}>
                          {graphDays && (
                            <>
                              <span className="currency-symbol">{graphDays.labels ? graphDays.labels : 'Last 2 weeks'}</span>
                            </>
                          )}

                          {!showOptions ? <DownArrow /> : <UpArrow />}
                        </div>
                        {showOptions && (
                          <ul className="options">
                            {graphTimeArray.map((option) => (
                              <li key={option.days} className="option" onClick={() => handleGraphChangeOnDays(option)}>
                                <span className="currency-symbol">{option.labels}</span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="graph stats-tab-desc-div">
                      {/* <img src=""></img> */}

                      <Line
                        // options={chartOptions}
                        id="canvas"
                        data={chartData}
                        options={chartOptions}
                        // width={chartWidth}
                        // height={chartHeight}
                      />
                    </div>
                  </div>
                )
                // ) : (
                //   <div className="previous-sales stats-tab-desc-div">
                //     <PreviousSales isLoadingProduct={isLoadingProduct} />
                //   </div>
                // )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
Graph.propTypes = {
  isLoadingProduct: PropTypes.bool,
  priceHistory: PropTypes.any,
  setShowOptions: PropTypes.func,
  graphDays: PropTypes.any,
  handleGraphChangeOnDays: PropTypes.func,
  showOptions: PropTypes.bool,
  defaultCurrency: PropTypes.any
};

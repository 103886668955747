import React, { useEffect, useRef, useState } from 'react';
import {
  Filter,
  BuyProduct,
  PendingBid,
  CancelBid,
  OfferMade,
  WaitingForTradeOffer,
  AcceptTradeOffers,
  History,
  LostAuction
} from 'components/Buy';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from 'hooks/useApi';
import { buy_sell_count } from 'api';
import { setBuyFilterCount } from 'reduxs/BuySlice';
import { useDispatch } from 'react-redux';

export const Buy = () => {
  //mobile screen State
  document.title = 'Zipit - Buy';
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const mobileRef = useRef(null);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState();

  const defaultCurrency = JSON.parse(localStorage.getItem('default'));

  const [rowCount, setRowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [visible, setVisible] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    from: 0,
    to: 0
  });

  // on resize , update the table layout and column widths
  useEffect(() => {
    const handleResize = () => {
      if (mobileRef.current) {
        setIsMobile(mobileRef.current.clientWidth <= 992);
      }
    };
    const resizeObserver = new ResizeObserver(handleResize);
    if (mobileRef.current) {
      resizeObserver.observe(mobileRef.current);
    }
    handleResize(); // Initial check
    return () => {
      resizeObserver.disconnect();
    };
  }, [isMobile]);

  const toggleMenu = () => {
    if (!isMenuOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const onCategoryChange = (category) => {
    if (isMobile) {
      toggleMenu();
    }
    setSelectedCategory(category);
    setRowCount(10);
    setCurrentPage(1);
    setData([]);
    let queryParams = `/buy`;

    if (category) {
      queryParams += `?view=${encodeURIComponent(category)}`;
    }
    navigate(queryParams);
  };

  console.log(location, 'location');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.size > 0) {
      const categoryNameParam = params.get('view');
      setSelectedCategory(categoryNameParam);
    } else {
      setSelectedCategory('Winning Bids');
    }
  }, [location.search]);

  const { apiCaller: BuySellAllFilterCount } = useApi({
    dataGetter: buy_sell_count
  });

  const handleFilterCount = async () => {
    const res = await BuySellAllFilterCount();
    dispatch(setBuyFilterCount(res.data.data));
  };

  return (
    <>
      <div
        className={`buy-sell-product-div ${Number(rowCount) === 0 || Number(rowCount) === null ? 'nothing-for-buy' : ''}`}
        ref={mobileRef}
      >
        <div className="container-fluid">
          <div className="container">
            <div className="buy-product-main-div">
              <div>
                {isMobile && (
                  <div onClick={() => toggleMenu()} className="sell-buy-filter-heading">
                    <p>{selectedCategory ? selectedCategory : 'Filter'}</p>
                    <img src="/images/common/right-arrow.svg" alt="left-aarow" />
                  </div>
                )}
              </div>
              <div className="buy-filter-div inventory-table">
                <Filter
                  complatedArray={['History', 'Lost Auctions']}
                  selectedCategory={selectedCategory}
                  onCategoryChange={onCategoryChange}
                  isMenuOpen={isMenuOpen}
                  toggleMenu={toggleMenu}
                />
              </div>
              {/* <div className={`buy-product-div ${buyData.length <= 0 ? 'buy-product-empty' : ''}`}> */}
              <div
                className={`buy-product-div ${Number(rowCount) === 0 || Number(rowCount) === null ? 'buy-product-empty' : ''}
                  ${selectedCategory === 'OffersMade' ? 'offer-table' : ''}
                  ${selectedCategory === 'Lost Auctions' ? 'lostauction-table' : ''}`}
              >
                {selectedCategory === 'Winning Bids' && (
                  <>
                    <PendingBid
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      data={data}
                      isLoading={isLoading}
                      rowCount={rowCount}
                      defaultCurrency={defaultCurrency}
                      setRowCount={setRowCount}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setIsLoading={setIsLoading}
                      setData={setData}
                      setVisible={setVisible}
                      visible={visible}
                      hasMore={hasMore}
                      setHasMore={setHasMore}
                      location={location}
                      navigate={navigate}
                      toggleMenu={toggleMenu}
                      isMobile={isMobile}
                      setPagination={setPagination}
                      pagination={pagination}
                    />
                  </>
                )}
                {selectedCategory === 'Losing Bids' && (
                  <>
                    <CancelBid
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      data={data}
                      isLoading={isLoading}
                      rowCount={rowCount}
                      defaultCurrency={defaultCurrency}
                      setRowCount={setRowCount}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setIsLoading={setIsLoading}
                      setData={setData}
                      setVisible={setVisible}
                      visible={visible}
                      hasMore={hasMore}
                      setHasMore={setHasMore}
                      location={location}
                      navigate={navigate}
                      toggleMenu={toggleMenu}
                      isMobile={isMobile}
                      setPagination={setPagination}
                      pagination={pagination}
                    />
                  </>
                )}
                {selectedCategory === 'Offers Made' && (
                  <>
                    <OfferMade
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      data={data}
                      isLoading={isLoading}
                      rowCount={rowCount}
                      defaultCurrency={defaultCurrency}
                      setRowCount={setRowCount}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setIsLoading={setIsLoading}
                      setData={setData}
                      setVisible={setVisible}
                      visible={visible}
                      hasMore={hasMore}
                      setHasMore={setHasMore}
                      location={location}
                      navigate={navigate}
                      toggleMenu={toggleMenu}
                      isMobile={isMobile}
                      handleFilterCount={handleFilterCount}
                      setPagination={setPagination}
                      pagination={pagination}
                    />
                  </>
                )}

                {selectedCategory === 'Make payment' && (
                  <>
                    <BuyProduct
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      data={data}
                      isLoading={isLoading}
                      rowCount={rowCount}
                      defaultCurrency={defaultCurrency}
                      setRowCount={setRowCount}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setIsLoading={setIsLoading}
                      setData={setData}
                      setVisible={setVisible}
                      visible={visible}
                      hasMore={hasMore}
                      setHasMore={setHasMore}
                      location={location}
                      navigate={navigate}
                      toggleMenu={toggleMenu}
                      isMobile={isMobile}
                      handleFilterCount={handleFilterCount}
                      setPagination={setPagination}
                      pagination={pagination}
                    />
                  </>
                )}
                {selectedCategory === 'Waiting For Trade offer' && (
                  <>
                    <WaitingForTradeOffer
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      data={data}
                      isLoading={isLoading}
                      rowCount={rowCount}
                      defaultCurrency={defaultCurrency}
                      setRowCount={setRowCount}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setIsLoading={setIsLoading}
                      setData={setData}
                      setVisible={setVisible}
                      visible={visible}
                      hasMore={hasMore}
                      setHasMore={setHasMore}
                      location={location}
                      navigate={navigate}
                      toggleMenu={toggleMenu}
                      isMobile={isMobile}
                      setPagination={setPagination}
                      pagination={pagination}
                    />
                  </>
                )}
                {selectedCategory === 'Lost Auctions' && (
                  <LostAuction
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    data={data}
                    isLoading={isLoading}
                    rowCount={rowCount}
                    defaultCurrency={defaultCurrency}
                    setRowCount={setRowCount}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    setIsLoading={setIsLoading}
                    setData={setData}
                    setVisible={setVisible}
                    visible={visible}
                    hasMore={hasMore}
                    setHasMore={setHasMore}
                    location={location}
                    navigate={navigate}
                    toggleMenu={toggleMenu}
                    isMobile={isMobile}
                    setPagination={setPagination}
                    pagination={pagination}
                  />
                )}
                {selectedCategory === 'Accept Trade offers' && (
                  <>
                    <AcceptTradeOffers
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      data={data}
                      isLoading={isLoading}
                      rowCount={rowCount}
                      defaultCurrency={defaultCurrency}
                      setRowCount={setRowCount}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setIsLoading={setIsLoading}
                      setData={setData}
                      setVisible={setVisible}
                      visible={visible}
                      hasMore={hasMore}
                      setHasMore={setHasMore}
                      location={location}
                      navigate={navigate}
                      toggleMenu={toggleMenu}
                      isMobile={isMobile}
                      setPagination={setPagination}
                      pagination={pagination}
                    />
                  </>
                )}
                {selectedCategory === 'History' && (
                  <>
                    <History
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      data={data}
                      isLoading={isLoading}
                      rowCount={rowCount}
                      defaultCurrency={defaultCurrency}
                      setRowCount={setRowCount}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setIsLoading={setIsLoading}
                      setData={setData}
                      setVisible={setVisible}
                      visible={visible}
                      hasMore={hasMore}
                      setHasMore={setHasMore}
                      location={location}
                      navigate={navigate}
                      toggleMenu={toggleMenu}
                      isMobile={isMobile}
                      setPagination={setPagination}
                      pagination={pagination}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

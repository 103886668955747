import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inventory: [],
  categories: []
};

const invetorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setInvetory(state, action) {
      state.inventory = action.payload;
    },
    setInventoryCategories(state, action) {
      state.categories = action.payload;
    }
  }
});

export const { setInvetory, setInventoryCategories } = invetorySlice.actions;
export default invetorySlice.reducer;

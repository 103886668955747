import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

import { Button } from 'components';

export const UserVerification = ({ data }) => {
  const sectionArray = data?.item;
  const third_section = sectionArray?.find((section) => Number(section.section_name) === 3);
  const fourth_section = sectionArray?.find((section) => Number(section.section_name) === 4);

  if (!third_section) {
    return null;
  }
  if (!fourth_section) {
    return null;
  }

  const handleClick = (button_url) => {
    if (button_url) {
      window.open(button_url, '_blank');
    }
  };

  return (
    <>
      <div className={`third_section section_padding ${!data ? 'section_padding_loading' : ''}`}>
        <div className="container-fluid">
          <div className="container">
            {third_section && (
              <div className="row align-items-center position-relative custome-row-gap user-verification">
                <div className="col-md-6 col">
                  <div className="common_text_div common_right_div text-white padding-right">
                    {third_section?.title && <h2 className="main_sub_heading">{third_section?.title}</h2>}
                    {third_section?.description && (
                      <div className="description">
                        <p>{third_section?.description}</p>
                      </div>
                    )}
                    {third_section?.button_name && (
                      <Button className="common_button mt-50" handleClick={() => handleClick(third_section?.button_url)}>
                        {third_section?.button_name}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col">
                  {third_section?.image && (
                    <div className="common_image_div common_left_div">
                      <img src={third_section?.image} alt="Zipit" className="img-fluid" />
                      <span className="third_section_shadow"></span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {fourth_section && (
              <div className="row align-items-center position-relative custome-row-gap select-skin">
                <div className="col-md-6 col order-md-1 order-2">
                  {fourth_section?.image && (
                    <div className="common_image_div common_left_div ">
                      {/* <img src="/images/home/section_fourth_image.png" alt="Zipit" className="img-fluid" /> */}
                      {/* <span className="fourth_section_shadow"></span>
                  <span className="fourth_section_shadow2"></span> */}
                      <img src={fourth_section?.image} alt="Zipit" className="img-fluid" />
                      <img src="/images/home/Group-36737.svg" alt="Zipit" className="img-fluid fourth-shadow-image" />
                    </div>
                  )}
                </div>
                <div className="col-md-6 col order-md-2 order-1">
                  <div className="common_text_div common_right_div text-white padding-right">
                    {fourth_section?.title && <h2 className="main_sub_heading">{fourth_section?.title}</h2>}
                    {fourth_section?.description && (
                      <div className="description">
                        <p>{fourth_section?.description}</p>
                      </div>
                    )}
                    {fourth_section?.button_name && (
                      <Button className="common_button mt-50" handleClick={() => handleClick(fourth_section?.button_url)}>
                        {fourth_section?.button_name}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

UserVerification.propTypes = {
  data: PropTypes.any
};

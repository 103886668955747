import { sellRoutes } from './apiRoutes';
import { axiosAuthInstance } from './auth';

export const get_sellinventorylist = (queryParams) => axiosAuthInstance.get(sellRoutes.get_sellinventorylist, { params: queryParams });

export const offer_view_seller = (queryParams) => axiosAuthInstance.get(sellRoutes.offer_view_seller, { params: queryParams });
export const seller_offer_accept = (body) => axiosAuthInstance.post(sellRoutes.seller_offer_accept, body);
export const offer_disable = (queryParams) => axiosAuthInstance.get(sellRoutes.offer_disable, { params: queryParams });
export const offer_decline = (queryParams) => axiosAuthInstance.get(sellRoutes.offer_decline, { params: queryParams });
export const single_offer_decline = (queryParams) => axiosAuthInstance.get(sellRoutes.single_offer_decline, { params: queryParams });
export const waiting_for_seller_inventory_payment = (queryParams) =>
  axiosAuthInstance.get(sellRoutes.waiting_for_seller_inventory_payment, { params: queryParams });
export const remove_user_offer = (queryParams) => axiosAuthInstance.get(sellRoutes.remove_user_offer, { params: queryParams });
export const seller_waiting_trade_list = (queryParams) =>
  axiosAuthInstance.get(sellRoutes.seller_waiting_trade_list, { params: queryParams });
export const seller_pending_accept_trade_list = (queryParams) =>
  axiosAuthInstance.get(sellRoutes.seller_accept_trade_list, { params: queryParams });
export const seller_sell_history = (queryParams) => axiosAuthInstance.get(sellRoutes.seller_sell_history, { params: queryParams });

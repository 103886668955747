import React, { useCallback, useEffect, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import PropTypes from 'prop-types';
import { convertCurrency, itemNameConverter, wearNameConverter } from 'lib';
import { Nothing } from '../Nothing';
import { CountdownTimer } from 'components';
import { useApi } from 'hooks/useApi';
import { axiosAuthInstance, user_bid_pending_view } from 'api';
import '../style.css';
import Cookies from 'js-cookie';

export const PendingBid = ({
  defaultCurrency,
  selectedCategory,
  setSelectedCategory,
  data,
  isLoading,
  rowCount,
  setRowCount,
  setCurrentPage,
  currentPage,
  setIsLoading,
  setData,
  setVisible,
  visible,
  hasMore,
  setHasMore,
  location,
  navigate,
  toggleMenu,
  isMobile,
  setPagination,
  pagination
}) => {
  // const isLoading = true;
  useEffect(() => {
    const Bearer_Token = Cookies.get('Bearer_Token');
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${Bearer_Token}`;
  }, []);

  const { apiCaller: userBidPendingView } = useApi({
    dataGetter: user_bid_pending_view
  });

  const fetchData = useCallback(
    async (page) => {
      try {
        setIsLoading(true);
        const queryParams = { page };
        const res = await userBidPendingView(queryParams);
        if (res.data.item.last_page > page) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        const newData = res.data.item.data.filter((item, index, self) => index === self.findIndex((i) => i.m_id === item.m_id));
        setData((prev) => {
          const updatedData = page === 1 ? newData : [...prev, ...newData];
          const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));
          const newVisible = uniqueArray.length;
          setVisible(newVisible);
          return uniqueArray;
        });

        const count = res.data.item.total;
        setPagination({ from: res.data.item.from, to: res.data.item.to });

        setRowCount(Number(count));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setTimeout(() => {
        setIsLoading(false);
        // }, 2000);
      }
    },
    [userBidPendingView, setIsLoading, setHasMore, setData, setRowCount, setVisible]
  );

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   if (params.size > 0) {
  //     const categoryNameParam = params.get('view');
  //     setSelectedCategory(categoryNameParam);
  //     fetchData(currentPage);
  //   }
  // }, [location.search, currentPage, selectedCategory]);

  useEffect(() => {
    // const params = new URLSearchParams(location.search);
    // if (!params.toString()) {
    // If no query parameters, proceed with the API call
    fetchData(currentPage);
    // }
  }, [fetchData, location.search, currentPage, selectedCategory]);

  const fetchMoreOnBottomReached = () => {
    if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
      if (visible < rowCount) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  useEffect(() => {
    visible < rowCount && rowCount > 0
      ? window.addEventListener('scroll', fetchMoreOnBottomReached)
      : window.removeEventListener('scroll', fetchMoreOnBottomReached);
    // Attach scroll event listener when the component mounts
    window.addEventListener('scroll', fetchMoreOnBottomReached);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', fetchMoreOnBottomReached);
    };
  }, [visible]);

  const columns = useMemo(() => {
    const columnsArray = [
      {
        accessorKey: 'item',
        header: <div className={`${isMobile ? 'd-none' : ''}`}>Item</div>,
        size: 150,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          /* eslint-disable */
          const stickers = row?.original?.data?.item?.stickers;
          /* eslint-enable */

          return (
            /* eslint-disable */

            <>
              {!isMobile ? (
                <div id={`${row?.original?.data.item_id}`} className="d-flex gap-15 align-items-center">
                  <div className="goods-card-main-div position-relative">
                    <div
                      className="goods-card-img"
                      style={{
                        backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.data.item?.imageUrl}")`
                      }}
                    ></div>
                    <div className="wear-name text-white">
                      <p className="font-10 font-500">{row?.original?.data?.wear && wearNameConverter(row?.original?.data?.wear)}</p>
                      <p className="font-10 font-500">
                        {row?.original?.data?.wear !== 'N/A' ? `/ ${Number(row?.original?.data?.float_id).toFixed(4)} ` : ''}
                      </p>
                    </div>

                    <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                    <div
                      className="border"
                      style={{
                        borderBottom: `5px solid ${row?.original?.data?.item?.r_color}`
                      }}
                    ></div>
                  </div>

                  <div className="display-product-name">
                    <div className="inventory-product-name">
                      <h3 className="productname">
                        {row?.original?.data.item?.fullname && itemNameConverter(row?.original?.data.item?.fullname)}
                      </h3>
                      <div className="d-flex gap-5 text-white">
                        <p className="productname-desc">{row?.original?.data.item?.category}</p>*
                        <p className="productname-desc">{row?.original?.data.item?.subCategory}</p>
                      </div>
                    </div>
                    {stickers && stickers.length > 0 && (
                      <div className="previous-sales-sticker product-sticker mt-10">
                        {stickers.map((item, index) => {
                          return (
                            <div
                              className="product-display-sticker"
                              key={index}
                              // dangerouslySetInnerHTML={{ __html: item.value }}
                            >
                              {/* <img src={item.value} alt="sticker" title={item.key} /> */}
                              <div className="single-sticker-div">
                                <img src={item.value} alt="sticker" />
                                {item.key && (
                                  <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                    <span>{item.key}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div id={`${row?.original?.data.item_id}`} className="responsive-buy-div">
                  <div className="goods-card-main-div position-relative">
                    <div
                      className="goods-card-img"
                      style={{
                        backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.data.item?.imageUrl}")`
                      }}
                    ></div>
                    <div className="wear-name text-white">
                      <p className="font-10 font-500">{row?.original?.data?.wear && wearNameConverter(row?.original?.data?.wear)}</p>
                      <p className="font-10 font-500">
                        {row?.original?.data?.wear !== 'N/A' ? `/ ${Number(row?.original?.data?.float_id).toFixed(4)} ` : ''}
                      </p>
                    </div>

                    <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                    <div
                      className="border"
                      style={{
                        borderBottom: `5px solid ${row?.original?.data?.item?.r_color}`
                      }}
                    ></div>
                  </div>

                  <div className="display-product-name">
                    <div className="inventory-product-name">
                      <h3 className="productname pb-5">
                        {row?.original?.data.item?.fullname && itemNameConverter(row?.original?.data.item?.fullname)}
                      </h3>
                      <div className="d-flex gap-5 text-white resonsive-product-desc mb-5">
                        <p className="productname-desc">{row?.original?.data.item?.category}</p>*
                        <p className="productname-desc">{row?.original?.data.item?.subCategory}</p>
                      </div>
                    </div>
                    <p className="mb-10">
                      {defaultCurrency && defaultCurrency?.symbol} {row?.original?.bidPrice}
                    </p>
                    {stickers && stickers.length > 0 && (
                      <div className="previous-sales-sticker product-sticker ">
                        {stickers.map((item, index) => {
                          return (
                            <div
                              className="product-display-sticker"
                              key={index}
                              // dangerouslySetInnerHTML={{ __html: item.value }}
                            >
                              <img src={item.value} alt="sticker" title={item.key} />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="responsive-btn-div">
                    <button onClick={() => navigate(`/market/${row?.original?.m_id}`)} className="table-button">
                      View
                    </button>
                  </div>
                </div>
              )}
            </>

            /* eslint-enable */
          );
        }
      }
    ];

    if (!isMobile) {
      columnsArray.push(
        {
          // accessorKey: 'wear',
          header: 'Wear',
          size: 150,
          // eslint-disable-next-line
          Cell: ({ row }) => {
            /* eslint-disable */
            const wear = row?.original?.data && row.original?.data.wear;

            return (
              <>
                <p>{wear}</p>
              </>
            );
            /* eslint-enable */
          }
        },
        {
          // accessorKey: 'item.category',
          header: 'Price',
          size: 100,
          // eslint-disable-next-line
          Cell: ({ row }) => {
            /* eslint-disable */

            return (
              <>
                <p>
                  {defaultCurrency && defaultCurrency?.symbol} {row?.original?.bidPrice}
                </p>
              </>
            );
            /* eslint-enable */
          }
        },
        {
          // accessorKey: 'item.category',
          header: 'Time Left',
          size: 100,
          // eslint-disable-next-line
          Cell: ({ row }) => {
            /* eslint-disable */

            // const daysCount = row.original.runtime;
            const days = row?.original?.data && row.original.data.runtime;
            const daysCount = days !== null ? parseInt(days) : null;
            const createdDate = row.original.created;
            return (
              <>
                {daysCount !== null ? (
                  <CountdownTimer daysCount={daysCount} createdDate={createdDate} key={row?.original?.m_id} />
                ) : (
                  <p></p>
                )}
              </>
            );
            /* eslint-enable */
          }
        },
        {
          header: <div className="buy-action d-none">Action</div>,
          // eslint-disable-next-line
          Cell: ({ row }) => (
            /* eslint-disable */
            <div>
              <button onClick={() => navigate(`/market/${row?.original?.m_id}`)} className="table-button">
                View
              </button>
            </div>
            /* eslint-enable */
          )
        }
      );
    }
    return columnsArray;
  }, [convertCurrency, isMobile]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    getRowId: (row) => /* eslint-disable */ row?.item_id /* eslint-enable */,
    onRowSelectionChange: false,
    // initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableBottomToolbar: true,
    enablePagination: false,

    muiTableContainerProps: {
      className: 'zipit-data-table',
      sx: {
        overflow: 'initial'
      }
    },
    muiBottomToolbarProps: {
      className: 'table-bottom-bar-pagination',
      sx: {
        // bottom: '56px',
        padding: 0,
        '& .MuiBox-root ': {
          justifyContent: 'end',
          marginTop: '10px'
        },
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#fff',
          height: '3px'
        },
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar2Indeterminate': {
          backgroundColor: '#6117BF',
          height: '3px'
        },
        '& .MuiLinearProgress-root.MuiLinearProgress-colorPrimary': {
          backgroundColor: '#6117BF',
          height: '3px'
        }
      }
    },
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        {data.length > 0 && (
          <div className="pagination">
            {pagination.from} - {pagination.to} of {rowCount}
          </div>
        )}
      </>
    ),
    muiTablePaperProps: {
      sx: {
        borderRadius: '0',
        boxShadow: 'none',
        overflow: 'initial'
      }
    },
    muiTableBodyProps: {
      sx: {
        '& tr td': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        },
        '& tr:not([data-selected="true"])': {
          backgroundColor: 'transparent'
        }
      },
      className: 'buytable-body'
    },
    mrtTheme: {
      baseBackgroundColor: '#0b051b',
      selectedRowBackgroundColor: '#ffffff1a'
    },
    muiTableBodyCellProps: {
      className: isLoading ? 'sell-product-loader-class' : ''
    },
    muiTableHeadProps: {
      sx: {
        '& tr th': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        }
      },
      className: 'buytable-head'
    },
    localization: {
      noRecordsToDisplay: ''
    },
    muiSkeletonProps: ({ row, column }) => {
      if (row && column) {
        // Apply static skeleton properties for each cell
        return {
          animation: 'wave',
          variant: 'rect',
          width: column.size,
          height: isMobile ? 40 : 70,
          className: 'mui-skeleton-zipit'
        };
      }
    },
    rowCount,
    state: {
      isLoading: currentPage === 1 ? isLoading : false,
      showProgressBars: currentPage === 1 ? false : isLoading,
      showLoadingOverlay: false
    }
  });

  return <>{rowCount && rowCount > 0 ? <MaterialReactTable table={table} /> : <Nothing />}</>;
};

PendingBid.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  rowCount: PropTypes.any,
  defaultCurrency: PropTypes.object,
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
  setRowCount: PropTypes.func,
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  setIsLoading: PropTypes.func,
  setData: PropTypes.func,
  setVisible: PropTypes.func,
  visible: PropTypes.number,
  hasMore: PropTypes.bool,
  setHasMore: PropTypes.func,
  location: PropTypes.any,
  navigate: PropTypes.any,
  toggleMenu: PropTypes.func,
  isMobile: PropTypes.bool,
  setPagination: PropTypes.func,
  pagination: PropTypes.object
};

import React, { useEffect } from 'react';
import './style.css';
import PropTypes from 'prop-types';

export const SteamApiKeyModal = ({ closeModal, onSubmit, setApiKey, errors, apiKeyErrorMessage, apiKey }) => {
  useEffect(() => {}, [errors.api_key]);

  const handleKeyDown = (e) => {
    if (e.which === 32) {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, pastedText.replace(/\s/g, ''));
  };
  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal">
      <div className="sell-modal-area">
        <div className="steam-account-popup position-relative steam-api-key">
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={() => closeModal()}>
            <img src="/images/common/modal-close.svg" alt="Zipit" className="img-fluid" />
          </button>
          <div className="">
            <div className="modal-text text-white">
              <p className="font-20 font-700">Please add Steam API Key</p>
              <p className="font-14 pb-0">
                Your API key is needed for loading your inventory and trading. Please go to Settings and add it.
              </p>
            </div>
            <div className="modal-form">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit(e);
                }}
              >
                {(apiKeyErrorMessage.status === 'error' || apiKeyErrorMessage.status === 'api-error') && (
                  <div className="api-key-form">
                    <label htmlFor="apiKey" className="text-white font-14 d-block pb-5">
                      Steam API Key
                    </label>
                    <div className="d-flex">
                      <input
                        className="form-control"
                        id="apiKey"
                        placeholder="Enter api key"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setApiKey({ ...apiKey, api_key: e.target.value.trim() })}
                        onPaste={handlePaste}
                      />
                    </div>
                    <a
                      // href="https://steamapis.com/"
                      href="https://steamcommunity.com/dev/apikey"
                      rel="noreferrer"
                      target="_blank"
                      className="text-white font-14 text-underline"
                    >
                      Get your Steam API Key
                      <img src="/images/common/link-icon.svg" alt="zipit" className="img-fluid ml-5" />
                    </a>
                    {errors.api_key ? <p className="error">{errors.api_key}</p> : null}
                  </div>
                )}
                {(apiKeyErrorMessage.status === 'error' || apiKeyErrorMessage.status === 'trade-error') && (
                  <div className="api-key-form">
                    <label htmlFor="apiKey" className="text-white font-14 d-block pb-5">
                      Steam Trade URL
                    </label>
                    <div className="d-flex">
                      <input
                        className="form-control"
                        id="apiKey"
                        placeholder="Enter trade url "
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setApiKey({ ...apiKey, tread_url: e.target.value.trim() })}
                        onPaste={handlePaste}
                      />
                      {/* <button className="form-control btn btn-primary" type="submit">
                        Apply
                      </button> */}
                    </div>
                    <a
                      href="https://steamcommunity.com/my/tradeoffers/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white font-14 text-underline "
                    >
                      Get your Steam Trade URL
                      <img src="/images/common/link-icon.svg" alt="Zipit" className="img-fluid ml-5" />
                    </a>
                    {errors.tread_url ? <p className="error">{errors.tread_url}</p> : null}
                  </div>
                )}
                <button className="form-control btn btn-primary w-100" type="submit">
                  Apply
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

SteamApiKeyModal.propTypes = {
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  setApiKey: PropTypes.func.isRequired,
  errors: PropTypes.object,
  apiKeyErrorMessage: PropTypes.any,
  apiKey: PropTypes.any
};

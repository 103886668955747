import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Filter, History, MakeTradeOffer, PendingAcceptTradeOffers, SellProduct, WaitForPayment } from 'components/Sell';
import { useApi } from 'hooks/useApi';
import { buy_sell_count } from 'api';
import { useDispatch } from 'react-redux';
import { setBuyFilterCount } from 'reduxs/BuySlice';

export const Sell = () => {
  document.title = 'Zipit - Sell';
  //mobile screen State
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const mobileRef = useRef(null);

  const [selectedCategory, setSelectedCategory] = useState();

  const [sorting, setSorting] = useState([]);
  const [rowCount, setRowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [visible, setVisible] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    from: 0,
    to: 0
  });
  const [buyData, setBuyData] = useState({
    Selling: [],
    'Private listings': [],
    'Offers received': []
  });

  const [waitingForPayment, setWaitingForPayment] = useState([]);
  const [makeTradeOfferData, setMakeTradeOfferData] = useState([]);
  const [pendingTradeOfferAccepts, setPendingTradeOfferAccepts] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  // on resize , update the table layout and column widths
  useEffect(() => {
    const handleResize = () => {
      if (mobileRef.current) {
        setIsMobile(mobileRef.current.clientWidth <= 992);
      }
    };
    const resizeObserver = new ResizeObserver(handleResize);
    if (mobileRef.current) {
      resizeObserver.observe(mobileRef.current);
    }
    handleResize(); // Initial check
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const toggleMenu = () => {
    if (!isMenuOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const DataEmpty = () => {
    setBuyData({
      Selling: [],
      'Private listings': [],
      'Offers received': []
    });
    setWaitingForPayment([]);
    setMakeTradeOfferData([]);
    setPendingTradeOfferAccepts([]);
    setHistoryData([]);
  };

  const onCategoryChange = (category) => {
    if (isMobile) {
      toggleMenu();
    }
    setSelectedCategory(category);
    setRowCount(10);
    setCurrentPage(1);
    DataEmpty();
    let queryParams = `/sell`;
    if (category) {
      queryParams += `?view=${encodeURIComponent(category)}`;
    }
    navigate(queryParams);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.size > 0) {
      const categoryNameParam = params.get('view');
      setSelectedCategory(categoryNameParam);
    } else {
      setSelectedCategory('Selling');
    }
  }, [location.search, currentPage, selectedCategory]);

  const { apiCaller: BuySellAllFilterCount } = useApi({
    dataGetter: buy_sell_count
  });

  const handleFilterCount = async () => {
    const res = await BuySellAllFilterCount();
    dispatch(setBuyFilterCount(res.data.data));
  };

  return (
    <>
      <div
        className={`buy-sell-product-div ${Number(rowCount) === 0 || Number(rowCount) === null ? 'nothing-for-buy' : ''}`}
        ref={mobileRef}
      >
        <div className="container-fluid">
          <div className="container">
            <div className="buy-product-main-div sell-product-div">
              {isMobile && (
                <div onClick={() => toggleMenu()} className="sell-buy-filter-heading">
                  <p>{selectedCategory ? selectedCategory : 'Filter'} </p>
                  <img src="/images/common/right-arrow.svg" alt="left-aarow" />
                </div>
              )}
              <div className="buy-filter-div inventory-table">
                <Filter
                  selectedCategory={selectedCategory}
                  onCategoryChange={onCategoryChange}
                  toggleMenu={toggleMenu}
                  isMenuOpen={isMenuOpen}
                />
              </div>
              <div className={`buy-product-div ${Number(rowCount) === 0 || Number(rowCount) === null ? 'buy-product-empty' : ''}`}>
                {(selectedCategory === 'Selling' || selectedCategory === 'Private listings' || selectedCategory === 'Offers received') && (
                  <SellProduct
                    isMobile={isMobile}
                    setSelectedCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                    sorting={sorting}
                    setSorting={setSorting}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    hasMore={hasMore}
                    setVisible={setVisible}
                    visible={visible}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    buyData={buyData}
                    setBuyData={setBuyData}
                    rowCount={rowCount}
                    setHasMore={setHasMore}
                    setRowCount={setRowCount}
                    handleFilterCount={handleFilterCount}
                    setPagination={setPagination}
                    pagination={pagination}
                  />
                )}

                {selectedCategory === 'Waiting For Payment' && (
                  <WaitForPayment
                    setSelectedCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                    isMobile={isMobile}
                    sorting={sorting}
                    setSorting={setSorting}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    hasMore={hasMore}
                    setVisible={setVisible}
                    visible={visible}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    waitingForPayment={waitingForPayment}
                    setWaitingForPayment={setWaitingForPayment}
                    rowCount={rowCount}
                    setHasMore={setHasMore}
                    setRowCount={setRowCount}
                    setPagination={setPagination}
                    pagination={pagination}
                  />
                )}
                {selectedCategory === 'Make Trade Offer' && (
                  <MakeTradeOffer
                    setSelectedCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                    isMobile={isMobile}
                    sorting={sorting}
                    setSorting={setSorting}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    hasMore={hasMore}
                    setVisible={setVisible}
                    visible={visible}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    setMakeTradeOfferData={setMakeTradeOfferData}
                    makeTradeOfferData={makeTradeOfferData}
                    rowCount={rowCount}
                    setHasMore={setHasMore}
                    setRowCount={setRowCount}
                    setPagination={setPagination}
                    pagination={pagination}
                  />
                )}
                {selectedCategory === 'Pending Accept Trade Offers' && (
                  <PendingAcceptTradeOffers
                    setSelectedCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                    isMobile={isMobile}
                    sorting={sorting}
                    setSorting={setSorting}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    hasMore={hasMore}
                    setVisible={setVisible}
                    visible={visible}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    pendingTradeOfferAccepts={pendingTradeOfferAccepts}
                    setPendingTradeOfferAccepts={setPendingTradeOfferAccepts}
                    rowCount={rowCount}
                    setHasMore={setHasMore}
                    setRowCount={setRowCount}
                    setPagination={setPagination}
                    pagination={pagination}
                  />
                )}
                {selectedCategory === 'History' && (
                  <History
                    setSelectedCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                    isMobile={isMobile}
                    sorting={sorting}
                    setSorting={setSorting}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    hasMore={hasMore}
                    setVisible={setVisible}
                    visible={visible}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    historyData={historyData}
                    setHistoryData={setHistoryData}
                    rowCount={rowCount}
                    setHasMore={setHasMore}
                    setRowCount={setRowCount}
                    setPagination={setPagination}
                    pagination={pagination}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { useSelector } from 'react-redux';
export const Filter = ({
  // complatedArray,
  selectedCategory,
  onCategoryChange,
  isMenuOpen,
  toggleMenu
  // onOfferMadeChange
}) => {
  const categoryActiveCounts = useSelector((state) => state.buy.buyFilterCount);

  return (
    <div className={`inventory-table-inner mobile-filter ${isMenuOpen && 'side-filter-menu-show'} `}>
      {isMenuOpen && (
        <div className="back-text" onClick={toggleMenu}>
          <img src="/images/common/left-arrow.svg" alt="left-aarow" /> Back
        </div>
      )}
      <div className="category-item-div">
        <div className="category-main-heading-div">
          <div className="category-heading text-white font-14 opacity-white">Active</div>

          <div
            className={`category-item d-flex justify-content-between ${selectedCategory === 'Winning Bids' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Winning Bids') {
                onCategoryChange('Winning Bids');
              }
            }}
          >
            <div className="text-white font-16">Winning bids</div>
            {categoryActiveCounts && categoryActiveCounts.winning ? (
              <span className="text-white font-16">{`(${categoryActiveCounts.winning})`}</span>
            ) : (
              <span className="text-white font-16">(0)</span>
            )}
          </div>
          <div
            className={`category-item d-flex justify-content-between ${selectedCategory === 'Losing Bids' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Losing Bids') {
                onCategoryChange('Losing Bids');
              }
            }}
          >
            <div className="text-white font-16">Losing bids</div>
            {categoryActiveCounts && categoryActiveCounts.not_wining ? (
              <span className="text-white font-16">{`(${categoryActiveCounts.not_wining})`}</span>
            ) : (
              <span className="text-white font-16">(0)</span>
            )}
          </div>
          <div
            className={`category-item d-flex justify-content-between ${selectedCategory === 'Offers Made' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Offers Made') {
                onCategoryChange('Offers Made');
              }
            }}
          >
            <div className="text-white font-16">Offers Made</div>
            {categoryActiveCounts && categoryActiveCounts.offer_made ? (
              <span className="text-white font-16">{`(${categoryActiveCounts.offer_made})`}</span>
            ) : (
              <span className="text-white font-16">(0)</span>
            )}
          </div>
        </div>
        <div className="category-main-heading-div">
          <div className="category-heading text-white font-14 opacity-white">panding</div>

          <div
            className={`category-item ${selectedCategory === 'Make payment' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Make payment') {
                onCategoryChange('Make payment');
              }
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="text-white font-16">Make payment</div>
              {categoryActiveCounts && categoryActiveCounts.makepayment ? (
                <span className="text-white font-16">{`(${categoryActiveCounts.makepayment})`}</span>
              ) : (
                <span className="text-white font-16">(0)</span>
              )}
            </div>
            {categoryActiveCounts && Number(categoryActiveCounts.makepayment) !== 0 && (
              <div className="d-flex gap-5 align-items-center mt-10" style={{ marginLeft: '-5px' }}>
                <img src={'/images/common/redspot.gif'} alt="" width={25} height={25} className="img-fluid" />
                <p className="font-14 pb-0">Action required</p>
              </div>
            )}
          </div>

          <div
            className={`category-item d-flex justify-content-between ${selectedCategory === 'Waiting For Trade offer' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Waiting For Trade offer') {
                onCategoryChange('Waiting For Trade offer');
              }
            }}
          >
            <div className="text-white font-16">Waiting For Trade offer</div>
            {categoryActiveCounts && categoryActiveCounts.make_trade ? (
              <span className="text-white font-16">{`(${categoryActiveCounts.make_trade})`}</span>
            ) : (
              <span className="text-white font-16">(0)</span>
            )}
          </div>

          <div
            className={`category-item ${selectedCategory === 'Accept Trade offers' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Accept Trade offers') {
                onCategoryChange('Accept Trade offers');
              }
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="text-white font-16">Accept Trade offers</div>
              {categoryActiveCounts && categoryActiveCounts?.complate_trade ? (
                <span className="text-white font-16">{`(${categoryActiveCounts?.complate_trade})`}</span>
              ) : (
                <span className="text-white font-16">(0)</span>
              )}
            </div>
            {categoryActiveCounts && categoryActiveCounts.complate_trade > 0 && (
              <div className="d-flex gap-5 align-items-center mt-10" style={{ marginLeft: '-5px' }}>
                <img src={'/images/common/redspot.gif'} alt="" width={25} height={25} className="img-fluid" />
                <p className="font-14 pb-0">Action required</p>
              </div>
            )}
          </div>
        </div>

        <div className="category-main-heading-div">
          <div className="category-heading text-white font-14 opacity-white">Complated</div>
          {/* {complatedArray.map((item, index) => ( */}
          <div
            className={`category-item d-flex justify-content-between ${selectedCategory === 'History' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'History') {
                onCategoryChange('History');
              }
            }}
          >
            <div className="text-white font-16">History</div>
            {/* {categoryActiveCounts && categoryActiveCounts.history ? (
              <span className="text-white font-16">{`(${categoryActiveCounts.buy_sell_history})`}</span>
            ) : (
              <span className="text-white font-16">(0)</span>
            )} */}
          </div>
          <div
            className={`category-item d-flex justify-content-between ${selectedCategory === 'Lost Auctions' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Lost Auctions') {
                onCategoryChange('Lost Auctions');
              }
            }}
          >
            <div className="text-white font-16">Lost Auctions</div>
          </div>
          {/* ))} */}
        </div>
      </div>
    </div>
  );
};

Filter.propTypes = {
  // complatedArray: PropTypes.array,
  selectedCategory: PropTypes.string,
  isMenuOpen: PropTypes.bool,
  onCategoryChange: PropTypes.func,
  toggleMenu: PropTypes.func
  // onOfferMadeChange: PropTypes.func
};

import React, { useEffect, useState } from 'react';
import { Accordion, SkeletonLoader } from 'components';
import { useApi } from 'hooks/useApi';
import { faq_page } from 'api';

export const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [activeChildIndex, setActiveChildIndex] = useState(null);
  document.title = 'Zipit - Frequently Asked Questions';

  const [accordionData, setAccordionData] = useState([]);
  const [faqData, setFaqData] = useState({
    heading: '',
    description: ''
  });
  const { apiCaller: onGetFaq } = useApi({
    dataGetter: faq_page
  });

  const fetchData = () => {
    let params = {
      pages: 'faq'
    };
    onGetFaq(params)
      .then((res) => {
        setAccordionData(res.data.item);
        setFaqData({
          heading: res.data.title,
          description: res.data.desription
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => console.error(`Failed to fetch terms & conditions data: ${err}`));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
    setActiveChildIndex(null);
  };

  const handleToggle = (event, index) => {
    event.stopPropagation();
    setActiveChildIndex(activeChildIndex === index ? null : index);
  };
  return (
    <>
      <div className="faq-div">
        <div className="container-fluid">
          <div className="container">
            <div className="similar-product-heading text-center border-0 max-900">
              <p className="main_heading">FAQs</p>
              <p className="sub-heading">{faqData.heading}</p>
              <p className="">{faqData.description}</p>
            </div>

            {isLoading ? (
              <SkeletonLoader
                width={'100%'}
                height={82}
                count={10}
                color="#0b051b"
                highlightColor="#242347"
                containerClassName="faq-inner-div"
                className="single-accordion-item"
                borderRadius={0}
              />
            ) : (
              <div className="faq-inner-div">
                {accordionData.map(({ main_title, list }, index) => (
                  <Accordion
                    key={index}
                    title={main_title}
                    items={list}
                    isActive={activeIndex === index}
                    onClick={() => handleAccordionClick(index)}
                    handleToggle={handleToggle}
                    activeChildIndex={activeChildIndex}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

export const UserOfferRemoveModal = ({ closeModal, offerRemoveByUserHandler, offerCountDownData }) => {
  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="edit-price-modal sell-model">
      <div className="sell-modal-area ">
        <div className="position-relative steam-account-popup">
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>
          <p className="offer-heading">Delete offer</p>
          <div className="mt-20">
            <p className="opacity-white">Are you sure you want to delete this Offer? This action cannot be undone.</p>
          </div>
          <div className="offer-button-div">
            <button
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              className="delete-button"
              onClick={() => offerRemoveByUserHandler(offerCountDownData)}
            >
              Delete
            </button>
            <button aria-label="Close Modal" aria-labelledby="close-modal" className="grey-button" onClick={closeModal}>
              cancel
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};

UserOfferRemoveModal.propTypes = {
  closeModal: PropTypes.func,
  offerRemoveByUserHandler: PropTypes.func,
  offerCountDownData: PropTypes.any
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
export const Offering = ({
  closeModal,
  acceptTerms,
  setAcceptTerms,
  bargainPrice,
  defaultCurrency,
  handleSubmitOffer,
  setOfferAmount,
  offerAmount,
  item,
  userOfferCount
}) => {
  const [error, setError] = useState('');

  // Handle input change for offer amount
  const handleOfferAmountChange = (e) => {
    const value = e.target.value;
    const decimalRegex = /^\d{0,7}(\.\d{0,2})?$/;
    setOfferAmount(value);
    if (value < parseFloat(bargainPrice)) {
      setError('Bargain price cannot be less than 20% off the original price.');
    } else if (value.startsWith('0')) {
      setError('The minimum price is 1€');
    } else if (!decimalRegex.test(value)) {
      setError('Price can have a maximum of 7 figures and 2 decimal places');
    } else {
      setError('');
    }
  };
  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal">
      <div className="sell-modal-area offer-modal">
        <div className="offer-popup position-relative">
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>
          <p className="offer-heading">Make an offer</p>
          <div className="alert-box">
            <p>Read this before you continue</p>
            <ul>
              <li>You can make a maximum of 3 offers per item</li>
              <li>You can make an offer without balance</li>
              <li>You cannot remove your offer if the buyer has accepted it</li>
              <li>Your offer is locked for 30 minutes</li>
              <li>Failure to pay if your offer has been accepted can result in a permanent ban</li>
            </ul>
          </div>
          <p className="pt-10 pb-10 font-700">You have {3 - (userOfferCount || 0)} bargain attempts left for this item</p>
          <div className="d-flex justify-content-between amount-offer-div">
            <p className="pt-10">Current price</p>
            <div>
              <p className="font-700 pt-10">
                {defaultCurrency && defaultCurrency?.symbol}
                {item.startBid && Number(item.startBid).toFixed(2)}
              </p>
            </div>
            <div className="input-outer-div">
              <div className={`grey-background input-main-div ${error ? 'invalid-input' : 'valid-input'}`}>
                <label className="input-lable">Your offer</label>
                <input
                  type="text"
                  name="price"
                  placeholder="0.00"
                  value={offerAmount}
                  // className={``}
                  onChange={handleOfferAmountChange}
                />
              </div>
              <p className="opacity-white font-12 mt-5 letter-space">
                Your offer must be at least {defaultCurrency && defaultCurrency?.symbol}
                {Number(bargainPrice).toFixed(2)}
              </p>
              <span style={{ color: 'red' }}>{error}</span>
            </div>
          </div>
          <div className="secure-text-div">
            <div className="d-flex gap-10">
              <img src="/images/market/shield.svg" alt="modal-close" className="img-fluid" />
              <div className="secure-text">
                <p className="font-700 pb-5">3DSecure is required for credit card payments.</p>
                <p className="font-12 opacity-white">What is 3DSecure really and how do I enable it?</p>
              </div>
            </div>
            <div className="secure-description mt-20">
              <p className="font-14 pb-0 font-700">
                3DS, or 3D Secure, is basically a two-factor authentication for your payments, much like logging in with Steam using the
                Steam Guard Authenticator. That means, when you pay, you have to confirm your payment, either in your banking app, through a
                text message sent to your phone, or whatever method your bank uses to confirm payments.
              </p>
            </div>
          </div>
          <label className="sell-label">
            I accept the
            <a href="/terms" rel="noreferrer" target="_blank" className="text-white text-underline">
              terms
            </a>
            and confirm that I want to sell this item.
            <input type="checkbox" name="term" onChange={(e) => setAcceptTerms(e.target.checked)} />
            <span className="checkmark l-0"></span>
          </label>
          <div className="offer-button-div">
            <button
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              className="green-button w-100"
              disabled={!acceptTerms || error}
              onClick={handleSubmitOffer}
            >
              Confirm offer
            </button>
          </div>
          <p className="opacity-white pt-10 text-center">Seller will have 24 hours to accept your offer.</p>
        </div>
      </div>
    </aside>
  );
};

Offering.propTypes = {
  closeModal: PropTypes.func,
  setAcceptTerms: PropTypes.func,
  acceptTerms: PropTypes.bool,
  bargainPrice: PropTypes.any,
  defaultCurrency: PropTypes.object,
  handleSubmitOffer: PropTypes.func,
  offerAmount: PropTypes.any,
  setOfferAmount: PropTypes.func,
  item: PropTypes.any,
  userOfferCount: PropTypes.number
};

import React from 'react';
import propTypes from 'prop-types';

export const TransctionModal = ({ closeModal, closeModalRef }) => {
  return (
    <div>
      <aside role="dialog" tabIndex="-1" aria-modal="true" className="edit-price-modal transction-modal">
        <div className="sell-modal-area edit-listing-modal">
          <div className="position-relative steam-account-popup">
            <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
              <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
            </button>
            <div className="" ref={closeModalRef}>
              <p id="howitwork" className="offer-heading">
                policies
              </p>
              <div className="transction-right-div">
                <div className="transction-description">
                  <p className="font-20">Ready to use</p>
                  <p className="opacity-white">
                    Balance that has arrived on your Zipit account from recently completed trades. This can be used for buying or you can
                    withdraw it anytime.
                  </p>
                </div>
                <div className="transction-description">
                  <p className="font-20">Pending balance</p>
                  <p className="opacity-white">
                    Money in transit from buyers towards your Zipit account from recently completed trades paid by credit card. This takes
                    between 1-3 banking days within the EU and up to 5 banking days outside of the EU.
                  </p>
                </div>
                <div className="transction-description">
                  <p className="font-20">Ready to use changed to 0?</p>
                  <p className="opacity-white">
                    This means a payout towards your bank account has been carried out. Depending on your bank, they will appear on your
                    bank account no later than the next banking day within the EU and up to 2 banking days outside of the EU.
                  </p>
                </div>
                <div className="transction-description">
                  <p className="font-20">Payment returned (optionally KYC not working)?</p>
                  <p className="opacity-white">Please contact support!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};
TransctionModal.propTypes = {
  closeModal: propTypes.func,
  closeModalRef: propTypes.any
};

import { SettingSideBar } from 'components';
import { getCurrencyItems } from 'lib';
import React, { useEffect, useRef, useState } from 'react';

export const Withdraw = () => {
  document.title = 'Zipit - WithDraw';

  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const [selectedOption, setSelectedOption] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState([]);

  const [price, setPrice] = useState('');
  const [error, setError] = useState('');

  const buttonRef = useRef(null);

  const onChangePrice = (e) => {
    const { value } = e.target;

    if (value < 5) {
      setError('Please select an amount between 5 and 10000€');
    } else {
      setError('');
    }
    setPrice(e.target.value);
  };

  const fetchData = async () => {
    try {
      const productData = await getCurrencyItems();
      setOptions(productData);
      // Set default selected option to Euro (€)
      const euroOption = productData.find((option) => option.code === 'EUR');
      setSelectedOption(euroOption);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
  };

  const handleOutsideClick = (event) => {
    if (buttonRef.current && buttonRef.current.contains(event.target)) return;
    setShowOptions(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="profile-page-main-div">
      <div className="container-fluid">
        <div className="container">
          <div className="profile-page-inner-div ">
            <SettingSideBar />
            <div className="deposit-main-div profile-page-common-div notification-page">
              <div className="profile-page-heading">
                <p>Withdraw</p>
              </div>
              <div className="deposit-outer-div">
                <div className="deposit-inner-div">
                  <div className="row">
                    <div className="col-lg-6 col">
                      <div className="input-outer-div mb-10">
                        <label className="font-14 opacity-white pb-5 d-block">price</label>
                        <div className={`grey-background input-main-div border-0 ${price !== '' && price < 5 ? 'invalid-input' : ''}`}>
                          <div className="position-relative prefix-div">
                            <p className="prefix l-0">{defaultCurrency && defaultCurrency.symbol}</p>
                            <input
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              name="price"
                              placeholder="0.00"
                              className="error-msg ml-3"
                              value={price}
                              onChange={onChangePrice}
                              onKeyDown={(e) => {
                                if (
                                  (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                                    e.key !== 'Backspace' &&
                                    e.key !== 'Delete' &&
                                    e.key !== 'ArrowLeft' &&
                                    e.key !== 'ArrowRight' &&
                                    e.key !== '.') || // Allow dot key
                                  (e.key === '.' && price.includes('.')) // Ensure only one dot is entered
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {error && <p className="color-red font-14 pb-0">{error}</p>}
                    </div>
                    <div className="col-lg-6 col">
                      <div className="form-control mb-0">
                        <label className="font-14 opacity-white pb-5 d-block position-relative fit-content">
                          Preferred display currency
                          <img src="/images/sell-modal/question.svg" alt="zipit" className="img-fluid tooltip-image" />
                          <div className="tooltiptext">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                          </div>
                        </label>

                        <div className=" withdraw-select-box" ref={buttonRef}>
                          <div className="currency-div w-100 " onClick={() => setShowOptions(!showOptions)}>
                            <div className="d-flex gap-10">
                              {selectedOption && (
                                <>
                                  <span className="currency-symbol">{selectedOption.symbol}</span>
                                  <span>{selectedOption.code}</span>
                                </>
                              )}
                            </div>
                            <img src="/images/header/down-arrow.svg" alt="Zipit" className="img-fluid"></img>
                          </div>
                          {showOptions && (
                            <ul className="options">
                              {options.map((option) => (
                                <li key={option.code} className="option" onClick={() => handleSelect(option)}>
                                  <span className="currency-symbol">{option.symbol}</span>
                                  <span className="currency-code">{option.currency}</span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="offer-button-div">
                    <button
                      aria-label="Close Modal"
                      aria-labelledby="close-modal"
                      className="green-button"
                      disabled={price === '' || error !== ''}
                    >
                      Withdraw
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

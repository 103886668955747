import React, { useMemo, useState } from 'react';

import './style.css';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { convertCurrency, itemNameConverter, wearNameConverter } from 'lib';
import Cookies from 'js-cookie';

export const Product = ({
  rowCount,
  OnSellModal,
  data,
  loading,
  setBulkSellItem,
  setWarningModal,
  setBulkSellModal,
  rowSelection,
  setRowSelection,
  setIsUserBanModalOpen,
  userBan,
  currentPage,
  pagination,
  refreshing,
  selectedCategory
}) => {
  const navigate = useNavigate();
  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const showSecondPrice = currency && currency.code !== defaultCurrency.code;
  const [sorting, setSorting] = useState([]);

  const selectedRows = Object.values(rowSelection);
  const bulkButtonDisabled = selectedRows.length <= 1;

  const onBulkSell = () => {
    const selectedRowModals = table.getSelectedRowModel();
    // eslint-disable-next-line
    const selectedRows = selectedRowModals.flatRows.map((row) => {
      /* eslint-disable */

      const modifiedRow = { ...row.original, startBid: '', selltype: 'market' };
      return modifiedRow;
      // return row.original;
      /* eslint-enable */
    });

    const auctionTerms = Cookies.get('USER_SHOW_CREATE_AUCTION_WARNING');
    if (auctionTerms === null || auctionTerms === undefined) {
      document.body.classList.add('modal-open');
      setWarningModal(true); // Open the warning modal
      setBulkSellItem(selectedRows);
    } else {
      document.body.classList.add('modal-open');
      if (userBan.status === 'block') {
        setIsUserBanModalOpen(true);
      } else {
        setBulkSellModal(true);
        setBulkSellItem(selectedRows);
      }
    }
  };

  const RoundedPrice = (value) => {
    // Round the price to two decimal places
    const roundedPrice = Math.round(value * 100) / 100;

    return roundedPrice.toFixed(2);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'item.name',
        header: 'Item',
        size: 150,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          /* eslint-disable */
          const stickers = row.original.item?.stickers;
          /* eslint-enable */
          return (
            /* eslint-disable */

            <div id={`${row?.original?.item_id}`}>
              <div className="goods-card-main-div position-relative">
                <div
                  className="goods-card-img"
                  style={{
                    backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.item?.imageUrl}")`
                  }}
                ></div>
                <div className="wear-name">
                  <p className="font-10 font-500">{wearNameConverter(row?.original?.wear)}</p>
                  <p className="font-10 font-500">
                    {row?.original?.wear !== 'N/A' ? `/ ${Number(row?.original?.float_id).toFixed(4)} ` : ''}
                  </p>
                </div>

                <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                <div
                  className="border"
                  style={{
                    borderBottom: `5px solid ${row?.original?.item?.r_color}`
                  }}
                ></div>
              </div>
              <div className="display-product-name">
                <div className="inventory-product-name">
                  <h3 className="productname">{itemNameConverter(row?.original?.item?.fullname)}</h3>
                  <div className="d-flex gap-5">
                    <p className="productname-desc">{row?.original?.item?.category}</p>*
                    <p className="productname-desc">{row?.original?.item?.subCategory}</p>
                  </div>
                </div>
                {stickers && stickers.length > 0 && (
                  <div className="previous-sales-sticker product-sticker mt-10">
                    {stickers.map((item, index) => {
                      return (
                        <div
                          className="product-display-sticker"
                          key={index}
                          // dangerouslySetInnerHTML={{ __html: item.value }}
                        >
                          <div className="single-sticker-div">
                            <img src={item.value} alt="sticker" />
                            {item.key && (
                              <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                <span>{item.key}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            /* eslint-enable */
          );
        }
      },
      {
        accessorKey: 'wear',
        header: 'Wear',
        size: 150
      },
      {
        // accessorKey: 'item.category',
        header: 'Price',
        size: 100,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          /* eslint-disable */
          return (
            <>
              <p>
                {defaultCurrency && defaultCurrency?.symbol} {RoundedPrice(row?.original?.average_price)}
                {/* {defaultCurrency.symbol} 10.00 */}
              </p>
              {showSecondPrice && (
                <p>
                  {currency && currency?.symbol} {convertCurrency(row?.original?.average_price)}
                </p>
              )}
            </>
          );
          /* eslint-enable */
        }
      },
      {
        accessorKey: 'bulkSell',
        header: (
          <div>
            <button onClick={onBulkSell} disabled={bulkButtonDisabled}>
              <img src="/images/inventory/list-icon.svg" alt="list-icon" />
              Sell in bulk
            </button>
          </div>
        ),
        // eslint-disable-next-line
        Cell: ({ row }) => {
          /* eslint-disable */
          const isPrivate = row.original.isPrivate;
          const market_has_id = row.original.market_has_id;
          return (
            <div>
              {row.original.market_status !== '1' ? (
                market_has_id !== undefined ? (
                  <button
                    className="view-button"
                    style={{ backgroundColor: '#ffffff1a' }}
                    onClick={() => navigate(`/view/private/${market_has_id}`)}
                  >
                    view
                  </button>
                ) : (
                  <button onClick={() => OnSellModal(row.original)} className="inventory-sell-button">
                    Sell
                  </button>
                )
              ) : (
                <>
                  {isPrivate === '1' ? (
                    <button
                      className="view-button"
                      style={{ backgroundColor: '#ffffff1a' }}
                      onClick={() => navigate(`/market/${row.original.m_id}`)}
                    >
                      view
                    </button>
                  ) : (
                    <button
                      className="view-button"
                      style={{ backgroundColor: '#ffffff1a' }}
                      onClick={() => navigate(`/listing/${row.original.item_id}`)}
                    >
                      view
                    </button>
                  )}
                </>
              )}
            </div>
            /* eslint-enable */
          );
        }
      }
    ],
    [bulkButtonDisabled, convertCurrency, RoundedPrice]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    // enableStickyHeader: true,
    enableRowSelection: (row) => {
      /* eslint-disable */
      const market_has_id = row.original.market_has_id;
      let marketStatus;
      if (market_has_id !== undefined) {
        marketStatus = '1';
      } else {
        marketStatus = row.original.market_status;
      }
      /* eslint-enable */

      return marketStatus !== '1';
    },
    getRowId: (row) => /* eslint-disable */ row?.item_id /* eslint-enable */,
    onRowSelectionChange: setRowSelection,
    // initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onSortingChange: setSorting,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableBottomToolbar: true,
    enablePagination: false,
    muiBottomToolbarProps: {
      // sx: {
      //   bottom: data.length > 3 ? 0 : '56px'
      // },
      className: 'table-bottom-bar-pagination',
      sx: {
        // bottom: '56px',
        padding: 0,
        '& .MuiBox-root ': {
          justifyContent: 'end',
          marginTop: '10px'
        },
        '& .MuiBox-root ': {
          justifyContent: 'end',
          marginTop: '10px'
        },
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#fff',
          height: '3px'
        },
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar2Indeterminate': {
          backgroundColor: '#6117BF',
          height: '3px'
        },
        '& .MuiLinearProgress-root.MuiLinearProgress-colorPrimary': {
          backgroundColor: '#6117BF',
          height: '3px'
        }
      }
    },
    // muiTableContainerProps: {
    //   className: data.length > 3 ? 'zipit-data-table' : 'zipit-data-table-inventory'
    // },
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        {rowCount > 0 && (
          <div className="pagination">
            {pagination.from} - {pagination.to} of {rowCount}
          </div>
        )}
      </>
    ),
    muiTablePaperProps: {
      sx: {
        borderRadius: '0',
        border: '1px solid rgb(255 255 255 / 20%) ',
        overflow: 'initial',
        '& .MuiBox-root ': {
          // maxHeight: 'calc(100vh - 250px)'
        }
      }
    },
    muiSelectCheckboxProps: {
      className: 'zipit-selected-checkbox'
    },
    muiTableBodyProps: {
      sx: {
        '& tr td': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        },
        '& tr:not([data-selected="true"])': {
          backgroundColor: 'transparent'
        }
      }
    },
    localization: {
      noRecordsToDisplay: ''
    },
    mrtTheme: {
      baseBackgroundColor: '#0b051b',
      selectedRowBackgroundColor: '#ffffff1a'
    },
    muiTableHeadProps: {
      sx: {
        '& tr th': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        }
      },
      className: 'buytable-head'
    },
    muiSkeletonProps: ({ row, column }) => {
      if (row && column) {
        // Apply static skeleton properties for each cell
        return {
          animation: 'wave',
          variant: 'rect',
          width: column.size,
          height: 70,
          className: 'mui-skeleton-zipit'
        };
      }
    },
    muiTableContainerProps: {
      sx: {
        overflow: 'initial',
        '& > .MuiBox-root': {
          maxHeight: 'calc(100vh - 200px)',
          backgroundColor: 'transparent'
          // display: 'block'
        }
      }
    },
    muiCircularProgressProps: {
      color: 'secondary',
      Component: (
        <>
          {selectedCategory === undefined && (
            <div
              className="inventory-loader-div"
              style={{
                maxHeight: 'calc(100vh - 250px)',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '10px'
              }}
            >
              <img src="/images/common/zipit-load.gif" alt="zipit" style={{ maxWidth: '87px' }} />
              {refreshing && (
                <div style={{ textAlign: 'center', fontSize: '22px', lineHeight: '25px' }}>
                  <p>Refresh Inventory</p>
                  <p>take a minute</p>
                </div>
              )}
            </div>
          )}
        </>
      ),
      className: refreshing ? 'zipit-loader-hide' : 'zipit-loader',
      sx: {
        backgroundColor: 'transparent'
      },
      thickness: 5,
      size: 75
    },
    rowCount,
    state: {
      isLoading: currentPage === 1 ? loading : false,
      // showSkeletons: currentPage === 1 ? loading : false,
      rowSelection,
      sorting,
      // showLoadingOverlay: currentPage === 1 ? loading : false,
      showProgressBars: currentPage === 1 ? false : loading
    }
  });

  return (
    <>
      {rowCount && rowCount > 0 ? (
        <MaterialReactTable table={table} />
      ) : (
        <div className="inventory-empty-div">
          <img src="/images/inventory/inventory-empty.svg" alt="zipit" className="img-fluid similar-product-img" />
          <p className="font-26 text-white pb-5">No items</p>
        </div>
      )}
    </>
  );
};

Product.propTypes = {
  data: PropTypes.array,
  rowCount: PropTypes.number,
  OnSellModal: PropTypes.func,
  loading: PropTypes.bool,
  setBulkSellItem: PropTypes.func,
  setWarningModal: PropTypes.func,
  setBulkSellModal: PropTypes.func,
  setRowSelection: PropTypes.func,
  rowSelection: PropTypes.object,
  setIsUserBanModalOpen: PropTypes.func,
  userBan: PropTypes.object,
  currentPage: PropTypes.number,
  pagination: PropTypes.object,
  refreshing: PropTypes.bool,
  selectedCategory: PropTypes.string
};

import React from 'react';

import { MaterialReactTable } from 'material-react-table';
import PropTypes from 'prop-types';

export const BulkSellAdd = ({ table }) => {
  return <MaterialReactTable table={table} />;
};

BulkSellAdd.propTypes = {
  data: PropTypes.array,
  rowCount: PropTypes.number,
  setBulkSellItem: PropTypes.func,
  setRowSelection: PropTypes.func,
  rowSelection: PropTypes.object,
  tableContainerRef: PropTypes.any,
  fetchMoreOnBottomReached: PropTypes.func,
  table: PropTypes.any
};

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.css';
import { OfferCountDown, SkeletonLoader, WishlistIcon, CountdownTimer } from 'components';
import { convertCurrency, itemNameConverter, notificationTimeAgo, wearNameConverter } from 'lib';
import { useSelector } from 'react-redux';

export const ProductSkin = ({
  onCheckoutModal,
  onOfferModal,
  item,
  Bearer_Token,
  isLoading,
  defaultCurrency,
  currency,
  showSecondPrice,
  bidPrice,
  onBidChange,
  onBidAdd,
  errors,
  isLoadingBid,
  onEditListing,
  successMes,
  bidArray,
  followHandle,
  follow,
  followingCount,
  tradeCount,
  isLoadingProduct,
  onSellerOfferModal,
  isOfferLoading,
  offerAcceptCountDown,
  userOfferCount,
  offerCountDownData,
  offerRemoveByUserModalHandler
}) => {
  // const isLoadingProduct = true;
  const navigate = useNavigate();
  const sellerUserId = useSelector((state) => state.user.userData.user_id);
  const RoundedPrice = (value) => {
    // Round the price to two decimal places
    const roundedPrice = Math.round(value * 100) / 100;

    return roundedPrice;
  };

  return (
    <>
      <div
        className={`market-product-detail-div ${
          (!offerAcceptCountDown && Number(item?.user_id) === Number(sellerUserId)) ||
          (offerAcceptCountDown && Number(offerAcceptCountDown.steam_id))
            ? 'listing-available'
            : ''
        }`}
      >
        <div className="container-fluid">
          <div className="container">
            {isLoadingProduct ? (
              <div className="main-div" style={{ minHeight: '63px' }}></div>
            ) : (
              <>
                <div className="main-div">
                  <div className="breadcrumb">
                    <Link to="/market" className="text-white">
                      Market
                    </Link>
                    <img src="/images/market/next.svg" alt="Zipit" className="img-fluid" height={16} width={12} />
                    <p className="text-white">{item && item.category}</p>
                    <img src="/images/market/next.svg" alt="Zipit" className="img-fluid" height={16} width={12} />
                    <p className="text-white">{item && itemNameConverter(item.item.fullname)}</p>
                  </div>
                </div>
              </>
            )}

            <div className="market-product-inner-div">
              <div className="market-product-detail-main">
                <div className="single-product-detail single-product-left-div position-relative">
                  {isLoadingProduct ? (
                    <SkeletonLoader
                      width={0}
                      height={0}
                      color="#0b051b"
                      highlightColor="#242347"
                      borderRadius={0}
                      className="market-wishlist"
                    />
                  ) : (
                    <>
                      {item?.user_id !== sellerUserId && (
                        <div
                          className={`wishlist-icon tooltip-image market-wishlist ml-0 
                            ${follow.some((f) => Number(f.market_id) === Number(item?.m_id) && f.status) ? 'follow-active' : 'unfollow'}`}
                          onClick={() => followHandle(item?.m_id)}
                        >
                          <WishlistIcon />
                          <div className="tooltiptext">
                            {follow.some((f) => Number(f.market_id) === Number(item?.m_id) && f.status) ? (
                              <p className="font-14 pb-0">Unfollow</p>
                            ) : (
                              <p className="font-14 pb-0">Follow</p>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="signle-product-heading-div">
                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="99%"
                        height={15}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        className="font-14 opacity-white mb-10"
                      />
                    ) : (
                      <p className="font-14 opacity-white">
                        {item && item.category} {item && '•' + item.item.subCategory}
                      </p>
                    )}

                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="99%"
                        height={40}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        className="sub-heading"
                      />
                    ) : (
                      <h3 className="sub-heading text-uppercase text-white">{item && itemNameConverter(item.item.fullname)}</h3>
                    )}
                  </div>
                  {isLoadingProduct ? (
                    <SkeletonLoader
                      width="99%"
                      height={100}
                      color="#0b051b"
                      highlightColor="#242347"
                      borderRadius={0}
                      className="description mt-10"
                    />
                  ) : (
                    item?.item?.description && (
                      <div className="description">
                        <div className="opacity-white" dangerouslySetInnerHTML={{ __html: item?.item?.description }} />
                      </div>
                    )
                  )}
                  <div className="market-product-info-div mt-30">
                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="99%"
                        height={20}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        count={6}
                        className="sell-product-info mb-0"
                      />
                    ) : (
                      <>
                        {item && item.wear && (
                          <div className="sell-product-info">
                            <p className="opacity-white">Waer</p>
                            <p>{item && item.wear}</p>
                          </div>
                        )}
                        {item && Number(item.float_id) > 0 && (
                          <div className="sell-product-info">
                            <p className="opacity-white">Float</p>
                            <p>{item && Number(item.float_id).toFixed(8)}</p>
                          </div>
                        )}
                        {item && item.item.rarity_name && (
                          <div className="sell-product-info">
                            <p className="opacity-white">Rarity</p>
                            <p>
                              <span className="classified" style={{ backgroundColor: `${item && item.item.r_color}` }}></span>
                              {item && item.item.rarity_name}
                            </p>
                          </div>
                        )}
                        {item && Number(item.paintseed) > 0 && (
                          <div className="sell-product-info">
                            <p className="opacity-white">Pattern ID</p>
                            <p>{item && item.paintseed}</p>
                          </div>
                        )}
                        {item && Number(item.paintindex) > 0 && (
                          <div className="sell-product-info">
                            <p className="opacity-white">Finish Catalog</p>
                            <p>{item && item.paintindex}</p>
                          </div>
                        )}
                        {/* {item && item.float_id && (
                          <div className="sell-product-info">
                            <p className="opacity-white">Item Specials</p>
                            <p>{item && Number(item.float_id).toFixed(8)}</p>
                          </div>
                        )} */}
                        {item && Number(item.fade_id) !== 0 && (
                          <div className="sell-product-info">
                            <p className="opacity-white">Fade</p>
                            <p>{`${item && Number(item.fade_id).toFixed(2)}%`}</p>
                          </div>
                        )}
                        <div className="d-flex gap-10">
                          {item && Number(item.float_id) !== 0 && String(item.float_id).split('.')[1].substring(0, 2) === '00' && (
                            <div className="product-float-id">
                              <img src="/images/market/star-float.svg" alt="star-float" />
                              Float
                              {item && item.float_id && (
                                <div className="sticker-tooltip sticker-tooltip-top market-sticker-tooltip">
                                  <p>low float</p>
                                </div>
                              )}
                            </div>
                          )}
                          {item && ['Sapphire', 'Ruby', 'Black Pearl', 'Emerald'].includes(item?.phase) && (
                            <div
                              className={`phases-id ${
                                item?.phase === 'Sapphire'
                                  ? 'sapphire-phase-id'
                                  : item?.phase === 'Ruby'
                                    ? 'ruby-phase-id'
                                    : item?.phase === 'Black Pearl'
                                      ? 'black-pearl-phase-id'
                                      : item?.phase === 'Emerald'
                                        ? 'emerald-phase-id'
                                        : ''
                              }`}
                            >
                              {item?.phase === 'Sapphire' && <img src="/images/market/sapphire-phase.svg" alt="sapphire-phase" />}
                              {item?.phase === 'Ruby' && <img src="/images/market/ruby.svg" alt="ruby-phase" />}
                              {item?.phase === 'Black Pearl' && <img src="/images/market/black-pearl.svg" alt="black-pearl-phase" />}
                              {item?.phase === 'Emerald' && <img src="/images/market/emerald.svg" alt="emerald-phase" />}
                              {item?.phase}
                            </div>
                          )}
                        </div>
                        {item && item.isPrivate === '1' && (
                          <div className="sell-product-info private-listing-info">
                            <p className="opacity-white private-listing">Private Listing</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {isLoadingProduct ? (
                  <div className="single-product-detail single-product-middle-div pb-0 single-product-skeleton">
                    <SkeletonLoader
                      width="99%"
                      height={500}
                      color="#0b051b"
                      highlightColor="#242347"
                      borderRadius={0}
                      className="single-product-image"
                    />
                  </div>
                ) : (
                  // <div className="single-product-middle-main-div">
                  <div
                    className={`single-product-detail single-product-middle-div ${
                      item && item?.item?.stickers.length > 0 ? 'sticker-aviailable' : ''
                    }`}
                    style={{
                      borderBottom: `5px solid ${item && item.item.r_color}`
                    }}
                  >
                    {/* {offerAcceptCountDown &&
                    Number(offerAcceptCountDown.steam_id) === Number(sellerUserId) ? (
                      <div className="auction-win-div">
                        <img
                          src="/images/common/trophy.svg"
                          alt="zipit"
                          height={20}
                          width={20}
                          className="img-fluid"
                        />
                        <div>
                          <p>
                            Congratulations! You sold this item for
                            {defaultCurrency && defaultCurrency.symbol}
                            {offerAcceptCountDown.offer}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="auction-win-div">
                        <img
                          src="/images/market/listhostimage.svg"
                          alt="zipit"
                          height={20}
                          width={20}
                          className="img-fluid"
                        />
                        <div>
                          <p>You are hosting this listing</p>
                        </div>
                      </div>
                    )} */}
                    {offerAcceptCountDown && Number(offerAcceptCountDown.steam_id) && (
                      <div className="auction-win-div">
                        <img src="/images/common/trophy.svg" alt="zipit" height={20} width={20} className="img-fluid" />
                        <div>
                          <p>
                            Congratulations! You {offerAcceptCountDown.status === 'seller' ? 'sold' : 'bought'} this item for{' '}
                            {defaultCurrency && defaultCurrency.symbol}
                            {offerAcceptCountDown.offer}
                          </p>
                        </div>
                      </div>
                    )}

                    {!offerAcceptCountDown && Number(item?.user_id) === Number(sellerUserId) && (
                      <div className="auction-win-div">
                        <img src="/images/market/listhostimage.svg" alt="zipit" height={20} width={20} className="img-fluid" />
                        <div>
                          <p>You are hosting this listing</p>
                        </div>
                      </div>
                    )}

                    {/* {Number(item?.user_id) === Number(sellerUserId) && (
                      <>
                        {Number(offerAcceptCountDown?.steam_id) === Number(sellerUserId) ? (
                          <div className="auction-win-div">
                            <img
                              src="/images/market/trophy.svg"
                              alt="zipit"
                              height={20}
                              width={20}
                              className="img-fluid"
                            />
                            <div>
                              <p>
                                Congratulations! you sold this item for
                                {offerAcceptCountDown && offerAcceptCountDown.bidPrice}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="auction-win-div">
                            <img
                              src="/images/market/listhostimage.svg"
                              alt="zipit"
                              height={20}
                              width={20}
                              className="img-fluid"
                            />
                            <div>
                              <p>You are hosting this listing</p>
                            </div>
                          </div>
                        )}
                      </>
                    )} */}
                    <div className="single-market-product-main-div">
                      <div className="single-product-float-info">
                        <div className="font-14 pb-0 text-white">
                          <span className="font-700" style={{ color: `${item && item.item.r_color}` }}>
                            {item && wearNameConverter(item.wear)}
                          </span>{' '}
                          {''}
                          {item && Number(item.float_id) !== 0 ? ` : ${item && Number(item.float_id).toFixed(4)}` : ''}
                        </div>
                        {item && Number(item.paintseed) !== 0 && (
                          <div className="font-12 text-white font-700">
                            P {''}/ {''}
                            {item && item.paintseed}
                          </div>
                        )}
                        {item && item.fade_id !== null && (
                          <>
                            <div className="font-12 opacity-white font-700 single-product-wear">
                              Fade
                              <span
                                style={{
                                  width: '6px',
                                  height: '6px',
                                  borderRadius: '1px',
                                  background: 'linear-gradient(45deg, #F7B500 12.5%, #B620E0 54.13%, #32C5FF 95.83%)'
                                }}
                              ></span>
                            </div>
                            <div className="font-12 text-white single-product-wear">{`${item && RoundedPrice(item.fade_id)}%`}</div>
                          </>
                        )}
                      </div>
                      <div className="single-product-image">
                        {item && (
                          <img
                            src={`${item && process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${item && item.item.imageUrl}`}
                            alt="zipit"
                            className="img-fluid single-product-img"
                          />
                        )}

                        <img src="/images/sell-modal/new-background-image.jpg" alt="Zipit" className="img-fluid sell-background" />
                      </div>
                      {item?.item?.stickers && item?.item?.stickers.length > 0 && (
                        <div className="market-product-sticker">
                          {item.item &&
                            item?.item?.stickers.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {/* <div dangerouslySetInnerHTML={{ __html: item.value }} /> */}
                                  {/* <img src={item.value} alt="sticker" title={item.key} /> */}
                                  <div className="single-sticker-div">
                                    <img src={item.value} alt="sticker" />
                                    {item.key && (
                                      <div className="sticker-tooltip sticker-tooltip-top market-sticker-tooltip">
                                        <span>{item.key}</span>
                                      </div>
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                  // </div>
                )}

                <div className="single-product-detail single-product-last-div single-product-right-div">
                  <div className="market-user-main-div">
                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="98%"
                        height={40}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        className="market-user-div mb-0"
                      />
                    ) : (
                      <div className="market-user-div">
                        {/* <img src="/images/market/user-image.png" alt="zipit" className="img-fluid" /> */}
                        {item && item.steamdetails.avatarfull ? (
                          <img src={item.steamdetails.avatarfull} alt="zipit" className="img-fluid rounded-circle" />
                        ) : (
                          <img src="/images/common/profile.svg" height={32} width={32} alt="zipit" className="img-fluid" />
                        )}
                        <p className="text-uppercase font-20 pb-0">{item && item.personaname}</p>
                      </div>
                    )}
                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="98%"
                        height={30}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        count={3}
                        className="market-user-div mb-0"
                      />
                    ) : (
                      <div className="market-user-dtail">
                        {/* <div className="sell-product-info">
                          <p>Delivery time</p>
                          <p>15 Minutes</p>
                        </div> */}
                        <div className="sell-product-info">
                          <p>Total trades</p>
                          <p>{tradeCount}</p>
                        </div>
                        {/* {item && item.d_shop === '1' && ( */}
                        <div
                          className="button-link white-border-button mt-30 w-100"
                          onClick={() => navigate(`/shop/${item && item.hash_id}`)}
                        >
                          Seller’s shop
                        </div>
                        {/* )} */}
                      </div>
                    )}
                  </div>

                  <div className="market-product-price">
                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="99%"
                        height={10}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        count={6}
                        className="market-product-price mb-10"
                      />
                    ) : (
                      <>
                        <div className="sell-product-info">
                          <p className="opacity-white">Average price</p>
                          <div className="market-product-price-div">
                            <p>
                              <span>{defaultCurrency && defaultCurrency.symbol}</span>
                              {item && RoundedPrice(item.average_price)}
                            </p>
                            {showSecondPrice && (
                              <>
                                <span>{showSecondPrice && '~'}</span>
                                <p>{showSecondPrice && currency.symbol + convertCurrency(item && item?.average_price)}</p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="sell-product-info">
                          <p className="opacity-white">Listed Price</p>
                          <div className="market-product-price-div">
                            <p>
                              <span> {defaultCurrency && defaultCurrency.symbol}</span>
                              {item && item.startBid}
                            </p>
                            {showSecondPrice && (
                              <>
                                <span> {showSecondPrice && '~'}</span>
                                <p>{showSecondPrice && currency.symbol + convertCurrency(item && item?.startBid)}</p>
                              </>
                            )}
                          </div>
                        </div>
                        {/* <div className="sell-product-info">
                          <p className="opacity-white">Steam Price</p>
                          <div className="market-product-price-div">
                            <p>
                              <span> {defaultCurrency && defaultCurrency.symbol}</span>
                              {item && item.startBid}
                            </p>
                            {showSecondPrice && (
                              <>
                                <span> {showSecondPrice && '~'}</span>
                                <p>
                                  {showSecondPrice &&
                                    currency.symbol + convertCurrency(item && item?.startBid)}
                                </p>
                              </>
                            )}
                          </div>
                        </div> */}
                        <div className="sell-product-info">
                          <p className="opacity-white">Followers</p>
                          <div className="market-product-price-div">
                            <p>{followingCount ? followingCount : 0}</p>
                          </div>
                        </div>
                        {item && item.selltype === 'auction' && (
                          <div className="sell-product-info">
                            <p className="opacity-white">Total bid</p>
                            <div className="market-product-price-div">
                              <p>{bidArray.length > 0 ? bidArray.length : 0}</p>
                            </div>
                          </div>
                        )}

                        {/* {(item && item.auctionType === 'lighting') ||
                          (item && item.auctionType === 'regular' && (
                            <>
                              <div className="sell-product-info">
                                <p className="opacity-white">Time left</p>
                                <div className="market-product-price-div">
                                  <CountdownTimer
                                    daysCount={parseInt(item?.runtime)}
                                    createdDate={item?.cread_date}
                                  />
                                </div>
                              </div>
                            </>
                          ))} */}

                        {item && item.selltype === 'auction' && (
                          <>
                            <div className="sell-product-info">
                              <p className="opacity-white">Time left</p>
                              <div className="market-product-price-div">
                                <CountdownTimer daysCount={parseInt(item?.runtime)} createdDate={item?.cread_date} />
                              </div>
                            </div>
                          </>
                        )}

                        {item && item.auctionType === 'lighting' && (
                          <div className="sell-product-info">
                            <p className="opacity-white">
                              This is a Lightning Auction. Last minute bids will extend the auction to 60 seconds
                            </p>
                          </div>
                        )}

                        {offerAcceptCountDown ? (
                          <OfferCountDown offerCountDownData={offerAcceptCountDown} />
                        ) : item && item.selltype === 'market' && item?.user_id !== sellerUserId ? (
                          <>
                            <div
                              className={`button-link background-button mt-30 w-100 ${isLoading && 'button-disabled'}`}
                              style={{ backgroundColor: `${item && item.item.r_color}` }}
                              onClick={() => onCheckoutModal(item)}
                            >
                              {isLoading && <img src="/images/common/loader.gif" alt="loader" width={20} height={20} />}
                              checkout {defaultCurrency && defaultCurrency?.symbol}
                              {item && item.startBid}
                            </div>
                            {Bearer_Token && (
                              <>
                                {item && Number(item.offer_status) !== 1 && (
                                  <>
                                    {Number(userOfferCount) >= 0 && Number(userOfferCount) <= 3 && (
                                      <>
                                        {offerCountDownData ? (
                                          <OfferCountDown
                                            offerCountDownData={offerCountDownData}
                                            offerRemoveByUserModalHandler={offerRemoveByUserModalHandler}
                                          />
                                        ) : (
                                          Number(userOfferCount) !== 3 && (
                                            <div
                                              className={`button-link white-border-button mt-10 text-uppercase w-100 
                                              ${isOfferLoading || successMes?.type === 'success' ? 'button-disabled' : ''}`}
                                              onClick={() => onOfferModal()}
                                            >
                                              {isOfferLoading && (
                                                <img src="/images/common/loader.gif" alt="loader" width={20} height={20} />
                                              )}
                                              Make an offer
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {successMes?.type === 'success' && (
                              <div className="auction-win-div position-relative mt-15">
                                <img src="/images/market/green-correct.svg" alt="zipit" height="20" width="20" className="img-fluid" />
                                <div>
                                  <p className=" pb-5 text-white">{successMes?.type}</p>
                                  <p className="font-14 pb-0">{successMes?.message}</p>
                                </div>
                              </div>
                            )}
                            <p className="mt-10 text-white">3DSecure is required for all credit card payments.</p>
                          </>
                        ) : item && item.selltype === 'auction' && item?.user_id !== sellerUserId ? (
                          <>
                            {bidPrice !== 0 ? (
                              <>
                                <div className="bid-div mt-30">
                                  <div className="sell-product-info mb-10">
                                    <p>Your Bid</p>
                                    <div className="market-product-price-div">
                                      <p>
                                        <span>{defaultCurrency && defaultCurrency?.symbol}</span>
                                        {Number(bidPrice).toFixed(2)}
                                      </p>
                                      {showSecondPrice && (
                                        <>
                                          <span>~</span>
                                          <p className="opacity-white">
                                            {currency?.symbol}
                                            {convertCurrency(Number(item?.bidPrice).toFixed(2))}
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="bid-input-div position-relative">
                                    <input
                                      name="bidPrice"
                                      value={bidPrice}
                                      onChange={(e) => onBidChange(e)}
                                      className={`bid-input ${errors.bidPrice || errors.bidBuyPrice ? 'invalid-input' : 'valid-input'}`}
                                    />
                                    {successMes?.type === 'success' ? (
                                      <div className="success-button">
                                        <img
                                          src="/images/common/success.svg"
                                          alt="Success"
                                          className="success-image"
                                          width={30}
                                          height={30}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className={`button-link white-border-button 
                                          ${errors.bidPrice || errors.bidBuyPrice ? 'button-disabled' : ''}`}
                                        style={{
                                          backgroundColor: `${item && item.item.r_color}`,
                                          border: 'none'
                                        }}
                                        onClick={() => onBidAdd(item)}
                                      >
                                        {isLoadingBid && <img src="/images/common/loader.gif" alt="loader" width={20} height={20} />}
                                        Bid
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {errors.bidPrice && <p className="color-red font-14 pb-0"> {errors.bidPrice} </p>}
                                {errors.bidBuyPrice && <p className="color-red font-14 pb-0">{errors.bidBuyPrice}</p>}

                                {Bearer_Token && item?.hascheckout === '1' && item.checkoutPrice !== null && (
                                  <div
                                    className={`button-link white-border-button mt-10 w-100 ${isLoading && 'button-disabled'}`}
                                    onClick={() => onCheckoutModal(item, item.checkoutPrice)}
                                  >
                                    {isLoading && <img src="/images/common/loader.gif" alt="loader" width={20} height={20} />}
                                    Buy Now {defaultCurrency && defaultCurrency?.symbol}
                                    {item && item.checkoutPrice}
                                  </div>
                                )}
                                {successMes?.type === 'success' && (
                                  <div className="auction-win-div position-relative mt-15">
                                    <img src="/images/market/green-correct.svg" alt="zipit" height="20" width="20" className="img-fluid" />
                                    <div>
                                      <p className=" pb-5 text-white">{successMes?.type}</p>
                                      <p className="font-14 pb-0">{successMes?.message}</p>
                                    </div>
                                  </div>
                                )}
                                <p className="mt-10 text-white">3DSecure is required for all credit card payments.</p>
                              </>
                            ) : (
                              <p>This auction is complete.</p>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="button-link white-border-button mt-10 text-uppercase w-100" onClick={() => onEditListing()}>
                              Edit
                            </div>
                            {item && item?.total_pending_offer && (
                              <div className="offer-button-div">
                                <div className="green-button button-link text-uppercase w-100 gap-10" onClick={() => onSellerOfferModal()}>
                                  <img src="/images/market/offer-coin.svg" alt="zipit" width={20} height={20} />
                                  Show Offer {item?.total_pending_offer}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {item && item.selltype === 'auction' && (
              <div className="product-bid-div">
                {isLoadingProduct ? (
                  <div className="similar-product-heading text-center">
                    <SkeletonLoader width="99%" height={30} color="#0b051b" highlightColor="#242347" borderRadius={0} />
                  </div>
                ) : (
                  <div
                    className="similar-product-heading text-center"
                    style={{
                      borderBottom: bidArray.length > 0 ? 'none' : '1px solid rgba(255, 255, 255, 0.25)'
                    }}
                  >
                    <p className="sub-heading text-uppercase text-white pb-0">
                      Bids <span className="font-14">{bidArray.length > 0 && `(${bidArray.length > 0 && bidArray.length})`}</span>
                    </p>
                  </div>
                )}

                <div className="product-bid-main-div">
                  {isLoadingProduct ? (
                    // Show skeleton loaders
                    <>
                      {[...Array(4)].map((_, index) => (
                        <div className="product-single-bid-div pb-0" key={index}>
                          <SkeletonLoader
                            width="99%"
                            height={30}
                            color="#0b051b"
                            highlightColor="#242347"
                            borderRadius={0}
                            count={1}
                            className="bid-number"
                          />
                          <SkeletonLoader
                            width="99%"
                            height={30}
                            color="#0b051b"
                            highlightColor="#242347"
                            borderRadius={0}
                            count={1}
                            className="bid-number"
                          />
                          <SkeletonLoader
                            width="99%"
                            height={30}
                            color="#0b051b"
                            highlightColor="#242347"
                            borderRadius={0}
                            count={1} // Adjusted count to match the number of loaders
                            className="bid-number"
                          />
                        </div>
                      ))}
                    </>
                  ) : bidArray.length > 0 ? (
                    // Show bid items
                    bidArray.map((item, index) => (
                      <div className="product-single-bid-div" key={index}>
                        <div className="bid-number">
                          <img src="/images/market/bid-icon.svg" alt="zipit" className="img-fluid bid-icon" />
                          <p>{item.bid_status ? 'You' : item?.id}</p>
                        </div>
                        <div className="bid-day">
                          <p>{item && notificationTimeAgo(item.created)}</p>
                        </div>
                        <div className="bid-price text-end">
                          <p>
                            {defaultCurrency && defaultCurrency?.symbol} {item?.bidPrice}
                          </p>
                          {showSecondPrice && (
                            <p className="font-14 opacity-white pb-0">
                              {currency?.symbol} {convertCurrency(item?.bidPrice)}
                            </p>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    // Show "No bids yet" message
                    <div className="no-similar-product">
                      <p className="opacity-white text-center">No bids yet. You can change that with a few clicks.</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ProductSkin.propTypes = {
  onCheckoutModal: PropTypes.func,
  onOfferModal: PropTypes.func,
  item: PropTypes.object,
  Bearer_Token: PropTypes.string,
  isLoading: PropTypes.bool,
  currency: PropTypes.object,
  defaultCurrency: PropTypes.object,
  showSecondPrice: PropTypes.bool,
  onBidChange: PropTypes.func,
  bidPrice: PropTypes.any,
  onBidAdd: PropTypes.func,
  errors: PropTypes.object,
  isLoadingBid: PropTypes.bool,
  onEditListing: PropTypes.func,
  isLoadingProduct: PropTypes.bool,
  successMes: PropTypes.object,
  bidArray: PropTypes.array,
  followHandle: PropTypes.func,
  follow: PropTypes.any,
  followingCount: PropTypes.number,
  tradeCount: PropTypes.number,
  onSellerOfferModal: PropTypes.func,
  isOfferLoading: PropTypes.bool,
  offerAcceptCountDown: PropTypes.any,
  userOfferCount: PropTypes.number,
  offerCountDownData: PropTypes.any,
  offerRemoveByUserModalHandler: PropTypes.func
};

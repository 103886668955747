import React from 'react';
import PropTypes from 'prop-types';

export const AutoComplete = ({ item, onSelecteditem }) => {
  return (
    <div className="single-search-item" onClick={() => onSelecteditem(item)}>
      <div className="search-suggestion-img">
        <img src={`${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${item?.item?.imageUrl}`} alt="auto-fill" height={100} width={100} />
      </div>
      <div className="search-product-name">
        <p className="font-16 font-700 pb-5">{item?.item?.name}</p>
        <div className="d-flex gap-5">
          <p className="productname-desc">{item?.item?.category}</p>*<p className="productname-desc">{item?.item?.subCategory}</p>
        </div>
      </div>
    </div>
  );
};
AutoComplete.propTypes = {
  item: PropTypes.object,
  onSelecteditem: PropTypes.func
};

import React from 'react';

export const NotFound = () => {
  document.title = 'Zipit - 404';

  return (
    <>
      <div className="pagenot-found-div">
        <div className="container-fluid">
          <div className="container">
            <div className="pagenot-found common-page-div">
              <div className="row custome-row-gap align-items-center">
                <div className="col-md-6 col">
                  <div className="notfound-text">
                    <p className="font-26">Ooops! </p>
                    <p className="font-26"> You landed on our 404-page</p>
                    <div className="notfound-description">
                      <p>
                        The page you are looking for, does not exist. You can click on the button below and get sent back to the frontpage.
                      </p>
                    </div>
                    <a href="/" className="button-link mt-20 purple-background text-uppercase">
                      TAKE ME HOME
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col">
                  <div className="notfound-image">
                    <img src="/images/common/404.svg" alt="Zipit" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

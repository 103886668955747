import React, { useCallback, useEffect, useRef, useState } from 'react';
import { axiosAuthInstance, notification_list, user_notification_seen, user_read_as_all_notification_seen } from 'api';
import { useApi } from 'hooks/useApi';
import { notificationTimeAgo } from 'lib';
import { useNavigate } from 'react-router-dom';
import { setNotificationCount } from 'reduxs/userSlice';
import { useDispatch } from 'react-redux';
import { setBuyFilterCount } from 'reduxs/BuySlice';
import Cookies from 'js-cookie';

export const BellNotification = () => {
  const [visible, setVisible] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = useState([]);
  const [itemTotal, setItemTotal] = useState(0);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const defaultCurrency = JSON.parse(localStorage.getItem('default'));

  const steamToken = Cookies.get('Bearer_Token');

  const navigate = useNavigate();
  const notificationDivRef = useRef(null);

  useEffect(() => {
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${steamToken}`;
  }, [steamToken]);

  const { apiCaller: onNotificationGet } = useApi({
    dataGetter: notification_list
  });

  const { apiCaller: userSeenNotification } = useApi({
    dataGetter: user_notification_seen
  });

  const { apiCaller: userReadAsAllNotification } = useApi({
    dataGetter: user_read_as_all_notification_seen
  });

  const fetchData = useCallback(
    async (page) => {
      try {
        let params = {
          page
        };
        if (Number(page) === 1) {
          setLoader(true);
        }
        const response = await onNotificationGet(params);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
        // console.log(response.data.data.data, 'lastpage');
        if (response?.data?.data?.last_page > page) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        const newData = response.data?.data?.data?.filter((item, index, self) => index === self.findIndex((i) => i.id === item.id));

        setNotification((prev) => {
          const updatedData = page === 1 ? newData : [...prev, ...newData];
          const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

          const newVisible = uniqueArray.length;
          setVisible(newVisible);
          return uniqueArray;
        });
        setItemTotal(response.data.data.total);
        const notifictionCount = response?.data?.not_seen;
        dispatch(setNotificationCount(notifictionCount));
        setLoader(false);
      } catch (error) {
        console.error(error.message);
        // Handle error
      }
    },
    [onNotificationGet]
  );

  const fetchMoreOnBottomReached = () => {
    const div = notificationDivRef.current;
    const atBottom = div.scrollTop + div.clientHeight >= div.scrollHeight;
    if (atBottom && hasMore && visible < itemTotal) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const div = notificationDivRef.current;
    if (div) {
      const handleScroll = () => {
        fetchMoreOnBottomReached();
      };

      div.addEventListener('scroll', handleScroll);

      return () => {
        div.removeEventListener('scroll', handleScroll);
      };
    }
  }, [visible, itemTotal]);

  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, currentPage]);

  const onNavigate = (market_id, n_id, status) => {
    if (Number(status) === 0) {
      const params = {
        n_id
      };
      userSeenNotification(params).then((res) => {
        if (res.data.status === 'success') {
          fetchData(1);
        }
        // navigate(`/market/${market_id}`);
      });
    }
    navigate(`/market/${market_id}`);
  };

  const MarkasReadAll = (e) => {
    e.stopPropagation();
    userReadAsAllNotification().then((res) => {
      if (res.data.status === 'success') {
        dispatch(setBuyFilterCount({ not_seen: 0 }));
        fetchData(1);
      }
    });
  };

  const renderNotification = (data) => {
    if (data.type === 'ALERT_TIME_LEFT') {
      return (
        <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
          <div className="single-notification-img">
            <img src="/images/common/Add_your_payment_method.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
          </div>
          <div className="single-notification-text">
            <p className="font-16 pb-5 position-relative">
              {data && data.data.name}
              {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
            </p>
            <p className="font-14 pb-5">{data && data.data.msg}</p>
            <p className="font-12 opacity-white">{data && notificationTimeAgo(data?.created)}</p>
            <div className="offer-button-div">
              <button className="green-button gap-10">
                <img src="/images/common/card_1.svg" alt="icon" className="img-fluid" height={20} width={20} />
                Pay Now {defaultCurrency && defaultCurrency.symbol}
                {data?.data?.price}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (data.type === 'BUYER_FAILED_PAYMENT_NOTIFY_BUYER') {
      return (
        <>
          <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
            <div className="single-notification-img">
              <img src="/images/common/payment-fail.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
            </div>
            <div className="single-notification-text">
              <p className="font-16 pb-5 position-relative">
                Payment failed
                {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
              </p>
              <p className="font-14 pb-5">
                {data.description}
                {data.data.name}
              </p>
              <p className="font-12 opacity-white">{notificationTimeAgo(data?.created)}</p>
            </div>
          </div>
        </>
      );
    } else if (data.type === 'BARGAIN_OFFER_RECEIVED') {
      return (
        <>
          <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
            <div className="single-notification-img">
              <img src="/images/header/offercoin.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
            </div>
            <div className="single-notification-text">
              <p className="font-16 pb-5 position-relative">
                You got an offer for {data.data.name}
                {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
              </p>
              {/* <p className="font-14 pb-5">{data && data.data.msg}</p> */}
              <p className="font-14 pb-5">You have 24 hours to accept the offer.</p>
              <p className="font-12 opacity-white">{notificationTimeAgo(data?.created)}</p>
            </div>
          </div>
        </>
      );
    } else if (data.type === 'SELLER_FAILED_MAKE_OFFER_NOTIFY_BUYER') {
      return (
        <>
          <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
            <div className="single-notification-img">
              <img src="/images/common/trade-offer-cancel.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
            </div>
            <div className="single-notification-text">
              <p className="font-16 pb-5 position-relative">
                Your offer for
                {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
              </p>
              <p className="font-14 pb-5">
                {data.data.name} {data && data.data.msg}
              </p>
              <p className="font-12 opacity-white">{notificationTimeAgo(data?.created)}</p>
            </div>
          </div>
        </>
      );
    } else if (data.type === 'SELLER_SUCESS_MAKE_OFFER_NOTIFY_BUYER') {
      return (
        <>
          <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
            <div className="single-notification-img">
              <img src="/images/common/offer-got-1.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
            </div>
            <div className="single-notification-text">
              <p className="font-16 pb-5 position-relative">
                offer accept
                {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
              </p>
              <p className="font-14 pb-5">
                {data.data.name} {data && data.data.msg}
              </p>
              <p className="font-12 opacity-white">{notificationTimeAgo(data?.created)}</p>
            </div>
          </div>
        </>
      );
    } else if (data.type === 'KYC_APPROVED') {
      return (
        <>
          <div
            className="single-notification-div"
            onClick={() => {
              onNavigate(data?.data?.market_id, data.id, data.is_seen);
            }}
          >
            <div className="single-notification-img">
              <img src="/images/common/kyc-completed.svg" alt="offer" className="img-fluid" height={40} width={40} />
            </div>
            <div className="single-notification-text">
              <p className="font-14 pb-5 position-relative">
                KYC Submission approved
                {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
              </p>
              <p className="font-14 pb-5">{data && data.data.msg}</p>
              <p className="font-12 opacity-white">{notificationTimeAgo(data?.created)}</p>
            </div>
          </div>
        </>
      );
    } else if (data.type === 'ALERT') {
      return (
        <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
          <div className="single-notification-img">
            <img src="/images/common/bell.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
          </div>
          <div className="single-notification-text">
            <p className="font-16 pb-5 position-relative">
              {data && data.data.name}
              {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
            </p>
            <p className="font-14 pb-5">{data && data.data.msg}</p>
            <p className="font-12 opacity-white">{data && notificationTimeAgo(data?.created)}</p>
          </div>
        </div>
      );
    } else if (data.type === 'SELLER_FAILED_BID_NOTIFY_BUYER') {
      return (
        <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
          <div className="single-notification-img">
            <img src="/images/common/not-winning.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
          </div>
          <div className="single-notification-text">
            <p className="font-16 pb-5 position-relative">
              Make Trade offer
              {data && data.data.name}
              {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
            </p>
            <p className="font-14 pb-5">{data && data.data.msg}</p>
            <p className="font-12 opacity-white">{data && notificationTimeAgo(data?.created)}</p>
          </div>
        </div>
      );
    } else if (data.type === 'MAKE_TRADE') {
      return (
        <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
          <div className="single-notification-img">
            <img src="/images/common/make-trade-offer-accept.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
          </div>
          <div className="single-notification-text">
            <p className="font-16 pb-5 position-relative">
              Make Trade offer
              {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
            </p>
            <p className="font-14 pb-5">
              {data && data.data.name}
              {data && data.data.msg}
            </p>
            <p className="font-12 opacity-white">{data && notificationTimeAgo(data?.created)}</p>
          </div>
        </div>
      );
    } else if (data.type === 'COMPLATE_TRADE') {
      return (
        <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
          <div className="single-notification-img">
            <img src="/images/common/make-trade-offer-accept.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
          </div>
          <div className="single-notification-text">
            <p className="font-16 pb-5 position-relative">
              Trade offer accepted
              {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
            </p>
            <p className="font-14 pb-5">
              {data && data.data.name}
              {data && data.data.msg}
            </p>
            <p className="font-12 opacity-white">{data && notificationTimeAgo(data?.created)}</p>
          </div>
        </div>
      );
    } else if (data.type === 'ACCEPT_TRADE') {
      return (
        <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
          <div className="single-notification-img">
            <img src="/images/common/accept-trade-offer.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
          </div>
          <div className="single-notification-text">
            <p className="font-16 pb-5 position-relative">
              Accept Trade offer
              {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
            </p>
            <p className="font-14 pb-5">
              The seller of
              {data && data.data.name}
              {data && data.data.msg}
            </p>
            <p className="font-12 opacity-white">{data && notificationTimeAgo(data?.created)}</p>
          </div>
        </div>
      );
    } else if (data.type === 'SELLER_SUCESS_BID_NOTIFY_BUYER') {
      return (
        <div className="single-notification-div" onClick={() => onNavigate(data?.data?.market_id, data.id, data.is_seen)}>
          <div className="single-notification-img">
            <img src="/images/common/winning-bid.svg" alt="Zipit" className="img-fluid" height={40} width={40} />
          </div>
          <div className="single-notification-text">
            <p className="font-16 pb-5 position-relative">
              You won the Bid
              {data.is_seen === '0' ? <span className="notification-seen"></span> : ''}
            </p>
            <p className="font-14 pb-5">
              {data && data.data.name}
              {''} {data && data.data.msg}
            </p>
            <p className="font-12 opacity-white">{data && notificationTimeAgo(data?.created)}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="zipit-notifications-dropdown">
        <div className="content zipit-infinite-scroll-container">
          <div className="notification-heading-tiv">
            <p className="notification-title text-uppercase"> Notifications </p>
            <p className="mark-read" onClick={MarkasReadAll}>
              Mark all as read
            </p>
          </div>
          <div className="notification-main-div" ref={notificationDivRef}>
            {loader ? (
              <div className="No-notification text-center">
                <img src="/images/common/loader.gif" alt="loader" height={20} width={20} />
              </div>
            ) : (
              <>
                {notification && notification.length > 0 ? (
                  <div className="single-notification-main-div">
                    {notification.map((data, index) => (
                      <React.Fragment key={index}>{renderNotification(data)}</React.Fragment>
                    ))}
                  </div>
                ) : (
                  <div className="No-notification">
                    <p>No have notification</p>
                  </div>
                )}
                {/* </div> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

export const SearchBar = ({ onRefresh, clearSearch, onSearchDebounced, search, refreshing, inventoryTotalPrice }) => {
  const searchValue = search != null ? search : '';
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  return (
    <div className="fixed-search-area">
      <div className="left-search-area">
        <div
          className={`refresh-button ${refreshing ? 'disabled-refresh-button' : ''}`}
          onClick={() => {
            if (!refreshing) {
              onRefresh();
            }
          }}
        >
          <img src="/images/inventory/refresh.svg" alt="Zipit" className="img-fluid" />
          Refresh
        </div>
        <div className="search-wrapper flex items-center">
          <div className="search-bar position-relative">
            <img src="/images/common/search-icon.svg" alt="Zipit" className="img-fluid search-icon" />
            <input
              type="text"
              placeholder="Search for an item"
              className="ng-valid ng-dirty ng-touched"
              onChange={(e) => onSearchDebounced(e.target.value)}
              value={searchValue}
              // defaultValue={search || ''}
            />
            {search && (
              <button className="clear-search-button" onClick={clearSearch}>
                <img src="/images/common/close.svg" height={12} alt="Zipit" className="img-fluid" />
              </button>
            )}
          </div>
        </div>
        {/* <div className="inventory-value-div">
          <p>Inventory value</p>
          <p className="font-700 font-14 pb-0">$ 222.00</p>
        </div> */}
      </div>
      <div className="right-search-area">
        {/* <p className="font-14 pb-0">
          <input type="checkbox" /> Show Tradelocked
        </p> */}
        <div className="inventory-value-div">
          <p>Inventory value</p>
          <p className="font-700 font-14 pb-0">
            {defaultCurrency && defaultCurrency?.symbol} {inventoryTotalPrice && Number(inventoryTotalPrice).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  onRefresh: PropTypes.func,
  clearSearch: PropTypes.func,
  onSearchDebounced: PropTypes.func,
  search: PropTypes.string,
  refreshing: PropTypes.bool,
  inventoryTotalPrice: PropTypes.any
};

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

export const News = ({ newsLatterData, onCloseNewsModal, showClass }) => {
  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className={`edit-price-modal ${showClass}`}>
      <div className="steam-account-popup position-relative">
        <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close newsletter" onClick={onCloseNewsModal}>
          <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
        </button>
        <div className="modal-body">
          <p className="offer-heading mb-10">News</p>
          <p className="font-14 pb-0">{newsLatterData.data.created}</p>
          <div className="news-image">
            <img src={newsLatterData.data.image} alt="Zipit" className="img-fluid" />
          </div>
          <p className="font-26">{newsLatterData.data.title}</p>
          <div dangerouslySetInnerHTML={{ __html: newsLatterData.data.description }} />
          {/* <p className="font-14 opacity-white">{newsLatterData.data.description}</p> */}
          <p className="font-14 pb-0 opacity-white">Thank you for choosing us.</p>
          <p className="font-14 pb-5 opacity-white mt-15">Sincerely,</p>
          <p className="font-16 pb-0 opacity-white">Zipit</p>
        </div>
      </div>
    </aside>
  );
};

News.propTypes = {
  newsLatterData: PropTypes.any,
  onCloseNewsModal: PropTypes.func,
  showClass: PropTypes.bool
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toast: {
    show: false,
    message: '',
    type: ''
  }
};

export const TOAST_SUCCESS = 'success';
export const TOAST_ERROR = 'error';
export const TOAST_WARNING = 'warning';

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast(state, action) {
      const { show, message, type } = action.payload;
      state.toast = { show, message, type };
    }
  }
});

export const { setToast } = toastSlice.actions;
export default toastSlice.reducer;

import { calculateRemoveOfferTimeRemaining } from 'lib';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const OfferCountDown = ({ offerCountDownData, offerRemoveByUserModalHandler, handleConfimTradeOfferSend }) => {
  // const calculateTimeRemaining = () => {
  //   const originalDateTime =
  //     offerCountDownData?.is_check_buyer_seller === 'seller' ||
  //     offerCountDownData?.is_check_buyer_seller === 'buyer' ||
  //     offerCountDownData?.d_status === 'pending'
  //       ? offerCountDownData.created
  //       : offerCountDownData?.comlate_date_time;

  //   const endTime = moment.utc(originalDateTime).add(24, 'hours').valueOf(); // Parse end time in UTC

  //   const currentTime = moment.utc().valueOf(); // Get current time in UTC
  //   const timeDiff = endTime - currentTime;

  //   if (timeDiff > 0) {
  //     const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //     const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  //     const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  //     return { hours, minutes, seconds };
  //   } else {
  //     return { hours: 0, minutes: 0, seconds: 0 };
  //   }
  // };

  const calculateTimeRemaining = () => {
    let originalDateTime;
    let endTime;

    if (offerCountDownData?.d_status === 'pending') {
      originalDateTime = offerCountDownData.created;
      endTime = moment.utc(originalDateTime).add(18, 'hours').valueOf();
    } else if (
      offerCountDownData?.status === 'seller' ||
      (offerCountDownData?.is_check_buyer_seller === 'buyer' && offerCountDownData?.d_status === 'make_trade')
    ) {
      originalDateTime = offerCountDownData.created;
      endTime = moment.utc(originalDateTime).add(18, 'hours').valueOf();
    } else if (offerCountDownData?.is_check_buyer_seller === 'seller' && offerCountDownData?.d_status === 'make_payment') {
      originalDateTime = offerCountDownData.created;
      endTime = moment.utc(originalDateTime).add(18, 'hours').valueOf();
    } else if (offerCountDownData?.is_check_buyer_seller === 'seller' && offerCountDownData?.d_status === 'make_trade') {
      originalDateTime = offerCountDownData.updated_date;
      endTime = moment.utc(originalDateTime).add(18, 'hours').valueOf();
    } else if (offerCountDownData?.d_status === 'accept_trade') {
      originalDateTime = offerCountDownData.updated_date;
      endTime = moment.utc(originalDateTime).add(18, 'hours').valueOf();
    }

    const currentTime = moment.utc().valueOf();
    const timeDiff = endTime - currentTime;

    if (timeDiff > 0) {
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 18)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      return { hours, minutes, seconds };
    } else {
      return { hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, [offerCountDownData]);

  const removeOfferTimeRemaining = calculateRemoveOfferTimeRemaining(offerCountDownData?.created);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const day = ('0' + date.getDate()).slice(-2); // Get day with leading zero
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Get month with leading zero
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  if (offerCountDownData?.d_status === 'pending') {
    return (
      <>
        <div className="wait-payment-method">
          <p className="font-14 font-700 ">Waiting for seller to accept or decline offer</p>
          <div className="payment-time">
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.hours}</p>
              <p className="opacity-white">Hours</p>
            </div>
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.minutes}</p>
              <p className="opacity-white">Minutes</p>
            </div>
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.seconds}</p>
              <p className="opacity-white">Seconds</p>
            </div>
          </div>
        </div>
        <button className="remove-product-offer" disabled={removeOfferTimeRemaining.minutes !== 0} onClick={offerRemoveByUserModalHandler}>
          {/* <button className="remove-product-offer" onClick={offerRemoveByUserModalHandler}> */}
          Remove your offer{' '}
          {removeOfferTimeRemaining.minutes !== 0 && `in ${removeOfferTimeRemaining.minutes}m ${removeOfferTimeRemaining.seconds}s`}
        </button>
      </>
    );
  } else if (
    offerCountDownData?.status === 'seller' ||
    (offerCountDownData?.is_check_buyer_seller === 'buyer' && offerCountDownData?.d_status === 'make_trade')
  ) {
    return (
      <div>
        <div className="wait-payment-method">
          <p className="font-14 font-700 ">Waiting for seller to make the trade offer</p>
          <p className="font-12 font-700 opacity-white">the seller of this has 24 hours to make the trade offer. YouWill be notified</p>
          <div className="payment-time">
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.hours}</p>
              <p className="opacity-white">Hours</p>
            </div>
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.minutes}</p>
              <p className="opacity-white">Minutes</p>
            </div>
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.seconds}</p>
              <p className="opacity-white">Seconds</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (offerCountDownData?.is_check_buyer_seller === 'seller' && offerCountDownData?.d_status === 'make_payment') {
    return (
      <div>
        <div className="wait-payment-method">
          <p className="font-14 font-700 ">Waiting for the buyer to add a payment method</p>
          <p className="font-12 font-700 opacity-white">
            The buyer of your item has 24 hours to add a payment method for this listing. you’ll be notified.
          </p>
          <div className="payment-time">
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.hours}</p>
              <p className="opacity-white">Hours</p>
            </div>
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.minutes}</p>
              <p className="opacity-white">Minutes</p>
            </div>
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.seconds}</p>
              <p className="opacity-white">Seconds</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (offerCountDownData?.is_check_buyer_seller === 'seller' && offerCountDownData?.d_status === 'make_trade') {
    return (
      <>
        <div className="scam-main-div">
          <div className="wait-payment-method">
            <p className="font-14 font-700 ">Make trade offer</p>
            <p className="font-12 font-700 opacity-white">You have 24 hours to make a trade offer</p>
            <div className="payment-time">
              <div className="single-payment-time">
                <p className="font-22 font-700 pb-5">{timeRemaining.hours}</p>
                <p className="opacity-white">Hours</p>
              </div>
              <div className="single-payment-time">
                <p className="font-22 font-700 pb-5">{timeRemaining.minutes}</p>
                <p className="opacity-white">Minutes</p>
              </div>
              <div className="single-payment-time">
                <p className="font-22 font-700 pb-5">{timeRemaining.seconds}</p>
                <p className="opacity-white">Seconds</p>
              </div>
            </div>
          </div>
          <div className="scams-div">
            <p className="font-700 pb-10">Important: Avoid scams!</p>
            <p className="font-12 font-700 yellow-text">
              Make sure that the values below are the same as in the steam app before confirming the trade. Once you send the offer, make
              sure you press the button below to let us know about it. if the offer is accepted and you don’t see the trade completed on the
              website contact support. WARNING: If you use this button to mislead and press it without sending the item, you will get
              banned. After 24 hours pass, if the offer is not accepted, please cancel it as we are unable to do that.
            </p>
            <div className="mt-10 steam-level-div justify-content-center">
              <p className="font-16 font-700">Steam level: </p>
              <span className="steam-level">{offerCountDownData?.buyer_steam_level}</span>
            </div>

            <p className="font-16 font-700 mt-20">
              Steam registration date:{offerCountDownData && formatDate(offerCountDownData.steamdetails.timecreated)}
            </p>
          </div>
          <div className="confirm-offer mt-20">
            <p className="text-uppercase yellow-text text-center">
              **You must send the offer to the buyer on steam and after that you need to confirm sending it**
            </p>
            <div className="steam-level-div mt-10">
              <span className="steam-level">1</span>
              <p className="font-14 font-700 pb-0">First step - make the trade offer</p>
            </div>
            <div className="offer-button-div">
              <a
                className="green-button w-100 button-link text-white"
                href={offerCountDownData?.buyer_trade_url}
                target="_blank"
                rel="noreferrer"
              >
                Accept trade
              </a>
            </div>
            <div className="steam-level-div">
              <span className="steam-level">2</span>
              <p className="font-14 font-700 pb-0">Second step - Click “confirm” after you have sent the trade offer!</p>
            </div>

            <div className="offer-button-div">
              <button className="yellow-background button-link" onClick={handleConfimTradeOfferSend}>
                Confirm trade offer accepted
              </button>
            </div>
          </div>

          <p className="text-center mt-10">3DSecure is required for all credit card payments.</p>
        </div>
      </>
    );
  } else if ('accept_trade' === offerCountDownData?.d_status && 'buyer' === offerCountDownData?.is_check_buyer_seller) {
    return (
      <>
        <div className="scam-main-div">
          <div className="wait-payment-method">
            <p className="font-14 font-700 ">Accept the trade</p>
            <p className="font-12 font-700 opacity-white">You have 24 hours to accept the steam trade</p>
            <div className="payment-time">
              <div className="single-payment-time">
                <p className="font-22 font-700 pb-5">{timeRemaining.hours}</p>
                <p className="opacity-white">Hours</p>
              </div>
              <div className="single-payment-time">
                <p className="font-22 font-700 pb-5">{timeRemaining.minutes}</p>
                <p className="opacity-white">Minutes</p>
              </div>
              <div className="single-payment-time">
                <p className="font-22 font-700 pb-5">{timeRemaining.seconds}</p>
                <p className="opacity-white">Seconds</p>
              </div>
            </div>
          </div>
          <div className="confirm-offer mt-20">
            <p className="text-uppercase yellow-text text-center">
              **You must accept the offer on steam and after receiving the item you need to confirm so the trade is completed **
            </p>
            <div className="steam-level-div">
              <span className="steam-level">1</span>
              <p className="font-14 font-700">First step - Accept the trade </p>
            </div>

            <div className="offer-button-div">
              <a
                className="green-button w-100 button-link text-white"
                href={offerCountDownData && `${offerCountDownData?.steamdetails.profileurl}/tradeoffers`}
                target="_blank"
                rel="noreferrer"
              >
                Accept trade
              </a>
            </div>
            <div className="steam-level-div">
              <span className="steam-level">1</span>
              <p className="font-14 font-700">Second step - confirm the trade</p>
            </div>

            <div className="offer-button-div">
              <button className="yellow-background button-link" onClick={handleConfimTradeOfferSend}>
                Confirm trade offer accepted
              </button>
            </div>
            <div>
              <p className="text-center mt-20 color-red">
                <span className="color-red">WARNING: </span> DO NOT CONFIRM IF THE ITEM IS NOT IN YOUR INVENTORY. You risk losing money if
                you do that. Contact support ASAP if you did a mistake.
              </p>
              <p className="text-center mt-10 color-red">
                Make sure you do not accept items that have a 15 days tradelock showing in the trade window.
              </p>

              <p className="text-center mt-10">3DSecure is required for all credit card payments.</p>
            </div>
          </div>
        </div>
      </>
    );
  } else if (offerCountDownData?.d_status === 'accept_trade' && 'buyer' !== offerCountDownData?.is_check_buyer_seller) {
    return (
      <div>
        <div className="wait-payment-method">
          <p className="font-14 font-700 ">Waiting for the buyer accept the trade</p>
          <div className="payment-time">
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.hours}</p>
              <p className="opacity-white">Hours</p>
            </div>
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.minutes}</p>
              <p className="opacity-white">Minutes</p>
            </div>
            <div className="single-payment-time">
              <p className="font-22 font-700 pb-5">{timeRemaining.seconds}</p>
              <p className="opacity-white">Seconds</p>
            </div>
          </div>
          <p className="font-12 font-700 opacity-white">
            The buyer of this item has 24 hours to accept the Steam trade. You will be notified.
          </p>
        </div>
      </div>
    );
  }
};

OfferCountDown.propTypes = {
  offerCountDownData: PropTypes.any,
  offerRemoveByUserModalHandler: PropTypes.func,
  handleConfimTradeOfferSend: PropTypes.func
};

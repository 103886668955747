import { page_template } from 'api';
import { useApi } from 'hooks/useApi';
import React, { useEffect, useState } from 'react';

export const Privacy = () => {
  document.title = 'Zipit - Privacy Policy';

  const [data, setData] = useState();
  const { apiCaller: onTermsDataGet } = useApi({
    dataGetter: page_template
  });

  const fetchData = () => {
    let params = {
      pages: 'Privacy Policy'
    };
    onTermsDataGet(params)
      .then((res) => {
        setData(res.data.item);
      })
      .catch((err) => console.error(err.message));
  };

  useEffect(() => {
    fetchData();
  }, []);

  // date format function
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}.${month}.${year}`;
  }

  return (
    <div>
      <div className="privacy-main-div common-page-div">
        <div className="container-fluid">
          <div className="container">
            <div className="common-page-inner-div max-900 text-center">
              <div className="privacy-main-div">
                <div className="privacy-div">
                  <p className="main_heading">{data?.label ? data?.label : 'Privacy policy'}</p>
                  <p className="opacity-white">Last update {data?.updated ? formatDate(data?.updated) : '00.00.000'}</p>
                </div>
                <div className="privacy-text-div mt-50">
                  <p className="font-26">{data?.short_title}</p>
                  <div className="info-box-main-div">
                    <div className="website-div">
                      <div className="website-image-div">
                        <img src="/images/common/mail.svg" alt="Zipit" height="40" width="40" />
                      </div>
                      <div className="website-text-div">
                        <a href={`mailto:${data?.email}`}>
                          <div className="discord-text">
                            <p> {data?.email}</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="website-div">
                      <div className="website-image-div">
                        <img src="/images/discord.svg" alt="Zipit" height="40" width="40" />
                      </div>
                      <div className="website-text-div">
                        <a href={`${data?.descord_link}`} target="blank">
                          <div className="discord-text">
                            <p>{data?.descord_title}</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    {/* <div className="website-div last-div">
                      <div className="website-image-div">
                        <img src="/images/common/location.svg" alt="Zipit" height="40" width="40" />
                      </div>
                      <div className="website-text-div">
                        <div className="discord-text  address-field">
                          <p className="text-white font-700 font-20 pb-0">{data?.address}</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="static-page-content" dangerouslySetInnerHTML={{ __html: data?.content }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { formatedTime, itemNameConverter } from 'lib';
import { SellerOfferAcceptModal } from '../SellerOfferAcceptModal';
import { SellerDeclineOfferModal } from '../SellerDeclineOfferModal';
import { SellerOfferDisabledModal } from '../SellerOfferDisabledModal';

export const SellerOfferListModal = ({
  closeModal,
  sellerOfferList,
  onAcceptOffer,
  OfferDisable,
  OfferDecline,
  handleSingleOfferDecline,
  setIsSellerOfferAcceptModalOpen,
  isSellerOfferAcceptModalOpen,
  setIsSingleDeclineOfferModal,
  isSingleDeclineOfferModal
}) => {
  const offer = sellerOfferList?.offer;
  const market_data = sellerOfferList?.market_data;
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));

  const [isDeclineAllOffer, setIsDeclineAllOffer] = useState(false);
  const [isDisabledOffer, setIsDisabledOffer] = useState(false);

  const [offerData, setOfferData] = useState();

  const onAcceptOfferModal = (data) => {
    setOfferData(data);
    setIsSellerOfferAcceptModalOpen(true);
  };

  const closeOfferModal = () => {
    setIsSellerOfferAcceptModalOpen(false);
    setIsSingleDeclineOfferModal(false);
    setIsDeclineAllOffer(false);
  };

  const handleSingleOfferDeclineModal = (data) => {
    setOfferData(data);
    setIsSingleDeclineOfferModal(true);
  };

  const OfferDisableModal = () => {
    setIsDisabledOffer(!isDisabledOffer);
  };

  return (
    <>
      <aside className="edit-price-modal">
        <div className="product-offer-popup position-relative">
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>

          <div className="product-offer-detail-div">
            <div className="product-offer-detail-left-div">
              <div className="product-offer-img-div">
                <img
                  src={`${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${market_data && market_data?.item?.imageUrl}`}
                  alt="zipit"
                  className="img-fluid"
                />
                <img src="/images/sell-modal/new-background-image.jpg" alt="Zipit" className="img-fluid sell-background" />
              </div>
              <div>
                <p className="product-detail">
                  <span> {market_data && market_data?.item?.category}</span>
                  {market_data && '•'}
                  {market_data && <span> {market_data?.item?.subCategory}</span>}
                </p>
                <p className="font-700 sub-heading pb-0">{market_data && itemNameConverter(market_data?.item?.fullname)}</p>
              </div>
            </div>
            <div className="product-offer-detail-right-div">
              <div className="product-offer-top-div">
                <p className="offer-heading">Offers</p>
                <div className="mt-10">
                  <div>
                    <p className=" opacity-white font-14 pb-5">Your Price</p>
                    <p className="font-18 font-700">
                      {defaultCurrency && defaultCurrency.symbol}
                      {market_data && market_data?.startBid}
                    </p>
                  </div>
                  <div className="product-offer-detail">
                    <p className="opacity-white">If you accept an offer all the other offers will be declined</p>
                    <div className="offer-button-div mt-0">
                      <button
                        className="grey-button"
                        onClick={() => {
                          handleSingleOfferDeclineModal(market_data);
                          setIsDeclineAllOffer(true);
                        }}
                      >
                        Decline all
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-offer-info-outer-div">
                <div className="product-offer-info-main ">
                  {offer &&
                    offer.length > 0 &&
                    offer.map((item, index) => {
                      const offerIndex = index + 1;
                      return (
                        <div key={index} className="single-product-offer-info">
                          <div>
                            <img src="/images/sell-modal/offericon.svg" alt="zipit" />
                          </div>
                          <div className="offer-product-heading-div">
                            <div className="product-offer-text">
                              <p className="pb-5 opacity-white">Offer #{offerIndex}</p>
                              <p>
                                {defaultCurrency && defaultCurrency.symbol} {item && item?.offer}
                              </p>
                            </div>

                            <div className="product-offer-left">
                              <img src="/images/sell-modal/time-left.svg" alt="Time Left" className="mr-5" />
                              {item && formatedTime(item?.created)}
                            </div>
                          </div>
                          {/* <div> */}
                          <div className="offer-button-div mt-0 gap-5">
                            <button className="green-button" onClick={() => onAcceptOfferModal(item)}>
                              Accept
                            </button>
                            <button className="offer-close" onClick={() => handleSingleOfferDeclineModal(item)}>
                              <img src="/images/sell-modal/close.svg" alt="Close" />
                            </button>
                          </div>
                          {/* </div> */}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="offer-button-div">
                <button className="grey-button product-disable-btn" onClick={OfferDisableModal}>
                  <img src="/images/market/disable.svg" alt="Zipit" className="img-fluid" />
                  Disable offers for this listing
                </button>
              </div>
            </div>
          </div>
        </div>
      </aside>
      {isSellerOfferAcceptModalOpen && (
        <SellerOfferAcceptModal offerData={offerData} onAcceptOffer={onAcceptOffer} closeOfferModal={closeOfferModal} />
      )}
      {isSingleDeclineOfferModal && (
        <SellerDeclineOfferModal
          offerData={offerData}
          handleSingleOfferDecline={handleSingleOfferDecline}
          closeOfferModal={closeOfferModal}
          OfferDecline={OfferDecline}
          isDeclineAllOffer={isDeclineAllOffer}
        />
      )}
      {isDisabledOffer && (
        <SellerOfferDisabledModal OfferDisable={OfferDisable} market_data={market_data} OfferDisableModal={OfferDisableModal} />
      )}
    </>
  );
};

SellerOfferListModal.propTypes = {
  closeModal: PropTypes.func,
  sellerOfferList: PropTypes.any,
  onAcceptOffer: PropTypes.func,
  OfferDisable: PropTypes.func,
  OfferDecline: PropTypes.func,
  handleSingleOfferDecline: PropTypes.func,
  setIsSellerOfferAcceptModalOpen: PropTypes.func,
  isSellerOfferAcceptModalOpen: PropTypes.bool,
  isSingleDeclineOfferModal: PropTypes.bool,
  setIsSingleDeclineOfferModal: PropTypes.func
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: {},
  bearerToken: '',
  commissionRate: '',
  currencyRate: {},
  notifictionCount: 0,
  userBlockDetails: {}
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setBearerToken(state, action) {
      state.bearerToken = action.payload;
    },
    setCommissionRate(state, action) {
      state.commissionRate = action.payload;
    },
    setCurrencyRate(state, action) {
      state.currencyRate = action.payload;
    },
    setNotificationCount(state, action) {
      state.notifictionCount = action.payload;
    },
    setUserBlockDetails(state, action) {
      state.userBlockDetails = action.payload;
    }
  }
});

export const { setUserData, setBearerToken, setCommissionRate, setCurrencyRate, setNotificationCount, setUserBlockDetails } =
  userSlice.actions;
export default userSlice.reducer;

import React, { useState } from 'react';
import propTypes from 'prop-types';

export const HowItWork = ({ closeModal, howItsWorkData, closeModalRef }) => {
  const [tab, setTab] = useState('Seller');

  const onTabChange = (name) => {
    setTab(name);
  };

  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal">
      <div className="sell-modal-area work-modal">
        <div className="offer-popup position-relative" ref={closeModalRef}>
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>
          <div className="work-heading-div">
            <p id="howitwork" className="offer-heading">
              How It Works
            </p>
            <div className="tab-header-div mt-30">
              <div className={`tab-heading-div ${tab === 'Seller' ? 'active' : ''}`} onClick={() => onTabChange('Seller')}>
                <p className="font-20 font-700 pb-0">Selling</p>
              </div>
              <div className={`tab-heading-div ${tab === 'Buyer' ? 'active' : ''}`} onClick={() => onTabChange('Buyer')}>
                <p className="font-20 font-700 pb-0">Buying</p>
              </div>
            </div>
          </div>
          <div className="sell-item-info mt-30">
            <div className="tab-description">
              {howItsWorkData?.data &&
                howItsWorkData.data
                  .filter((item) => item.status === tab) // Filter data based on the selected tab
                  .map((item) => (
                    <div key={item.id}>
                      <h3>{item.name}</h3>
                      <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                    </div>
                  ))}
            </div>
            <div className="offer-button-div">
              <button className="green-button w-100" onClick={closeModal}>
                OKay
              </button>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

HowItWork.propTypes = {
  closeModal: propTypes.func,
  howItsWorkData: propTypes.object,
  closeModalRef: propTypes.any
};

import React from 'react';
import { useNavigate } from 'react-router-dom';

import './style.css';

import { Button } from 'components';
import { useApi } from 'hooks/useApi';
import { home_page_section } from 'api';

export const DiscordServer = () => {
  const { data } = useApi({
    dataGetter: home_page_section,
    autoRetrieve: true
  });

  const section = data?.item;

  const handleClick = (button_url) => {
    if (button_url) {
      window.open(button_url, '_blank');
    }
  };

  const addClassArray = ['single_text_1', 'single_text_2', 'single_text_3'];
  return (
    <>
      {section ? (
        <div className="section_fifth pb-60 position-relative discord-server">
          <div className="container-fluid">
            <div className="container">
              <div className="common_heading max-700 text-center text-white">
                {section?.title && <h2 className="main_sub_heading">{section?.title}</h2>}
                {section?.description && (
                  <div className="description">
                    <p>{section?.description}.</p>
                  </div>
                )}
                {section?.button_name && (
                  <Button className="common_button mt-50" handleClick={() => handleClick(section?.button_url)}>
                    {section?.button_name}
                  </Button>
                )}
              </div>
              <div className="fifth_section_div">
                <img src="/images/home/fifth-section-shadow.svg" alt="Zipit" className="img-fluid fifth-section-shadow" />
                {section?.image_details.length > 0 &&
                  section?.image_details?.map(({ image, title }, index) => {
                    const classToAdd = addClassArray[index % addClassArray.length];
                    return (
                      <div className={`single_gun_div ${classToAdd}`} key={index}>
                        <div className="single_text_outer_div ">
                          <div className="single_text_div ">
                            <div className="single-text-inner-div">
                              <p>{title}</p>
                            </div>
                          </div>
                        </div>
                        <img src={image} alt="Zipit" className="img-fluid " />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="section_fifth pb-60 position-relative discord-server discord-server-loading"></div>
      )}
    </>
  );
};

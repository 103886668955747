import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { convertCurrency, itemNameConverter, wearNameConverter } from 'lib';

import { axiosAuthInstance, seller_sell_history } from 'api';
import { useApi } from 'hooks/useApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { Nothing } from '../Nothing';
import { useDispatch } from 'react-redux';
import { setSellCount } from 'reduxs/SellSlice';
import Cookies from 'js-cookie';
export const History = ({
  selectedCategory,
  sorting,
  setSorting,
  setCurrentPage,
  currentPage,
  hasMore,
  setVisible,
  visible,
  setIsLoading,
  isLoading,
  historyData,
  setHistoryData,
  rowCount,
  setHasMore,
  setRowCount,
  isMobile,
  setPagination,
  pagination
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));

  useEffect(() => {
    const Bearer_Token = Cookies.get('Bearer_Token');
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${Bearer_Token}`;
  }, []);

  const { apiCaller: onSellerSellHistoryList } = useApi({
    dataGetter: seller_sell_history
  });

  const fetchData = useCallback(
    async (page) => {
      try {
        setIsLoading(true);
        let params = {
          page
        };
        const res = await onSellerSellHistoryList(params);
        const newData = res.data.data.data.filter((item, index, self) => index === self.findIndex((i) => i.m_id === item.m_id));
        setHistoryData((prev) => {
          const updatedData = page === 1 ? newData : [...prev, ...newData];
          const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

          const newVisible = uniqueArray.length;
          setVisible(newVisible);
          return uniqueArray;
        });
        dispatch(setSellCount(res.data.data.total));
        setPagination({ from: res.data.data.from, to: res.data.data.to });
        setRowCount(res.data.data.total);
      } catch (error) {
        console.error(error.message);
      } finally {
        // setTimeout(() => {
        setIsLoading(false);
        // }, 2000);
      }
    },
    [onSellerSellHistoryList]
  );

  useEffect(() => {
    fetchData(currentPage, selectedCategory);
  }, [fetchData, location.search, currentPage, selectedCategory]);

  const fetchMoreOnBottomReached = () => {
    if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
      if (visible < rowCount) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  useEffect(() => {
    visible < rowCount && rowCount > 0
      ? window.addEventListener('scroll', fetchMoreOnBottomReached)
      : window.removeEventListener('scroll', fetchMoreOnBottomReached);
    // Attach scroll event listener when the component mounts
    window.addEventListener('scroll', fetchMoreOnBottomReached);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', fetchMoreOnBottomReached);
    };
  }, [visible]);

  const columns = useMemo(() => {
    const columnsArray = [
      {
        accessorKey: 'item.fullname',
        header: <div className={`${isMobile ? 'd-none' : ''}`}>Item</div>,
        size: 150,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          /* eslint-disable */
          const stickers = row.original.item?.stickers;
          /* eslint-enable */

          return (
            /* eslint-disable */
            <>
              {!isMobile ? (
                <div id={`${row?.original?.item_id}`} className="d-flex gap-15 align-items-center">
                  <div className="goods-card-main-div position-relative">
                    <div
                      className="goods-card-img"
                      style={{
                        backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.item?.imageUrl}")`
                      }}
                    ></div>
                    <div className="wear-name text-white">
                      <p className="font-10 font-500">{row?.original && wearNameConverter(row?.original?.wear)}</p>
                      <p className="font-10 font-500">
                        {row?.original?.wear !== 'N/A' ? `/ ${Number(row?.original?.float_id).toFixed(4)} ` : ''}
                      </p>
                    </div>

                    <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                    <div
                      className="border"
                      style={{
                        borderBottom: `5px solid ${row?.original?.item?.r_color}`
                      }}
                    ></div>
                  </div>

                  <div className="display-product-name">
                    <div className="inventory-product-name">
                      <p className="productname">{row?.original && itemNameConverter(row?.original?.item?.fullname)}</p>
                      <div className="d-flex gap-5 text-white">
                        <p className="productname-desc">{row?.original?.item?.category}</p>*
                        <p className="productname-desc">{row?.original?.item?.subCategory}</p>
                      </div>
                    </div>
                    {stickers && stickers.length > 0 && (
                      <div className="previous-sales-sticker product-sticker mt-10">
                        {stickers.map((item, index) => {
                          return (
                            <div
                              className="product-display-sticker"
                              key={index}
                              // dangerouslySetInnerHTML={{ __html: item.value }}
                            >
                              {/* <img src={item.value} alt="sticker" title={item.key} /> */}
                              <div className="single-sticker-div">
                                <img src={item.value} alt="sticker" />
                                {item.key && (
                                  <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                    <span>{item.key}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div id={`${row?.original?.item_id}`} className="responsive-buy-div">
                  <div className="goods-card-main-div position-relative">
                    <div
                      className="goods-card-img"
                      style={{
                        backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.item?.imageUrl}")`
                      }}
                    ></div>
                    <div className="wear-name text-white">
                      <p className="font-10 font-500">{row?.original && wearNameConverter(row?.original?.wear)}</p>
                      <p className="font-10 font-500">
                        {row?.original?.wear !== 'N/A' ? `/ ${Number(row?.original?.float_id).toFixed(4)} ` : ''}
                      </p>
                    </div>

                    <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                    <div
                      className="border"
                      style={{
                        borderBottom: `5px solid ${row?.original?.item?.r_color}`
                      }}
                    ></div>
                  </div>

                  <div className="display-product-name">
                    <div className="inventory-product-name">
                      <p className="productname">{row?.original && itemNameConverter(row?.original?.item?.fullname)}</p>
                      <div className="d-flex gap-5 text-white  resonsive-product-desc mb-5">
                        <p className="productname-desc">{row?.original?.item?.category}</p>*
                        <p className="productname-desc">{row?.original?.item?.subCategory}</p>
                      </div>
                      <p className="mb-10">
                        {defaultCurrency && defaultCurrency.symbol} {row.original.startBid}
                      </p>
                    </div>
                    {stickers && stickers.length > 0 && (
                      <div className="previous-sales-sticker product-sticker mt-10">
                        {stickers.map((item, index) => {
                          return (
                            <div
                              className="product-display-sticker"
                              key={index}
                              // dangerouslySetInnerHTML={{ __html: item.value }}
                            >
                              {/* <img src={item.value} alt="sticker" title={item.key} /> */}
                              <div className="single-sticker-div">
                                <img src={item.value} alt="sticker" />
                                {item.key && (
                                  <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                    <span>{item.key}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="responsive-btn-div">
                    <button onClick={() => navigate(`/view/private/${row.original.market_hash_id}`)} className="table-button">
                      View
                    </button>
                  </div>
                </div>
              )}
            </>
            /* eslint-enable */
          );
        }
      }
    ];

    columnsArray.push(
      {
        accessorKey: 'wear',
        header: 'Wear',
        size: 150
      },
      {
        // accessorKey: 'item.category',
        header: 'Price',
        size: 100,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          /* eslint-disable */
          return (
            <>
              <p>
                {defaultCurrency && defaultCurrency.symbol} {row.original.startBid}
              </p>
            </>
          );
          /* eslint-enable */
        }
      },
      // {
      //   // accessorKey: 'item.category',
      //   header: 'Time Left',
      //   size: 100,
      //   // eslint-disable-next-line
      //   Cell: ({ row }) => {
      //     /* eslint-disable */
      //     const createdDateUTC = moment.utc(row.original.updated_date);
      //     const currentDateUTC = moment.utc();

      //     // Check if created_date is in the future
      //     const isFutureDate = createdDateUTC.isAfter(currentDateUTC);

      //     return (
      //       <>
      //         <CountdownTimer daysCount={24} createdDate={createdDateUTC} key={row?.original?.item_id} />
      //       </>
      //     );
      //     /* eslint-enable */
      //   }
      // },
      {
        header: <div className="buy-action d-none">Action</div>,
        // eslint-disable-next-line
        Cell: ({ row }) => (
          /* eslint-disable */
          <div>
            <button
              className="view-button table-button sellproduct-button"
              style={{ backgroundColor: '#ffffff1a' }}
              onClick={() => navigate(`/view/private/${row.original.market_hash_id}`)}
            >
              view
            </button>
          </div>
          /* eslint-enable */
        )
      }
    );
    return columnsArray;
  }, [convertCurrency]);

  const table = useMaterialReactTable({
    columns,
    data: historyData,
    enableRowSelection: false,
    getRowId: (row) => /* eslint-disable */ row?.item_id /* eslint-enable */,
    onRowSelectionChange: false,
    // initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableBottomToolbar: true,
    enablePagination: false,

    muiTableContainerProps: {
      className: 'zipit-data-table',
      sx: {
        overflow: 'initial'
      }
    },
    muiBottomToolbarProps: {
      className: 'table-bottom-bar-pagination',
      sx: {
        // bottom: '56px',
        padding: 0,
        '& .MuiBox-root ': {
          justifyContent: 'end',
          marginTop: '10px'
        },
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#fff',
          height: '3px'
        },
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar2Indeterminate': {
          backgroundColor: '#6117BF',
          height: '3px'
        },
        '& .MuiLinearProgress-root.MuiLinearProgress-colorPrimary': {
          backgroundColor: '#6117BF',
          height: '3px'
        }
      }
    },
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        {historyData.length > 0 && (
          <div className="pagination">
            {pagination.from} - {pagination.to} of {rowCount}
          </div>
        )}
      </>
    ),
    muiTablePaperProps: {
      sx: {
        borderRadius: '0',
        boxShadow: 'none',
        overflow: 'initial'
      }
    },
    muiTableBodyProps: {
      sx: {
        '& tr td': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        },
        '& tr:not([data-selected="true"])': {
          backgroundColor: 'transparent'
        }
      },
      className: 'buytable-body'
    },
    mrtTheme: {
      baseBackgroundColor: '#0b051b',
      selectedRowBackgroundColor: '#ffffff1a'
    },
    muiTableBodyCellProps: {
      className: isLoading ? 'sell-product-loader-class' : ''
    },
    muiTableHeadProps: {
      sx: {
        '& tr th': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        }
      },
      className: 'buytable-head'
    },
    localization: {
      noRecordsToDisplay: ''
    },
    muiSkeletonProps: ({ row, column }) => {
      if (row && column) {
        // Apply static skeleton properties for each cell
        return {
          animation: 'wave',
          variant: 'rect',
          width: column.size,
          height: isMobile ? 40 : 70,
          className: 'mui-skeleton-zipit'
        };
      }
    },
    rowCount,
    state: {
      isLoading: currentPage === 1 ? isLoading : false,
      showProgressBars: currentPage === 1 ? false : isLoading,
      showLoadingOverlay: false
    }
  });
  return <>{rowCount && rowCount > 0 ? <MaterialReactTable table={table} /> : <Nothing />}</>;
};

History.propTypes = {
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
  sorting: PropTypes.any,
  setSorting: PropTypes.func,
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  hasMore: PropTypes.bool,
  setVisible: PropTypes.func,
  visible: PropTypes.any,
  setIsLoading: PropTypes.func,
  isLoading: PropTypes.bool,
  historyData: PropTypes.arrayOf(PropTypes.object),
  setHistoryData: PropTypes.func,
  rowCount: PropTypes.number,
  setHasMore: PropTypes.func,
  setRowCount: PropTypes.func,
  isMobile: PropTypes.bool,
  setPagination: PropTypes.func,
  pagination: PropTypes.object
};

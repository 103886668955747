import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import {
  axiosAuthInstance,
  get_similar_inventory,
  steam_pricehistory,
  market_private_view,
  confirm_trade_offer_accepted,
  buyer_accept_trade,
  check_api_key,
  buy_inventory,
  user_bid_add,
  add_following,
  buyer_waiting_trade,
  steam_set_api_key
} from 'api';
import { useApi } from 'hooks/useApi';
import {
  MarketView,
  ConfirmTradeOfferSendModal,
  Graph,
  SimilarProduct,
  SteamApiKeyModal,
  ConfirmBid,
  Checkout,
  UserBanModal
} from 'components';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

export const MarketPrivateView = () => {
  document.title = 'Zipit - Market Skin';
  const { market_hash_id } = useParams();

  const userBan = useSelector((state) => state.user.userBlockDetails);
  const [isUserBanModalOpen, setIsUserBanModalOpen] = useState(false);

  const [item, setItem] = useState();

  const [acceptWarning, setAcceptWarning] = useState(false);

  const [similarProduct, setSimilarProduct] = useState([]);
  const [offerAcceptCountDown, setOfferAcceptCountDown] = useState(null);

  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [bidArray, setBidArray] = useState([]);
  const [offerCountDown, setOfferCountDown] = useState();

  const [follow, setFollow] = useState([]);
  const [followingCount, setFollowingCount] = useState(0);
  const [tradeCount, setTradeCount] = useState(0);

  const [confimTradeOfferSendModalOpen, setConfimTradeOfferSendModalOpen] = useState(false);

  const [priceHistory, setPriceHistory] = useState([]);
  const [graphDays, setGraphDays] = useState({ labels: 'Last 2 weeks', days: '15' });
  const [showOptions, setShowOptions] = useState(false);

  const [confirmBuyModal, setConfirmBuyModal] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [checkOutItemData, setCheckOutItemData] = useState();
  const [bidItemData, setBidItemData] = useState();
  const [bidPrice, setBidPrice] = useState('');
  const [isLoadingBid, setIsLoadingBid] = useState(false);

  const [checkBidStatus, setCheckBidStatus] = useState(false);
  const [successMes, setSuccessMes] = useState({});
  const [confirmBidModal, setConfirmBidModal] = useState(false);

  const [loadingIndex, setLoadingIndex] = useState(null);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  //steam api key setter and getter hooks
  const [steamAPiKeyModal, setSteamApiKeyModal] = useState(false);
  const [apiKey, setApiKey] = useState({ api_key: '', tread_url: '' });
  const [errors, setErrors] = useState({});
  const [apiKeyErrorMessage, setApiKeyErrorMessage] = useState();

  /*************** currency get code  **************** */
  const tf2Currencies = JSON.parse(localStorage.getItem('tf2-currencies'));
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const showSecondPrice = tf2Currencies && tf2Currencies.code !== defaultCurrency.code;
  const currencies = showSecondPrice ? tf2Currencies?.code : defaultCurrency?.code;

  /*****************  get bearer token code and add axios header *****************/
  const Bearer_Token = Cookies.get('Bearer_Token');

  useEffect(() => {
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${Bearer_Token}`;
  }, [Bearer_Token]);

  /******************************* Api Calls ************************************/

  const { apiCaller: onMarketPrivateView } = useApi({
    dataGetter: market_private_view
  });

  const { apiCaller: onGetSimilarInventory } = useApi({
    dataGetter: get_similar_inventory
  });

  const { apiCaller: steamPriceHistory } = useApi({
    dataGetter: steam_pricehistory
  });

  const { apiCaller: onConfirmTradeOfferAccepted } = useApi({
    dataGetter: confirm_trade_offer_accepted
  });

  const { apiCaller: onBuyerConfirmTreadeAccept } = useApi({
    dataGetter: buyer_accept_trade
  });

  const { apiCaller: onApiKeyCheck } = useApi({
    dataGetter: check_api_key
  });

  const { apiCaller: onCheckOutItem } = useApi({
    dataGetter: buy_inventory
  });

  const { apiCaller: onBidAddByUser } = useApi({
    dataGetter: user_bid_add
  });

  const { apiCaller: onFollowItem } = useApi({
    dataGetter: add_following
  });

  const { apiCaller: onBuyerWaitingTrade } = useApi({
    dataGetter: buyer_waiting_trade
  });

  const { apiCaller: onSteamSetApiKey } = useApi({
    dataGetter: steam_set_api_key
  });

  /******************************* get item data or similar product data ************************************/
  const fetchData = async (market_hash_id) => {
    try {
      setIsLoadingProduct(true);
      const response = await onMarketPrivateView(market_hash_id);
      if (response.data.status === 'success') {
        if (response.data.data) {
          // console.log(response.data.data.m_id, ' response.data.data.m_id');
          setItem(response.data.data);
          setFollowingCount(Number(response.data.following_count));
          setTradeCount(Number(response?.data.data.trade_count));
          setBidArray(response.data.totalbid);

          /* similar inventory api call */
          const similerProductRes = await onGetSimilarInventory({
            category: response.data.data.category,
            market_id: response.data.data.m_id
          });
          setSimilarProduct(similerProductRes.data.item);
          const similerProductArray = similerProductRes.data.item;
          /* similar inventory api call end */

          const followItems = similerProductArray.map((product) => ({
            market_id: Number(product.m_id),
            status: product.following_status
          }));
          setFollow(followItems);

          /* price graph api call */
          const priceHistory = await steamPriceHistory({
            hasbyname: response.data.data.item.fullname,
            days: graphDays.days,
            currency: currencies
          });
          setPriceHistory(priceHistory.data.data);
          /* price graph api call end */

          setOfferCountDown(response.data.data.user_offer_data);
        }

        setTimeout(() => {
          setIsLoadingProduct(false);
        }, 1000);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(market_hash_id);
  }, [market_hash_id]);

  const onBidAdd = (itemData, indexNum) => {
    setBidItemData(itemData);
    setLoadingIndex(indexNum);
    if (userBan.status === 'block') {
      setIsUserBanModalOpen(true);
    } else {
      setIsLoadingBid(true);
      onApiKeyCheck().then((res) => {
        setCheckBidStatus(true);
        if (res.data.api_status === 1) {
          setTimeout(() => {
            setIsLoadingBid(false);
            document.body.classList.add('modal-open');
            setConfirmBidModal(true);
          }, 1000);
        } else {
          if (res.data.api_status === 0) {
            setTimeout(() => {
              setIsLoadingBid(false);
              setApiKeyErrorMessage(res.data);
              document.body.classList.add('modal-open');
              setSteamApiKeyModal(true);
            }, 1000);
          }
        }
      });
    }
  };

  /************ on check out but to check api key valide or not and user login or not  ************/
  const onCheckoutModal = (itemData, indexNum) => {
    setCheckOutItemData(itemData);
    setLoadingIndex(indexNum);

    try {
      if (userBan.status === 'block') {
        setIsUserBanModalOpen(true);
      } else {
        setIsLoading(true);
        onApiKeyCheck().then((res) => {
          if (res.data.api_status === 1) {
            setTimeout(() => {
              setIsLoading(false);
              document.body.classList.add('modal-open');
              setConfirmBuyModal(true);
            }, 1000);
          } else {
            if (res.data.api_status === 0) {
              setTimeout(() => {
                setIsLoading(false);
                setApiKeyErrorMessage(res.data);
                document.body.classList.add('modal-open');
                setSteamApiKeyModal(true);
              }, 1000);
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /***************** handle submit for check api key *****************/
  const validateValues = (apiKey) => {
    let errors = {};
    if ((apiKeyErrorMessage.status === 'error' || apiKeyErrorMessage.status === 'api-error') && apiKey.api_key === '') {
      errors.api_key = 'This field is required';
    }
    if ((apiKeyErrorMessage.status === 'error' || apiKeyErrorMessage.status === 'trade-error') && apiKey.tread_url === '') {
      errors.tread_url = 'This field is required';
    }
    return errors;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateValues(apiKey);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        setErrors({});
        const param = {};
        if (apiKey.api_key) {
          param.api_key = apiKey.api_key;
        }
        if (apiKey.tread_url) {
          param.tread_url = apiKey.tread_url;
        }
        const res = await onSteamSetApiKey(param);
        if (res.data.status === 'failed') {
          setErrors({ api_key: res.data.message });
        } else {
          setSteamApiKeyModal(false);
          if (checkBidStatus) {
            setConfirmBidModal(true);
          } else {
            setConfirmBuyModal(true);
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const onCloseAPiKeyModal = () => {
    document.body.classList.remove('modal-open');
    setSteamApiKeyModal(false);
  };

  /************ handle submit for confirm bid   ************/
  const ConfirmBidHandle = (marketId, bid) => {
    const params = {
      market_id: marketId,
      bidPrice: Number(bid).toFixed(2)
    };
    onBidAddByUser(params).then((res) => {
      if (res.data.status === 'success') {
        document.body.classList.remove('modal-open');
        setConfirmBidModal(false);
        // fetchData(market_id);
        setBidArray((prevBidArray) => [...prevBidArray, res.data.item[0]]);
        setSuccessMes({ message: res.data.massage, type: res.data.status });
      }
    });
  };

  /************ handle submit for confirm buy   ************/

  const onConfirmBuy = (marketId) => {
    onCheckOutItem({ market_id: marketId }).then((res) => {
      if (res.data.status === 'success') {
        setConfirmBuyModal(false);
        document.body.classList.remove('modal-open');
        setShowPaymentModal(true);
        onBuyerWaitingTrade({ buy_id: res.data.data.buy_id, market_id: marketId }).then((res) => {
          // if (res.data.status === 'success') {
          // }
        });
      }
    });
  };

  const followHandle = (marketId) => {
    onFollowItem({ market_id: marketId }).then((res) => {
      if (res.data.status === 'success') {
        setFollowingCount(followingCount + 1);
        if (res.data.follow_status === 'delete') {
          setFollowingCount(followingCount - 1);
          const updatedFollow = follow.map((f) => {
            if (Number(f.market_id) === Number(marketId)) {
              return { ...f, status: false };
            }
            return f;
          });
          setFollow(updatedFollow);
        } else {
          const updatedFollow = follow.map((f) => {
            if (Number(f.market_id) === Number(marketId)) {
              return { ...f, status: true };
            }
            return f;
          });
          setFollow(updatedFollow);
        }
      }
    });
  };

  const closeModal = async () => {
    document.body.classList.remove('modal-open');
    setConfimTradeOfferSendModalOpen(false);
    setConfirmBuyModal(false);
    setConfirmBidModal(false);
  };

  const handleGraphChangeOnDays = async (daysObject) => {
    setGraphDays(daysObject);
    setShowOptions(false);
    const priceHistory = await steamPriceHistory({
      hasbyname: item.item.fullname,
      day: daysObject.days,
      currency: currencies
    });
    setPriceHistory(priceHistory.data.data);
  };

  const handleConfimTradeOfferSend = async () => {
    document.body.classList.add('modal-open');
    setConfimTradeOfferSendModalOpen(true);
  };

  const TradeOfferAccepted = (tradeData) => {
    onConfirmTradeOfferAccepted({
      buy_id: tradeData.id,
      market_id: tradeData.m_id
    }).then((res) => {
      if (res.data.status === 'success') {
        setOfferAcceptCountDown(res.data.data);
        closeModal();
      }
    });
  };

  const buyerTradeOfferAccept = (tradeData) => {
    onBuyerConfirmTreadeAccept({
      buy_id: tradeData.id,
      market_id: tradeData.m_id
    }).then((res) => {
      if (res.data.status === 'success') {
        setOfferAcceptCountDown(res.data.data);
        closeModal();
      }
    });
  };
  const userBanModalClose = () => {
    document.body.classList.remove('modal-open');
    setIsUserBanModalOpen(false);
  };

  return (
    <div>
      {isUserBanModalOpen && (
        <UserBanModal setIsUserBanModalOpen={setIsUserBanModalOpen} userBan={userBan} userBanModalClose={userBanModalClose} />
      )}
      {steamAPiKeyModal && <SteamApiKeyModal onSubmit={onSubmit} setApiKey={setApiKey} closeModal={onCloseAPiKeyModal} errors={errors} />}
      {confirmBidModal && (
        <ConfirmBid
          ConfirmBidHandle={ConfirmBidHandle}
          closeModal={closeModal}
          setAcceptTerms={setAcceptTerms}
          acceptTerms={acceptTerms}
          showSecondPrice={showSecondPrice}
          tf2Currencies={tf2Currencies}
          defaultCurrency={defaultCurrency}
          bidItemData={bidItemData}
          setBidItemData={setBidItemData}
          bidPrice={bidPrice}
        />
      )}
      {confirmBuyModal && (
        <Checkout
          closeModal={closeModal}
          setAcceptTerms={setAcceptTerms}
          acceptTerms={acceptTerms}
          item={item}
          checkOutItemData={checkOutItemData}
          showSecondPrice={showSecondPrice}
          tf2Currencies={tf2Currencies}
          defaultCurrency={defaultCurrency}
          acceptWarning={acceptWarning}
          setAcceptWarning={setAcceptWarning}
          onConfirmBuy={onConfirmBuy}
        />
      )}
      {confimTradeOfferSendModalOpen && (
        <ConfirmTradeOfferSendModal
          closeModal={closeModal}
          TradeOfferAccepted={TradeOfferAccepted}
          setAcceptWarning={setAcceptWarning}
          acceptWarning={acceptWarning}
          item={item}
          buyerTradeOfferAccept={buyerTradeOfferAccept}
        />
      )}
      <MarketView
        item={item}
        Bearer_Token={Bearer_Token}
        defaultCurrency={defaultCurrency}
        currency={tf2Currencies}
        showSecondPrice={showSecondPrice}
        isLoadingProduct={isLoadingProduct}
        bidArray={bidArray}
        follow={follow}
        followingCount={followingCount}
        tradeCount={tradeCount}
        offerAcceptCountDown={offerAcceptCountDown}
        offerCountDownData={offerCountDown}
        handleConfimTradeOfferSend={handleConfimTradeOfferSend}
      />
      <Graph
        isLoadingProduct={isLoadingProduct}
        priceHistory={priceHistory}
        graphDays={graphDays}
        setShowOptions={setShowOptions}
        showOptions={showOptions}
        handleGraphChangeOnDays={handleGraphChangeOnDays}
        tf2Currencies={tf2Currencies}
        defaultCurrency={defaultCurrency}
        showSecondPrice={showSecondPrice}
      />
      <SimilarProduct
        similarProduct={similarProduct}
        defaultCurrency={defaultCurrency}
        onCheckoutModal={onCheckoutModal}
        onBidAdd={onBidAdd}
        loadingIndex={loadingIndex}
        isLoadingBid={isLoadingBid}
        followHandle={followHandle}
        follow={follow}
        successMes={successMes}
        isLoadingProduct={isLoadingProduct}
        isLoading={isLoading}
      />
    </div>
  );
};

import React from 'react';

import './style.css';
import { Button } from 'components/common';
import { useApi } from 'hooks/useApi';
import { home_page_contact } from 'api';

export const Community = () => {
  const { data } = useApi({
    dataGetter: home_page_contact,
    autoRetrieve: true
  });

  const section = data?.data;

  const handleClick = (button_url) => {
    if (button_url) {
      window.open(button_url, '_blank');
    }
  };
  return (
    <>
      {section ? (
        <section className="community-section">
          <div className="container-fluid">
            <div className="container position-relative">
              <div className="community-heading-div">
                {section?.title && <h2 className="main_sub_heading">{section?.title}</h2>}
                {section?.description && <div className="description" dangerouslySetInnerHTML={{ __html: section?.description }}></div>}
              </div>
              <div className="community-text-div position-relative">
                <span className="community-gredient"></span>
                <div className="row">
                  <div className="col-sm-6 col ">
                    <div className="community-inner-div discord-text-div">
                      {section?.descord_image && (
                        <div className="community-img-div">
                          <img src={section?.descord_image} alt="Zipit" className="img-fluid" />
                        </div>
                      )}
                      <div className="community-button-div">
                        {section?.descord_title && (
                          <Button className="common_button" handleClick={() => handleClick(section?.descord_link)}>
                            <div className="zipit-btn-div">
                              <span></span>
                              <img src="/images/home/zipit-btn-logo.svg" alt="Zipit" className="img-fluid" />
                            </div>
                            {section?.descord_title}
                          </Button>
                        )}
                        {section?.descord_link_title && (
                          <div className="home-contact">
                            <img src="/images/home/discord-icon.svg" alt="Zipit" className="img-fluid" />
                            <p className="home-contact-text">{section?.descord_link_title}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col">
                    <div className="community-inner-div facebook-text-div">
                      <div className="community-button-div">
                        {section?.descord_fb_title && (
                          <Button className="common_button" handleClick={() => handleClick(section?.facebook_link)}>
                            <div className="zipit-btn-div">
                              <img src="/images/home/zipit-btn-logo.svg" alt="Zipit" className="img-fluid" />
                            </div>
                            {section?.descord_fb_title}
                          </Button>
                        )}
                        {section?.facebook_title && (
                          <div className="home-contact">
                            <img src="/images/home/home-facebook.svg" alt="Zipit" className="img-fluid" />
                            <p className="home-contact-text">{section?.facebook_title}</p>
                          </div>
                        )}
                      </div>
                      {section?.facebook_image && (
                        <div className="community-img-div">
                          <img src={section?.facebook_image} alt="Zipit" className="img-fluid " />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="community-section community-section-loading"></section>
      )}
    </>
  );
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RoundedPrice } from 'lib';
export const Auction = ({
  setAcceptTerms,
  setCurrentTime,
  currentTime,
  selectedDay,
  setSelectedDay,
  // setHasBuyNow,
  // hasBuyNow,
  setAuctionType,
  auctionType,
  setRunTime,
  // showSecondPrice,
  // currency,
  defaultCurrency,
  // convertedAmount,
  buyEarningPrice,
  // buyListedPrice,
  auctionBuyPrice,
  // bargainPrice,
  error,
  average_price,
  mainPrice,
  hasCheckOut,
  setHasCheckOut,
  checkOutPrice
  // handleBargainPriceChange
}) => {
  const commissionRate = useSelector((state) => state.user.commissionRate);
  const handleAuctionTypeChange = (event) => {
    setAuctionType(event.target.value);
    setAcceptTerms();
    if (event.target.value === 'lighting') {
      setRunTime(60);
    }
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setRunTime(day);
  };

  const formatDate = (date) => {
    const options = { weekday: 'long', day: 'numeric', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const [weekday, month, day] = formattedDate.split(' ');
    return `${weekday} ${day} ${month}`;
  };

  const renderDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + selectedDay);
    return formatDate(currentDate);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTime = new Date();
      newTime.setHours(newTime.getHours() + 1);
      setCurrentTime(newTime);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="sel-modal-row">
      <div className="row custome-row-gap">
        <div className="col-lg-6 col">
          <div className="sell-modal-left-div">
            {/* <div className="sell-modal-info">
              {showSecondPrice && (
                <>
                  <p>Your Approx Earnings.</p>
                  <p>
                    {currency?.symbol} {convertedAmount}
                  </p>
                </>
              )}
            </div> */}

            <div className="sell-modal-info position-relative">
              <div>
                Average Market Price
                <img src="/images/sell-modal/question.svg" alt="zipit" className="img-fluid tooltip-image" />
                <div className="tooltiptext">
                  <p>The average market price is based on the average price similar skins are listed at.</p>
                </div>
              </div>
              <p>
                {defaultCurrency && defaultCurrency.symbol}
                {average_price && RoundedPrice(average_price)}
              </p>
            </div>
            <div className="sell-modal-info position-relative">
              <div>
                Commission Rate
                <img src="/images/sell-modal/discount.svg" alt="zipit" className="img-fluid tooltip-image" />
                <div className="tooltiptext">
                  <p>The commision rate for items sold are {commissionRate && commissionRate + '%'}</p>
                </div>
              </div>
              <p>{commissionRate && commissionRate + '%'}</p>
            </div>

            <div className="mt-20 condition-label border-top pt-10">
              {/* {mainPrice < 2 && <p className="opacity-white">Bargains are disabled for items under €2</p>}

              <div className="condition-label">
                <label className={`sell-label ${mainPrice < 2 ? 'sell-min-bargain-price' : ''}`}>
                  Set custom minimum bargain price
                  <input
                    type="checkbox"
                    name="custom_price"
                    onChange={(e) => setHasBuyNow(e.target.checked)}
                    disabled={mainPrice < 2}
                    checked={hasBuyNow}
                  />
                  <span className="checkmark l-0"></span>
                  <img src="/images/sell-modal/question.svg" alt="zipit" className="img-fluid tooltip-image" />
                  <div className="tooltiptext">
                    <p>
                      You can set a custom minimum bargain reserve price for your listing. We are not going to show it to potential buyers,
                      instead we will show the standard 20% of the sell price. The custom amount has to be more than 20% of the sell price.
                    </p>
                  </div>
                </label>

                {hasBuyNow && (
                  <>
                    <div className={`grey-background input-main-div mb-5 ${error.bargainPrice ? 'invalid-input' : 'valid-input'}`}>
                      <label className="input-lable ">{defaultCurrency && defaultCurrency.symbol}</label>
                      <input
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="bargain"
                        // className={error.bargainPrice ? 'invalid-input' : 'valid-input'}
                        placeholder="0"
                        value={bargainPrice || ''}
                        onChange={handleBargainPriceChange}
                        autoComplete="off"
                        onKeyDown={(e) => {
                          if (
                            (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                              e.key !== 'Backspace' &&
                              e.key !== 'Delete' &&
                              e.key !== 'ArrowLeft' &&
                              e.key !== 'ArrowRight' &&
                              e.key !== '.') || // Allow dot key
                            (e.key === '.' && bargainPrice.includes('.')) // Ensure only one dot is entered
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </>
                )}
                {error.bargainPrice && <p style={{ color: 'red', paddingBottom: '5px' }}>{error.bargainPrice}</p>}
              </div> */}

              <label className={`sell-label ${Number(mainPrice) === 0 ? 'sell-min-bargain-price' : ''}`}>
                Buyout Price
                <input
                  type="checkbox"
                  name=""
                  onChange={(e) => setHasCheckOut(e.target.checked)}
                  checked={hasCheckOut}
                  disabled={Number(mainPrice) === 0}
                />
                <span className="checkmark l-0"></span>
              </label>
              {hasCheckOut && (
                <>
                  <div className={`grey-background input-main-div mb-5 ${error.checkOutPrice ? 'invalid-input' : 'valid-input'}`}>
                    <label className="input-lable ">{defaultCurrency && defaultCurrency.symbol}</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="bargain"
                      placeholder="0"
                      // className={error.bargainPrice ? 'invalid-input' : 'valid-input'}
                      value={checkOutPrice || ''}
                      onChange={auctionBuyPrice}
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (
                          (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                            e.key !== 'Backspace' &&
                            e.key !== 'Delete' &&
                            e.key !== 'ArrowLeft' &&
                            e.key !== 'ArrowRight' &&
                            e.key !== '.') || // Allow dot key
                          (e.key === '.' && checkOutPrice.includes('.')) // Ensure only one dot is entered
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {error.checkOutPrice && <p className="color-red font-14 pb-0 mb-5">{error.checkOutPrice}</p>}
                </>
              )}
            </div>
            {hasCheckOut && (
              <>
                <div className="sell-modal-info position-relative">
                  <div>
                    Listed Price
                    <img src="/images/sell-modal/question.svg" alt="zipit" className="img-fluid tooltip-image" />
                    <div className="tooltiptext">
                      <p>The price the skin will be listed at in the market place.</p>
                    </div>
                  </div>
                  <p>
                    {defaultCurrency && defaultCurrency.symbol}
                    {checkOutPrice ? checkOutPrice : '0'}
                  </p>
                </div>
                <div className="sell-modal-info position-relative mt-10">
                  <div>
                    Your Earnings
                    <img src="/images/sell-modal/question.svg" alt="zipit" className="img-fluid tooltip-image" />
                    <div className="tooltiptext">
                      <p>
                        The estimated payout if your item sells at the listed price. If the bargain offers are lower than the listed price,
                        your payout will change accordingly.
                      </p>
                    </div>
                  </div>
                  <p>
                    {defaultCurrency && defaultCurrency.symbol} {buyEarningPrice}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-lg-6 col">
          <div className="padding-left sell-modal-right-div">
            <div className="auction-type">
              <p>Type</p>
              <div className="auction-type-main mx-10">
                <div className={`grey-background ${auctionType === 'regular' ? 'checked' : ''}`}>
                  <label className={`sell-label`}>
                    <span className="text-uppercase">Regular </span>
                    <span className="font-12">Up to 8 days</span>
                    <input
                      type="radio"
                      name="auction_type"
                      value="regular"
                      checked={auctionType === 'regular'}
                      onChange={handleAuctionTypeChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className={`grey-background ${auctionType === 'lighting' ? 'checked' : ''}`}>
                  <label className={`sell-label`}>
                    <span> lighting </span>
                    <span className="font-12">60 mins</span>
                    <input
                      type="radio"
                      name="auction_type"
                      value="lighting"
                      checked={auctionType === 'lighting'}
                      onChange={handleAuctionTypeChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <p className="opacity-white">Last minute bids will extend the auction to 60 seconds</p>
            </div>

            {auctionType === 'regular' && (
              <div className="auction-regulare mt-30">
                <div className="auction-time ">
                  <p>Time</p>
                  <div className="auction-type-main mx-10 ">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((day) => (
                      <div
                        key={day}
                        className={`grey-background auction-time-box ${selectedDay === day ? 'selected' : ''}`}
                        onClick={() => handleDayClick(day)}
                      >
                        {day} day
                      </div>
                    ))}
                  </div>
                </div>

                <div className="auction-listing mt-30">
                  <p className="opacity-white">Listing will end on</p>
                  <div className="d-flex gap-10 mx-10">
                    <img src="/images/sell-modal/clock.svg" alt="zipit" className="img-fluid" />
                    {selectedDay !== null && <p>{renderDate()}</p>}
                  </div>
                  <div className="condition-label">
                    <label className="sell-label">
                      I accept the
                      <a href="/" rel="noreferrer" target="_blank" className="text-white text-underline">
                        terms
                      </a>
                      and confirm that I want to sell this item.
                      <input type="checkbox" name="term" onChange={(e) => setAcceptTerms(e.target.checked)} />
                      <span className="checkmark l-0"></span>
                    </label>
                  </div>
                </div>
              </div>
            )}

            {auctionType === 'lighting' && (
              <div className="auction-light mt-30">
                <div className="auction-type">
                  <p>Time</p>
                  <div className="auction-type-main mx-10 ">
                    <div className="grey-background auction-time-box">60 minutes</div>
                  </div>

                  <div className="auction-listing mt-30">
                    <p className="opacity-white">Listing will end on</p>
                    <div className="d-flex gap-10 mx-10">
                      <img src="/images/sell-modal/time.svg" alt="zipit" className="img-fluid" />
                      {new Date(currentTime.getTime()).toLocaleTimeString([], {
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true
                      })}
                    </div>
                    <div className="condition-label">
                      <label className="sell-label">
                        I accept the
                        <a href="/terms" rel="noreferrer" target="_blank" className="text-white text-underline">
                          terms
                        </a>
                        and confirm that I want to sell this item.
                        <input type="checkbox" name="term" onChange={(e) => setAcceptTerms(e.target.checked)} />
                        <span className="checkmark l-0"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Auction.propTypes = {
  setAcceptTerms: PropTypes.any,
  auctionType: PropTypes.string,
  // setHasBuyNow: PropTypes.func,
  // hasBuyNow: PropTypes.bool,
  setHasCheckOut: PropTypes.func,
  hasCheckOut: PropTypes.bool,
  checkOutPrice: PropTypes.any,
  setSelectedDay: PropTypes.func,
  selectedDay: PropTypes.number,
  currentTime: PropTypes.object,
  setCurrentTime: PropTypes.func,
  setAuctionType: PropTypes.func,
  setRunTime: PropTypes.func,
  // showSecondPrice: PropTypes.bool,
  // currency: PropTypes.object,
  defaultCurrency: PropTypes.object,
  // convertedAmount: PropTypes.any,
  // buyListedPrice: PropTypes.any,
  buyEarningPrice: PropTypes.any,
  bargainPrice: PropTypes.any,
  error: PropTypes.any,
  auctionBuyPrice: PropTypes.func,
  average_price: PropTypes.any,
  mainPrice: PropTypes.any
  // handleBargainPriceChange: PropTypes.func
};

import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './style.css';

import { Button } from 'components';
import { useSelector } from 'react-redux';

export const Hero = ({ banner_section }) => {
  const commissionRate = useSelector((state) => state.user.commissionRate);

  // if (!banner_section) {
  //   return null;
  // }

  const handleClick = (button_url) => {
    if (button_url) {
      window.open(button_url, '_blank');
    }
  };
  return (
    <>
      {banner_section ? (
        <section className="hero-main-div">
          <section className="hero_section section_padding position-relative">
            <img src="/images/home/cs_go.svg" alt="Zipit" className="img-fluid cs_go" />
            <div className="container-fluid">
              <div className="container position-relative">
                <div className="banner_text_div">
                  <img src="/images/home/zipit.svg" alt="Zipit" className="img-fluid zipit" />
                  <div className="common_heading text-center">
                    {banner_section?.title && <h1 className="main_heading pb-0">{banner_section?.title}</h1>}
                    {banner_section?.button_name && (
                      <Button className="common_button mt-50" handleClick={() => handleClick(banner_section?.button_url)}>
                        {banner_section?.button_name}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="container">
                <div className="gun_div">
                  <img src="/images/home/banner_gredient.svg" alt="Zipit" className="banner_gredient" />
                  <img src="/images/home/banner_gun.webp" alt="Zipit" className="img-fluid banner_gun" />
                  <img src="/images/home/gun_shadow.svg" alt="Zipit" className="img-fluid gun_shadow" />
                  <img src="/images/home/gun_handle.svg" alt="Zipit" className="img-fluid gun_handle" />
                  <div className="simcard_Text card_1">
                    <span className="card_shadow shadow_1"></span>
                    <p>
                      <span>
                        <span className="font-40">{commissionRate && commissionRate}</span>
                        <span className="font-24">%</span>
                      </span>
                      Commission
                    </p>
                  </div>
                  <div className="simcard_Text card_2">
                    <span className="card_shadow shadow_2"></span>
                    <p>
                      <span>
                        <span className="font-40">LATEST</span>
                      </span>
                      cs2 Skins
                    </p>
                  </div>
                  <div className="simcard_Text card_3">
                    <span className="card_shadow shadow_3"></span>
                    <p>
                      <span>
                        <span className="font-40">+1000</span>
                      </span>
                      skins
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <img src="/images/home/welcome_cs2.webp" alt="Zipit" className="img-fluid welcome_cs2" />
            <img src="/images/home/share_refer.svg" alt="Zipit" className="img-fluid Share_refer" />
          </section>
          <div className="container-fluid">
            <div className="container">
              <div className="box_main_div">
                <div className="box_inner_div">
                  {banner_section?.description.length > 0 &&
                    banner_section?.description.map((item, index) => {
                      return (
                        <div key={index} className="single_box_div">
                          <div className="single-box-heading-div">
                            <p className="font-30 single-box-heading">{item?.title}</p>
                          </div>
                          <div className="description text-white">
                            <p>{item?.description}</p>
                          </div>
                          <Link to={item?.url}>
                            <img src="/images/home/box_arrow.svg" alt="Zipit" className="img-fluid" />
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="hero-main-div-loading"></section>
      )}
    </>
  );
};

Hero.propTypes = {
  banner_section: PropTypes.any
};

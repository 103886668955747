import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
export const Filter = ({ selectedCategory, onCategoryChange, isMenuOpen, toggleMenu }) => {
  const categoryActiveCounts = useSelector((state) => state.buy.buyFilterCount);

  return (
    <div className={`inventory-table-inner mobile-filter ${isMenuOpen && 'side-filter-menu-show'} `}>
      {isMenuOpen && (
        <div className="back-text" onClick={toggleMenu}>
          <img src="/images/common/left-arrow.svg" alt="left-aarow" /> Back
        </div>
      )}
      <div className="category-item-div">
        <div className="category-main-heading-div">
          <div className="category-heading text-white font-14 opacity-white">Active</div>

          <div
            className={`category-item d-flex justify-content-between ${selectedCategory === 'Selling' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Selling') {
                onCategoryChange('Selling');
              }
            }}
          >
            <div className="text-white font-16">Selling</div>
            <span className="text-white font-16">
              {categoryActiveCounts && categoryActiveCounts.seller_is_selling ? (
                `(${categoryActiveCounts.seller_is_selling})`
              ) : (
                <span className="text-white font-16">(0)</span>
              )}
            </span>
          </div>
          <div
            className={`category-item d-flex justify-content-between ${selectedCategory === 'Private listings' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Private listings') {
                onCategoryChange('Private listings');
              }
            }}
          >
            <div className="text-white font-16">Private Listings</div>
            <span className="text-white font-16">
              {categoryActiveCounts && categoryActiveCounts.seller_isprivate ? (
                `(${categoryActiveCounts.seller_isprivate})`
              ) : (
                <span className="text-white font-16">(0)</span>
              )}
            </span>
          </div>
          <div
            className={`category-item ${selectedCategory === 'Offers received' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Offers received') {
                onCategoryChange('Offers received');
              }
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="text-white font-16">Offers Received</div>
              <span className="text-white font-16">
                {categoryActiveCounts && categoryActiveCounts.seller_isoffer ? (
                  `(${categoryActiveCounts.seller_isoffer})`
                ) : (
                  <span className="text-white font-16">(0)</span>
                )}
              </span>
            </div>
            {categoryActiveCounts && categoryActiveCounts.seller_isoffer > 0 && (
              <div className="d-flex gap-5 align-items-center mt-10" style={{ marginLeft: '-5px' }}>
                <img src={'/images/common/redspot.gif'} alt="" width={25} height={25} className="img-fluid" />
                <p className="font-14 pb-0">Action required</p>
              </div>
            )}
          </div>
        </div>
        <div className="category-main-heading-div">
          <div className="category-heading text-white font-14 opacity-white">panding</div>
          <div
            className={`category-item d-flex justify-content-between ${selectedCategory === 'Waiting For Payment' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Waiting For Payment') {
                onCategoryChange('Waiting For Payment');
              }
            }}
          >
            <div className="text-white font-16">Waiting For Payment</div>
            <span className="text-white font-16">
              {categoryActiveCounts && categoryActiveCounts.seller_make_payment ? (
                `(${categoryActiveCounts.seller_make_payment})`
              ) : (
                <span className="text-white font-16">(0)</span>
              )}
            </span>
          </div>
          <div
            className={`category-item ${selectedCategory === 'Make Trade Offer' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'Make Trade Offer') {
                onCategoryChange('Make Trade Offer');
              }
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="text-white font-16">Make Trade Offer</div>
              <span className="text-white font-16">
                {categoryActiveCounts && categoryActiveCounts.seller_wainting_trade ? (
                  `(${categoryActiveCounts.seller_wainting_trade})`
                ) : (
                  <span className="text-white font-16">(0)</span>
                )}
              </span>
            </div>
            {categoryActiveCounts && categoryActiveCounts.seller_wainting_trade > 0 && (
              <div className="d-flex gap-5 align-items-center mt-10" style={{ marginLeft: '-5px' }}>
                <img src={'/images/common/redspot.gif'} alt="" width={25} height={25} className="img-fluid" />
                <p className="font-14 pb-0">Action required</p>
              </div>
            )}
          </div>
          <div
            className={`category-item d-flex justify-content-between ${
              selectedCategory === 'Pending Accept Trade Offers' ? 'selected' : ''
            }`}
            onClick={() => {
              if (selectedCategory !== 'Pending Accept Trade Offers') {
                onCategoryChange('Pending Accept Trade Offers');
              }
            }}
          >
            <div className="text-white font-16">Pending Accept Trade Offers</div>
            {categoryActiveCounts && categoryActiveCounts.pending_accept_trade_offer ? (
              `(${categoryActiveCounts.pending_accept_trade_offer})`
            ) : (
              <span className="text-white font-16">(0)</span>
            )}
          </div>
        </div>
        <div className="category-main-heading-div">
          <div className="category-heading text-white font-14 opacity-white">Complated</div>
          <div
            className={`category-item d-flex justify-content-between ${selectedCategory === 'History' ? 'selected' : ''}`}
            onClick={() => {
              if (selectedCategory !== 'History') {
                onCategoryChange('History');
              }
            }}
          >
            <div className="text-white font-16">History</div>
          </div>
        </div>
        <div className="category-main-heading-div sell-sidebar-button">
          <Link to="/inventory" className="button-link w-100 green-background">
            sell Item
          </Link>
        </div>
      </div>
    </div>
  );
};

Filter.propTypes = {
  selectedCategory: PropTypes.string,
  onCategoryChange: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func
};

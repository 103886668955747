import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  makePaymentCount: null,
  buyFilterCount: {
    makepayment: 0,
    make_trade: 0,
    complate_trade: 0,
    winning: 0,
    not_wining: 0,
    offer_made: 0,
    buy_sell_history: 0,
    lost_auction: 0,
    seller_is_selling: 0,
    seller_isprivate: 0,
    seller_isoffer: 0,
    seller_make_payment: 0,
    seller_wainting_trade: 0,
    pending_accept_trade_offer: 0,
    seller_history: 0,
    not_seen: 0
  }
};

const BuySlice = createSlice({
  name: 'buy',
  initialState,
  reducers: {
    setBuyMakePaymentCount(state, action) {
      state.makePaymentCount = action.payload;
    },
    setBuyFilterCount(state, action) {
      state.buyFilterCount = action.payload;
    }
  }
});

export const { setBuyMakePaymentCount, setBuyFilterCount } = BuySlice.actions;
export default BuySlice.reducer;

import React from 'react';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RoundedPrice, convertCurrency, itemNameConverter, notificationTimeAgo, wearNameConverter } from 'lib';
import { OfferCountDown, SkeletonLoader, CountdownTimer } from 'components';

export const MarketView = ({
  onEditListing,
  item,
  bidArray,
  folloingCount,
  tradeCount,
  isLoadingProduct,
  handleConfimTradeOfferSend,
  offerAcceptCountDown
}) => {
  const navigate = useNavigate();
  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const showSecondPrice = currency && currency.code !== defaultCurrency.code;
  const hash_id = useSelector((state) => state.user.userData.hash_id);

  return (
    <>
      <div className="market-product-detail-div listing-available">
        <div className="container-fluid">
          <div className="container">
            {isLoadingProduct ? (
              <div className="main-div" style={{ minHeight: '63px' }}></div>
            ) : (
              <>
                <div className="main-div">
                  <div className="breadcrumb">
                    <Link to="/inventory" className="text-white">
                      Inventory
                    </Link>
                    <img src="/images/market/next.svg" alt="Zipit" className="img-fluid" height={16} width={12} />
                    <p className="text-white">{item && item.category}</p>
                    <img src="/images/market/next.svg" alt="Zipit" className="img-fluid" height={16} width={12} />
                    <p className="text-white">{item && itemNameConverter(item.item.fullname)}</p>
                  </div>
                </div>
              </>
            )}
            <div className="market-product-inner-div">
              <div className="market-product-detail-main">
                <div className="single-product-detail single-product-left-div">
                  <div className="single-product-inner-left-div">
                    {isLoadingProduct ? (
                      <SkeletonLoader width="99%" height={15} color="#0b051b" highlightColor="#242347" borderRadius={0} className="mb-10" />
                    ) : (
                      <p className="font-14 opacity-white">
                        {item && item.category} {item && '•' + item.item.subCategory}
                      </p>
                    )}
                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="99%"
                        height={40}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        className="sub-heading"
                      />
                    ) : (
                      <h3 className="sub-heading text-uppercase text-white">{item && itemNameConverter(item.item.fullname)}</h3>
                    )}
                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="99%"
                        height={100}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        className="description mt-10"
                      />
                    ) : (
                      item?.item?.description && (
                        <div className="description">
                          <div className="opacity-white" dangerouslySetInnerHTML={{ __html: item?.item?.description }} />
                        </div>
                      )
                    )}
                    <div className="market-product-info-div mt-30">
                      {isLoadingProduct ? (
                        <SkeletonLoader
                          width="99%"
                          height={20}
                          color="#0b051b"
                          highlightColor="#242347"
                          borderRadius={0}
                          count={6}
                          className="sell-product-info mb-0"
                        />
                      ) : (
                        <>
                          {item && item.wear && (
                            <div className="sell-product-info">
                              <p className="opacity-white">Waer</p>
                              <p>{item && item.wear}</p>
                            </div>
                          )}
                          {item && item.float_id && (
                            <div className="sell-product-info">
                              <p className="opacity-white">Float</p>
                              <p>{item && Number(item.float_id).toFixed(8)}</p>
                            </div>
                          )}
                          {item && item.item.rarity_name && (
                            <div className="sell-product-info">
                              <p className="opacity-white">Rarity</p>
                              <p>
                                <span className="classified" style={{ backgroundColor: `${item && item?.item?.r_color}` }}></span>
                                {item && item.item.rarity_name}
                              </p>
                            </div>
                          )}
                          {item && Number(item.paintseed) > 0 && (
                            <div className="sell-product-info">
                              <p className="opacity-white">Pattern ID</p>
                              <p>{item && item.paintseed}</p>
                            </div>
                          )}
                          {item && Number(item.paintindex) > 0 && (
                            <div className="sell-product-info">
                              <p className="opacity-white">Finish Catalog</p>
                              <p>{item && item.paintindex}</p>
                            </div>
                          )}
                          {/* {item && item.float_id && (
                            <div className="sell-product-info">
                              <p className="opacity-white">Item Specials</p>
                              <p>{item && Number(item.float_id).toFixed(8)}</p>
                            </div>
                          )} */}
                          {item && Number(item.fade_id) !== 0 && (
                            <div className="sell-product-info">
                              <p className="opacity-white">Fade</p>
                              <p>{`${item && Number(item.fade_id).toFixed(2)}%`}</p>
                            </div>
                          )}
                          {/* {item && Number(item.float_id) !== 0 && String(item.float_id).split('.')[1].substring(0, 2) === '00' && (
                            <div className="product-float-id">
                              <img src="/images/market/star-float.svg" alt="star-float" />
                              Float
                              {item && item.float_id && (
                                <div className="sticker-tooltip sticker-tooltip-top market-sticker-tooltip">
                                  <p>low float</p>
                                </div>
                              )}
                            </div>
                          )} */}
                          <div className="d-flex gap-10">
                            {item && Number(item.float_id) !== 0 && String(item.float_id).split('.')[1].substring(0, 2) === '00' && (
                              <div className="product-float-id">
                                <img src="/images/market/star-float.svg" alt="star-float" />
                                Float
                                {item && item.float_id && (
                                  <div className="sticker-tooltip sticker-tooltip-top market-sticker-tooltip">
                                    <p>low float</p>
                                  </div>
                                )}
                              </div>
                            )}
                            {item && ['Sapphire', 'Ruby', 'Black Pearl', 'Emerald'].includes(item?.phase) && (
                              <div
                                className={`phases-id ${
                                  item?.phase === 'Sapphire'
                                    ? 'sapphire-phase-id'
                                    : item?.phase === 'Ruby'
                                      ? 'ruby-phase-id'
                                      : item?.phase === 'Black Pearl'
                                        ? 'black-pearl-phase-id'
                                        : item?.phase === 'Emerald'
                                          ? 'emerald-phase-id'
                                          : ''
                                }`}
                              >
                                {item?.phase === 'Sapphire' && <img src="/images/market/sapphire-phase.svg" alt="sapphire-phase" />}
                                {item?.phase === 'Ruby' && <img src="/images/market/ruby.svg" alt="ruby-phase" />}
                                {item?.phase === 'Black Pearl' && <img src="/images/market/black-pearl.svg" alt="black-pearl-phase" />}
                                {item?.phase === 'Emerald' && <img src="/images/market/emerald.svg" alt="emerald-phase" />}
                                {item?.phase}
                              </div>
                            )}
                          </div>
                          {item && item.isPrivate === '1' && (
                            <div className="sell-product-info private-listing-info">
                              <p className="opacity-white private-listing">Private Listing</p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {isLoadingProduct ? (
                  <div className="single-product-detail single-product-middle-div pb-0 single-product-skeleton">
                    <SkeletonLoader
                      width="99%"
                      height={400}
                      color="#0b051b"
                      highlightColor="#242347"
                      borderRadius={0}
                      className="single-product-image single-product-skeleton"
                    />
                  </div>
                ) : (
                  // </div>

                  <div
                    className={`single-product-detail single-product-scam-middle-div ${
                      item && item?.item?.stickers.length > 0 ? 'sticker-aviailable' : ''
                    }`}
                  >
                    <div
                      className={`single-product-middle-inner-div
                        ${item && item.d_status !== 'make_trade' ? 'market-accept-countdown' : 'avoid-scam-div'}`}
                      style={{
                        borderBottom: `5px solid ${item && item.item.r_color}`
                      }}
                    >
                      <div className="auction-win-div">
                        <img src="/images/market/listhostimage.svg" alt="zipit" height={20} width={20} className="img-fluid" />
                        <div>
                          <p>You are hosting this listing</p>
                        </div>
                      </div>
                      <div className="single-market-product-main-div">
                        <div className="single-product-image">
                          {item && (
                            <img
                              src={`${item && process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${item && item.item.imageUrl}`}
                              alt="zipit"
                              className="img-fluid single-product-img"
                            />
                          )}
                          <img src="/images/sell-modal/new-background-image.jpg" alt="Zipit" className="img-fluid sell-background" />
                        </div>
                        {item?.item?.stickers && item?.item?.stickers.length > 0 && (
                          <div className="market-product-sticker">
                            {item.item &&
                              item?.item?.stickers.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {/* <div dangerouslySetInnerHTML={{ __html: item.value }} /> */}
                                    {/* <img src={item.value} alt="sticker" title={item.key} /> */}
                                    <div className="single-sticker-div">
                                      <img src={item.value} alt="sticker" />
                                      {item.key && (
                                        <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip market-sticker-tooltip">
                                          <span>{item.key}</span>
                                        </div>
                                      )}
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="single-product-detail single-product-last-div">
                  <div className="market-user-main-div">
                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="99%"
                        height={40}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        className="market-user-div mb-0"
                      />
                    ) : (
                      <div className="market-user-div">
                        {/* <img src="/images/market/user-image.png" alt="zipit" className="img-fluid" /> */}
                        {item && item.seller_steam_details?.avatarfull ? (
                          <img src={item.seller_steam_details?.avatarfull} alt="zipit" className="img-fluid" />
                        ) : (
                          <img src="/images/common/profile.svg" height={32} width={32} alt="zipit" className="img-fluid" />
                        )}
                        <p className="text-uppercase font-20 pb-0">{item && item.seller_steam_details.personaname}</p>
                      </div>
                    )}
                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="99%"
                        height={30}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        count={3}
                        className="market-user-div mb-0"
                      />
                    ) : (
                      <div className="market-user-dtail">
                        {/* <div className="sell-product-info">
                          <p>Delivery time</p>
                          <p>15 Minutes</p>
                        </div> */}
                        <div className="sell-product-info">
                          <p>Total trades</p>
                          <p>{tradeCount}</p>
                        </div>
                        <div className="button-link white-border-button mt-30 w-100" onClick={() => navigate(`/shop/${hash_id}`)}>
                          Seller’s shop
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="market-product-price">
                    {isLoadingProduct ? (
                      <SkeletonLoader
                        width="99%"
                        height={10}
                        color="#0b051b"
                        highlightColor="#242347"
                        borderRadius={0}
                        count={6}
                        className="market-product-price mb-10"
                      />
                    ) : (
                      <>
                        <div className="sell-product-info">
                          <p className="opacity-white">Average Price</p>
                          <div className="market-product-price-div">
                            <p>
                              <span>{defaultCurrency && defaultCurrency.symbol}</span>
                              {item && RoundedPrice(item?.average_price)}
                            </p>
                            {showSecondPrice && (
                              <>
                                <span>{showSecondPrice && '~'}</span>
                                <p>{showSecondPrice && currency.symbol + convertCurrency(item && item?.average_price)}</p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="sell-product-info">
                          <p className="opacity-white">Listed Price</p>
                          <div className="market-product-price-div">
                            <p>
                              <span>{defaultCurrency && defaultCurrency.symbol}</span>
                              {item && item?.startBid}
                            </p>
                            {showSecondPrice && (
                              <>
                                <span>{showSecondPrice && '~'}</span>
                                <p>{showSecondPrice && currency.symbol + convertCurrency(item && item?.startBid)}</p>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="sell-product-info">
                          <p className="opacity-white">Followers</p>
                          <div className="market-product-price-div">
                            <p>{folloingCount ? folloingCount : 0}</p>
                          </div>
                        </div>
                        {item && item.selltype === 'auction' && (
                          <div className="sell-product-info">
                            <p className="opacity-white">Total bid</p>
                            <div className="market-product-price-div">
                              <p>{bidArray?.length > 0 ? bidArray?.length : 0}</p>
                            </div>
                          </div>
                        )}
                        {item && item.selltype === 'auction' && (
                          <>
                            <div className="sell-product-info">
                              <p className="opacity-white">Time left</p>
                              <div className="market-product-price-div">
                                <CountdownTimer daysCount={Number(item?.runtime)} createdDate={item?.cread_date} />
                              </div>
                            </div>
                          </>
                        )}
                        {item && item.auctionType === 'lighting' && (
                          <div className="sell-product-info">
                            <p className="opacity-white">
                              This is a Lightning Auction. Last minute bids will extend the auction to 60 seconds
                            </p>
                          </div>
                        )}
                        {offerAcceptCountDown?.d_status === 'complate_trade' && item?.d_status === 'complate_trade' ? (
                          ' '
                        ) : offerAcceptCountDown ? (
                          <OfferCountDown offerCountDownData={offerAcceptCountDown} />
                        ) : (
                          <OfferCountDown offerCountDownData={item} handleConfimTradeOfferSend={handleConfimTradeOfferSend} />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {item && item.selltype === 'auction' && (
              <div className="product-bid-div">
                {isLoadingProduct ? (
                  <div className="similar-product-heading text-center">
                    <SkeletonLoader width="99%" height={30} color="#0b051b" highlightColor="#242347" borderRadius={0} />
                  </div>
                ) : (
                  <div
                    className="similar-product-heading text-center"
                    style={{
                      borderBottom: bidArray?.length > 0 ? 'none' : '1px solid rgba(255, 255, 255, 0.25)'
                    }}
                  >
                    <p className="sub-heading text-uppercase text-white pb-0">
                      Bids
                      <span className="font-14">({bidArray?.length > 0 && bidArray?.length})</span>
                    </p>
                  </div>
                )}
                <div className="product-bid-main-div">
                  {isLoadingProduct ? (
                    // Show skeleton loaders
                    <>
                      {[...Array(4)].map((_, index) => (
                        <div className="product-single-bid-div" key={index}>
                          <SkeletonLoader
                            width="99%"
                            height={20}
                            color="#0b051b"
                            highlightColor="#242347"
                            borderRadius={0}
                            count={1}
                            className="bid-number"
                          />
                          <SkeletonLoader
                            width="99%"
                            height={20}
                            color="#0b051b"
                            highlightColor="#242347"
                            borderRadius={0}
                            count={1}
                            className="bid-number"
                          />
                          <SkeletonLoader
                            width="99%"
                            height={20}
                            color="#0b051b"
                            highlightColor="#242347"
                            borderRadius={0}
                            count={1} // Adjusted count to match the number of loaders
                            className="bid-price text-end"
                          />
                        </div>
                      ))}
                    </>
                  ) : bidArray?.length > 0 ? (
                    // Show bid items
                    bidArray?.map((item, index) => (
                      <div className="product-single-bid-div" key={index}>
                        <div className="bid-number">
                          <img src="/images/market/bid-icon.svg" alt="zipit" className="img-fluid bid-icon" />
                          <p>{item.bid_status ? 'You' : item?.id}</p>
                        </div>
                        <div className="bid-day">
                          <p>{item && notificationTimeAgo(item.created)}</p>
                        </div>
                        <div className="bid-price text-end">
                          <p>
                            {defaultCurrency && defaultCurrency?.symbol} {item?.bidPrice}
                          </p>
                          {showSecondPrice && (
                            <p className="font-14 opacity-white pb-0">
                              {currency?.symbol} {convertCurrency(item?.bidPrice)}
                            </p>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    // Show "No bids yet" message
                    <div className="no-similar-product">
                      <p className="opacity-white text-center">No bids yet. You can change that with a few clicks.</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

MarketView.propTypes = {
  onCheckouModal: PropTypes.func,
  onEditListing: PropTypes.func,
  item: PropTypes.object,
  bidArray: PropTypes.array,
  folloingCount: PropTypes.number,
  tradeCount: PropTypes.number,
  isLoadingProduct: PropTypes.bool,
  handleConfimTradeOfferSend: PropTypes.func,
  offerAcceptCountDown: PropTypes.any
};

import axios from 'axios';
import { authRoutes } from './apiRoutes';

export const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 20000
});

export const currencyRateInstace = axios.create({
  baseURL: process.env.REACT_APP_CURRENCY_RATE_API,
  timeout: 20000
});

export const login = () => axiosAuthInstance.get(authRoutes.login);

export const logout = (param) => axiosAuthInstance.get(authRoutes.logout, param);

export const token = () => axiosAuthInstance.get(authRoutes.token);

export const commissionRate = () => axiosAuthInstance.get(authRoutes.commissionRate);

export const check_api_key = () => axiosAuthInstance.get(authRoutes.check_api_key);
export const steam_set_api_key = (body) => axiosAuthInstance.post(authRoutes.steam_set_api_key, body);
export const currency_rate = () => currencyRateInstace.get(authRoutes.currency_rate);
export const newsletter = () => axiosAuthInstance.get(authRoutes.newsletter);
export const ischeck_chkout_intry = () => axiosAuthInstance.get(authRoutes.ischeck_chkout_intry);
export const buy_sell_count = (queryParams) => axiosAuthInstance.get(authRoutes.payment_status_inventory, { params: queryParams });

import React, { useEffect, useState } from 'react';

import { Hero, Community, SecureTrading, UserVerification, DiscordServer } from 'components/Home';
import { useApi } from 'hooks/useApi';
import { home_page_template } from 'api';

export const Home = () => {
  document.title = 'Zipit - Home';

  const { data } = useApi({
    dataGetter: home_page_template,
    autoRetrieve: true
  });

  const [sectionData, setSectionData] = useState();

  useEffect(() => {
    setSectionData(data?.item);
  }, [data]);
  const banner_section = sectionData?.find((section) => Number(section.section_name) === 1);

  return (
    <>
      {data ? (
        <>
          <Hero banner_section={banner_section} />
          <SecureTrading data={data} />
          <UserVerification data={data} />
          <DiscordServer data={data} />
          <Community data={data} />
        </>
      ) : (
        <div className="hero-main-div-loading"></div>
      )}
    </>
  );
};

import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { RoundedPrice, itemNameConverter, wearNameConverter } from 'lib';

export const MobileSellTable = ({
  rowCount,
  data,
  onRemoveItem,
  tableContainerRef,
  showSecondPrice,
  currency,
  defaultCurrency,
  selectedRowItem,
  setSelectedRowItem,
  handleInputChange,
  showConvertedPrice,
  addPrice,
  onSavePrice,
  showPrice,
  setSorting,
  sorting,
  onCloseModal,
  convertedAmount,
  priceModal,
  selectedSellItem,
  prices,
  error,
  listedPrice,
  earning,
  PriceError
}) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'item.name',
        header: 'Item',
        size: 150,
        // eslint-disable-next-line
        Cell: ({ row }) => (
          /* eslint-disable */
          <div className="mobile-individual-table">
            <div>
              <div className="goods-card-main-div position-relative">
                <div
                  className="goods-card-img"
                  style={{
                    backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.item?.imageUrl}")`
                  }}
                ></div>
                <div className="wear-name">
                  <p className="font-10 font-500">{wearNameConverter(row?.original?.wear)}</p>
                  <p className="font-10 font-500">
                    {row?.original?.wear !== 'N/A' ? `/ ${Number(row?.original?.float_id).toFixed(4)} ` : ''}
                  </p>
                </div>
                <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                <div
                  className="border"
                  style={{
                    borderBottom: `5px solid ${row?.original?.item?.r_color}`
                  }}
                ></div>
              </div>
              <div className="sell-modal-button mt-20">
                <button
                  onClick={() => addPrice(row.original)}
                  className={PriceError ? (row.original.startBid === '' ? 'empty-price-error' : '') : ''}
                >
                  <p className="d-flex gap-5 sell-price-div">
                    <span className="opacity-eight">
                      {defaultCurrency && defaultCurrency.symbol + ' ' + showPrice(row.original.item_id)}
                    </span>
                    {showSecondPrice && (
                      <>
                        ~
                        <span className="opacity-five">
                          {currency?.symbol}
                          {showConvertedPrice(row.original.item_id)}
                        </span>
                      </>
                    )}
                  </p>
                  <img src="/images/inventory/pencil.svg" alt="edit" height={14} width={14} />
                </button>
              </div>
              {PriceError &&
                (row.original.startBid === '' ? <span style={{ color: 'red' }}>Please specify a price for the item</span> : '')}
            </div>
            <div className="inventory-product-name display-product-name">
              <h3 className="productname">{itemNameConverter(row?.original?.item?.fullname)}</h3>
              <div className="d-flex gap-5 product-desc">
                <p className="productname-desc">{row?.original?.item?.category}</p>*
                <p className="productname-desc">{row?.original?.item?.subCategory}</p>
              </div>
            </div>
            <div className="sell-delete-button">
              <button onClick={() => onRemoveItem(row.original)}>
                <img src="/images/inventory/sell-delete.svg" alt="delete" height={18} width={18} className="img-fluid" />
              </button>
            </div>
          </div>
          /* eslint-enable */
        )
      }
    ],
    [onRemoveItem, showPrice, showConvertedPrice]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    getRowId: (row) => /* eslint-disable */ row?.item_id /* eslint-enable */,
    onRowSelectionChange: setSelectedRowItem,
    // initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onSortingChange: setSorting,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight: 'calc(100vh - 360px)' }
    },
    muiTablePaperProps: {
      sx: {
        borderRadius: '0',
        borderTop: '1px solid rgb(255 255 255 / 20%)',
        borderBottom: '1px solid rgb(255 255 255 / 20%)'
      }
    },

    muiTableBodyProps: {
      sx: {
        '& tr td': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        },
        '& tr:not([data-selected="true"])': {
          backgroundColor: 'transparent'
        }
      }
    },
    mrtTheme: {
      baseBackgroundColor: '#0b051b',
      selectedRowBackgroundColor: '#ffffff1a'
    },

    muiTableHeadProps: {
      sx: {
        '& tr th': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        }
      }
    },

    rowCount,
    state: {
      rowSelection: selectedRowItem,
      sorting
    }
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {priceModal && (
        <>
          <div className="inner-popup">
            <div className="inner-popup-div position-relative">
              <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={onCloseModal}>
                <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
              </button>
              <div className="inner-popup-text">
                <p className="offer-heading">Change price</p>
                <div className="mt-10 mb-10">
                  <p className="font-20">Fixed Price</p>
                  <div className="input-outer-div">
                    <div className="grey-background input-main-div">
                      <label className="input-lable">price</label>
                      <div className="position-relative prefix-div">
                        <p className="prefix">{defaultCurrency && defaultCurrency.symbol}</p>
                        <input
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          name={selectedSellItem?.item_id}
                          placeholder="1.00"
                          onChange={handleInputChange}
                          value={prices[selectedSellItem?.item_id] || ''}
                          className={`${error ? 'error-msg' : ''}`}
                          onKeyDown={(e) => {
                            if (
                              (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                                e.key !== 'Backspace' &&
                                e.key !== 'Delete' &&
                                e.key !== 'ArrowLeft' &&
                                e.key !== 'ArrowRight' &&
                                e.key !== '.') || // Allow dot key
                              (e.key === '.' && prices[selectedSellItem?.item_id].includes('.')) // Ensure only one dot is entered
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                  </div>
                </div>

                {/* {showSecondPrice && (
                  <p className="mb-10">
                    <span className="opacity-white"> Your Approx Earnings.</span>
                    <span className="curreny-symbol"> {currency?.symbol}</span>
                    {convertedAmount}
                  </p>
                )} */}
                <p className="mb-10">
                  <span className="opacity-white"> Average Market Price: {''}</span>
                  <span className="curreny-symbol">
                    {defaultCurrency && defaultCurrency.symbol}
                    {RoundedPrice(selectedSellItem.average_price)}
                  </span>
                </p>
                <p className="mb-10">
                  <span className="opacity-white">Listed price: {''}</span>
                  <span className="curreny-symbol">
                    {defaultCurrency && defaultCurrency.symbol}
                    {prices[selectedSellItem?.item_id] ? prices[selectedSellItem?.item_id] : 0}
                  </span>
                  {/* {listedPrice} */}
                </p>
                <p className="mb-10">
                  <span className="opacity-white">Your earnings: {''}</span>
                  <span className="curreny-symbol">
                    {defaultCurrency && defaultCurrency.symbol}
                    {prices[selectedSellItem?.item_id] ? earning : 0}
                  </span>
                </p>
                <div className="offer-button-div">
                  <button
                    // disabled={bulkSellItemsArray.length <= 1 || !acceptTerms}
                    className="green-button"
                    onClick={onSavePrice}
                  >
                    Confirm
                  </button>
                  <button
                    // disabled={bulkSellItemsArray.length <= 1 || !acceptTerms}
                    className="grey-button"
                    onClick={onCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

MobileSellTable.propTypes = {
  data: PropTypes.array,
  rowCount: PropTypes.number,
  onRemoveItem: PropTypes.func,
  tableContainerRef: PropTypes.any,
  showSecondPrice: PropTypes.bool,
  currency: PropTypes.object,
  defaultCurrency: PropTypes.object,
  setSelectedRowItem: PropTypes.func,
  selectedRowItem: PropTypes.any,
  onSavePrice: PropTypes.func,
  showPrice: PropTypes.func,
  showConvertedPrice: PropTypes.func,
  addPrice: PropTypes.func,
  handleInputChange: PropTypes.func,
  setSorting: PropTypes.func,
  onCloseModal: PropTypes.func,
  sorting: PropTypes.array,
  convertedAmount: PropTypes.any,
  priceModal: PropTypes.any,
  selectedSellItem: PropTypes.any,
  prices: PropTypes.any,
  error: PropTypes.any,
  earning: PropTypes.any,
  listedPrice: PropTypes.any,
  PriceError: PropTypes.any
};

import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

export const SkeletonLoader = ({ width, height, count, className, color, highlightColor, borderRadius, containerClassName }) => {
  return (
    <Skeleton
      width={width}
      height={height}
      count={count}
      baseColor={color}
      highlightColor={highlightColor}
      enableAnimation={true}
      borderRadius={borderRadius}
      className={className}
      containerClassName={containerClassName}
    />
  );
};

SkeletonLoader.propTypes = {
  width: PropTypes.any,
  height: PropTypes.number,
  count: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string,
  highlightColor: PropTypes.string,
  borderRadius: PropTypes.any,
  containerClassName: PropTypes.string
};

import React, { useEffect, useState } from 'react';
import { ProductSkin } from 'components/Skin';
import { useNavigate, useParams } from 'react-router-dom';
import {
  axiosAuthInstance,
  check_api_key,
  get_similar_inventory,
  remove_sell_inventory,
  single_market_inventory,
  steam_set_api_key,
  user_bid_add,
  user_offer_add,
  buy_inventory,
  add_following,
  offer_view_seller,
  seller_offer_accept,
  offer_decline,
  offer_disable,
  single_offer_decline,
  steam_pricehistory,
  remove_user_offer,
  buyer_waiting_trade
} from 'api';
import { useApi } from 'hooks/useApi';
import {
  Checkout,
  ConfirmBid,
  EditListing,
  Graph,
  LoginWithSteamModal,
  Offering,
  PaymentModal,
  SellerOfferListModal,
  SimilarProduct,
  // SkeletonLoader,
  SteamApiKeyModal,
  UserBanModal,
  UserOfferRemoveModal
} from 'components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

// const client = new w3cwebsocket('ws://localhost:8080');

export const Skin = () => {
  document.title = 'Zipit - Market Skin';
  const { market_id } = useParams();
  const navigate = useNavigate();

  const userId = useSelector((state) => state.user.userData.user_id);

  const userBan = useSelector((state) => state.user.userBlockDetails);
  const [isUserBanModalOpen, setIsUserBanModalOpen] = useState(false);

  const [loginWithSteam, setLoginWithSteam] = useState(false);

  const [item, setItem] = useState();
  const [editListingModal, setEditListingModal] = useState(false);

  const [acceptWarning, setAcceptWarning] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [confirmBuyModal, setConfirmBuyModal] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [similarProduct, setSimilarProduct] = useState([]);
  const [checkOutItemData, setCheckOutItemData] = useState();
  const [bidItemData, setBidItemData] = useState();
  const [bidPrice, setBidPrice] = useState('');
  const [isLoadingBid, setIsLoadingBid] = useState(false);
  const [offerAcceptCountDown, setOfferAcceptCountDown] = useState(null);

  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [checkBidStatus, setCheckBidStatus] = useState(false);
  const [checkOfferStatus, setCheckOfferStatus] = useState(false);
  const [successMes, setSuccessMes] = useState({});
  const [bidArray, setBidArray] = useState([]);
  const [confirmBidModal, setConfirmBidModal] = useState(false);
  const [offerAmount, setOfferAmount] = useState('');
  const [isOfferLoading, setIsOfferLoading] = useState(false);
  const [offerCountDown, setOfferCountDown] = useState();
  const [offerRemoveModal, setOfferRemoveModal] = useState(false);

  const [loadingIndex, setLoadingIndex] = useState(null);
  const [follow, setFollow] = useState([]);
  const [followingCount, setFollowingCount] = useState(0);
  const [tradeCount, setTradeCount] = useState(0);
  const [userOfferCount, setUserOfferCount] = useState(0);

  const [sellerOfferModal, setSellerOfferModal] = useState(false);
  const [sellerOfferList, setSellerOfferList] = useState([]);
  const [isSellerOfferAcceptModalOpen, setIsSellerOfferAcceptModalOpen] = useState(false);

  const [status, setStatus] = useState(true);

  const [priceHistory, setPriceHistory] = useState([]);
  const [graphDays, setGraphDays] = useState({ labels: 'Last 2 weeks', days: '15' });
  const [showOptions, setShowOptions] = useState(false);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [isSingleDeclineOfferModal, setIsSingleDeclineOfferModal] = useState(false);
  //steam api key setter and getter hooks
  const [steamAPiKeyModal, setSteamApiKeyModal] = useState(false);
  const [apiKey, setApiKey] = useState({ api_key: '', tread_url: '' });
  const [errors, setErrors] = useState({});
  const [apiKeyErrorMessage, setApiKeyErrorMessage] = useState();

  /*************** currency get code  **************** */
  const tf2Currencies = JSON.parse(localStorage.getItem('tf2-currencies'));
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const showSecondPrice = tf2Currencies && tf2Currencies.code !== defaultCurrency.code;
  const currencies = showSecondPrice ? tf2Currencies?.code : defaultCurrency?.code;

  /*****************  get bearer token code and add axios header *****************/
  const Bearer_Token = Cookies.get('Bearer_Token');

  useEffect(() => {
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${Bearer_Token}`;
  }, [Bearer_Token]);

  /******************************* Api Calls ************************************/
  const { apiCaller: onApiKeyCheck } = useApi({
    dataGetter: check_api_key
  });

  const { apiCaller: onSteamSetApiKey } = useApi({
    dataGetter: steam_set_api_key
  });

  const { apiCaller: onCheckOutItem } = useApi({
    dataGetter: buy_inventory
  });

  const { apiCaller: single_skin } = useApi({
    dataGetter: single_market_inventory
  });

  const { apiCaller: onGetSimilarInventory } = useApi({
    dataGetter: get_similar_inventory
  });

  const { apiCaller: onBidAddByUser } = useApi({
    dataGetter: user_bid_add
  });

  const { apiCaller: onSellItemDelete } = useApi({
    dataGetter: remove_sell_inventory
  });

  const { apiCaller: onOfferByUser } = useApi({
    dataGetter: user_offer_add
  });

  const { apiCaller: onFollowItem } = useApi({
    dataGetter: add_following
  });

  const { apiCaller: onOfferViewSeller } = useApi({
    dataGetter: offer_view_seller
  });

  const { apiCaller: sellerOfferAccept } = useApi({
    dataGetter: seller_offer_accept
  });

  const { apiCaller: onOfferDecline } = useApi({
    dataGetter: offer_decline
  });

  const { apiCaller: onOfferDisable } = useApi({
    dataGetter: offer_disable
  });

  const { apiCaller: singleOfferDecline } = useApi({
    dataGetter: single_offer_decline
  });

  const { apiCaller: steamPriceHistory } = useApi({
    dataGetter: steam_pricehistory
  });

  const { apiCaller: userRemoveOffer } = useApi({
    dataGetter: remove_user_offer
  });

  const { apiCaller: onBuyerWaitingTrade } = useApi({
    dataGetter: buyer_waiting_trade
  });

  /******************************* get item data or similar product data ************************************/
  const fetchData = async (market_id) => {
    try {
      setIsLoadingProduct(true);
      const response = await single_skin({ market_id });
      if (response.data.status === 'success') {
        if (response.data.data) {
          setItem(response.data.data);
          setFollowingCount(Number(response.data.following_count));
          setTradeCount(Number(response?.data.trade_count));
          setBidArray(response.data.totalbid);
          setOfferAcceptCountDown(response.data.offer_accept_count_down);
          if (response.data.data.user_offer_count !== undefined) {
            setUserOfferCount(Number(response.data.data.user_offer_count));
          }

          /* similar inventory api call */
          const similerProductRes = await onGetSimilarInventory({
            category: response.data.data.category,
            market_id
          });
          setSimilarProduct(similerProductRes.data.item);
          const similerProductArray = similerProductRes.data.item;
          /* similar inventory api call end */

          const followItems = similerProductArray.map((product) => ({
            market_id: Number(product.m_id),
            status: product.following_status
          }));
          setFollow([{ market_id: Number(market_id), status: response.data.following_status }, ...followItems]);

          /* price graph api call */
          const priceHistory = await steamPriceHistory({
            hasbyname: response.data.data.item.fullname,
            days: graphDays.days,
            currency: currencies
          });
          setPriceHistory(priceHistory.data.data);
          /* price graph api call end */

          setOfferCountDown(response.data.data.user_offer_data);

          setStatus(true);
        } else {
          setStatus(false);
        }

        setTimeout(() => {
          setIsLoadingProduct(false);
        }, 1000);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(market_id);
  }, [market_id]);

  /******************************* bid function ************************************/

  useEffect(() => {
    setBidPrice(Number(item?.nextbid).toFixed(2));
    setOfferAmount(Number(item?.bargainPrice).toFixed(2));
  }, [item]);

  const onBidChange = (e) => {
    const newBidPrice = e.target.value;
    setBidPrice(newBidPrice);
    handleApplyBargainPrice(newBidPrice);
  };

  const handleApplyBargainPrice = (price) => {
    const decimalRegex = /^\d{0,7}(\.\d{0,2})?$/;
    if (parseFloat(price) < parseFloat(item.nextbid)) {
      setErrors({ bidPrice: 'Must be equal to or greater than the start price.' });
    } else if (price.startsWith('0')) {
      setErrors({ bidPrice: 'Must be equal to or greater than the start price.' });
    } else if (!decimalRegex.test(price)) {
      setErrors({ bidPrice: 'Price can have a maximum of 7 figures and 2 decimal places' });
    } else {
      setErrors({});
    }
  };

  const onBidAdd = (itemData, indexNum) => {
    setBidItemData(itemData);
    setLoadingIndex(indexNum);
    if (!Bearer_Token) {
      document.body.classList.add('modal-open');
      setLoginWithSteam(true);
    } else {
      if (userBan.status === 'block') {
        setIsUserBanModalOpen(true);
      } else {
        setIsLoadingBid(true);
        onApiKeyCheck().then((res) => {
          setCheckBidStatus(true);
          if (res.data.api_status === 1) {
            setTimeout(() => {
              setIsLoadingBid(false);
              document.body.classList.add('modal-open');
              setConfirmBidModal(true);
            }, 1000);
          } else {
            if (res.data.api_status === 0) {
              setApiKeyErrorMessage(res.data);
              setTimeout(() => {
                setIsLoadingBid(false);
                document.body.classList.add('modal-open');
                setSteamApiKeyModal(true);
              }, 1000);
            }
          }
        });
      }
    }
  };

  /************ on check out but to check api key valide or not and user login or not  ************/
  const onCheckoutModal = (itemData, indexNum) => {
    setCheckOutItemData(itemData);
    setLoadingIndex(indexNum);

    if (!Bearer_Token) {
      document.body.classList.add('modal-open');
      setLoginWithSteam(true);
    } else {
      try {
        if (userBan.status === 'block') {
          setIsUserBanModalOpen(true);
        } else {
          setIsLoading(true);
          onApiKeyCheck().then((res) => {
            if (res.data.api_status === 1) {
              setTimeout(() => {
                setIsLoading(false);
                document.body.classList.add('modal-open');
                setConfirmBuyModal(true);
              }, 1000);
            } else {
              if (res.data.api_status === 0) {
                setApiKeyErrorMessage(res.data);
                setTimeout(() => {
                  setIsLoading(false);
                  document.body.classList.add('modal-open');
                  setSteamApiKeyModal(true);
                }, 1000);
              }
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  /***************** handle submit for check api key *****************/
  const validateValues = (apiKey) => {
    let errors = {};
    if ((apiKeyErrorMessage.status === 'error' || apiKeyErrorMessage.status === 'api-error') && apiKey.api_key === '') {
      errors.api_key = 'This field is required';
    }
    if ((apiKeyErrorMessage.status === 'error' || apiKeyErrorMessage.status === 'trade-error') && apiKey.tread_url === '') {
      errors.tread_url = 'This field is required';
    }
    return errors;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateValues(apiKey);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        setErrors({});
        const param = {};
        if (apiKey.api_key) {
          param.api_key = apiKey.api_key;
        }
        if (apiKey.tread_url) {
          param.tread_url = apiKey.tread_url;
        }
        const res = await onSteamSetApiKey(param);
        if (res.data.status === 'failed') {
          setErrors({ api_key: res.data.message });
        } else {
          setSteamApiKeyModal(false);
          if (checkBidStatus) {
            setConfirmBidModal(true);
          } else {
            if (checkOfferStatus) {
              setOfferModal(true);
            } else {
              setConfirmBuyModal(true);
            }
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  /************ handle submit for confirm bid   ************/
  const ConfirmBidHandle = (marketId, bid) => {
    const params = {
      market_id: marketId,
      bidPrice: Number(bid).toFixed(2)
    };
    onBidAddByUser(params).then((res) => {
      if (res.data.status === 'success') {
        document.body.classList.remove('modal-open');
        setConfirmBidModal(false);
        // fetchData(market_id);
        // setBidArray((prevBidArray) => [...prevBidArray, res.data.item[0]]);
        setBidArray((prevBidArray) => [res.data.item[0], ...prevBidArray]);
        setSuccessMes({ message: res.data.massage, type: res.data.status });
        setBidPrice(Number(res.data.nexbid).toFixed(2));
      }
    });
  };

  /************ handle submit for confirm buy   ************/

  const onConfirmBuy = (marketId) => {
    onCheckOutItem({ market_id: marketId }).then((res) => {
      if (res.data.status === 'success') {
        setConfirmBuyModal(false);
        document.body.classList.remove('modal-open');
        // setShowPaymentModal(true);
        onBuyerWaitingTrade({ buy_id: res.data.data.buy_id, market_id: marketId }).then((res) => {
          if (res.data.status === 'success') {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        });
      }
    });
  };

  /***************** this functions offer modal open and close  *****************/
  const onOfferModal = () => {
    try {
      if (userBan.status === 'block') {
        setIsUserBanModalOpen(true);
      } else {
        setIsOfferLoading(true);
        onApiKeyCheck().then((res) => {
          if (res.data.api_status === 1) {
            setTimeout(() => {
              setIsOfferLoading(false);
              document.body.classList.add('modal-open');
              setOfferModal(true);
            }, 1000);
          } else {
            if (res.data.api_status === 0) {
              setApiKeyErrorMessage(res.data);
              setTimeout(() => {
                setIsOfferLoading(false);
                setCheckOfferStatus(true);
                document.body.classList.add('modal-open');
                setSteamApiKeyModal(true);
              }, 1000);
            }
          }
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /************ handle submit for offer    ************/

  const handleSubmitOffer = () => {
    const params = {
      market_id,
      offer: offerAmount
    };
    onOfferByUser(params).then((res) => {
      if (res.data.status === 'success') {
        setOfferCountDown(res.data.item.data);
        setUserOfferCount(Number(res.data.item.count));
        document.body.classList.remove('modal-open');
        setOfferModal(false);
        setSuccessMes({ message: res.data.massage, type: res.data.status });
      }
    });
  };

  const offerRemoveByUserModalHandler = () => {
    document.body.classList.add('modal-open');
    setOfferRemoveModal(true);
  };

  const offerRemoveByUserHandler = (data) => {
    userRemoveOffer({ offer_id: data.id, market_id: data.m_id })
      .then((res) => {
        if (res.data?.status === 'success') {
          document.body.classList.remove('modal-open');

          toast.success(res.data.msg);
          setOfferCountDown(null);
          closeModal();
        } else {
          toast.error(res.data.massage);
        }
      })
      .catch((err) => {
        console.error(`${err.message}`);
      });
  };

  const closeModal = async () => {
    document.body.classList.remove('modal-open');
    setConfirmBuyModal(false);
    setOfferModal(false);
    setEditListingModal(false);
    setConfirmBidModal(false);
    setSellerOfferModal(false);
    setShowPaymentModal(false);
    setOfferRemoveModal(false);
  };

  const deleteModalClose = () => {
    setDeleteModal(false);
  };

  const onCloseLoginModal = () => {
    document.body.classList.remove('modal-open');
    setLoginWithSteam(false);
  };

  const onCloseAPiKeyModal = () => {
    document.body.classList.remove('modal-open');
    setSteamApiKeyModal(false);
  };

  const onEditListing = () => {
    document.body.classList.add('modal-open');
    setEditListingModal(true);
  };

  /***************** delete listing function *****************/

  const onDelete = (market_id) => {
    onSellItemDelete({ market_id }).then((res) => {
      if (res.data.status === 'success') {
        document.body.classList.remove('modal-open');
        setDeleteModal(false);
        setEditListingModal(false);
        navigate('/inventory');
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccessMes({});
    }, 30000);
  }, [successMes]);

  const followHandle = (marketId) => {
    if (!Bearer_Token) {
      document.body.classList.add('modal-open');
      setLoginWithSteam(true);
    } else {
      onFollowItem({ market_id: marketId }).then((res) => {
        if (res.data.status === 'success') {
          setFollowingCount(followingCount + 1);
          if (res.data.follow_status === 'delete') {
            setFollowingCount(followingCount - 1);
            const updatedFollow = follow.map((f) => {
              if (Number(f.market_id) === Number(marketId)) {
                return { ...f, status: false };
              }
              return f;
            });
            setFollow(updatedFollow);
          } else {
            const updatedFollow = follow.map((f) => {
              if (Number(f.market_id) === Number(marketId)) {
                return { ...f, status: true };
              }
              return f;
            });
            setFollow(updatedFollow);
          }
        }
      });
    }
  };

  /***************** seller offer show Modal *****************/

  const onSellerOfferModal = () => {
    document.body.classList.add('modal-open');

    onOfferViewSeller({ market_id })
      .then((response) => {
        setSellerOfferList(response.data); // Uncomment the following line if you want to set the seller offer modal to true after receiving the response
        setSellerOfferModal(true);
      })
      .catch((error) => {
        console.error(error.message);

        // Handle any errors here
      });
  };

  const onAcceptOffer = (itemData) => {
    const params = {
      offer_id: itemData.id,
      m_id: itemData.m_id
    };
    sellerOfferAccept(params).then((res) => {
      if (res.data.status === 'Success') {
        toast.success(res.data.massage);
        closeModal();
        navigate(`/view/private/${res.data.data.market_hash_id}`);
        fetchData(market_id);
      }
    });
  };

  const OfferDecline = (m_id) => {
    onOfferDecline({ m_id })
      .then((res) => {
        if (res.data?.status === 'Success') {
          toast.success(res.data.massage);
          fetchData(market_id);
          closeModal();
        } else {
          toast.error(res.data.massage);
        }
      })
      .catch((err) => {
        console.error(`${err.message}`);
      });
  };

  const OfferDisable = (m_id) => {
    onOfferDisable({ m_id })
      .then((res) => {
        if (res.data?.status === 'Success') {
          toast.success(res.data.massage);
          OfferDecline(m_id);
          fetchData(market_id);
          closeModal();
        } else {
          toast.error(res.data.massage);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleSingleOfferDecline = (offer_id, m_id) => {
    singleOfferDecline({ offer_id, m_id })
      .then((res) => {
        if (res.data?.status === 'Success') {
          toast.success(res.data.massage);
          fetchData(market_id);
          closeModal();
        } else {
          toast.error(res.data.massage);
        }
      })
      .catch((err) => {
        console.error(`${err.message}`);
      });
  };

  const handleGraphChangeOnDays = async (daysObject) => {
    setGraphDays(daysObject);
    setShowOptions(false);
    const priceHistory = await steamPriceHistory({
      hasbyname: item.item.fullname,
      day: daysObject.days,
      currency: currencies
    });
    setPriceHistory(priceHistory.data.data);
  };

  const userBanModalClose = () => {
    document.body.classList.remove('modal-open');
    setIsUserBanModalOpen(false);
  };

  return (
    <div>
      {status ? (
        <>
          {isUserBanModalOpen && (
            <UserBanModal setIsUserBanModalOpen={setIsUserBanModalOpen} userBan={userBan} userBanModalClose={userBanModalClose} />
          )}
          {loginWithSteam && <LoginWithSteamModal onClose={onCloseLoginModal} />}
          {offerRemoveModal && (
            <UserOfferRemoveModal
              offerCountDownData={offerCountDown}
              offerRemoveByUserHandler={offerRemoveByUserHandler}
              closeModal={closeModal}
            />
          )}
          {steamAPiKeyModal && (
            <SteamApiKeyModal
              onSubmit={onSubmit}
              setApiKey={setApiKey}
              apiKey={apiKey}
              closeModal={onCloseAPiKeyModal}
              errors={errors}
              apiKeyErrorMessage={apiKeyErrorMessage}
            />
          )}
          <ProductSkin
            isLoading={isLoading}
            onCheckoutModal={onCheckoutModal}
            onOfferModal={onOfferModal}
            item={item}
            Bearer_Token={Bearer_Token}
            defaultCurrency={defaultCurrency}
            currency={tf2Currencies}
            showSecondPrice={showSecondPrice}
            bidPrice={bidPrice}
            onBidAdd={onBidAdd}
            onBidChange={onBidChange}
            errors={errors}
            isLoadingBid={isLoadingBid}
            onEditListing={onEditListing}
            isLoadingProduct={isLoadingProduct}
            successMes={successMes}
            bidArray={bidArray}
            followHandle={followHandle}
            follow={follow}
            followingCount={followingCount}
            tradeCount={tradeCount}
            onSellerOfferModal={onSellerOfferModal}
            isOfferLoading={isOfferLoading}
            offerAcceptCountDown={offerAcceptCountDown}
            sellerOfferList={sellerOfferList}
            userOfferCount={userOfferCount}
            offerCountDownData={offerCountDown}
            offerRemoveByUserModalHandler={offerRemoveByUserModalHandler}
          />
          {confirmBidModal && (
            <ConfirmBid
              ConfirmBidHandle={ConfirmBidHandle}
              closeModal={closeModal}
              setAcceptTerms={setAcceptTerms}
              acceptTerms={acceptTerms}
              showSecondPrice={showSecondPrice}
              tf2Currencies={tf2Currencies}
              defaultCurrency={defaultCurrency}
              bidItemData={bidItemData}
              setBidItemData={setBidItemData}
              bidPrice={bidPrice}
            />
          )}
          {confirmBuyModal && (
            <Checkout
              closeModal={closeModal}
              setAcceptTerms={setAcceptTerms}
              acceptTerms={acceptTerms}
              item={item}
              checkOutItemData={checkOutItemData}
              showSecondPrice={showSecondPrice}
              tf2Currencies={tf2Currencies}
              defaultCurrency={defaultCurrency}
              acceptWarning={acceptWarning}
              setAcceptWarning={setAcceptWarning}
              onConfirmBuy={onConfirmBuy}
            />
          )}
          {editListingModal && (
            <EditListing
              closeModal={closeModal}
              deleteModalClose={deleteModalClose}
              item={item}
              setDeleteModal={setDeleteModal}
              deleteModal={deleteModal}
              onDelete={onDelete}
              setEditListingModal={setEditListingModal}
              fetchData={fetchData}
              errors={errors}
              id={market_id}
            />
          )}
          {offerModal && (
            <Offering
              closeModal={closeModal}
              setAcceptTerms={setAcceptTerms}
              acceptTerms={acceptTerms}
              setOfferAmount={setOfferAmount}
              offerAmount={offerAmount}
              showSecondPrice={showSecondPrice}
              tf2Currencies={tf2Currencies}
              defaultCurrency={defaultCurrency}
              handleSubmitOffer={handleSubmitOffer}
              bargainPrice={item?.bargainPrice}
              item={item}
              userOfferCount={userOfferCount}
            />
          )}
          {sellerOfferModal && (
            <SellerOfferListModal
              closeModal={closeModal}
              sellerOfferList={sellerOfferList}
              onAcceptOffer={onAcceptOffer}
              OfferDisable={OfferDisable}
              OfferDecline={OfferDecline}
              handleSingleOfferDecline={handleSingleOfferDecline}
              isSellerOfferAcceptModalOpen={isSellerOfferAcceptModalOpen}
              setIsSellerOfferAcceptModalOpen={setIsSellerOfferAcceptModalOpen}
              isSingleDeclineOfferModal={isSingleDeclineOfferModal}
              setIsSingleDeclineOfferModal={setIsSingleDeclineOfferModal}
            />
          )}
          {showPaymentModal && <PaymentModal closeModal={closeModal} />}
          <Graph
            isLoadingProduct={isLoadingProduct}
            priceHistory={priceHistory}
            graphDays={graphDays}
            setShowOptions={setShowOptions}
            showOptions={showOptions}
            handleGraphChangeOnDays={handleGraphChangeOnDays}
            tf2Currencies={tf2Currencies}
            defaultCurrency={defaultCurrency}
            showSecondPrice={showSecondPrice}
          />
          <SimilarProduct
            similarProduct={similarProduct}
            defaultCurrency={defaultCurrency}
            onCheckoutModal={onCheckoutModal}
            isLoading={isLoading}
            onBidAdd={onBidAdd}
            loadingIndex={loadingIndex}
            isLoadingBid={isLoadingBid}
            followHandle={followHandle}
            follow={follow}
            successMes={successMes}
            isLoadingProduct={isLoadingProduct}
          />
        </>
      ) : (
        <>
          <div className="common-page-inner-div">
            <p>No have A Data</p>
          </div>
        </>
      )}
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterPrice: [],
  filterFade: []
};

const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setSideBarFilterPrice(state, action) {
      state.filterPrice = action.payload;
    },
    setSideBarFilterFade(state, action) {
      state.filterFade = action.payload;
    }
  }
});

export const { setSideBarFilterPrice, setSideBarFilterFade } = marketSlice.actions;
export default marketSlice.reducer;

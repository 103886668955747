import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const BulkSellModal = ({
  setListingTypeModal,
  runTime,
  setRunTime,
  sellType,
  setSellType,
  auctionType,
  setAuctionType,
  onSellTypeSave,
  selectedRowItem
}) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(1);

  const handleAuctionTypeChange = (name) => {
    setAuctionType(name);
    if (name === 'lighting') {
      setRunTime(60);
    }
  };

  const handleSellTypeChange = (name) => {
    setSellType(name);
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setRunTime(day);
  };

  const formatDate = (date) => {
    const options = { weekday: 'long', day: 'numeric', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const [weekday, month, day] = formattedDate.split(' ');
    return `${weekday} ${day} ${month}`;
  };

  const renderDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + selectedDay);
    return formatDate(currentDate);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTime = new Date();
      newTime.setHours(newTime.getHours() + 1);
      setCurrentTime(newTime);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const selectedRows = Object.values(selectedRowItem);

  const totalRow = selectedRows.length;
  return (
    <div>
      <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal">
        <div className="sell-modal-area">
          <div className="offer-popup position-relative">
            <button
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              className="modal-close"
              onClick={() => setListingTypeModal(false)}
            >
              <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
            </button>
            <div className="">
              <div className="work-heading-div">
                <p className="offer-heading">Sell</p>
                <div className="tab-header-div mt-30">
                  <div
                    className={`tab-heading-div ${sellType === 'auction' ? 'is-ative active' : ''}`}
                    onClick={() => handleSellTypeChange('auction')}
                  >
                    <p className="font-20 font-700 pb-0">List as Auction</p>
                  </div>

                  <div
                    className={`tab-heading-div ${sellType === 'market' ? 'is-ative active' : ''}`}
                    onClick={() => handleSellTypeChange('market')}
                  >
                    <p className="font-20 font-700 pb-0"> Fixed Price</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sell-item-info mt-30">
              <div className="sell-description">
                {sellType === 'auction' ? (
                  <>
                    <div className="sell-modal-right-div">
                      <div className="auction-type">
                        <p>Auction Type</p>
                        <div className="auction-type-main mx-10">
                          <div className="tab-header-div">
                            <div
                              className={`tab-heading-div ${auctionType === 'regular' ? 'is-ative active' : ''}`}
                              onClick={() => handleAuctionTypeChange('regular')}
                            >
                              <span className="text-uppercase">Regular </span>
                              <span className="font-12">Up to 8 days</span>
                            </div>
                            <div
                              className={`tab-heading-div ${auctionType === 'lighting' ? 'is-ative active' : ''}`}
                              onClick={() => handleAuctionTypeChange('lighting')}
                            >
                              <span> lighting </span>
                              <span className="font-12">60 mins</span>
                            </div>
                          </div>
                        </div>
                        <p className="opacity-white">Last minute bids will extend the auction to 60 seconds</p>
                      </div>

                      {auctionType === 'regular' && (
                        <div className="auction-regulare mt-30">
                          <div className="auction-time ">
                            <p>Time</p>
                            <div className="auction-type-main mx-10 ">
                              {[1, 2, 3, 4, 5, 6, 7, 8].map((day) => (
                                <div
                                  key={day}
                                  className={`grey-background auction-time-box ${selectedDay === day ? 'selected' : ''}`}
                                  onClick={() => handleDayClick(day)}
                                >
                                  {day} day
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="auction-listing mt-30">
                            <p className="opacity-white">Listing will end on</p>
                            <div className="d-flex gap-10 mx-10">
                              <img src="/images/sell-modal/clock.svg" alt="zipit" className="img-fluid" />
                              {selectedDay !== null && <p>{renderDate()}</p>}
                            </div>
                          </div>
                        </div>
                      )}

                      {auctionType === 'lighting' && (
                        <div className="auction-light mt-30">
                          <div className="auction-type">
                            <p>Time</p>
                            <div className="auction-type-main mx-10 ">
                              <div className="grey-background auction-time-box">60 minutes</div>
                            </div>

                            <div className="auction-listing mt-30">
                              <p className="opacity-white">Listing will end on</p>
                              <div className="d-flex gap-10 mx-10">
                                <img src="/images/sell-modal/time.svg" alt="zipit" className="img-fluid" />
                                {new Date(currentTime.getTime()).toLocaleTimeString([], {
                                  hour: 'numeric',
                                  minute: '2-digit',
                                  hour12: true
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="auction-number-div">
                      <div className="auction-number">
                        <p className="font-12 font-700">{totalRow}</p>
                        <p className="font-12 font-700">items will be change</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="auction-number-div">
                      <div className="auction-number">
                        <p className="font-12 font-700">{totalRow}</p>
                        <p className="font-12 font-700">items will be change</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="offer-button-div">
              <button className="green-button w-100" onClick={onSellTypeSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

BulkSellModal.propTypes = {
  setListingTypeModal: PropTypes.func,
  runTime: PropTypes.number,
  setRunTime: PropTypes.func,
  sellType: PropTypes.string,
  setSellType: PropTypes.func,
  auctionType: PropTypes.string,
  setAuctionType: PropTypes.func,
  onSellTypeSave: PropTypes.func,
  selectedRowItem: PropTypes.object
};

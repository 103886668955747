import { BuyRoutes } from './apiRoutes';
import { axiosAuthInstance } from './auth';

export const buy_inventory = (body) => axiosAuthInstance.post(BuyRoutes.buy_inventory, body);

export const get_buy_inventory = (queryParams) => axiosAuthInstance.get(BuyRoutes.get_buy_inventory, { params: queryParams });

export const user_bid_pending_view = (queryParams) => axiosAuthInstance.get(BuyRoutes.user_bid_pending_view, { params: queryParams });
export const user_bid_cancle_view = (queryParams) => axiosAuthInstance.get(BuyRoutes.user_bid_cancle_view, { params: queryParams });
export const user_make_offer_view = (queryParams) => axiosAuthInstance.get(BuyRoutes.user_make_offer_view, { params: queryParams });

export const buyer_waiting_trade = (body) => axiosAuthInstance.post(BuyRoutes.buyer_waiting_trade, body);

export const buyer_waiting_trade_list = (queryParams) => axiosAuthInstance.get(BuyRoutes.buyer_waiting_trade_list, { params: queryParams });
export const buyer_accept_trade_list = (queryParams) => axiosAuthInstance.get(BuyRoutes.buyer_accept_trade_list, { params: queryParams });
export const buyer_purchase_history = (queryParams) => axiosAuthInstance.get(BuyRoutes.buyer_purchase_history, { params: queryParams });
export const buyer_lost_auction = (queryParams) => axiosAuthInstance.get(BuyRoutes.buyer_lost_auction, { params: queryParams });

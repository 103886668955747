import React from 'react';
import PropTypes from 'prop-types';
import { useApi } from 'hooks/useApi';
import { buy_sell_count } from 'api';

export const UserUnBanPopup = ({ setIsUserUnBanModalOpen }) => {
  const { apiCaller: BuySellAllFilterCount } = useApi({
    dataGetter: buy_sell_count
  });

  const onCloseModal = async () => {
    const res = await BuySellAllFilterCount({ status: 0 });
    if (res.data) {
      setIsUserUnBanModalOpen(false);
    }
  };
  return (
    <div>
      <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal" onClick={() => onCloseModal()}>
        <div className="sell-modal-area offer-modal">
          <div className="position-relative steam-account-popup">
            <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close">
              <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
            </button>

            <p className="pb-20 mb-20 font-700 offer-heading d-flex align-item-center gap-5">
              <img src="/images/common/danger.svg" alt="modal-close" className="img-fluid" />
              Ban Lifted
            </p>
            <div className="secure-text border-top pt-20 banned-popup-text">
              <p>
                We are writing to inform you that the ban on your account has been successfully lifted. You now have full access to
                platform.We appreciate your patience during this process and thank you for your understanding.
              </p>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

UserUnBanPopup.propTypes = {
  setIsUserUnBanModalOpen: PropTypes.func
};

import React, { useCallback, useEffect, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import PropTypes from 'prop-types';
import { convertCurrency, itemNameConverter, wearNameConverter } from 'lib';
import { Nothing } from '../Nothing';
import '../style.css';
import { useApi } from 'hooks/useApi';
import { axiosAuthInstance, buyer_waiting_trade, get_buy_inventory } from 'api';
import moment from 'moment';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const BuyProduct = ({
  defaultCurrency,
  selectedCategory,
  setSelectedCategory,
  data,
  isLoading,
  rowCount,
  setRowCount,
  setCurrentPage,
  currentPage,
  setIsLoading,
  setData,
  setVisible,
  visible,
  hasMore,
  setHasMore,
  location,
  navigate,
  toggleMenu,
  isMobile,
  handleFilterCount,
  setPagination,
  pagination
}) => {
  useEffect(() => {
    const Bearer_Token = Cookies.get('Bearer_Token');
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${Bearer_Token}`;
  }, []);

  const { apiCaller: userMakePayment } = useApi({
    dataGetter: get_buy_inventory
  });
  const { apiCaller: onBuyerWaitingTrade } = useApi({
    dataGetter: buyer_waiting_trade
  });

  const fetchData = useCallback(
    async (page) => {
      try {
        setIsLoading(true);
        const queryParams = { page };
        const res = await userMakePayment(queryParams);
        if (res.data.item.last_page > page) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        const newData = res.data.item.data.filter((item, index, self) => index === self.findIndex((i) => i.m_id === item.m_id));
        setData((prev) => {
          const updatedData = page === 1 ? newData : [...prev, ...newData];
          const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));
          const newVisible = uniqueArray.length;
          setVisible(newVisible);
          return uniqueArray;
        });
        const count = res.data.item.total;
        setPagination({ from: res.data.item.from, to: res.data.item.to });
        setRowCount(Number(count));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setTimeout(() => {
        setIsLoading(false);
        // }, 2000);
      }
    },
    [userMakePayment, setIsLoading, setHasMore, setData, setRowCount, setVisible]
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, location.search, currentPage, selectedCategory]);

  const fetchMoreOnBottomReached = () => {
    if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
      if (visible < rowCount) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  useEffect(() => {
    visible < rowCount && rowCount > 0
      ? window.addEventListener('scroll', fetchMoreOnBottomReached)
      : window.removeEventListener('scroll', fetchMoreOnBottomReached);
    window.addEventListener('scroll', fetchMoreOnBottomReached);
    return () => {
      window.removeEventListener('scroll', fetchMoreOnBottomReached);
    };
  }, [visible]);

  const onPaymentHandler = (item) => {
    onBuyerWaitingTrade({ buy_id: item.buy_id, market_id: item.m_id }).then((res) => {
      if (res.data.status === 'success') {
        toast.success('Payment Completed Successfully');
        const updated = data.filter((f) => Number(f.m_id) !== Number(item.m_id));
        setData(updated);
        setVisible(updated.length);
        setRowCount(Number(updated.length));
        handleFilterCount();
      } else {
        toast.error(res.data.massage);
      }
    });
  };

  const columns = useMemo(() => {
    const columnsArray = [
      {
        accessorKey: 'item.name',
        header: <div className={`${isMobile ? 'd-none' : ''}`}>Item</div>,
        size: 150,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          /* eslint-disable */
          const stickers = row.original.item?.stickers;
          /* eslint-enable */
          return (
            /* eslint-disable */
            <>
              {!isMobile ? (
                <div id={`${row?.original?.item_id}`} className="d-flex gap-15 align-items-center">
                  <div className="goods-card-main-div position-relative">
                    <div
                      className="goods-card-img"
                      style={{
                        backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.item?.imageUrl}")`
                      }}
                    ></div>
                    <div className="wear-name text-white">
                      <p className="font-10 font-500">{row?.original?.wear && wearNameConverter(row?.original?.wear)}</p>
                      <p className="font-10 font-500">
                        {row?.original?.wear !== 'N/A' ? `/ ${Number(row?.original?.float_id).toFixed(4)} ` : ''}
                      </p>
                    </div>
                    <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                    <div
                      className="border"
                      style={{
                        borderBottom: `5px solid ${row.original?.item?.r_color && row.original?.item?.r_color}`
                      }}
                    ></div>
                  </div>
                  <div className="display-product-name">
                    <div className="inventory-product-name">
                      <h3 className="productname">{row?.original?.item?.fullname && itemNameConverter(row?.original?.item?.fullname)}</h3>
                      <div className="d-flex gap-5 text-white">
                        <p className="productname-desc">{row?.original?.item?.category}</p>*
                        <p className="productname-desc">{row?.original?.item?.subCategory}</p>
                      </div>
                    </div>
                    {stickers && stickers.length > 0 && (
                      <div className="previous-sales-sticker product-sticker mt-10">
                        {stickers.map((item, index) => {
                          return (
                            <div
                              className="product-display-sticker"
                              key={index}
                              // dangerouslySetInnerHTML={{ __html: item.value }}
                            >
                              {/* <img src={item.value} alt="sticker" title={item.key} /> */}
                              <div className="single-sticker-div">
                                <img src={item.value} alt="sticker" />
                                {item.key && (
                                  <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                    <span>{item.key}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div id={`${row?.original?.item_id}`} className="responsive-buy-div make-payment-table">
                  <div className="goods-card-main-div position-relative">
                    <div
                      className="goods-card-img"
                      style={{
                        backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.item?.imageUrl}")`
                      }}
                    ></div>
                    <div className="wear-name text-white">
                      <p className="font-12">{row?.original?.wear && wearNameConverter(row?.original?.wear)}</p>
                      <p className="font-10">{row?.original?.wear !== 'N/A' ? `/ ${Number(row?.original?.float_id).toFixed(4)} ` : ''}</p>
                    </div>

                    <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                    <div
                      className="border"
                      style={{
                        borderBottom: `5px solid ${row.original?.item?.r_color}`
                      }}
                    ></div>
                  </div>
                  <div className="display-product-name">
                    <div className="inventory-product-name">
                      <h3 className="productname">{row?.original?.item?.fullname && itemNameConverter(row?.original?.item?.fullname)}</h3>
                      <div className="d-flex gap-5 text-white resonsive-product-desc mb-5">
                        <p className="productname-desc">{row?.original?.item?.category}</p>*
                        <p className="productname-desc">{row?.original?.item?.subCategory}</p>
                      </div>
                    </div>
                    <p className="mb-10">
                      {defaultCurrency && defaultCurrency.symbol} {row.original?.startBid && row.original?.startBid}
                    </p>
                    {stickers && stickers.length > 0 && (
                      <div className="previous-sales-sticker product-sticker mt-10">
                        {stickers.map((item, index) => {
                          return (
                            <div
                              className="product-display-sticker"
                              key={index}
                              // dangerouslySetInnerHTML={{ __html: item.value }}
                            >
                              {/* <img src={item.value} alt="sticker" title={item.key} /> */}
                              <div className="single-sticker-div">
                                <img src={item.value} alt="sticker" />
                                {item.key && (
                                  <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                    <span>{item.key}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="responsive-btn-div">
                    <button onClick={() => onPaymentHandler(row.original)} className="table-button">
                      Pay Now
                    </button>
                  </div>
                </div>
              )}
            </>
            /* eslint-enable */
          );
        }
      }
    ];

    if (!isMobile) {
      columnsArray.push(
        {
          accessorKey: 'wear',
          header: 'Wear',
          size: 150
          // eslint-disable-next-line
        },
        {
          // accessorKey: 'item.category',
          header: 'Price',
          size: 100,
          // eslint-disable-next-line
          Cell: ({ row }) => {
            /* eslint-disable */
            return (
              <>
                <p>
                  {defaultCurrency && defaultCurrency.symbol} {row.original.startBid}
                </p>
              </>
            );
            /* eslint-enable */
          }
        },
        {
          // accessorKey: 'item.category',
          header: 'Time Left',
          size: 100,
          // eslint-disable-next-line
          Cell: ({ row }) => {
            /* eslint-disable */
            let value;
            const createdDateUTC = moment.utc(row.original.created);
            const currentDateUTC = moment.utc();
            const isFutureDate = createdDateUTC.isAfter(currentDateUTC);
            const daysCount = isFutureDate ? 18 : 20;
            const targetDate = moment.utc(createdDateUTC);
            const now = moment.utc();
            const differenceMs = targetDate.diff(now);
            if (differenceMs > 0) {
              const duration = moment.duration(differenceMs);
              const hours = Math.floor(duration.asHours());
              const minutes = duration.minutes();
              const seconds = duration.seconds();
              if (hours > 0) {
                value = `${hours}h ${minutes}m`;
              } else {
                value = `${minutes}m ${seconds}s`;
              }
            }
            return <>{daysCount !== null ? <p>{value}</p> : <p></p>}</>;
            /* eslint-enable */
          }
        },
        {
          header: <div className="buy-action d-none">Action</div>,
          size: 100,
          // eslint-disable-next-line
          Cell: ({ row }) => (
            /* eslint-disable */
            <div>
              <button onClick={() => onPaymentHandler(row.original)} className="table-button pay-now-btn">
                Pay Now
              </button>
            </div>
            /* eslint-enable */
          )
        }
      );
    }
    return columnsArray;
  }, [convertCurrency, isMobile, onPaymentHandler]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    getRowId: (row) => /* eslint-disable */ row?.item_id /* eslint-enable */,
    onRowSelectionChange: false,
    // initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableBottomToolbar: true,
    enablePagination: false,

    muiTableContainerProps: {
      className: 'zipit-data-table',
      sx: {
        overflow: 'initial'
      }
    },
    muiBottomToolbarProps: {
      className: 'table-bottom-bar-pagination',
      sx: {
        // bottom: '56px',
        padding: 0,
        '& .MuiBox-root ': {
          justifyContent: 'end',
          marginTop: '10px'
        },
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#fff',
          height: '3px'
        },
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar2Indeterminate': {
          backgroundColor: '#6117BF',
          height: '3px'
        },
        '& .MuiLinearProgress-root.MuiLinearProgress-colorPrimary': {
          backgroundColor: '#6117BF',
          height: '3px'
        }
      }
    },
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        {data.length > 0 && (
          <div className="pagination">
            {pagination.from} - {pagination.to} of {rowCount}
          </div>
        )}
      </>
    ),
    muiTablePaperProps: {
      sx: {
        borderRadius: '0',
        boxShadow: 'none',
        overflow: 'initial'
      }
    },
    muiTableBodyProps: {
      sx: {
        '& tr td': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        },
        '& tr:not([data-selected="true"])': {
          backgroundColor: 'transparent'
        }
      },
      className: 'buytable-body'
    },
    mrtTheme: {
      baseBackgroundColor: '#0b051b',
      selectedRowBackgroundColor: '#ffffff1a'
    },
    muiTableBodyCellProps: {
      className: isLoading ? 'sell-product-loader-class' : ''
    },
    muiTableHeadProps: {
      sx: {
        '& tr th': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        }
      },
      className: 'buytable-head'
    },
    localization: {
      noRecordsToDisplay: ''
    },
    muiSkeletonProps: ({ row, column }) => {
      if (row && column) {
        // Apply static skeleton properties for each cell
        return {
          animation: 'wave',
          variant: 'rect',
          width: column.size,
          height: isMobile ? 40 : 70,
          className: 'mui-skeleton-zipit'
        };
      }
    },
    rowCount,
    state: {
      isLoading: currentPage === 1 ? isLoading : false,
      showProgressBars: currentPage === 1 ? false : isLoading,
      showLoadingOverlay: false
    }
  });

  return <>{rowCount && rowCount > 0 ? <MaterialReactTable table={table} /> : <Nothing />}</>;
};

BuyProduct.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  rowCount: PropTypes.any,
  defaultCurrency: PropTypes.object,
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
  setRowCount: PropTypes.func,
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  setIsLoading: PropTypes.func,
  setData: PropTypes.func,
  setVisible: PropTypes.func,
  visible: PropTypes.number,
  hasMore: PropTypes.bool,
  setHasMore: PropTypes.func,
  location: PropTypes.any,
  navigate: PropTypes.any,
  toggleMenu: PropTypes.func,
  isMobile: PropTypes.bool,
  handleFilterCount: PropTypes.func,
  setPagination: PropTypes.func,
  pagination: PropTypes.object
};

import React, { useEffect } from 'react';
import './style.css';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useApi } from 'hooks/useApi';
import { buy_sell_count } from 'api';
import { setBuyFilterCount } from 'reduxs/BuySlice';

export const NavBar = () => {
  const location = useLocation();
  const { pathname } = location;
  const buyFilterCount = useSelector((state) => state.buy.buyFilterCount);
  const Bearer_Token = Cookies.get('Bearer_Token');
  const dispatch = useDispatch();

  let lastScrollTop = 0;

  // window.addEventListener('scroll', function () {
  //   let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
  //   const dropDown = document.querySelectorAll('.topheader-main');

  //   if (Bearer_Token) {
  //     if (currentScroll > lastScrollTop) {
  //       // scrolling down
  //       document.querySelector('.topheader-main')?.classList?.remove('hidden');
  //       document.querySelector('.topheader-main')?.classList?.add('show');
  //     } else {
  //       // scrolling up
  //       document.querySelector('.topheader-main')?.classList?.add('hidden ');
  //       document.querySelector('.topheader-main')?.classList?.remove('show');
  //       dropDown.style.zIndex = `9999999`;
  //     }
  //   }

  //   lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
  // });
  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      const topHeader = document.querySelector('.topheader-main');

      if (Bearer_Token) {
        if (currentScroll > lastScrollTop) {
          // scrolling down
          topHeader?.classList?.remove('hidden');
          topHeader?.classList?.add('show');
        } else {
          // scrolling up
          topHeader?.classList?.add('hidden');
          topHeader?.classList?.remove('show');
          // topHeader.style.zIndex = '999999';
        }
      }

      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [Bearer_Token]);

  const { apiCaller: BuySellAllFilterCount } = useApi({
    dataGetter: buy_sell_count
  });

  const handleFilterCount = async () => {
    const res = await BuySellAllFilterCount();
    dispatch(setBuyFilterCount(res.data.data));
  };

  return (
    <div className="topheader-main hidden">
      <div className="container-fluid">
        <div className="container">
          <div className="topheader">
            <div className="leftside-topheader topheader-main-menu">
              <div className="topheader-menu ">
                <Link to={'/inventory'} className="navbar-link">
                  <span className={`font-16 font-700 text-white ${pathname.startsWith('/inventory') ? 'active' : ''}`}>
                    <img
                      src={`/images/common/${pathname === '/inventory' ? 'treasure-active.svg' : 'treasure.svg'}`}
                      alt="Zipit"
                      className="img-fluid"
                    />
                    Inventory
                  </span>
                </Link>
              </div>
              <div className="topheader-menu">
                <Link to={'/market'} className="navbar-link">
                  <span className={`font-16 font-700 text-white ${pathname.startsWith('/market') ? 'active' : ''}`}>
                    <img
                      src={`/images/common/${pathname.startsWith('/market') ? 'store-active.svg' : 'store.svg'}`}
                      alt="Zipit"
                      className="img-fluid"
                    />
                    Market
                  </span>
                </Link>
              </div>
            </div>
            <div className="rightside-topheader topheader-main-menu">
              <div className="topheader-menu" onClick={handleFilterCount}>
                <Link to={'/buy'} className="navbar-link">
                  <span className={`font-16 font-700 text-white ${pathname === '/buy' ? 'active' : ''}`}>
                    <img src={'/images/header/buy.svg'} alt="Zipit" className="img-fluid responsive-img" />
                    Buy
                  </span>
                </Link>
                {buyFilterCount?.makepayment || buyFilterCount?.complate_trade ? (
                  <img src={'/images/common/redspot.gif'} alt="Zipit" width={25} height={25} className="img-fluid redspot" />
                ) : (
                  ''
                )}
              </div>

              <div className="topheader-menu" onClick={handleFilterCount}>
                <Link to={'/sell'} className="navbar-link">
                  <span className={`font-16 font-700 text-white ${pathname === '/sell' ? 'active' : ''}`}>
                    <img src={'/images/header/sell.svg'} alt="Zipit" className="img-fluid responsive-img" />
                    Sell
                  </span>
                </Link>
                {buyFilterCount?.seller_isoffer || buyFilterCount?.seller_wainting_trade ? (
                  <img src={'/images/common/redspot.gif'} alt="Zipit" width={25} height={25} className="img-fluid redspot" />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

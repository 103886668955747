export const authRoutes = {
  login: '/steam_auth',
  logout: '/steam_logout',
  token: '/check_tkn',
  commissionRate: '/commissionrate',
  check_api_key: '/check_api_key',
  steam_set_api_key: '/steam_set_api_key',
  currency_rate: '/EUR',
  newsletter: '/newsletter',
  ischeck_chkout_intry: '/ischeck_chkout_intry',
  payment_status_inventory: '/payment_status_inventory'
};
export const categoryRoutes = {
  category: '/category'
};
export const inventoryRoutes = {
  inventory: '/get_inventory',
  inventoryCategory: '/getcategory',
  steamApikey: '/steam_api_key',
  inventroy_sell: '/inventroy_sell',
  inventory_list_view: '/inventory_list_view',
  remove_sell_inventory: '/remove_sell_inventory',
  sellpriceupdate: '/sellpriceupdate',
  refresh_inventory: '/refresh_inventory',
  update_price: '/update_price'
};
export const notificationsRoutes = {
  notifications: '/user_notification',
  getNotification: '/get_noficn',
  notificationList: '/notification_list',
  user_notification_seen: '/user_notification_seen',
  mark_as_read_notification: '/mark_as_read_notification'
};
export const marketRoutes = {
  mrkt_goods_ct: '/mrkt_goods_ct',
  goods_market: '/goods_market',
  market_sidebar: '/market_sidebar',
  search_mrktet: '/search_mrktet'
};
export const singleSkinRoutes = {
  single_market_inventory: '/single_market_inventory',
  get_similar_inventory: '/get_similar_inventory',
  user_bid: '/user_bid',
  user_offer: '/user_offer',
  steam_pricehistory: '/steam_pricehistory'
};
export const sellerShopRoutes = {
  sellershop: '/sellershop'
};
export const BuyRoutes = {
  buy_inventory: '/buy_inventory',
  get_buy_inventory: '/get_buy_inventory',
  user_bid_pending_view: '/user_bid_pending_view',
  user_bid_cancle_view: '/user_bid_cancel_view',
  user_make_offer_view: '/user_make_offer_view',
  buyer_waiting_trade: '/buyer_waiting_trade',
  buyer_waiting_trade_list: '/buyer_waiting_trade_list',
  buyer_accept_trade_list: '/buyer_accept_trade_list',
  buyer_purchase_history: '/buyer_user_history',
  buyer_lost_auction: '/buyer_lost_auction'
};
export const paymentRoutes = {
  stripePayment: '/stripepayment',
  add_wallet: '/wallet'
};
export const sellRoutes = {
  get_sellinventorylist: '/get_sellinventorylist',
  offer_view_seller: '/offer_view_seller',
  seller_offer_accept: '/seller_offer_accept',
  offer_decline: '/offer_decline',
  offer_disable: '/offer_disable',
  single_offer_decline: '/single_offer_decline',
  payment_status_inventory: '/payment_status_inventory',
  waiting_for_seller_inventory_payment: '/waiting_for_seller_inventory_payment',
  remove_user_offer: '/remove_user_offer',
  seller_waiting_trade_list: '/seller_waiting_trade_list',
  seller_accept_trade_list: '/seller_accept_trade_list',
  seller_sell_history: '/seller_user_history'
};
export const settingPageRoutes = {
  page_template: '/page_template',
  faq_page: '/faq'
};
export const followingRoutes = {
  add_following: '/add_following',
  get_following: '/get_following',
  set_auction_timeprice: '/set_auction_timeprice'
};
export const homeRoutes = {
  home_page_template: '/home_page_template',
  home_page_section: '/home_page_section',
  home_page_contact: '/home_page_contact',
  howItsWorkpopup: '/HowItsWorkPopup'
};
export const marketPrivateView = {
  market_private_view: '/view/:id',
  confirm_trade_offer_accepted: 'confirm_trade',
  buyer_accept_trade: '/buyer_accept_trade'
};
export const transactions = {
  transection_list_view: '/transection_list'
};

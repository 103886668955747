import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { useSelector } from 'react-redux';
import { SkeletonLoader } from 'components';

export const Filter = ({
  onCategoryChange,
  totalCountOfInventory,
  selectedCategory,
  handleAllCategoryChange,
  loading,
  isMenuOpen,
  isMobile,
  toggleMenu
}) => {
  const categories = useSelector((state) => state.inventory.categories);
  const handleCategoryChange = (category) => {
    if (isMobile) {
      toggleMenu();
    }
    onCategoryChange(category);
  };

  return (
    <div className={`ng-star-inserted inventory-table mobile-filter ${isMenuOpen ? 'side-filter-menu-show' : ''}`}>
      <div className="inventory-table-inner inventory-sidebar-table">
        {isMobile && (
          <>
            <div
              className="back-text border-bottom-0"
              onClick={() => {
                if (isMobile) {
                  toggleMenu();
                }
              }}
            >
              <img src="/images/common/left-arrow.svg" alt="left-aarow" />
              <div className="category text-white font-16">Back</div>
            </div>
          </>
        )}
        <div className="ng-star-inserted">
          {loading ? (
            <SkeletonLoader width="100%" height={60} color="#0b051b" highlightColor="#242347" borderRadius={0} className="category-item" />
          ) : (
            <div
              className={`category-item d-flex justify-content-between
                ${selectedCategory === undefined || selectedCategory === null ? 'selected' : ''}`}
              onClick={() => handleAllCategoryChange()}
            >
              <div className="text-white font-16">All</div>
              <span className="text-white font-16">({totalCountOfInventory})</span>
            </div>
          )}
          {loading ? (
            <SkeletonLoader
              width="100%"
              height={60}
              count={15}
              borderRadius={0}
              color="#0b051b"
              highlightColor="#242347"
              // className="category-item-div"
              className="category-item"
            />
          ) : (
            categories &&
            categories?.map((item, i) => {
              return (
                <div className="category-item-div" key={i}>
                  <div
                    className={`${selectedCategory === item.cat_name ? 'selected' : ''} category-item d-flex justify-content-between `}
                    onClick={() => handleCategoryChange(item.cat_name)}
                  >
                    <div className="text-white font-16">{item.cat_name}</div>
                    <span className="text-white font-16">({item.numberOfItems})</span>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

Filter.propTypes = {
  onCategoryChange: PropTypes.func,
  totalCountOfInventory: PropTypes.number,
  selectedCategory: PropTypes.string,
  handleAllCategoryChange: PropTypes.func,
  loading: PropTypes.any,
  isMenuOpen: PropTypes.bool,
  setIsMenuOpen: PropTypes.func,
  isMobile: PropTypes.bool,
  toggleMenu: PropTypes.func
};

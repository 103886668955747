import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { convertCurrency, itemNameConverter, wearNameConverter } from 'lib';

import './style.css';
import {
  axiosAuthInstance,
  get_sellinventorylist,
  offer_decline,
  offer_disable,
  offer_view_seller,
  seller_offer_accept,
  single_offer_decline
} from 'api';
import { useApi } from 'hooks/useApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { Nothing } from '../Nothing';
import { useDispatch } from 'react-redux';
import { setSellCount } from 'reduxs/SellSlice';
import { SellerOfferListModal, CountdownTimer } from 'components';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

export const SellProduct = ({
  selectedCategory,
  setSelectedCategory,
  sorting,
  setSorting,
  setCurrentPage,
  currentPage,
  hasMore,
  setVisible,
  visible,
  setIsLoading,
  isLoading,
  buyData,
  setBuyData,
  rowCount,
  setHasMore,
  setRowCount,
  handleFilterCount,
  isMobile,
  setPagination,
  pagination
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));

  const [sellerOfferModal, setSellerOfferModal] = useState(false);
  const [isSingleDeclineOfferModal, setIsSingleDeclineOfferModal] = useState(false);
  const [isSellerOfferAcceptModalOpen, setIsSellerOfferAcceptModalOpen] = useState(false);

  const [sellerOfferList, setSellerOfferList] = useState();
  const [offerCount, setOfferCount] = useState(0);

  useEffect(() => {
    const Bearer_Token = Cookies.get('Bearer_Token');
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${Bearer_Token}`;
  }, []);

  const { apiCaller: onSellInventoryList } = useApi({
    dataGetter: get_sellinventorylist
  });

  const { apiCaller: onOfferViewSeller } = useApi({
    dataGetter: offer_view_seller
  });

  const { apiCaller: sellerOfferAccept } = useApi({
    dataGetter: seller_offer_accept
  });

  const { apiCaller: onOfferDecline } = useApi({
    dataGetter: offer_decline
  });

  const { apiCaller: onOfferDisable } = useApi({
    dataGetter: offer_disable
  });

  const { apiCaller: singleOfferDecline } = useApi({
    dataGetter: single_offer_decline
  });

  const fetchData = useCallback(
    async (page, category) => {
      try {
        setIsLoading(true);
        let params = {
          page
        };
        if (category !== null && category !== '' && category !== undefined) {
          params.status = category;
        }
        const res = await onSellInventoryList(params);
        const newData = res?.data?.item?.data?.filter((item, index, self) => index === self.findIndex((i) => i.item_id === item.item_id));
        setBuyData((prev) => {
          const updatedData = page === 1 ? newData : [...prev[category], ...newData];
          const uniqueArray = Array.from(new Set(updatedData?.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

          const newVisible = uniqueArray.length;
          setVisible(newVisible);
          return { ...prev, [category]: uniqueArray };
        });
        dispatch(setSellCount({ category: res.data.item.total }));
        setRowCount(res.data.item.total);
        setPagination({ from: res.data.item.from, to: res.data.item.to });
      } catch (error) {
        console.error(error.message);
      } finally {
        // setTimeout(() => {
        setIsLoading(false);
        // }, 1000);
      }
    },
    [onSellInventoryList]
  );

  useEffect(() => {
    fetchData(currentPage, selectedCategory);
  }, [fetchData, location.search, currentPage, selectedCategory]);

  const [fetchThreshold, setFetchThreshold] = useState(200);

  const fetchMoreOnBottomReached = () => {
    // if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
    const scrollPlus = isMobile ? 100 : 40;
    if (window.pageYOffset + window.innerHeight >= document.documentElement.scrollHeight - fetchThreshold) {
      if (visible < rowCount) {
        setCurrentPage(currentPage + 1);
        setFetchThreshold((prevThreshold) => prevThreshold + scrollPlus);

        // fetchData(selectedCategory, currentPage + 1, search);
      }
    }
  };

  useEffect(() => {
    visible < rowCount && rowCount > 0
      ? window.addEventListener('scroll', fetchMoreOnBottomReached)
      : window.removeEventListener('scroll', fetchMoreOnBottomReached);
    window.addEventListener('scroll', fetchMoreOnBottomReached);
    return () => {
      window.removeEventListener('scroll', fetchMoreOnBottomReached);
    };
  }, [visible, rowCount]);

  /***************** seller offer show Modal *****************/

  const closeModal = async () => {
    document.body.classList.remove('modal-open');
    setSellerOfferModal(false);
    setIsSingleDeclineOfferModal(false);
  };

  const onSellerOfferModal = (market_id) => {
    document.body.classList.add('modal-open');

    onOfferViewSeller({ market_id })
      .then((response) => {
        setSellerOfferList(response.data);
        setSellerOfferModal(true);
      })
      .catch((error) => {
        console.error(error.message);

        // Handle any errors here
      });
  };

  const onAcceptOffer = (itemData) => {
    const params = {
      offer_id: itemData.id,
      m_id: itemData.m_id
    };
    sellerOfferAccept(params).then((res) => {
      if (res.data.status === 'Success') {
        toast.success(res.data.massage);
        const updatedOffersData = buyData[selectedCategory].filter((f) => Number(f.m_id) !== Number(itemData.m_id));
        setBuyData((prev) => ({
          ...prev,
          [selectedCategory]: updatedOffersData
        }));
        setVisible(updatedOffersData.length);
        dispatch(setSellCount({ category: updatedOffersData.length }));
        setRowCount(updatedOffersData.length);
        closeModal();
        handleFilterCount();
      }
    });
  };

  const OfferDecline = (m_id) => {
    onOfferDecline({ m_id })
      .then((res) => {
        if (res.data?.status === 'Success') {
          toast.success(res.data.massage);
          closeModal();
          const updatedOffersData = buyData[selectedCategory].filter((f) => Number(f.m_id) !== Number(m_id));
          setBuyData((prev) => ({
            ...prev,
            [selectedCategory]: updatedOffersData
          }));
          setVisible(updatedOffersData.length);
          dispatch(setSellCount({ category: updatedOffersData.length }));
          setRowCount(updatedOffersData.length);
          handleFilterCount();
        } else {
          toast.error(res.data.massage);
        }
      })
      .catch((err) => {
        console.error(`${err.message}`);
      });
  };

  const OfferDisable = (m_id) => {
    onOfferDisable({ m_id })
      .then((res) => {
        if (res.data?.status === 'Success') {
          toast.success(res.data.massage);
          OfferDecline(m_id);
          closeModal();
        } else {
          toast.error(res.data.massage);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleSingleOfferDecline = (offer_id, m_id) => {
    singleOfferDecline({ offer_id, m_id })
      .then((res) => {
        if (res.data?.status === 'Success') {
          toast.success(res.data.massage);
          const updatedOffers = sellerOfferList?.offer?.filter((f) => Number(f.id) !== Number(offer_id));
          setSellerOfferList((prevState) => ({
            ...prevState,
            offer: updatedOffers
          }));

          if (updatedOffers.length !== 0) {
            setIsSingleDeclineOfferModal(false);
            setOfferCount(updatedOffers.length);
          } else {
            closeModal();
            const updatedOffersData = buyData[selectedCategory].filter((f) => Number(f.m_id) !== Number(m_id));
            setBuyData((prev) => ({
              ...prev,
              [selectedCategory]: updatedOffersData
            }));
            setVisible(updatedOffersData.length);
            dispatch(setSellCount({ category: updatedOffersData.length }));
            setRowCount(updatedOffersData.length);
            handleFilterCount();
          }
        } else {
          toast.error(res.data.massage);
        }
      })
      .catch((err) => {
        console.error(`${err.message}`);
      });
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        accessorKey: 'item.fullname',
        header: 'Item',
        size: 150,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          /* eslint-disable */
          const stickers = row.original.item?.stickers;

          /* eslint-enable */

          return (
            /* eslint-disable */
            <>
              {!isMobile ? (
                <div id={`${row?.original?.item_id}`} className="d-flex gap-15 align-items-center">
                  <div className="goods-card-main-div position-relative">
                    <div
                      className="goods-card-img"
                      style={{
                        backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.item?.imageUrl}")`
                      }}
                    ></div>
                    <div className="wear-name text-white">
                      <p className="font-10 font-500">{row?.original?.item && wearNameConverter(row?.original?.wear)}</p>
                      <p className="font-10 font-500">
                        {row?.original?.wear !== 'N/A' ? `/ ${Number(row?.original?.float_id).toFixed(4)} ` : ''}
                      </p>
                    </div>

                    <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                    <div
                      className="border"
                      style={{
                        borderBottom: `5px solid ${row?.original?.item?.r_color}`
                      }}
                    ></div>
                  </div>

                  <div className="display-product-name">
                    <div className="inventory-product-name">
                      <p className="productname">{row?.original?.item && itemNameConverter(row?.original?.item?.fullname)}</p>
                      <div className="d-flex gap-5 text-white">
                        <p className="productname-desc">{row?.original?.item?.category}</p>*
                        <p className="productname-desc">{row?.original?.item?.subCategory}</p>
                      </div>
                    </div>
                    {stickers && stickers.length > 0 && (
                      <div className="previous-sales-sticker product-sticker mt-10">
                        {stickers.map((item, index) => {
                          return (
                            <div
                              className="product-display-sticker"
                              key={index}
                              // dangerouslySetInnerHTML={{ __html: item.value }}
                            >
                              {/* <img src={item.value} alt="sticker" title={item.key} /> */}
                              <div className="single-sticker-div">
                                <img src={item.value} alt="sticker" />
                                {item.key && (
                                  <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                    <span>{item.key}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {/* {isMobile && (
                    <>
                      <div>fsdaffds</div> */}
                  {/* <div className="offer-recieved-button">
                        {selectedCategory === 'Offers received' && (
                          <button
                            className="green-button table-button sellproduct-button"
                            onClick={() => onSellerOfferModal(row.original.m_id)}
                          >
                            Offers #{offerCount !== 0 ? offerCount : row.original.total_pending_offer}
                          </button>
                        )}

                        <button
                          className="view-button table-button sellproduct-button"
                          style={{ backgroundColor: '#ffffff1a' }}
                          onClick={() => navigate(`/market/${row.original.m_id}`)}
                        >
                          view
                        </button>
                      </div> */}
                  {/* </>
                  )} */}
                </div>
              ) : (
                <div id={`${row?.original?.item_id}`} className="responsive-buy-div">
                  <div className="goods-card-main-div position-relative">
                    <div
                      className="goods-card-img"
                      style={{
                        backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.item?.imageUrl}")`
                      }}
                    ></div>
                    <div className="wear-name text-white">
                      <p className="font-10 font-500">{row?.original?.item && wearNameConverter(row?.original?.wear)}</p>
                      <p className="font-10 font-500">
                        {row?.original?.wear !== 'N/A'
                          ? `/ ${row?.original?.float_id && Number(row?.original?.float_id) !== 0 && ` ${row?.original?.float_id && Number(row?.original?.float_id).toFixed(4)}`} `
                          : ''}
                      </p>
                    </div>

                    <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                    <div
                      className="border"
                      style={{
                        borderBottom: `5px solid ${row?.original?.item?.r_color}`
                      }}
                    ></div>
                  </div>

                  <div className="display-product-name">
                    <div className="inventory-product-name">
                      <h3 className="productname">{row?.original?.item && itemNameConverter(row?.original?.item?.fullname)}</h3>
                      <div className="d-flex gap-5 text-white resonsive-product-desc">
                        <p className="productname-desc">{row?.original?.item?.category}</p>*
                        <p className="productname-desc">{row?.original?.item?.subCategory}</p>
                      </div>
                      <p className="mb-10">
                        {defaultCurrency.symbol} {row.original.startBid}
                      </p>
                    </div>
                    {stickers && stickers.length > 0 && (
                      <div className="previous-sales-sticker product-sticker mt-10">
                        {stickers.map((item, index) => {
                          return (
                            <div
                              className="product-display-sticker"
                              key={index}
                              // dangerouslySetInnerHTML={{ __html: item.value }}
                            >
                              {/* <img src={item.value} alt="sticker" title={item.key} /> */}
                              <div className="single-sticker-div">
                                <img src={item.value} alt="sticker" />
                                {item.key && (
                                  <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                    <span>{item.key}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="responsive-btn-div">
                    {selectedCategory === 'Offers received' && (
                      <div className="offer-recieved-button mb-5">
                        <button
                          className="green-button table-button sellproduct-button"
                          onClick={() => onSellerOfferModal(row.original.m_id)}
                        >
                          Offers #{offerCount !== 0 ? offerCount : row?.original?.total_pending_offer}
                        </button>
                      </div>
                    )}

                    <button
                      onClick={() => navigate(`/market/${row?.original?.m_id}`)}
                      className="table-button w-100 justify-content-center"
                    >
                      View
                    </button>
                  </div>
                </div>
              )}
            </>
            /* eslint-enable */
          );
        }
      }
    ];
    if (!isMobile) {
      columnList.push(
        {
          accessorKey: 'wear',
          header: 'Wear',
          size: 150
        },
        {
          // accessorKey: 'item.category',
          header: 'Price',
          size: 100,
          // eslint-disable-next-line
          Cell: ({ row }) => {
            /* eslint-disable */
            return (
              <>
                <p>
                  {defaultCurrency && defaultCurrency.symbol} {row?.original?.startBid}
                </p>
              </>
            );
            /* eslint-enable */
          }
        }
      );
      if (selectedCategory !== 'Private listings') {
        columnList.push({
          // accessorKey: 'item.category',
          header: 'Time Left',
          size: 100,
          // eslint-disable-next-line
          Cell: ({ row }) => {
            /* eslint-disable */

            const days = row?.original?.item && row.original.runtime;
            const daysCount = days !== null ? parseInt(days) : null;
            const createdDate = row.original.cread_date;

            return (
              <>
                {daysCount !== null ? (
                  <CountdownTimer daysCount={daysCount} createdDate={createdDate} key={row?.original?.item_id} />
                ) : (
                  <p></p>
                  // selectedCategory === 'Offers received' && (
                  //   <CountdownTimer daysCount={18} createdDate={createdDate} key={row?.original?.item_id} />
                  // )
                )}
              </>
            );
            /* eslint-enable */
          }
        });
      }
    }
    if (!isMobile) {
      columnList.push({
        header: <div className="buy-action d-none">Action</div>,
        // eslint-disable-next-line
        Cell: ({ row }) => (
          /* eslint-disable */
          <div className="offer-recieved-button">
            {selectedCategory === 'Offers received' && (
              <button className="green-button table-button sellproduct-button" onClick={() => onSellerOfferModal(row?.original?.m_id)}>
                Offers #{offerCount !== 0 ? offerCount : row?.original?.total_pending_offer}
              </button>
            )}

            <button
              className="view-button table-button sellproduct-button"
              style={{ backgroundColor: '#ffffff1a' }}
              onClick={() => navigate(`/market/${row?.original?.m_id}`)}
            >
              view
            </button>
          </div>
          /* eslint-enable */
        )
      });
    }
    return columnList;
  }, [convertCurrency, selectedCategory, offerCount, isMobile]);

  const table = useMaterialReactTable({
    columns,
    data: buyData[selectedCategory] || [],
    enableRowSelection: false,
    getRowId: (row) => /* eslint-disable */ row?.item_id /* eslint-enable */,
    onRowSelectionChange: false,
    // initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableBottomToolbar: true,
    enablePagination: false,

    muiTableContainerProps: {
      className: 'zipit-data-table',
      sx: {
        overflow: 'initial'
      }
    },
    muiBottomToolbarProps: {
      className: 'table-bottom-bar-pagination',
      sx: {
        // bottom: '56px',
        padding: 0,
        '& .MuiBox-root ': {
          justifyContent: 'end',
          marginTop: '10px'
        },
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#fff',
          height: '3px'
        },
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar2Indeterminate': {
          backgroundColor: '#6117BF',
          height: '3px'
        },
        '& .MuiLinearProgress-root.MuiLinearProgress-colorPrimary': {
          backgroundColor: '#6117BF',
          height: '3px'
        }
      }
    },
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        {buyData[selectedCategory].length > 0 && (
          <div className="pagination">
            {pagination.from} - {pagination.to} of {rowCount}
          </div>
        )}
      </>
    ),
    muiTablePaperProps: {
      sx: {
        borderRadius: '0',
        boxShadow: 'none',
        overflow: 'initial'
      }
    },
    muiTableBodyProps: {
      sx: {
        '& tr td': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        },
        '& tr:not([data-selected="true"])': {
          backgroundColor: 'transparent'
        }
      },
      className: 'buytable-body'
    },
    mrtTheme: {
      baseBackgroundColor: '#0b051b',
      selectedRowBackgroundColor: '#ffffff1a'
    },
    muiTableBodyCellProps: {
      className: isLoading ? 'sell-product-loader-class' : ''
    },
    muiTableHeadProps: {
      sx: {
        '& tr th': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        }
      },
      className: 'buytable-head'
    },
    localization: {
      noRecordsToDisplay: ''
    },
    muiSkeletonProps: ({ row, column }) => {
      if (row && column) {
        // Apply static skeleton properties for each cell
        return {
          animation: 'wave',
          variant: 'rect',
          width: column.size,
          height: isMobile ? 40 : 70,
          className: 'mui-skeleton-zipit'
        };
      }
    },
    rowCount,
    state: {
      isLoading: currentPage === 1 ? isLoading : false,
      showProgressBars: currentPage === 1 ? false : isLoading,
      showLoadingOverlay: false
    }
  });
  return (
    <>
      {rowCount && rowCount > 0 ? (
        <>
          {sellerOfferModal && (
            <SellerOfferListModal
              closeModal={closeModal}
              sellerOfferList={sellerOfferList}
              onAcceptOffer={onAcceptOffer}
              OfferDisable={OfferDisable}
              OfferDecline={OfferDecline}
              handleSingleOfferDecline={handleSingleOfferDecline}
              isSellerOfferAcceptModalOpen={isSellerOfferAcceptModalOpen}
              setIsSellerOfferAcceptModalOpen={setIsSellerOfferAcceptModalOpen}
              isSingleDeclineOfferModal={isSingleDeclineOfferModal}
              setIsSingleDeclineOfferModal={setIsSingleDeclineOfferModal}
            />
          )}
          <MaterialReactTable table={table} />{' '}
        </>
      ) : (
        <Nothing />
      )}
    </>
  );
};

SellProduct.propTypes = {
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
  sorting: PropTypes.any,
  setSorting: PropTypes.func,
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  hasMore: PropTypes.bool,
  setVisible: PropTypes.func,
  visible: PropTypes.any,
  setIsLoading: PropTypes.func,
  isLoading: PropTypes.bool,
  buyData: PropTypes.object,
  setBuyData: PropTypes.func,
  rowCount: PropTypes.number,
  setHasMore: PropTypes.func,
  setRowCount: PropTypes.func,
  handleFilterCount: PropTypes.func,
  isMobile: PropTypes.bool,
  setPagination: PropTypes.func,
  pagination: PropTypes.object
};

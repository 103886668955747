import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { Link } from 'react-router-dom';

export const PaymentModal = ({ closeModal }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [showOptions, setShowOptions] = useState(false);

  const countries = [
    'Afghanistan',
    'Albania',
    'Algeria'
    // Add more countries here
  ];

  const handleSelect = (country) => {
    setSelectedCountry(country);
    setShowOptions(false);
  };
  return (
    <>
      <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal">
        <div className=" offer-modal">
          <div className="offer-popup position-relative">
            <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
              <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
            </button>
            <div className="checkout-page">
              <div className="checkout-inner-div">
                <p className="offer-heading">Checkout</p>
                <div className="order-summery">
                  <p className="font-18 font-700">Order summary</p>
                  <div className="order-summery-div">
                    <div className="order-summery-product">
                      <img
                        src="https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq5WYh-TLPbTYhFRd4cJ5nqeZrY-l3lbk_RJrZ2H1LIGSJA9rMlmC_wTrxbzvhpTuu5-fzSFg6SJx-z-DyKPJvxFR"
                        alt="zipit"
                        className="img-fluid single-product-img"
                      />
                      <img src="/images/sell-modal/new-background-image.jpg" alt="Zipit" className="img-fluid sell-background" />
                    </div>
                    <div className="summery-product-name">
                      <h3 className="productname">Army sheen</h3>
                      <div className="d-flex gap-5 text-white">
                        <p className="productname-desc">Sticker</p>*<p className="productname-desc">Sticker</p>
                      </div>
                      <div className="previous-sales-sticker product-sticker mt-10">
                        <div className="product-display-sticker">
                          <img
                            src="https://steamcdn-a.akamaihd.net/apps/730/icons/econ/stickers/pinups_capsule/kimberly.51418b5d1ecff134b18c847607e455eba9e907fb.png"
                            alt="sticker"
                            title=" Kimberly, Astralis (Holo) "
                          />
                        </div>
                        <div className="product-display-sticker">
                          <img
                            src="https://steamcdn-a.akamaihd.net/apps/730/icons/econ/stickers/rmr2020/astr_holo.29073da0ac1a07bb4754e3dc0ba1eed69cf30fe2.png"
                            alt="sticker"
                            title=" 2020 RMR"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="summery-product-factory">
                      <p className="productname">Factory New</p>
                      <p className="productname-desc">0.20956844</p>
                    </div>
                    <div className="summery-product-price">
                      <p className="font-14">$10.00</p>
                    </div>
                  </div>
                  <div className="total-price">
                    <p className="font-18 font-700">Order summary</p>
                    <p className="font-18 font-700">$10.00</p>
                  </div>
                  <div className="select-payment-method mt-30">
                    <p className="font-18 font-700">Select your payment method</p>
                    <div className="row mt-15">
                      <div className="col-6 col">
                        <div className="grey-background ">
                          <label className="sell-label w-100">
                            Balance
                            <input type="radio" name="balance" value="" />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col">
                        <div className="grey-background ">
                          <label className="sell-label w-100">
                            Credit card
                            <input type="radio" name="balance" value="" />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="payment-method-text mt-30">
                      <p className="font-18 font-700 pb-10">The amount will be deducted from your current balance.</p>
                      <p className="font-16 opacity-white">Please note that bank rates and other local fees might apply.</p>
                      <div className="offer-button-div">
                        <button className="green-button w-100">PAy $ 60.00</button>
                      </div>
                    </div>
                    <div className="paid-text-div mt-30">
                      <p className="font-18 font-700">You have successfully paid for this item!</p>
                      <p className="font-16  mt-30">What happens, next:</p>
                      <ol className="paid-text-ul">
                        <li>The seller now has 24 hours to make a trade offer via steam.</li>
                        <li>Make sure your profile is not private and that your trade link is correct.</li>
                        <li>You need to accept the trade offer within 24 hours.</li>
                        <li>
                          Very important! After receiving the item you purchased you MUST confirm receving it by using the button found on
                          the item page that looks like his:
                        </li>
                      </ol>
                      <div className="offer-button-div">
                        <button className="yellow-background button-link w-100">Confirm trade offer accepted</button>
                        <Link to={'/'} className="text-white font-16 font-700 text-underline mt-20 text-center">
                          Go to item page
                        </Link>
                      </div>
                    </div>
                    <div className="payment-detail">
                      <div className="row mt-30">
                        <div className="col-lg-6 col">
                          <div className="form-control">
                            <label className="opacity-white">Card number</label>
                            <div className="position-relative">
                              <input type="number" className="w-100" placeholder="1234 5678 9012 3456" />
                              <img src="/images/common/credit-card.svg" className="card-img" alt="credit" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col">
                          <div className="form-control">
                            <label className="opacity-white">Name on card</label>
                            <input className="w-100" placeholder="John doe" />
                          </div>
                        </div>
                        <div className="col-lg-6 col">
                          <div className="form-control security-code-input">
                            <label className="opacity-white">Expiry date</label>
                            <input type="number" className="w-100" placeholder="MM/YY" />
                          </div>
                        </div>
                        <div className="col-lg-6 col">
                          <div className="form-control security-code-input">
                            <label className="opacity-white">Security code</label>
                            <input className="w-100" placeholder="3 digits" />
                          </div>
                        </div>
                        <div className="col-12 col">
                          <label className="sell-label">
                            Save for my next payment
                            <input type="checkbox" name="" />
                            <span className="checkmark l-0"></span>
                          </label>
                        </div>
                      </div>
                      <div className="billing-address mt-30">
                        <p className="font-18 opacity-white">Billing Address</p>
                        <div className="row mt-30">
                          <div className="col-lg-12 col">
                            <div className="form-control ">
                              <label className="opacity-white">Country</label>
                              <div className="country-select-box position-relative">
                                <div
                                  className={`currency-div ${selectedCountry ? '' : 'default-selected'}`}
                                  onClick={() => setShowOptions(!showOptions)}
                                >
                                  {selectedCountry || 'Country'}

                                  <img src="/images/header/down-arrow.svg" alt="Zipit" className="img-fluid" />
                                </div>
                                {showOptions && (
                                  <ul className="options">
                                    {countries.map((country, index) => (
                                      <li key={index} className="option" onClick={() => handleSelect(country)}>
                                        {country}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="form-control">
                              <label className="opacity-white">Street</label>
                              <input className="w-100" />
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="form-control">
                              <label className="opacity-white">House number</label>
                              <input className="w-100" />
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="form-control security-code-input">
                              <label className="opacity-white">Postal code</label>
                              <input type="text" className="w-100" />
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="form-control security-code-input">
                              <label className="opacity-white">City</label>
                              <input className="w-100" />
                            </div>
                          </div>

                          <div className="col-12 col">
                            <div className="offer-button-div">
                              <button className="green-button w-100">PAy $ 60.00</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
PaymentModal.propTypes = {
  closeModal: PropTypes.func
};

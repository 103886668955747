import React from 'react';
import PropTypes from 'prop-types';

export const ConfirmTradeOfferSendModal = ({
  closeModal,
  setAcceptWarning,
  acceptWarning,
  TradeOfferAccepted,
  item,
  buyerTradeOfferAccept
}) => {
  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal">
      <div className="sell-modal-area">
        <div className="offer-popup position-relative">
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>
          <p className="offer-heading">
            {item.is_check_buyer_seller === 'buyer' ? 'Confirm trade offer accepted' : 'Confirm trade offer send'}
          </p>
          <div className="alert-box">
            <p>Read this before you continue</p>
            {item.is_check_buyer_seller === 'buyer' ? (
              <ul>
                <li> I have accepted the offer and i have the item i purchased.</li>
                <li>I understand that is i confirm this without accepting the item I risk losing my payment.</li>
              </ul>
            ) : (
              <ul>
                <li>I have sent the offer to the correct accoutn.</li>
                <li>
                  I understand that if confirm this
                  <span>without sending the item to the buyer</span> i will get banned.
                </li>
                <li>I understand that the payment will be received with a delay of a few hours.</li>
              </ul>
            )}
            <label className="sell-label">
              I have read and i agree
              <input type="checkbox" name="term" onChange={(e) => setAcceptWarning(e.target.checked)} />
              <span className="checkmark"></span>
            </label>
          </div>

          <div className="offer-button-div">
            {item.is_check_buyer_seller === 'buyer' ? (
              <button className="green-button" disabled={!acceptWarning} onClick={(e) => buyerTradeOfferAccept(item)}>
                confirm
              </button>
            ) : (
              <button className="green-button" disabled={!acceptWarning} onClick={(e) => TradeOfferAccepted(item)}>
                confirm
              </button>
            )}

            <button aria-label="Close Modal" aria-labelledby="close-modal" className="grey-button" onClick={closeModal}>
              cancel
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};

ConfirmTradeOfferSendModal.propTypes = {
  closeModal: PropTypes.func,
  acceptWarning: PropTypes.bool,
  setAcceptWarning: PropTypes.func,
  TradeOfferAccepted: PropTypes.func,
  item: PropTypes.object,
  buyerTradeOfferAccept: PropTypes.func
};

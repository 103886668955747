import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { SkeletonLoader } from 'components';
import { RoundedPrice, convertCurrency, itemNameConverter, wearNameConverter } from 'lib';

export const MarketSkin = ({ market, itemsPerPage, isLoading, loading, itemTotal, status }) => {
  const navigate = useNavigate();
  const skeletonItems = Array.from({ length: itemsPerPage }, (_, index) => index);

  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  // const  = true;
  return (
    <>
      <div className="market-product-list resposive-product-list market-page">
        <div className="market-product-row">
          <>
            {status ? (
              [...Array(10)].map((item, index) => (
                <div className="main-single-market-product" key={index}>
                  <SkeletonLoader
                    height={290}
                    width="100%"
                    color="#0b051b"
                    highlightColor="#242347"
                    className=""
                    borderRadius={0}
                  ></SkeletonLoader>
                </div>
              ))
            ) : Number(itemTotal) && Number(itemTotal) > 0 ? (
              market.map((item, index) => (
                <div className="main-single-market-product" id={item.m_id} key={index} onClick={() => navigate(`/market/${item.m_id}`)}>
                  <div className="single-market-product">
                    <div className="market-fade-info">
                      <div className="font-14 pb-0 text-white">
                        <span className="font-700" style={{ color: `${item.item.r_color}` }}>
                          {wearNameConverter(item.wear)}
                        </span>{' '}
                        {''}
                        {item && Number(item.float_id) !== 0 ? ` : ${item && Number(item.float_id).toFixed(4)}` : ''}
                        {/* <span style={{ backgroundColor: `${item.item.r_color && item.item.r_color}` }}></span> */}
                      </div>
                      {item && Number(item.paintseed) !== 0 && (
                        <div className="font-12 text-white font-700">
                          P {''}/ {''}
                          {item && item.paintseed}
                        </div>
                      )}
                      {item && item.fade_id !== null && (
                        <>
                          <div className="font-12 opacity-white font-700 single-product-wear">
                            Fade
                            <span
                              style={{
                                width: '6px',
                                height: '6px',
                                borderRadius: '1px',
                                background: 'linear-gradient(45deg, #F7B500 12.5%, #B620E0 54.13%, #32C5FF 95.83%)'
                              }}
                            ></span>
                          </div>
                          <div className="font-12 text-white single-product-wear">{`${item && RoundedPrice(item.fade_id)}%`}</div>
                        </>
                      )}
                    </div>

                    <div
                      className="single-product-image marketlist-product-image"
                      style={{
                        borderBottom: `5px solid ${item && item.item.r_color}`
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${item?.item?.imageUrl}`}
                        alt="zipit"
                        className="img-fluid similar-product-img"
                      />
                      <img src="/images/sell-modal/new-background-image.jpg" alt="Zipit" className="img-fluid sell-background" />
                    </div>

                    <div className="single-product-main-div">
                      <div className="d-flex gap-5">
                        {item && ['Sapphire', 'Ruby', 'Black Pearl', 'Emerald'].includes(item?.phase) && (
                          <div
                            className={`phases-id ${
                              item?.phase === 'Sapphire'
                                ? 'sapphire-phase-id'
                                : item?.phase === 'Ruby'
                                  ? 'ruby-phase-id'
                                  : item?.phase === 'Black Pearl'
                                    ? 'black-pearl-phase-id'
                                    : item?.phase === 'Emerald'
                                      ? 'emerald-phase-id'
                                      : ''
                            }`}
                          >
                            {item?.phase === 'Sapphire' && <img src="/images/market/sapphire-phase.svg" alt="sapphire-phase" />}
                            {item?.phase === 'Ruby' && <img src="/images/market/ruby.svg" alt="ruby-phase" />}
                            {item?.phase === 'Black Pearl' && <img src="/images/market/black-pearl.svg" alt="black-pearl-phase" />}
                            {item?.phase === 'Emerald' && <img src="/images/market/emerald.svg" alt="emerald-phase" />}
                            {item && item.phase && (
                              <div className="sticker-tooltip sticker-tooltip-top market-sticker-tooltip">
                                <p>{item?.phase}</p>
                              </div>
                            )}
                          </div>
                        )}
                        {item && Number(item.float_id) !== 0 && String(item.float_id).split('.')[1].substring(0, 2) === '00' && (
                          <div className="product-float-id">
                            <img src="/images/market/star-float.svg" alt="star-float" />
                            Float
                            {item && item.float_id && (
                              <div className="sticker-tooltip sticker-tooltip-top market-sticker-tooltip">
                                <p>low float</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="single-product-name">
                        <h3 className="pb-5 product-name">{item && itemNameConverter(item.item.fullname)}</h3>
                        <p>
                          {currency ? currency.symbol : defaultCurrency && defaultCurrency?.symbol}
                          {currency ? item && convertCurrency(item.startBid) : item && Number(item.startBid).toFixed(2)}
                        </p>
                      </div>
                      {item?.item?.stickers && item?.item?.stickers.length > 0 && (
                        <div className="previous-sales-sticker product-sticker">
                          {item.item &&
                            item?.item?.stickers.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <div className="single-sticker-div">
                                    <img src={item.value} alt="sticker" />
                                    {item.key && (
                                      <div className="sticker-tooltip sticker-tooltip-left">
                                        <span>{item.key}</span>
                                      </div>
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                        </div>
                      )}
                      {item.item.r_color && (
                        <div className="rarety-div ">
                          <span style={{ backgroundColor: `${item.item.r_color && item.item.r_color}` }}></span>
                          {item.item.rarity_name && (
                            <div className="sticker-tooltip sticker-tooltip-top market-sticker-tooltip">
                              <p>{item.item.rarity_name}</p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="main-single-market-product no-single-product">
                <p>No data available</p>
              </div>
            )}
          </>

          {Number(itemTotal) !== 0 &&
            isLoading &&
            skeletonItems.map((index) => (
              <div className="main-single-market-product" key={index}>
                <SkeletonLoader height={290} width="100%" color="#0b051b" highlightColor="#242347" className="" borderRadius={0} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

MarketSkin.propTypes = {
  market: PropTypes.array,
  getMarketData: PropTypes.func,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  clearMarketData: PropTypes.func,
  itemsPerPage: PropTypes.number,
  loading: PropTypes.bool,
  itemTotal: PropTypes.any,
  status: PropTypes.bool
};

import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { NotFound, Home } from 'view';
import { Auth } from 'auth/auth';
import { useAppRoutes } from 'hooks/useAppRoutes';
import { NavBar } from 'components';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

export const Content = () => {
  const { routes } = useAppRoutes();
  const Token = useSelector((state) => state.user.bearerToken);
  const isAuth = Cookies.get('Bearer_Token');
  useEffect(() => {
    // Add or remove the 'authenticated' class based on the isAuthenticated state
    if (isAuth !== undefined && isAuth !== '') {
      if (isAuth !== undefined && isAuth !== '') {
        document.body.classList.add('authenticated');
      } else {
        document.body?.classList.remove('authenticated');
      }
      // Clean up the effect
      return () => {
        document.body?.classList.remove('authenticated');
      };
    }
  }, [isAuth]);

  return (
    <Auth>
      <Routes>
        {routes}
        <Route
          path="/"
          element={
            <>
              {isAuth !== undefined && isAuth !== undefined ? <NavBar /> : null}
              <Home />
            </>
          }
        />
        <Route key="not-found" path="*" element={<NotFound />} />
      </Routes>
    </Auth>
  );
};

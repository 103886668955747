export function getCurrencyItems() {
  return getCurrency('/images/currency.json').then((result) => result.currency);
}

function getCurrency(endpoint) {
  const delay = (0.5 + Math.random() * 2) * 1000;
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      fetch(endpoint)
        .then((res) => {
          if (!res.ok) {
            throw new Error('Network response was not ok');
          }
          return res.json();
        })
        .then(resolve)
        .catch(reject);
    }, delay);
  });
}

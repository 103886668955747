import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import './style.css';
import { CheckBox } from 'components';
import { useSelector } from 'react-redux';
export const SideFilter = ({
  handlePriceChange,
  priceRange,
  sideBarOptions,
  handleWearCheck,
  applyFilters,
  clearFilters,
  selectedWears,
  itemCount,
  marketSortOrder,
  AuctionsortOrder,
  onSortOrder,
  onPriceChange,
  sortOrderFilter,
  filterWear,
  selectedPrice,
  filterPopular,
  onPopular,
  popular,
  onPatternId,
  onPatternIdAdd,
  patternIds,
  patternIdsArray,
  removePatternId,
  filterPatternId,
  onStickersSearch,
  filterPrice,
  setSortOrderFilter,
  PhaseArray,
  onPhase,
  selectedPhase,
  filterPhase,
  handleCategoryCheck,
  filterChildCategory,
  selectedChildCategory,
  searchStickers,
  filterStickers,
  clearSearch,
  resetFilters,
  sellType,
  isMobileFilter,
  isMobileFilterOpen,
  toggleMarketFilter,
  subMenuOpen,
  menuText,
  onToggleOpenMenu,
  isMobile,
  RarePattern,
  handleRarePatternCheck,
  selectedRarePattern,
  filterRarePattern,
  fadeRange,
  filterFade,
  handleFadeChange,
  selectedFade,
  isCateLoading,
  selectedCategory
}) => {
  // Sort Order Filter Dropdown
  const price = useSelector((state) => state.market.filterPrice);
  const fadeArray = useSelector((state) => state.market.filterFade);
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));

  const arrowImage = <img src="/images/market/next.svg" alt="Zipit" className="img-fluid" height={16} width={12} />;

  const [isSortOrder, setIsSortOrder] = useState();
  const [sortOrderLabel, setSortOrderLable] = useState();

  useEffect(() => {
    if (sellType === 'auction') {
      if (sortOrderFilter === 'runTime') {
        setIsSortOrder('runTime');
        setSortOrderFilter('runTime');
      } else {
        setIsSortOrder(sortOrderFilter);
        setSortOrderFilter(sortOrderFilter);
      }
    } else {
      if (sortOrderFilter === 'popularity') {
        setIsSortOrder('popularity');
        setSortOrderFilter('popularity');
      } else {
        setIsSortOrder(sortOrderFilter);
        setSortOrderFilter(sortOrderFilter);
      }
    }
  }, [sellType, sortOrderFilter]);

  useEffect(() => {
    if (sellType === 'auction') {
      const sortingName = AuctionsortOrder.filter((item) => {
        return item.value === isSortOrder;
      });
      setSortOrderLable(sortingName[0]);
    } else {
      const sortingName = marketSortOrder.filter((item) => {
        return item.value === isSortOrder;
      });
      setSortOrderLable(sortingName[0]);
    }
  }, [AuctionsortOrder, marketSortOrder, isSortOrder]);
  return (
    <div
      className={`market-filter inventory-table mobile-filter
        ${isMobileFilterOpen ? 'side-filter-menu-show' : ''}`}
    >
      {isMobile && isMobileFilterOpen && (
        <div className="back-text border-bottom-0" onClick={toggleMarketFilter}>
          <img src="/images/common/left-arrow.svg" alt="left-aarow" /> Back
        </div>
      )}
      <div className={`inventory-table-inner`}>
        {!isMobile && (
          <div className="category-item d-flex justify-content-between border-0">
            <div className="category text-white font-16" style={{ textTransform: 'capitalize' }}>
              {sellType}
            </div>
            {isCateLoading ? (
              ''
            ) : (
              <p className="item-count">
                ({itemCount} <span>items</span>)
              </p>
            )}
            {resetFilters > 0 && (
              <div className="category text-white font-16 reset-button" onClick={() => clearFilters('resetAllFilter')}>
                Reset
              </div>
            )}
          </div>
        )}

        <div className="market-sidebar-filter">
          <div
            className={`category-item d-flex justify-content-between `}
            onClick={() => {
              if (isMobileFilter) {
                onToggleOpenMenu('Sort');
              }
            }}
          >
            <div className="category-heading-div">
              <p className="text-white font-16">
                {sellType === 'auction' ? sortOrderLabel?.label ?? 'Ending Soon' : sortOrderLabel?.label ?? 'Newest'}
              </p>
            </div>

            <span>{arrowImage}</span>
            <div
              className={`dropdown-content
                ${subMenuOpen && menuText === 'Sort' ? 'show-filter-submenu' : ''}`}
            >
              <div className="dropdown-inner-content">
                <div className="checkbox-filter">
                  <div className="mobile-dropdown-back">
                    {subMenuOpen && menuText === 'Sort' && (
                      <p
                        className="text-white font-16"
                        onClick={() => {
                          if (isMobileFilter) {
                            onToggleOpenMenu('Sort');
                          }
                        }}
                      >
                        <img src="/images/common/left-arrow.svg" alt="left-aarow" />
                        Back
                      </p>
                    )}
                  </div>
                  {/* )} */}
                  <ul className="dropdown-ul">
                    {(sellType === 'auction' ? AuctionsortOrder : marketSortOrder).map((order, index) => (
                      <li key={index} onClick={() => onSortOrder(order.value)}>
                        {order.label}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={`category-item d-flex justify-content-between `}>
            <div
              className="category-heading-div"
              onClick={() => {
                if (isMobileFilter) {
                  onToggleOpenMenu('Price');
                }
              }}
            >
              {!isMobileFilter && filterPrice.length > 0 && (
                <span onClick={(e) => clearFilters('price')} className="remove-apply">
                  X
                </span>
              )}
              <p className="text-white font-16" style={{ paddingLeft: !isMobileFilter && filterPrice.length > 0 ? '33px' : '' }}>
                Price
              </p>
            </div>

            <span>{arrowImage}</span>
            <div
              className={`dropdown-content
                ${subMenuOpen && menuText === 'Price' ? 'show-filter-submenu' : ''}`}
            >
              <div className="dropdown-inner-content">
                <div className="dropdown-content-div">
                  <p
                    className="text-white font-16"
                    onClick={() => {
                      if (isMobileFilter) {
                        onToggleOpenMenu('Price');
                      }
                    }}
                  >
                    {subMenuOpen && menuText === 'Price' && (
                      <span>
                        <img src="/images/common/left-arrow.svg" alt="left-aarow" />
                      </span>
                    )}
                    Price
                  </p>
                  <div className="price-filter">
                    <div className="range-filter">
                      <RangeSlider
                        min={price[0]}
                        max={price[1]}
                        value={priceRange}
                        // defaultValue={filterPrice}
                        onInput={handlePriceChange}
                        className="range"
                      />
                    </div>
                    <div className="price-input-box">
                      <div className="price-box">
                        <span className="opacity-white">{currency ? currency.symbol : defaultCurrency && defaultCurrency.symbol}</span>
                        <p>{price[0]}</p>
                      </div>
                      <div className="price-box">
                        <span className="opacity-white">{currency ? currency.symbol : defaultCurrency && defaultCurrency.symbol}</span>
                        <p>{price[1]}</p>
                      </div>
                    </div>
                    <div className="price-inputs-main mt-20">
                      <div className="price-inputs">
                        <div className="input-label">From</div>
                        <div className="input-placeholder position-relative">
                          <span className="opacity-white">{currency ? currency.symbol : defaultCurrency && defaultCurrency.symbol}</span>
                          <input
                            placeholder="0"
                            className="text-white"
                            name="minPrice"
                            value={priceRange[0]}
                            onChange={(e) => onPriceChange(e)}
                          />
                        </div>
                      </div>
                      <div className="price-inputs">
                        <div className="input-label">To</div>
                        <div className="input-placeholder position-relative">
                          <span className="opacity-white">{currency ? currency.symbol : defaultCurrency && defaultCurrency.symbol}</span>
                          <input
                            placeholder="0"
                            className="text-white"
                            name="maxPrice"
                            value={priceRange[1]}
                            onChange={(e) => onPriceChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`price-filter-button apply-button 
                    ${selectedPrice.length > 0 ? '' : 'disabled'}`}
                  onClick={() => applyFilters('price')}
                >
                  Apply
                </div>
                <div
                  className="price-filter-button clear-button opacity-white"
                  onClick={() => {
                    if (isMobileFilter) {
                      onToggleOpenMenu('price');
                    }
                    clearFilters('price');
                  }}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
          <div className={`category-item d-flex justify-content-between`}>
            <div
              className="category-heading-div"
              onClick={() => {
                if (isMobileFilter) {
                  onToggleOpenMenu('Wear');
                }
              }}
            >
              {!isMobileFilter && filterWear.length > 0 && (
                <span onClick={() => clearFilters('wear')} className="remove-apply">
                  X
                </span>
              )}
              <p className="text-white font-16" style={{ paddingLeft: !isMobileFilter && filterWear.length > 0 ? '33px' : '' }}>
                Wear
              </p>
            </div>
            <span>{arrowImage}</span>
            <div
              className={`dropdown-content
                ${subMenuOpen && menuText === 'Wear' ? 'show-filter-submenu' : ''}`}
            >
              <div className="dropdown-inner-content">
                <div className="dropdown-content-div">
                  <p
                    className="text-white font-16 mb-10"
                    onClick={() => {
                      if (isMobileFilter) {
                        onToggleOpenMenu('Wear');
                      }
                    }}
                  >
                    {subMenuOpen && menuText === 'Wear' && (
                      <span>
                        <img src="/images/common/left-arrow.svg" alt="left-aarow" />
                      </span>
                    )}
                    Wear
                  </p>
                  <div className="checkbox-filter">
                    {sideBarOptions?.weardata &&
                      sideBarOptions?.weardata?.map((option, index) => (
                        <CheckBox
                          option={option}
                          key={index}
                          handleCheckbox={handleWearCheck}
                          checked={selectedWears.includes(option.name)}
                        />
                      ))}
                  </div>
                </div>
                <div
                  className={`price-filter-button apply-button 
                    ${selectedWears.length === 0 ? 'disabled' : ''}`}
                  // onClick={applyFilters}
                  onClick={() => applyFilters('wear')}
                >
                  Apply
                </div>
                <div
                  className="price-filter-button clear-button opacity-white"
                  onClick={() => {
                    if (isMobileFilter) {
                      onToggleOpenMenu('wear');
                    }
                    clearFilters('wear');
                  }}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
          <div className={`category-item d-flex justify-content-between `}>
            <div
              className="category-heading-div"
              onClick={() => {
                if (isMobileFilter) {
                  onToggleOpenMenu('PatternID');
                }
              }}
            >
              {!isMobileFilter && filterPatternId.length > 0 && (
                <span onClick={() => clearFilters('patternId')} className="remove-apply">
                  X
                </span>
              )}
              <p
                className="text-white font-16"
                style={{
                  paddingLeft: !isMobileFilter && filterPatternId.length > 0 ? '33px' : ''
                }}
              >
                Pattern ID
              </p>
            </div>
            <span>{arrowImage}</span>
            <div
              className={`dropdown-content
                ${subMenuOpen && menuText === 'PatternID' ? 'show-filter-submenu' : ''}`}
            >
              <div className="dropdown-inner-content">
                <div className="dropdown-content-div">
                  <p
                    className="text-white font-16 mb-10"
                    onClick={() => {
                      if (isMobileFilter) {
                        onToggleOpenMenu('PatternID');
                      }
                    }}
                  >
                    {subMenuOpen && menuText === 'PatternID' && (
                      <span>
                        <img src="/images/common/left-arrow.svg" alt="left-aarow" />
                      </span>
                    )}
                    Pattern ID
                  </p>
                  <div className="category-form">
                    <div className="form-control ">
                      <input
                        name="patternId"
                        type="text"
                        value={patternIds || ''}
                        onChange={(e) => onPatternId(e.target.value)}
                        autoComplete="off"
                      />
                      <button className="text-white form-button" disabled={!patternIds} onClick={onPatternIdAdd}>
                        Add
                      </button>
                    </div>
                    <div className="pattern-id-number-div">
                      {patternIdsArray &&
                        patternIdsArray.map((item, index) => {
                          return (
                            <div key={index} className="pattern-id-number">
                              {item}
                              <p onClick={(e) => removePatternId(item, index)} className="pattern-close">
                                X
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div
                  className={`price-filter-button apply-button 
                    ${patternIdsArray.length > 0 ? '' : 'disabled'}`}
                  onClick={() => applyFilters('patternId')}
                >
                  Apply
                </div>
                <div
                  className="price-filter-button clear-button opacity-white"
                  onClick={() => {
                    if (isMobileFilter) {
                      onToggleOpenMenu('patternId');
                    }
                    clearFilters('patternId');
                  }}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
          <div className={`category-item d-flex justify-content-between `}>
            <div
              className="category-heading-div"
              onClick={() => {
                if (isMobileFilter) {
                  onToggleOpenMenu('Popular');
                }
              }}
            >
              {!isMobileFilter && filterPopular === 'true' && (
                <span onClick={() => clearFilters('popular')} className="remove-apply">
                  X
                </span>
              )}
              <p
                className="text-white font-16"
                style={{
                  paddingLeft: !isMobileFilter && filterPopular === 'true' ? '33px' : ''
                }}
              >
                Popular
              </p>
            </div>
            <span>{arrowImage}</span>
            <div
              className={`dropdown-content
                ${subMenuOpen && menuText === 'Popular' ? 'show-filter-submenu' : ''}`}
            >
              <div className="dropdown-inner-content">
                <div className="dropdown-content-div">
                  <p
                    className="text-white font-16 mb-10"
                    onClick={() => {
                      if (isMobileFilter) {
                        onToggleOpenMenu('Popular');
                      }
                    }}
                  >
                    {subMenuOpen && menuText === 'Popular' && (
                      <span>
                        <img src="/images/common/left-arrow.svg" alt="left-aarow" />
                      </span>
                    )}
                    Popular
                  </p>
                  <div className="toggle-button">
                    <div className="setting-checkbox right-search-area">
                      <input
                        name="popular"
                        type="checkbox"
                        onChange={(e) => onPopular(e.target.checked)}
                        checked={popular === true || popular === 'true'}
                      />
                      <label>Show only popular items</label>
                    </div>
                  </div>
                </div>
                <div className={`price-filter-button apply-button`} onClick={() => applyFilters('popular')}>
                  Apply
                </div>
                <div
                  className="price-filter-button clear-button opacity-white"
                  onClick={() => {
                    if (isMobileFilter) {
                      onToggleOpenMenu('popular');
                    }
                    clearFilters('popular');
                  }}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
          <div className={`category-item d-flex justify-content-between `}>
            <div
              className="category-heading-div"
              onClick={() => {
                if (isMobileFilter) {
                  onToggleOpenMenu('RarePattern');
                }
              }}
            >
              {!isMobileFilter && filterRarePattern.length > 0 && (
                <span onClick={() => clearFilters('rare')} className="remove-apply">
                  X
                </span>
              )}
              <p
                className="text-white font-16"
                style={{
                  paddingLeft: !isMobileFilter && filterRarePattern.length > 0 ? '33px' : ''
                }}
              >
                Rare Pattern
              </p>
            </div>
            <span>{arrowImage}</span>
            <div
              className={`dropdown-content
                ${subMenuOpen && menuText === 'RarePattern' ? 'show-filter-submenu' : ''}`}
            >
              <div className="dropdown-inner-content">
                <div className="dropdown-content-div">
                  <p
                    className="text-white font-16 mb-10"
                    onClick={() => {
                      if (isMobileFilter) {
                        onToggleOpenMenu('RarePattern');
                      }
                    }}
                  >
                    {subMenuOpen && menuText === 'RarePattern' && (
                      <span>
                        <img src="/images/common/left-arrow.svg" alt="left-aarow" />
                      </span>
                    )}
                    Rare Pattern
                  </p>
                  <div className="checkbox-filter">
                    {RarePattern.map((option, index) => {
                      return (
                        <label className="sell-label" key={index}>
                          {option.name}
                          <input
                            type="checkbox"
                            name={option.name}
                            onChange={() => handleRarePatternCheck(option.value)}
                            checked={selectedRarePattern.includes(option.value)}
                          />
                          <span className="checkmark l-0"></span>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={`price-filter-button apply-button 
                    ${selectedRarePattern?.length === 0 ? 'disabled' : ''}`}
                  onClick={selectedRarePattern?.length > 0 ? () => applyFilters('rare') : null}
                >
                  Apply
                </div>
                <div className="price-filter-button clear-button opacity-white" onClick={() => clearFilters('rare')}>
                  Clear
                </div>
              </div>
            </div>
          </div>
          <div className={`category-item d-flex justify-content-between `}>
            <div
              className="category-heading-div"
              onClick={() => {
                if (isMobileFilter) {
                  onToggleOpenMenu('Stickers');
                }
              }}
            >
              {!isMobileFilter && filterStickers !== '' && (
                <span onClick={() => clearFilters('stickers')} className="remove-apply">
                  X
                </span>
              )}
              <p className="text-white font-16" style={{ paddingLeft: !isMobileFilter && filterStickers !== '' ? '33px' : '' }}>
                Stickers
              </p>
            </div>
            <span>{arrowImage}</span>
            <div
              className={`dropdown-content
                ${subMenuOpen && menuText === 'Stickers' ? 'show-filter-submenu' : ''}`}
            >
              <div className="dropdown-inner-content">
                <div className="dropdown-content-div">
                  <p
                    className="text-white font-16 mb-10"
                    onClick={() => {
                      if (isMobileFilter) {
                        onToggleOpenMenu('Stickers');
                      }
                    }}
                  >
                    {subMenuOpen && menuText === 'Stickers' && (
                      <span>
                        <img src="/images/common/left-arrow.svg" alt="left-aarow" />
                      </span>
                    )}
                    Stickers
                  </p>
                  <div className="dropdown-search">
                    <div className="header-search position-relative">
                      <img src="/images/header/header-search.svg" alt="Zipit" height="15" width="15" className="img-fluid search-icon" />
                      <input
                        type="text"
                        placeholder="Search for an item"
                        className=""
                        value={searchStickers || ''}
                        onChange={(e) => onStickersSearch(e)}
                      />
                      {searchStickers && (
                        <button className="clear-search-button" onClick={clearSearch}>
                          <img src="/images/common/whiteclose.svg" height={10} alt="Zipit" className="img-fluid" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`price-filter-button apply-button 
                    ${searchStickers === '' ? 'disabled' : ''}`}
                  onClick={() => applyFilters('stickers')}
                >
                  Apply
                </div>
                <div
                  className="price-filter-button clear-button opacity-white"
                  onClick={() => {
                    if (isMobileFilter) {
                      onToggleOpenMenu('stickers');
                    }
                    clearFilters('stickers');
                  }}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
          <div className={`category-item d-flex justify-content-between `}>
            <div
              className="category-heading-div"
              onClick={() => {
                if (isMobileFilter) {
                  onToggleOpenMenu('Phase');
                }
              }}
            >
              {!isMobileFilter && filterPhase.length > 0 && (
                <span onClick={() => clearFilters('Phase')} className="remove-apply">
                  X
                </span>
              )}
              <p className="text-white font-16" style={{ paddingLeft: !isMobileFilter && filterPhase.length > 0 ? '33px' : '' }}>
                Phase
              </p>
            </div>
            <span>{arrowImage}</span>
            <div
              className={`dropdown-content w-400 dropdown-bottom
                ${subMenuOpen && menuText === 'Phase' ? 'show-filter-submenu' : ''}`}
            >
              <div className="dropdown-inner-content">
                <div className="dropdown-content-div">
                  <p className="text-white font-16 mb-10">
                    {subMenuOpen && menuText === 'Phase' && (
                      <span
                        onClick={() => {
                          if (isMobileFilter) {
                            onToggleOpenMenu('Phase');
                          }
                        }}
                      >
                        <img src="/images/common/left-arrow.svg" alt="left-aarow" />
                      </span>
                    )}
                    Phase
                  </p>
                  <div className="checkbox-filter">
                    {PhaseArray.map((item, index) => {
                      return (
                        <label className="sell-label" key={index}>
                          {item}
                          <input type="checkbox" name={item} onChange={() => onPhase(item, index)} checked={selectedPhase.includes(item)} />
                          <span className="checkmark l-0"></span>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={`price-filter-button apply-button 
                    ${selectedPhase.length === 0 ? 'disabled' : ''}`}
                  onClick={() => applyFilters('Phase')}
                >
                  Apply
                </div>
                <div
                  className="price-filter-button clear-button opacity-white"
                  onClick={() => {
                    if (isMobileFilter) {
                      onToggleOpenMenu('Phase');
                    }
                    clearFilters('Phase');
                  }}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
          <div
            className={`category-item d-flex justify-content-between `}
            onClick={() => {
              if (isMobileFilter) {
                onToggleOpenMenu('Fade');
              }
            }}
          >
            {!isMobileFilter && filterFade.length > 0 && (
              <span onClick={() => clearFilters('fade')} className="remove-apply">
                X
              </span>
            )}
            <div className="category-heading-div" style={{ paddingLeft: !isMobileFilter && filterFade.length > 0 ? '33px' : '' }}>
              <p className="text-white font-16">Fade</p>
            </div>
            <span>{arrowImage}</span>
            <div
              className={`dropdown-content w-400 dropdown-bottom
                ${subMenuOpen && menuText === 'Fade' ? 'show-filter-submenu' : ''}`}
            >
              <div className="dropdown-inner-content">
                <div className="dropdown-content-div">
                  <p
                    className="text-white font-16"
                    onClick={() => {
                      if (isMobileFilter) {
                        onToggleOpenMenu('Fade');
                      }
                    }}
                  >
                    {subMenuOpen && menuText === 'Fade' && (
                      <span>
                        <img src="/images/common/left-arrow.svg" alt="left-aarow" />
                      </span>
                    )}
                    Fade
                  </p>
                  <div className="range-filter mt-30">
                    <RangeSlider
                      step={1}
                      min={fadeArray[0]}
                      max={fadeArray[1]}
                      value={fadeRange}
                      onInput={handleFadeChange}
                      className="range"
                    />
                  </div>

                  <div className="price-input-box">
                    <div className="price-box">
                      <p>{`${fadeRange[0]}%`}</p>
                    </div>
                    <div className="price-box">
                      <p>{`${fadeRange[1]}%`}</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`price-filter-button apply-button 
                    ${selectedFade.length === 0 ? 'disabled' : ''}`}
                  onClick={selectedFade.length > 0 ? () => applyFilters('fade') : null}
                >
                  Apply
                </div>
                <div
                  className="price-filter-button clear-button opacity-white"
                  onClick={() => {
                    if (isMobileFilter) {
                      onToggleOpenMenu('Fade');
                    }
                    clearFilters('fade');
                  }}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
          <div className={`category-item d-flex justify-content-between `}>
            <div
              className="category-heading-div"
              onClick={() => {
                if (isMobileFilter) {
                  onToggleOpenMenu('Category');
                }
              }}
            >
              {!isMobileFilter && filterChildCategory.length > 0 && (
                <span onClick={() => clearFilters('category')} className="remove-apply">
                  X
                </span>
              )}
              <p
                className="text-white font-16"
                style={{
                  paddingLeft: !isMobileFilter && filterChildCategory.length > 0 ? '33px' : ''
                }}
              >
                Category
              </p>
            </div>
            <span>{arrowImage}</span>
            <div
              className={`dropdown-content dropdown-bottom
                ${subMenuOpen && menuText === 'Category' ? 'show-filter-submenu' : ''}`}
            >
              <div className="dropdown-inner-content">
                <div className="dropdown-content-div">
                  <p
                    className="text-white font-16 mb-10"
                    onClick={() => {
                      if (isMobileFilter) {
                        onToggleOpenMenu('Category');
                      }
                    }}
                  >
                    {subMenuOpen && menuText === 'Category' && (
                      <span>
                        <img src="/images/common/left-arrow.svg" alt="left-aarow" />
                      </span>
                    )}
                    Category
                  </p>
                  <div className="checkbox-filter">
                    {sideBarOptions?.category &&
                      sideBarOptions?.category?.map((option, index) => (
                        <CheckBox
                          option={option}
                          key={index}
                          handleCheckbox={handleCategoryCheck}
                          checked={selectedChildCategory.includes(Number(option.id.toString()))}
                          // onChange={() => handleCheckbox(option.id.toString())} // Handle checkbox change
                        />
                      ))}
                  </div>
                </div>
                <div
                  className={`price-filter-button apply-button 
                    ${selectedChildCategory.length === 0 ? 'disabled' : ''}`}
                  onClick={() => applyFilters('category')}
                >
                  Apply
                </div>
                <div
                  className="price-filter-button clear-button opacity-white"
                  onClick={() => {
                    if (isMobileFilter) {
                      onToggleOpenMenu('category');
                    }
                    clearFilters('category');
                  }}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SideFilter.propTypes = {
  priceRange: PropTypes.array,
  handlePriceChange: PropTypes.func,
  sideBarOptions: PropTypes.object,
  handleWearCheck: PropTypes.func,
  applyFilters: PropTypes.func,
  selectedWears: PropTypes.arrayOf(PropTypes.string),
  clearFilters: PropTypes.func,
  itemCount: PropTypes.number,
  marketSortOrder: PropTypes.array,
  AuctionsortOrder: PropTypes.array,
  onSortOrder: PropTypes.func,
  onPriceChange: PropTypes.func,
  sortOrderFilter: PropTypes.string,
  filterWear: PropTypes.array,
  selectedPrice: PropTypes.array,
  onPopular: PropTypes.func,
  popular: PropTypes.any,
  filterPopular: PropTypes.any,
  onPatternId: PropTypes.func,
  onPatternIdAdd: PropTypes.func,
  patternIds: PropTypes.any,
  patternIdsArray: PropTypes.arrayOf(PropTypes.string),
  removePatternId: PropTypes.func,
  filterPatternId: PropTypes.array,
  onStickersSearch: PropTypes.func,
  filterPrice: PropTypes.any,
  setSortOrderFilter: PropTypes.func,
  PhaseArray: PropTypes.arrayOf(PropTypes.string),
  onPhase: PropTypes.func,
  filterPhase: PropTypes.array,
  selectedPhase: PropTypes.array,
  handleCategoryCheck: PropTypes.func,
  selectedChildCategory: PropTypes.array,
  filterChildCategory: PropTypes.array,
  filterStickers: PropTypes.string,
  searchStickers: PropTypes.string,
  clearSearch: PropTypes.func,
  resetFilters: PropTypes.number,
  sellType: PropTypes.string,
  isMobileFilter: PropTypes.bool,
  isMobileFilterOpen: PropTypes.bool,
  toggleMarketFilter: PropTypes.func,
  subMenuOpen: PropTypes.bool,
  menuText: PropTypes.string,
  onToggleOpenMenu: PropTypes.func,
  isMobile: PropTypes.bool,
  RarePattern: PropTypes.array,
  handleRarePatternCheck: PropTypes.func,
  selectedRarePattern: PropTypes.array,
  filterRarePattern: PropTypes.array,
  fadeRange: PropTypes.array,
  filterFade: PropTypes.array,
  handleFadeChange: PropTypes.func,
  selectedFade: PropTypes.array,
  isCateLoading: PropTypes.bool,
  selectedCategory: PropTypes.string
};

import React, { useEffect, useState } from 'react';
import { axiosAuthInstance, getNotification, notification } from 'api';
import { useApi } from 'hooks/useApi';
import { toast } from 'react-toastify';
import { SettingSideBar } from 'components';
import Cookies from 'js-cookie';

export const Notifications = () => {
  document.title = 'Zipit - Notifications';

  useEffect(() => {
    const Bearer_Token = Cookies.get('Bearer_Token');
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${Bearer_Token}`;
  }, []);

  const { data, apiCaller: onNotificationGet } = useApi({
    dataGetter: getNotification,
    autoRetrieve: true
  });

  useEffect(() => {
    if (data && data.data && data.data.n_details) {
      const { general, sellAuction, bidAuction } = data.data.n_details;
      setGeneral(general);
      setSellAuction(sellAuction);
      setBidAuction(bidAuction);
      // const newUserSettings = {
      //   notification: {
      //     email: data.data.email === '0' ? false : true,
      //     discord: data.data.discord === '0' ? false : true
      //   },
      //   general,
      //   sellAuction,
      //   bidAuction
      // };
      // localStorage.setItem('settings', JSON.stringify(newUserSettings));
    }
    // onNotificationGet();
  }, [data]);

  const settings = JSON.parse(localStorage.getItem('settings')) || {};

  const [general, setGeneral] = useState(
    settings.general || {
      new_and_feature_update: false,
      tips: false,
      last_logged: false
    }
  );

  const [sellAuction, setSellAuction] = useState(
    settings.sellAuction || {
      receive_bid_offer: false,
      sell_item: false,
      auction_ends_without_any_bids: false
    }
  );

  const [bidAuction, setBidAuction] = useState(
    settings.bidAuction || {
      outbid_auction: false,
      won_auction: false,
      lost_auction: false
    }
  );

  const [notifications, setNotifications] = useState(
    settings.notification || {
      email: false,
      discord: false
    }
  );

  const [shouldCallAPI, setShouldCallAPI] = useState(false);

  const { apiCaller: onNotificationData } = useApi({
    dataGetter: notification
  });

  const onGeneral = (e) => {
    setGeneral((prevGeneral) => ({
      ...prevGeneral,
      [e.target.name]: e.target.checked
    }));
    setShouldCallAPI(true);
  };

  const onSellAuction = (e) => {
    setSellAuction((prevSellAuction) => ({
      ...prevSellAuction,
      [e.target.name]: e.target.checked
    }));
    setShouldCallAPI(true);
  };

  const onBidAuction = (e) => {
    setBidAuction((prevBidAuction) => ({
      ...prevBidAuction,
      [e.target.name]: e.target.checked
    }));
    setShouldCallAPI(true);
  };

  const onNotification = (e) => {
    setNotifications({ ...notifications, [e.target.name]: e.target.checked });
    setShouldCallAPI(true);
  };
  const handleSubmit = () => {
    const notificationData = {
      general,
      sellAuction,
      bidAuction,
      email: notifications.email,
      discord: notifications.discord
    };

    onNotificationData(notificationData)
      .then((res) => {
        if (res.data && res.data.status === 'success') {
          toast.success(res.data.massage);
          onNotificationGet();
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    if (shouldCallAPI) {
      handleSubmit();
      setShouldCallAPI(false);
    }
  }, [shouldCallAPI]);

  return (
    <>
      <div className="profile-page-main-div">
        <div className="container-fluid">
          <div className="container">
            <div className="profile-page-inner-div">
              <SettingSideBar />
              <div className="profile-page-common-div notification-page">
                <div className="profile-page-heading">
                  <p>Notification</p>
                </div>
                <div className="common-page-inner-div  pb-60">
                  <div className="notification-div">
                    <div className="right-search-area setting-checkbox mb-0">
                      <span className="font-18 pb-0 mr-4">Email Notifications</span>
                      <input type="checkbox" name="email" onChange={onNotification} checked={notifications.email} />
                    </div>
                    <div className="first-section-content">
                      <div className="subsection">
                        <p className="font-20"> General </p>
                        <div className="condition-label">
                          <label className={`sell-label ${!general.new_and_feature_update ? 'notification-disabled' : ''}`}>
                            News about Zipit and feature updates
                            <input
                              type="checkbox"
                              name="new_and_feature_update"
                              onChange={onGeneral}
                              checked={general.new_and_feature_update}
                            />
                            <span className="checkmark l-0"></span>
                          </label>
                        </div>

                        <div className="condition-label">
                          <label className={`sell-label ${!general.tips ? 'notification-disabled' : ''}`}>
                            Tips on getting more out of Zipit
                            <input type="checkbox" name="tips" onChange={onGeneral} checked={general.tips} />
                            <span className="checkmark l-0"></span>
                          </label>
                        </div>
                        <div className="condition-label">
                          <label className={`sell-label ${!general.last_logged ? 'notification-disabled' : ''}`}>
                            Things you missed since you last logged into Zipit
                            <input type="checkbox" name="last_logged" onChange={onGeneral} checked={general.last_logged} />
                            <span className="checkmark l-0"></span>
                          </label>
                        </div>
                      </div>
                      <div className="sell-auction">
                        <div className="subsection border-bottom-0">
                          <p className="font-20"> Sell Auctions </p>
                          <div className="condition-label">
                            <label className={`sell-label ${!sellAuction.receive_bid_offer ? 'notification-disabled' : ''}`}>
                              When you receive a bid or an offer
                              <input
                                type="checkbox"
                                name="receive_bid_offer"
                                onChange={onSellAuction}
                                checked={sellAuction.receive_bid_offer}
                              />
                              <span className="checkmark l-0"></span>
                            </label>
                          </div>
                          <div className="condition-label">
                            <label className={`sell-label ${!sellAuction.sell_item ? 'notification-disabled' : ''}`}>
                              When you sell an item
                              <input type="checkbox" name="sell_item" onChange={onSellAuction} checked={sellAuction.sell_item} />
                              <span className="checkmark l-0"></span>
                            </label>
                          </div>

                          <div className="condition-label">
                            <label className={`sell-label ${!sellAuction.auction_ends_without_any_bids ? 'notification-disabled' : ''}`}>
                              When your auction ends without any bids
                              <input
                                type="checkbox"
                                name="auction_ends_without_any_bids"
                                onChange={onSellAuction}
                                checked={sellAuction.auction_ends_without_any_bids}
                              />
                              <span className="checkmark l-0"></span>
                            </label>
                          </div>
                        </div>
                        <div className="subsection border-bottom-0">
                          <p className="font-20"> Bid Auctions </p>
                          <div className="condition-label">
                            <label className={`sell-label ${!bidAuction.lost_auction ? 'notification-disabled' : ''}`}>
                              When you have lost an auction
                              <input type="checkbox" name="lost_auction" onChange={onBidAuction} checked={bidAuction.lost_auction} />
                              <span className="checkmark l-0"></span>
                            </label>
                          </div>
                          <div className="condition-label">
                            <label className={`sell-label ${!bidAuction.won_auction ? 'notification-disabled' : ''}`}>
                              When you have won an auction
                              <input type="checkbox" name="won_auction" onChange={onBidAuction} checked={bidAuction.won_auction} />
                              <span className="checkmark l-0"></span>
                            </label>
                          </div>
                          <div className="condition-label">
                            <label className={`sell-label ${!bidAuction.outbid_auction ? 'notification-disabled' : ''}`}>
                              When you were outbid on an auction
                              <input type="checkbox" name="outbid_auction" onChange={onBidAuction} checked={bidAuction.outbid_auction} />
                              <span className="checkmark l-0"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <div className="subsection">
                    <div className="setting-checkbox right-search-area ">
                      <label>Discord notifications</label>
                      <input
                        type="checkbox"
                        name="discord"
                        onChange={onNotification}
                        checked={notifications.discord}
                      />
                    </div>
                    <p className="opacity-white">
                      You need to connect to Discord on the Settings page to enable notifications.
                    </p>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

export const LoginWithSteamModal = ({ onClose }) => {
  const handleAuthSteam = () => {
    window.location.href = 'https://cms.zipit.absd.dk/auth';
  };
  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="edit-price-modal">
      <div className="steam-account-popup position-relative text-center">
        <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={() => onClose()}>
          <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
        </button>
        <p className="offer-heading">Log in to continue</p>
        <p className="steam-account-text mt-20">You need to login to Zipit with your steam account to continue.</p>

        <div className="offer-button-div mt-30">
          <div className="w-100 white-button text-dark font-700 font-16 text-center" onClick={handleAuthSteam}>
            <img src="/images/header/steam-login.svg" alt="steam-login" className="img-fluid" />
            Login with Steam
          </div>
        </div>
        <p className="font-14 mt-10 text-white opcaity-5">This site is not associated with Valve Corp.</p>
      </div>
    </aside>
  );
};

LoginWithSteamModal.propTypes = {
  onClose: PropTypes.func
};

import React from 'react';

export const ArrowIcon = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        width: '16px',
        height: ' 16px',
        fill: 'currentcolor'
      }}
    >
      <g id="icon_home_arrow" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path
          d="M6.49856562,3.09386651 L6.58578644,3.17157288 L10.8284271,7.41421356 C10.9786288,7.56441521 11.0710606,7.75051046 11.1057225,7.94488884 C11.143067,8.15862117 11.1122705,8.37837176 11.0139461,8.57271875 L10.9778339,8.63789909 L10.9778339,8.63789909 C10.9560396,8.67390659 10.9322777,8.70819081 10.9061335,8.74120631 L10.8284271,8.82842712 L6.58578644,13.0710678 C6.19526215,13.4615921 5.56209717,13.4615921 5.17157288,13.0710678 C4.80894318,12.7084381 4.78304105,12.1365927 5.09386651,11.7440751 L5.17157288,11.6568542 L8.70667966,8.12067966 L5.17157288,4.58578644 C4.78104858,4.19526215 4.78104858,3.56209717 5.17157288,3.17157288 C5.53420257,2.80894318 6.10604797,2.78304105 6.49856562,3.09386651 Z"
          id="Path"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const LogoutIcon = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7735 17.1562C11.7735 17.38 11.6846 17.5946 11.5264 17.7529C11.3682 17.9111 11.1536 18 10.9298 18H2.97559C2.22992 17.9992 1.51504 17.7026 0.987773 17.1753C0.460508 16.648 0.163923 15.9332 0.163086 15.1875V2.8125C0.163923 2.06684 0.460508 1.35195 0.987773 0.824687C1.51504 0.297423 2.22992 0.00083746 2.97559 0L10.9298 0C11.1536 0 11.3682 0.0888948 11.5264 0.247129C11.6846 0.405362 11.7735 0.619974 11.7735 0.84375C11.7735 1.06753 11.6846 1.28214 11.5264 1.44037C11.3682 1.59861 11.1536 1.6875 10.9298 1.6875H2.97559C2.67732 1.68783 2.39136 1.80646 2.18045 2.01736C1.96954 2.22827 1.85091 2.51423 1.85059 2.8125V15.1875C1.8509 15.4858 1.96953 15.7717 2.18044 15.9826C2.39135 16.1936 2.67731 16.3122 2.97559 16.3125H10.9298C11.1536 16.3125 11.3682 16.4014 11.5264 16.5596C11.6846 16.7179 11.7735 16.9325 11.7735 17.1562ZM15.5899 8.4034L11.5803 4.39376C11.5022 4.31397 11.4091 4.25046 11.3064 4.2069C11.2037 4.16333 11.0933 4.14058 10.9817 4.13996C10.8701 4.13935 10.7595 4.16087 10.6563 4.2033C10.5531 4.24572 10.4593 4.3082 10.3804 4.38711C10.3015 4.46603 10.239 4.55982 10.1966 4.66304C10.1542 4.76626 10.1327 4.87688 10.1333 4.98847C10.1339 5.10007 10.1567 5.21043 10.2003 5.31317C10.2438 5.4159 10.3074 5.50898 10.3872 5.587L12.9563 8.15625H6.31399C6.09021 8.15625 5.8756 8.24514 5.71737 8.40338C5.55913 8.56161 5.47024 8.77622 5.47024 9C5.47024 9.22378 5.55913 9.43839 5.71737 9.59662C5.8756 9.75486 6.09021 9.84375 6.31399 9.84375H12.9562L10.387 12.413C10.3083 12.4912 10.2458 12.5843 10.2031 12.6867C10.1605 12.7892 10.1384 12.8991 10.1383 13.0101C10.1381 13.1211 10.1599 13.231 10.2023 13.3336C10.2447 13.4361 10.3069 13.5293 10.3854 13.6078C10.4639 13.6863 10.5571 13.7485 10.6597 13.791C10.7622 13.8334 10.8722 13.8551 10.9832 13.855C11.0942 13.8548 11.204 13.8328 11.3065 13.7901C11.409 13.7474 11.502 13.6849 11.5803 13.6062L15.5899 9.59664C15.7481 9.4384 15.837 9.22379 15.837 9.00002C15.837 8.77624 15.7481 8.56163 15.5899 8.4034Z"
      fill="rgb(255 255 255 / 40%)"
    />
  </svg>
);

export const WishlistIcon = () => (
  <svg width="22" height="22" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.2547 3.11859C19.1159 1.88341 17.5532 1.20312 15.8543 1.20312C14.5844 1.20312 13.4214 1.60461 12.3975 2.39635C11.8809 2.79599 11.4127 3.28493 11 3.85561C10.5874 3.2851 10.1191 2.79599 9.60234 2.39635C8.57864 1.60461 7.41563 1.20312 6.14571 1.20312C4.44676 1.20312 2.88394 1.88341 1.7451 3.11859C0.619858 4.33934 0 6.00706 0 7.81477C0 9.67535 0.693375 11.3785 2.18201 13.1748C3.5137 14.7816 5.42766 16.4127 7.64407 18.3015C8.40089 18.9465 9.25876 19.6777 10.1495 20.4565C10.3848 20.6626 10.6868 20.7761 11 20.7761C11.313 20.7761 11.6152 20.6626 11.8501 20.4568C12.7409 19.6778 13.5993 18.9464 14.3564 18.301C16.5725 16.4126 18.4865 14.7816 19.8182 13.1746C21.3068 11.3785 22 9.67535 22 7.81461C22 6.00706 21.3801 4.33934 20.2547 3.11859Z"
      fill="#D73838"
    />
  </svg>
);
export const Setting = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4611 10.9725L15.8066 9.67876C15.8276 9.47925 15.8456 9.24602 15.8456 8.99926C15.8456 8.7525 15.8283 8.51927 15.8066 8.31976L17.4626 7.02527C17.7701 6.78227 17.8541 6.35101 17.6583 5.99179L15.9378 3.015C15.7541 2.67901 15.3513 2.5065 14.9426 2.65725L12.9903 3.44099C12.6161 3.17099 12.2238 2.943 11.8181 2.76075L11.5211 0.690012C11.4723 0.297 11.1303 0 10.7261 0H7.27611C6.87185 0 6.53062 0.297 6.4826 0.684L6.18486 2.76226C5.79185 2.93927 5.40562 3.16427 5.01486 3.4425L3.05736 2.65651C2.69058 2.51476 2.25183 2.67374 2.06958 3.00825L0.34685 5.98876C0.143612 6.33227 0.2276 6.77851 0.541862 7.02752L2.19635 8.32127C2.17009 8.57401 2.15736 8.79377 2.15736 9.00004C2.15736 9.2063 2.17012 9.42602 2.19635 9.67954L0.54035 10.974C0.232838 11.2178 0.149588 11.649 0.345338 12.0075L2.06585 14.9843C2.24961 15.3195 2.6486 15.4935 3.06109 15.342L5.01335 14.5583C5.38685 14.8275 5.77909 15.0555 6.18486 15.2385L6.48186 17.3085C6.53059 17.703 6.87185 18 7.27685 18H10.7268C11.1311 18 11.4731 17.703 11.5211 17.316L11.8188 15.2385C12.2118 15.0608 12.5973 14.8365 12.9888 14.5575L14.9463 15.3435C15.0408 15.3802 15.1391 15.399 15.2403 15.399C15.5313 15.399 15.7991 15.24 15.9341 14.9925L17.6621 12C17.8541 11.649 17.7701 11.2178 17.4611 10.9725ZM9.00109 12C7.3466 12 6.0011 10.6545 6.0011 9C6.0011 7.34551 7.3466 6.00001 9.00109 6.00001C10.6556 6.00001 12.0011 7.34551 12.0011 9C12.0011 10.6545 10.6556 12 9.00109 12Z"
      fill="rgb(255 255 255 / 40%)"
    />
    <defs>
      <clipPath id="clip0_870_564">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const Notification = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Vector"
        d="M16.0343 12.6848C14.9003 11.7263 14.25 10.3253 14.25 8.841V6.75C14.25 4.11075 12.2895 1.926 9.75 1.56V0.75C9.75 0.33525 9.414 0 9 0C8.586 0 8.25 0.33525 8.25 0.75V1.56C5.70975 1.926 3.75 4.11075 3.75 6.75V8.841C3.75 10.3253 3.09975 11.7263 1.959 12.6908C1.66725 12.9405 1.5 13.3035 1.5 13.6875C1.5 14.4113 2.08875 15 2.8125 15H15.1875C15.9113 15 16.5 14.4113 16.5 13.6875C16.5 13.3035 16.3328 12.9405 16.0343 12.6848Z"
        fill="rgb(255 255 255 / 40%)"
      />
      <path
        id="Vector_2"
        d="M8.99964 18C10.3579 18 11.4941 17.0317 11.7551 15.75H6.24414C6.50514 17.0317 7.64139 18 8.99964 18Z"
        fill="rgb(255 255 255 / 40%)"
      />
    </g>
    <defs>
      <clipPath id="clip0_526_315">
        <rect width="18" height="18" fill="rgb(255 255 255 / 40%)" />
      </clipPath>
    </defs>
  </svg>
);

export const Following = () => (
  <svg width="18" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 21.3784C11.8786 21.3784 11.7572 21.347 11.6485 21.2842C11.5304 21.2161 8.72392 19.5866 5.8772 17.1314C4.18999 15.6763 2.84318 14.233 1.87427 12.8417C0.620464 11.0415 -0.0100507 9.3098 0.000121129 7.69482C0.0120274 5.8156 0.685104 4.04833 1.89551 2.71848C3.12635 1.36624 4.76894 0.621582 6.5208 0.621582C8.76596 0.621582 10.8187 1.87924 12.0001 3.87151C13.1814 1.87928 15.2341 0.621582 17.4793 0.621582C19.1344 0.621582 20.7134 1.29349 21.9258 2.51355C23.2563 3.85243 24.0123 5.74421 23.9999 7.70372C23.9897 9.31589 23.3473 11.0449 22.0908 12.8427C21.1189 14.2333 19.7739 15.6759 18.0933 17.1306C15.257 19.5856 12.4707 21.215 12.3535 21.2831C12.2442 21.3466 12.1221 21.3784 12 21.3784Z"
      fill="rgb(255 255 255 / 40%)"
    />
  </svg>
);

export const Store = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g opacity="0.3">
        <path
          d="M23.9958 8H0V9C0 9.53043 0.210714 10.0391 0.585786 10.4142C0.960859 10.7893 1.46957 11 2 11H5C5.53043 11 6.03914 10.7893 6.41421 10.4142C6.78929 10.0391 7 9.53043 7 9C7 9.53043 7.21071 10.0391 7.58579 10.4142C7.96086 10.7893 8.46957 11 9 11H15C15.5304 11 16.0391 10.7893 16.4142 10.4142C16.7893 10.0391 17 9.53043 17 9C17 9.53043 17.2107 10.0391 17.5858 10.4142C17.9609 10.7893 18.4696 11 19 11H22C22.5304 11 23.0391 10.7893 23.4142 10.4142C23.7893 10.0391 24 9.53043 24 9V8H23.9958Z"
          fill="white"
        />
        <path
          d="M23.7544 7L21.9617 0.725C21.9019 0.516107 21.7758 0.332356 21.6023 0.201547C21.4288 0.0707382 21.2174 -1.21163e-05 21.0002 1.55638e-09L16.4199 1.55638e-09L17.8199 7H23.7544Z"
          fill="white"
        />
        <path
          d="M7.58011 1.55638e-09H2.99986C2.78259 -1.21163e-05 2.57122 0.0707382 2.39774 0.201547C2.22426 0.332356 2.0981 0.516107 2.03836 0.725L0.245605 7H6.18011L7.58011 1.55638e-09Z"
          fill="white"
        />
        <path d="M14.3802 0H9.61973L8.21973 7H15.7802L14.3802 0Z" fill="white" />
        <path
          d="M19 11.9999C18.2616 12.0005 17.5492 11.7277 17 11.2341C16.4508 11.7277 15.7384 12.0005 15 11.9999H9C8.26162 12.0005 7.54917 11.7277 7 11.2341C6.45083 11.7277 5.73838 12.0005 5 11.9999H2C1.65911 11.9992 1.32087 11.94 1 11.8249V22.9999C1 23.2651 1.10536 23.5195 1.29289 23.707C1.48043 23.8945 1.73478 23.9999 2 23.9999H4V14.9999C4 14.7347 4.10536 14.4803 4.29289 14.2928C4.48043 14.1052 4.73478 13.9999 5 13.9999H10C10.2652 13.9999 10.5196 14.1052 10.7071 14.2928C10.8946 14.4803 11 14.7347 11 14.9999V23.9999H22C22.2652 23.9999 22.5196 23.8945 22.7071 23.707C22.8946 23.5195 23 23.2651 23 22.9999V11.8249C22.6791 11.94 22.3409 11.9992 22 11.9999H19ZM20 17.9999C20 18.2651 19.8946 18.5195 19.7071 18.707C19.5196 18.8945 19.2652 18.9999 19 18.9999H14C13.7348 18.9999 13.4804 18.8945 13.2929 18.707C13.1054 18.5195 13 18.2651 13 17.9999V14.9999C13 14.7347 13.1054 14.4803 13.2929 14.2928C13.4804 14.1052 13.7348 13.9999 14 13.9999H19C19.2652 13.9999 19.5196 14.1052 19.7071 14.2928C19.8946 14.4803 20 14.7347 20 14.9999V17.9999Z"
          fill="white"
        />
      </g>
    </g>
    <defs></defs>
  </svg>
);
export const SteamIcon = () => (
  // <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <g opacity="0.3">
  //     <path
  //       id="white"
  //       d="M0.530346 16.7491C2.13769 22.1085 7.10892 26.0167 12.9915 26.0167C20.1758 26.0167 25.9999 20.1926 25.9999 13.0084C25.9999 5.82409 20.1758 0 12.9915 0C6.09793 0 0.456767 5.36311 0.0112305 12.1441C0.854335 13.5576 1.18158 14.432 0.529533 16.7491H0.530346Z"
  //       fill="white"
  //     />
  //     <path
  //       id="black"
  //       d="M12.3213 9.75014L12.3225 9.81437L9.13955 14.4384C8.62409 14.4149 8.10701 14.5055 7.61594 14.7075C7.40217 14.7944 7.19808 14.9034 7.00699 15.0328L0.0170229 12.1518C0.0170229 12.1518 -0.144769 14.8128 0.529227 16.7958L5.47078 18.8349C5.71469 19.9438 6.47893 20.9162 7.60171 21.3837C8.48504 21.7507 9.47793 21.7521 10.3623 21.3877C11.2467 21.0232 11.9503 20.3226 12.3185 19.4397C12.5149 18.9695 12.6097 18.463 12.5965 17.9535L17.1466 14.6994L17.2584 14.7015C19.9841 14.7015 22.1995 12.4795 22.1995 9.75014C22.1995 7.02082 19.9881 4.80696 17.2584 4.80127C14.534 4.80127 12.3172 7.02082 12.3172 9.75014H12.3213ZM11.5571 19.1202C10.966 20.543 9.33508 21.2125 7.91757 20.6219C7.28854 20.3575 6.77868 19.8711 6.48503 19.2552L8.09359 19.9219C8.34208 20.0253 8.60852 20.0788 8.87768 20.0792C9.14684 20.0796 9.41345 20.027 9.66227 19.9244C9.91109 19.8217 10.1372 19.671 10.3278 19.481C10.5184 19.2909 10.6696 19.0651 10.7729 18.8166C10.9821 18.3143 10.9833 17.7496 10.7764 17.2465C10.5694 16.7433 10.1712 16.3429 9.66923 16.1332L8.00253 15.4441C8.64401 15.2002 9.37329 15.1921 10.0554 15.4754C10.7465 15.7612 11.275 16.2994 11.5579 16.9877C11.8408 17.6759 11.8392 18.4349 11.5538 19.1202M17.2629 13.049C16.3895 13.0466 15.5526 12.6982 14.9356 12.08C14.3186 11.4619 13.9717 10.6244 13.9709 9.75095C13.9719 8.87769 14.3189 8.04039 14.9359 7.4224C15.5529 6.80442 16.3896 6.4561 17.2629 6.45374C18.1363 6.45589 18.9732 6.80411 19.5904 7.42212C20.2076 8.04013 20.5546 8.87755 20.5556 9.75095C20.5549 10.6245 20.2079 11.4621 19.5907 12.0803C18.9735 12.6985 18.1364 13.0468 17.2629 13.049ZM14.7962 9.74567C14.7956 9.08944 15.0556 8.45984 15.5191 7.99524C15.9826 7.53064 16.6115 7.26906 17.2677 7.26798C18.6316 7.26798 19.741 8.37776 19.741 9.74567C19.7413 10.402 19.4809 11.0317 19.0172 11.4961C18.5534 11.9606 17.9241 12.2219 17.2677 12.2225C16.6116 12.2216 15.9826 11.9602 15.5191 11.4957C15.0556 11.0313 14.7958 10.4018 14.7962 9.74567Z"
  //       fill="#38353F"
  //     />
  //   </g>
  // </svg>
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.571538 18.0388C2.30265 23.8109 7.65666 28.02 13.9922 28.02C21.7297 28.02 28.0022 21.7475 28.0022 14.01C28.0022 6.27254 21.7297 0 13.9922 0C6.56782 0 0.492294 5.77606 0.0124512 13.0792C0.920474 14.6015 1.27291 15.5432 0.570662 18.0388H0.571538Z"
      fill="white"
    />
    <path
      d="M13.27 10.5011L13.2714 10.5702L9.84328 15.5504C9.28814 15.525 8.73124 15.6226 8.20236 15.8402C7.97214 15.9338 7.75232 16.0511 7.54652 16.1904L0.0183336 13.0877C0.0183336 13.0877 -0.155916 15.9536 0.569977 18.0892L5.89203 20.2853C6.15471 21.4797 6.9778 22.5269 8.18704 23.0304C9.13838 23.4257 10.2077 23.4272 11.1602 23.0347C12.1127 22.6421 12.8704 21.8876 13.267 20.9368C13.4785 20.4303 13.5806 19.8848 13.5664 19.3361L18.4669 15.8314L18.5873 15.8336C21.5228 15.8336 23.9089 13.4405 23.9089 10.5011C23.9089 7.5616 21.5272 5.17727 18.5873 5.17114C15.6531 5.17114 13.2657 7.5616 13.2657 10.5011H13.27ZM12.447 20.5926C11.8104 22.125 10.0539 22.8461 8.52722 22.2099C7.84976 21.9252 7.30063 21.4013 6.98437 20.738L8.71679 21.456C8.98442 21.5674 9.27137 21.625 9.56126 21.6255C9.85114 21.6259 10.1383 21.5693 10.4063 21.4587C10.6742 21.3482 10.9178 21.1859 11.123 20.9812C11.3283 20.7764 11.4912 20.5333 11.6024 20.2656C11.8277 19.7247 11.829 19.1165 11.6061 18.5746C11.3832 18.0328 10.9544 17.6015 10.4138 17.3756L8.61872 16.6335C9.30959 16.3708 10.095 16.3621 10.8297 16.6672C11.574 16.975 12.1431 17.5547 12.4478 18.2959C12.7525 19.0371 12.7508 19.8545 12.4435 20.5926M18.5921 14.0539C17.6514 14.0514 16.7501 13.6761 16.0856 13.0104C15.4211 12.3446 15.0475 11.4426 15.0467 10.5019C15.0477 9.56144 15.4214 8.65967 16.0859 7.9941C16.7504 7.32853 17.6516 6.9534 18.5921 6.95085C19.5327 6.95317 20.4341 7.3282 21.0988 7.99379C21.7635 8.65939 22.1373 9.56129 22.1384 10.5019C22.1376 11.4428 21.7639 12.3449 21.0991 13.0107C20.4344 13.6765 19.5329 14.0516 18.5921 14.0539ZM15.9354 10.4963C15.9349 9.78949 16.2149 9.11142 16.7141 8.61104C17.2132 8.11067 17.8906 7.82895 18.5973 7.82779C20.0662 7.82779 21.261 9.02302 21.261 10.4963C21.2613 11.2032 20.9809 11.8813 20.4815 12.3815C19.982 12.8818 19.3043 13.1632 18.5973 13.1638C17.8907 13.1628 17.2133 12.8813 16.7141 12.3811C16.2149 11.8809 15.9351 11.2029 15.9354 10.4963Z"
      fill="url(#paint0_linear_3994_178)"
    />
    <defs>
      <linearGradient id="paint0_linear_3994_178" x1="24.5413" y1="15.3273" x2="2.82636" y2="19.5344" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7000FF" />
        <stop offset="1" stopColor="#AB2FFE" />
      </linearGradient>
    </defs>
  </svg>
);

export const Question = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M9 0C4.02571 0 0 4.02525 0 9C0 13.9742 4.02525 18 9 18C13.9743 18 18 13.9747 18 9C18 4.02571 13.9747 0 9 0ZM9 16.7442C4.72985 16.7442 1.25582 13.2702 1.25582 9C1.25582 4.72982 4.72985 1.25582 9 1.25582C13.2702 1.25582 16.7442 4.72982 16.7442 9C16.7442 13.2702 13.2702 16.7442 9 16.7442Z"
        fill="white"
      />
      <path
        d="M8.73336 11.3879C8.23579 11.3879 7.83301 11.8026 7.83301 12.3001C7.83301 12.7859 8.22395 13.2123 8.73336 13.2123C9.24277 13.2123 9.64552 12.7859 9.64552 12.3001C9.64552 11.8026 9.23089 11.3879 8.73336 11.3879Z"
        fill="white"
      />
      <path
        d="M8.88752 4.48132C7.28819 4.48132 6.55371 5.4291 6.55371 6.0688C6.55371 6.53083 6.94465 6.74408 7.2645 6.74408C7.90424 6.74408 7.64363 5.83185 8.85198 5.83185C9.44429 5.83185 9.9182 6.0925 9.9182 6.63746C9.9182 7.27716 9.25477 7.6444 8.86383 7.9761C8.52025 8.27222 8.07011 8.75798 8.07011 9.7768C8.07011 10.3928 8.23597 10.5705 8.72166 10.5705C9.30212 10.5705 9.4206 10.3099 9.4206 10.0848C9.4206 9.46876 9.43245 9.11337 10.084 8.60396C10.4039 8.35519 11.4108 7.54958 11.4108 6.43601C11.4108 5.32244 10.4039 4.48132 8.88752 4.48132Z"
        fill="white"
      />
    </g>
    <defs></defs>
  </svg>
);
export const Bell = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="bell 1">
      <g id="Group">
        <path
          id="Vector"
          d="M16.0343 12.6848C14.9003 11.7263 14.25 10.3253 14.25 8.841V6.75C14.25 4.11075 12.2895 1.926 9.75 1.56V0.75C9.75 0.33525 9.414 0 9 0C8.586 0 8.25 0.33525 8.25 0.75V1.56C5.70975 1.926 3.75 4.11075 3.75 6.75V8.841C3.75 10.3253 3.09975 11.7263 1.959 12.6908C1.66725 12.9405 1.5 13.3035 1.5 13.6875C1.5 14.4113 2.08875 15 2.8125 15H15.1875C15.9113 15 16.5 14.4113 16.5 13.6875C16.5 13.3035 16.3328 12.9405 16.0343 12.6848Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M8.99964 18C10.3579 18 11.4941 17.0317 11.7551 15.75H6.24414C6.50514 17.0317 7.64139 18 8.99964 18Z"
          fill="white"
        />
      </g>
    </g>
    <defs></defs>
  </svg>
);

export const DownArrow = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="down-arrow 1">
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M11.8082 3.04845L11.4151 2.65264C11.2914 2.52903 11.1268 2.46093 10.9509 2.46093C10.7751 2.46093 10.6103 2.52903 10.4866 2.65264L6.00268 7.13678L1.51357 2.64767C1.39006 2.52406 1.22528 2.45605 1.04947 2.45605C0.873666 2.45605 0.708786 2.52406 0.585176 2.64767L0.192002 3.04104C-0.0640005 3.29684 -0.0640005 3.71353 0.192002 3.96934L5.53683 9.33338C5.66034 9.45689 5.82493 9.54392 6.00229 9.54392H6.00434C6.18025 9.54392 6.34483 9.45679 6.46835 9.33338L11.8082 3.98387C11.9319 3.86036 11.9998 3.6908 12 3.51499C12 3.33909 11.9319 3.17187 11.8082 3.04845Z"
            fill="white"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const UpArrow = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8082 8.95155L11.4151 9.34736C11.2914 9.47097 11.1268 9.53907 10.9509 9.53907C10.7751 9.53907 10.6103 9.47097 10.4866 9.34736L6.00268 4.86322L1.51357 9.35233C1.39006 9.47594 1.22528 9.54395 1.04947 9.54395C0.873666 9.54395 0.708786 9.47594 0.585176 9.35233L0.192002 8.95896C-0.0640005 8.70316 -0.0640005 8.28647 0.192002 8.03066L5.53683 2.66662C5.66034 2.54311 5.82493 2.45608 6.00229 2.45608H6.00434C6.18025 2.45608 6.34483 2.54321 6.46835 2.66662L11.8082 8.01613C11.9319 8.13964 11.9998 8.3092 12 8.48501C12 8.66091 11.9319 8.82813 11.8082 8.95155Z"
      fill="black"
    />
  </svg>
);

export const Deposit = () => (
  <svg width="18" height="18" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 0.25H3.5C1.85 0.25 0.5 1.6 0.5 3.25C0.5 4.525 1.25 5.575 2.375 6.025V3.25C2.375 2.95 2.525 2.65 2.675 2.425C2.825 2.2 3.2 2.125 3.5 2.125H12.5C12.8 2.125 13.1 2.275 13.325 2.425C13.55 2.575 13.625 2.95 13.625 3.25V6.025C14.75 5.575 15.5 4.525 15.5 3.25C15.5 1.6 14.15 0.25 12.5 0.25ZM12.5 12.25V3.25H3.5V12.25C3.5 13.075 4.175 13.75 5 13.75H11C11.825 13.75 12.5 13.075 12.5 12.25ZM7.4 7.3L6.875 7.825C6.65 8.05 6.275 8.05 6.05 7.825C5.825 7.6 5.825 7.225 6.05 7L7.025 6.025C7.55 5.5 8.375 5.5 8.9 6.025L9.875 7C10.1 7.225 10.1 7.6 9.875 7.825C9.65 8.05 9.275 8.05 9.05 7.825L8.525 7.3V10.45C8.525 10.75 8.3 11.05 7.925 11.05C7.55 11.05 7.325 10.825 7.325 10.45V7.3H7.4Z"
      fill="rgb(255 255 255 / 40%)"
    />
  </svg>
);

export const Withdraw = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0625 16.5938H3.9375C2.08125 16.5938 0.5625 15.075 0.5625 13.2188C0.5625 11.7844 1.40625 10.6031 2.67188 10.0969V13.2188C2.67188 13.5563 2.84062 13.8938 3.00937 14.1469C3.17812 14.4 3.6 14.4844 3.9375 14.4844H14.0625C14.4 14.4844 14.7375 14.3156 14.9906 14.1469C15.2437 13.9781 15.3281 13.5563 15.3281 13.2188V10.0969C16.5938 10.6031 17.4375 11.7844 17.4375 13.2188C17.4375 15.075 15.9187 16.5938 14.0625 16.5938ZM14.0625 3.09375V13.2188H3.9375V3.09375C3.9375 2.16563 4.69688 1.40625 5.625 1.40625H12.375C13.3031 1.40625 14.0625 2.16563 14.0625 3.09375ZM8.325 8.6625L7.73438 8.07188C7.48125 7.81875 7.05938 7.81875 6.80625 8.07188C6.55313 8.325 6.55313 8.74688 6.80625 9L7.90313 10.0969C8.49375 10.6875 9.42187 10.6875 10.0125 10.0969L11.1094 9C11.3625 8.74688 11.3625 8.325 11.1094 8.07188C10.8563 7.81875 10.4344 7.81875 10.1813 8.07188L9.59062 8.6625V5.11875C9.59062 4.78125 9.3375 4.44375 8.91563 4.44375C8.49375 4.44375 8.24062 4.69688 8.24062 5.11875V8.6625H8.325Z"
      fill="rgb(255 255 255 / 40%)"
    />
  </svg>
);

export const Transactions = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3281 18H2.67188C3.25438 18 3.72656 17.5278 3.72656 16.9453C3.72656 16.557 4.04135 16.2422 4.42969 16.2422H15.6797C16.068 16.2422 16.3828 16.557 16.3828 16.9453C16.3828 17.5278 15.9106 18 15.3281 18Z"
      fill="rgb(255 255 255 / 40%)"
    />
    <path
      d="M14.2489 0.52084C14.0902 0.625008 13.8998 0.703125 13.6141 0.703125C12.757 0.703125 12.757 0 11.9 0C11.0431 0 11.0431 0.703125 10.1862 0.703125C9.32931 0.703125 9.32931 0 8.47237 0C7.61548 0 7.61548 0.703125 6.75858 0.703125C5.90171 0.703125 5.90171 0 5.04482 0C4.18788 0 4.18788 0.703125 3.33095 0.703125C3.0453 0.703125 2.8549 0.625008 2.6962 0.52084C2.23313 0.216809 1.61719 0.561937 1.61719 1.11589V16.3834C1.61719 16.7032 1.88438 16.9606 2.20767 16.9446C2.45429 16.9324 2.6647 16.7541 2.72524 16.5147C2.91776 15.7529 3.60893 15.1875 4.42969 15.1875H14.625C15.0133 15.1875 15.3281 14.8727 15.3281 14.4844V1.11604C15.3281 0.561973 14.7121 0.216809 14.2489 0.52084ZM9.52734 11.2852C9.52734 11.6735 9.21255 11.9883 8.82422 11.9883H4.60547C4.21713 11.9883 3.90234 11.6735 3.90234 11.2852C3.90234 10.8968 4.21713 10.582 4.60547 10.582H8.82422C9.21255 10.582 9.52734 10.8968 9.52734 11.2852ZM12.3398 8.82422H4.60547C4.21713 8.82422 3.90234 8.50943 3.90234 8.12109C3.90234 7.73276 4.21713 7.41797 4.60547 7.41797H12.3398C12.7282 7.41797 13.043 7.73276 13.043 8.12109C13.043 8.50943 12.7282 8.82422 12.3398 8.82422ZM12.3398 5.66016H4.60547C4.21713 5.66016 3.90234 5.34537 3.90234 4.95703C3.90234 4.5687 4.21713 4.25391 4.60547 4.25391H12.3398C12.7282 4.25391 13.043 4.5687 13.043 4.95703C13.043 5.34537 12.7282 5.66016 12.3398 5.66016Z"
      fill="rgb(255 255 255 / 40%)"
    />
  </svg>
);

export const Shop = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9831 6.05138L16.965 1.97662C16.8401 1.476 16.3901 1.125 15.8737 1.125H2.12738C1.611 1.125 1.161 1.476 1.035 1.97662L0.016875 6.05138C0.005625 6.09525 0 6.14138 0 6.1875C0 7.73775 1.19813 9 2.67188 9C3.528 9 4.29187 8.57363 4.78125 7.91213C5.27063 8.57363 6.0345 9 6.89062 9C7.74675 9 8.51062 8.57363 9 7.91213C9.48938 8.57363 10.2521 9 11.1094 9C11.9666 9 12.7294 8.57363 13.2188 7.91213C13.7081 8.57363 14.4709 9 15.3281 9C16.8019 9 18 7.73775 18 6.1875C18 6.14138 17.9944 6.09525 17.9831 6.05138Z"
      fill="rgb(255 255 255 / 40%)"
    />
    <path
      d="M15.3281 10.1249C14.562 10.1249 13.8341 9.89088 13.2188 9.46338C11.988 10.3195 10.2308 10.3195 9 9.46338C7.76925 10.3195 6.012 10.3195 4.78125 9.46338C4.16587 9.89088 3.438 10.1249 2.67188 10.1249C2.1195 10.1249 1.59863 9.9955 1.125 9.775V15.7499C1.125 16.3709 1.629 16.8749 2.25 16.8749H6.75V12.3749H11.25V16.8749H15.75C16.371 16.8749 16.875 16.3709 16.875 15.7499V9.775C16.4014 9.9955 15.8805 10.1249 15.3281 10.1249Z"
      fill="rgb(255 255 255 / 40%)"
    />
  </svg>
);

export const Support = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* <g opacity="0.4" clip-path="url(#clip0_870_587)"> */}
    <path
      d="M6.02926 3.91623C6.14183 3.51925 6.17442 3.09783 6.12337 2.68755C6.03844 2.00429 5.73602 1.38565 5.24886 0.898488C5.1216 0.771223 4.98178 0.654082 4.83332 0.550441C4.31775 0.190477 3.71099 0.000105527 3.07874 5.79504e-08C2.89786 -3.50983e-05 2.71582 0.0159258 2.53747 0.0474962C2.34974 0.080754 2.19906 0.207914 2.13451 0.387563C2.06968 0.567879 2.10502 0.762574 2.22901 0.908367L3.21247 2.06483L2.06487 3.21244L0.908438 2.22898C0.762574 2.10495 0.567844 2.06965 0.387527 2.13451C0.207844 2.19916 0.0807188 2.34984 0.0474962 2.53747C0.0159258 2.71564 -3.50983e-05 2.89775 5.79504e-08 3.0787C0.000140683 3.71099 0.190477 4.31775 0.550441 4.83332C0.653977 4.98164 0.771082 5.12142 0.898488 5.24886C1.38565 5.73602 2.00429 6.03844 2.68755 6.12337C3.09783 6.17439 3.51921 6.14183 3.91623 6.02926L6.26548 8.37851L8.37851 6.26548L6.02926 3.91623Z"
      fill="rgb(255 255 255 / 40%)"
    />
    <path
      d="M11.9703 14.0837C11.8578 14.4807 11.8252 14.9021 11.8762 15.3124C11.9612 15.9957 12.2636 16.6143 12.7507 17.1015C12.8782 17.2289 13.018 17.346 13.1663 17.4495C13.6819 17.8095 14.2886 17.9999 14.9209 18C15.1018 18 15.284 17.984 15.4621 17.9525C15.6498 17.9192 15.8005 17.7921 15.8651 17.6124C15.93 17.4321 15.8947 17.2374 15.7706 17.0916L14.7872 15.9351L15.9348 14.7875L17.0912 15.771C17.237 15.895 17.4318 15.9304 17.612 15.8655C17.7917 15.8009 17.9189 15.6502 17.9521 15.4625C17.9837 15.2842 17.9997 15.1021 17.9996 14.9213C17.9995 14.289 17.8091 13.6823 17.4492 13.1667C17.3455 13.0182 17.2284 12.8784 17.1011 12.7511C16.614 12.2639 15.9953 11.9615 15.312 11.8766C14.9018 11.8256 14.4804 11.8581 14.0834 11.9707L11.7341 9.62146L9.62109 11.7345L11.9703 14.0837Z"
      fill="rgb(255 255 255 / 40%)"
    />
    <path
      d="M17.8448 1.34774L16.6524 0.155314C16.4763 -0.0208186 16.2065 -0.0504202 15.9964 0.0833142L13.5352 1.64949C13.4146 1.72627 13.3293 1.85069 13.3012 1.99096L13.0904 3.0453L6.64551 9.49014L8.50995 11.3546L14.9548 4.90974L16.0092 4.69887C16.1494 4.67082 16.2738 4.58553 16.3506 4.46487L17.9167 2.00379C18.0505 1.79367 18.021 1.52388 17.8448 1.34774Z"
      fill="rgb(255 255 255 / 40%)"
    />
    <path
      d="M8.82057 13.1568L4.8431 9.17936C4.63715 8.97342 4.30327 8.97342 4.09733 9.17936C3.89138 9.38531 3.89138 9.71919 4.09733 9.92513L4.22164 10.0494L0.771056 13.5C-0.257019 14.5281 -0.257019 16.2008 0.771056 17.2289C1.79913 18.257 3.47186 18.257 4.49994 17.2289L7.95052 13.7783L8.07484 13.9026C8.28078 14.1086 8.61466 14.1086 8.82061 13.9026C9.02648 13.6967 9.02648 13.3628 8.82057 13.1568ZM5.30516 13.4406L2.62165 16.1241C2.4157 16.33 2.08182 16.33 1.87588 16.1241C1.66993 15.9181 1.66993 15.5843 1.87588 15.3783L4.55939 12.6948C4.76533 12.4889 5.09921 12.4889 5.30516 12.6948C5.51107 12.9007 5.51107 13.2346 5.30516 13.4406Z"
      fill="rgb(255 255 255 / 40%)"
    />
    {/* </g> */}
    <defs>
      <clipPath id="clip0_870_587">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

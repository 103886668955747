import React from 'react';
import { Link } from 'react-router-dom';

export const Nothing = () => {
  return (
    <div>
      <div className="nothing-buy-div text-center">
        <div className="nothing-image-div">
          <img src="/images/market/not-buy.png" alt="zipit" className="img-fluid similar-product-img" />
        </div>
        <p className="font-26 text-white pb-5">You are not winning any items</p>
        <div className="description">
          <p>Search and find an item from the market Place.</p>
        </div>
        <Link to="/market" className="button-link mt-20 green-background text-uppercase">
          Go to Marketpage
        </Link>
      </div>
    </div>
  );
};

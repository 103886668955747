import React, { useEffect, useState } from 'react';

import { TopBar, Footer } from 'components';
import { getCurrencyItems } from 'lib';

import { Content } from './Content';

export const Main = () => {
  const [options, setOption] = useState([]);

  const fetchData = async () => {
    try {
      const productData = await getCurrencyItems();
      setOption(productData);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <TopBar options={options} />
      <div>
        <Content />
      </div>
      <Footer options={options} />
    </>
  );
};

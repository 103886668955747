import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export const CountdownTimer = ({ createdDate, daysCount }) => {
  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    if (daysCount <= 8) {
      const calculateTimeLeft = () => {
        const targetDate = moment.utc(createdDate).add(daysCount, 'days'); // Parse target date in UTC
        const difference = targetDate.diff(moment.utc(), 'milliseconds'); // Calculate difference using UTC time

        if (difference > 0) {
          const duration = moment.duration(difference);
          const days = duration.days();
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          if (days > 0) {
            setTimeRemaining(`${days}d ${hours}hr`);
          } else if (hours > 0) {
            setTimeRemaining(`${hours}hr ${minutes}min`);
          } else {
            setTimeRemaining(`${minutes}min ${seconds}sec`);
          }
        } else {
          setTimeRemaining('0m');
        }
      };

      const timer = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      // Calculate time left immediately on mount
      calculateTimeLeft();

      return () => clearInterval(timer);
    } else if (daysCount === 18) {
      const targetDate = moment.utc(createdDate).add(18, 'hours');
      const difference = targetDate.diff(moment.utc(), 'milliseconds');

      const calculateTimeRemaining = () => {
        const duration = moment.duration(difference);

        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        if (hours > 0) {
          setTimeRemaining(`${hours}h ${minutes}m`);
        } else {
          setTimeRemaining(`${minutes}m ${seconds}s`);
        }
      };

      const timer = setInterval(() => {
        calculateTimeRemaining();
      }, 1000);

      // Calculate time remaining immediately on mount
      calculateTimeRemaining();

      return () => clearInterval(timer);
    } else if (daysCount === 20) {
      const endTime = moment.utc(createdDate).add(20, 'minutes').valueOf(); // Parse end time in UTC

      const calculateTimeRemaining = () => {
        const currentTime = moment.utc().valueOf(); // Get current time in UTC
        const timeDiff = endTime - currentTime;

        if (timeDiff > 0) {
          const minutesRemaining = Math.floor(timeDiff / (1000 * 60)); // Calculate remaining minutes directly
          const secondsRemaining = Math.floor((timeDiff % (1000 * 60)) / 1000);
          setTimeRemaining(`${minutesRemaining}m ${secondsRemaining}s`);
        } else {
          setTimeRemaining('0m');
        }
      };

      const timer = setInterval(() => {
        calculateTimeRemaining();
      }, 1000);

      // Calculate time remaining immediately on mount
      calculateTimeRemaining();

      return () => clearInterval(timer);
    } else if (daysCount === 29) {
      const targetDate = moment.utc(createdDate).add(30, 'minutes');
      const difference = targetDate.diff(moment.utc(), 'milliseconds');

      const calculateTimeRemaining = () => {
        const duration = moment.duration(difference);

        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        if (hours > 0) {
          setTimeRemaining(`${hours}h ${minutes}m`);
        } else {
          setTimeRemaining(`${minutes}m ${seconds}s`);
        }
      };

      const timer = setInterval(() => {
        calculateTimeRemaining();
      }, 1000);

      // Calculate time remaining immediately on mount
      calculateTimeRemaining();

      return () => clearInterval(timer);
    } else {
      const endTime = moment.utc(createdDate).add(60, 'minutes').valueOf(); // Parse end time in UTC

      const calculateTimeRemaining = () => {
        const currentTime = moment.utc().valueOf(); // Get current time in UTC
        const timeDiff = endTime - currentTime;

        if (timeDiff > 0) {
          const minutesRemaining = Math.floor(timeDiff / (1000 * 60)); // Calculate remaining minutes directly
          const secondsRemaining = Math.floor((timeDiff % (1000 * 60)) / 1000);
          setTimeRemaining(`${minutesRemaining}m ${secondsRemaining}s`);
        } else {
          setTimeRemaining('0m');
        }
      };

      const timer = setInterval(() => {
        calculateTimeRemaining();
      }, 1000);

      // Calculate time remaining immediately on mount
      calculateTimeRemaining();

      return () => clearInterval(timer);
    }
  }, [createdDate, daysCount]);

  return <div>{timeRemaining}</div>;
};

CountdownTimer.propTypes = {
  createdDate: PropTypes.any,
  daysCount: PropTypes.number
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import './style.css';
import { Auction } from './Auction';
import { Market } from './Market';
import { useApi } from 'hooks/useApi';
import { inventroy_sell } from 'api';
import { RoundedPrice, calculateCommission, convertCurrency, itemNameConverter } from 'lib';
import Cookies from 'js-cookie';
export const SellModal = ({ closeModal, sellItem, setWarningModal, setShowSellModal, fetchData }) => {
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
  const showSecondPrice = currency && currency.code !== defaultCurrency.code;
  const location = useLocation();

  const [mainPrice, setMainPrice] = useState(0);
  const [sellType, setSellType] = useState('market');
  const [error, setError] = useState({
    inputValue: '',
    bargainPrice: ''
  });
  const [acceptTerms, setAcceptTerms] = useState();

  const [auctionType, setAuctionType] = useState('regular');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(1);
  const [runTime, setRunTime] = useState(1);

  const [checkOutPrice, setCheckOutPrice] = useState(0);
  const [hasCheckOut, setHasCheckOut] = useState(false);
  const [hasBuyNow, setHasBuyNow] = useState(false);
  const [bargainPrice, setBargainPrice] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);
  const [buyListedPrice, setBuyListedPrice] = useState(0);
  const [buyEarningPrice, setBuyEarningPrice] = useState(0);

  const [PrivateListing, setPrivateListing] = useState(false);
  const [confirmPrivateListing, setConfirmPrivateListing] = useState(false);

  // const [listedPrice, setListedPrice] = useState(0);
  const [earning, setEarning] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState(0);

  const { apiCaller: inventroySellerAPICaller } = useApi({
    dataGetter: inventroy_sell
  });

  const handleSellTypeChange = (event) => {
    setSellType(event.target.value);
    setIsPrivate(false);
    setMainPrice(0);
    setAcceptTerms();
    setBargainPrice(null);
    setHasCheckOut(false);
    setHasBuyNow(false);
    setCheckOutPrice(0);
    // setListedPrice(0);
    setEarning(0);
    setConvertedAmount(0);
    setBuyListedPrice(0);
    setBuyEarningPrice(0);
  };

  const validatePrice = () => {
    setHasCheckOut(false);
    setCheckOutPrice(0);
    setHasBuyNow(false);
    setBargainPrice(0);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const decimalRegex = /^\d{0,7}(\.\d{0,2})?$/;

    if (value < 1) {
      setError({ mainPrice: 'The minimum price is 1€' });
      validatePrice();
    } else if (value.startsWith('0')) {
      setError({ mainPrice: 'The minimum price is 1€' });
      validatePrice();
    } else if (!decimalRegex.test(value)) {
      setError({ mainPrice: 'Price can have a maximum of 7 figures and 2 decimal places' });
      validatePrice();
    } else {
      setError('');
    }
    setMainPrice(value);
    if (currency !== null && currency !== undefined) {
      const amount = convertCurrency(value);
      setConvertedAmount(amount);
    }
    const { earningAmount } = calculateCommission(value);
    // setListedPrice(listedAmount);
    setEarning(Number(earningAmount));
  };

  const params = new URLSearchParams(location.search);

  const userAuctionTerms = () => {
    const auctionTerms = Cookies.get('USER_SHOW_CREATE_AUCTION_WARNING');
    const decimalRegex = /^\d{0,7}(\.\d{0,2})?$/;

    if (!mainPrice) {
      setError({ mainPrice: 'This Field is required' });
      return; // Exit if inputValue is empty
    } else if (!decimalRegex.test(mainPrice)) {
      setError({ mainPrice: 'Price can have a maximum of 7 figures and 2 decimal places' });
    } else {
      setError('');
    }

    if (auctionTerms === null) {
      setWarningModal(true);
    } else {
      if (isPrivate === true) {
        setConfirmPrivateListing(true);
        setIsPrivate(false);
        setPrivateListing(true);
        return; // Exit if isPrivate is true
      }
      // If isPrivate is false, proceed with the API call
      const sellObject = {
        item_id: sellItem.item_id,
        selltype: sellType,
        bundleId: false,
        buyNowPrice: bargainPrice ? bargainPrice : null,
        hasBuyNow: bargainPrice ? hasBuyNow : false,
        relistOptions: {
          relistOptions: false
        },
        isPrivate: PrivateListing,
        startBid: mainPrice
      };

      if (sellType === 'auction') {
        Object.assign(sellObject, {
          auctionType: auctionType,
          runtime: runTime,
          checkOutPrice: checkOutPrice ? checkOutPrice : null,
          hasCheckOut: checkOutPrice ? hasCheckOut : false
        });
      }

      const sellItemObject = JSON.stringify(sellObject);
      inventroySellerAPICaller({ sellItemObject }).then((res) => {
        if (res.data.status === 'success') {
          document.body.classList.remove('modal-open');
          setShowSellModal(false);
          const categoryNameParam = params.get('view');
          fetchData(categoryNameParam, 1, '', false);
        }
      });
    }
  };

  const handleBargainPriceChange = (e) => {
    setBargainPrice(e.target.value);
    handleApplyBargainPrice(e.target.value);
  };

  const auctionBuyPrice = (e) => {
    setCheckOutPrice(e.target.value);
    const { listedAmount, earningAmount } = calculateCommission(e.target.value);
    setBuyListedPrice(listedAmount);
    setBuyEarningPrice(earningAmount);
    const decimalRegex = /^\d{0,7}(\.\d{0,2})?$/;
    if (parseFloat(mainPrice) > parseFloat(e.target.value)) {
      setError({ checkOutPrice: 'Must be equal to or greater than the start price.' });
    } else if (checkOutPrice.startsWith('0')) {
      setError({ checkOutPrice: 'The minimum price is 1€' });
    } else if (!decimalRegex.test(e.target.value)) {
      setError({ checkOutPrice: 'Price can have a maximum of 7 figures and 2 decimal places' });
    } else {
      setError({});
    }
  };

  const handleApplyBargainPrice = (price) => {
    // Convert bargain price input to a number
    const enteredBargainPrice = parseFloat(price);
    // Calculate the minimum allowed bargain price
    const minAllowedBargainPrice = (mainPrice / 100) * 20;
    const decimalRegex = /^\d{0,7}(\.\d{0,2})?$/;

    // Check if the entered bargain price is less than the minimum allowed
    if (enteredBargainPrice <= minAllowedBargainPrice) {
      // Show an error message or handle the error as needed
      setError({ bargainPrice: 'Bargain price cannot be less than 20% off the original price.' });
    } else if (price.startsWith('0')) {
      setError({ bargainPrice: 'The minimum price is 1€' });
    } else if (!decimalRegex.test(price)) {
      setError({ bargainPrice: 'Price can have a maximum of 7 figures and 2 decimal places' });
    } else {
      setError({});
    }
  };

  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal sell-modal-div">
      {confirmPrivateListing && (
        <aside role="dialog" tabIndex="-1" aria-modal="true" className="edit-price-modal">
          <div className="steam-account-popup position-relative max-600">
            <button
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              className="modal-close"
              onClick={() => {
                setConfirmPrivateListing(false);
                setPrivateListing(false);
                setIsPrivate(false);
              }}
            >
              <img src="/images/common/modal-close.svg" alt="Zipit" className="img-fluid" />
            </button>
            <p className="offer-heading">Confirm to create a private listing</p>
            <p className="opacity-white font-16 font-500 mt-20">
              You are about to create a private listing. Private listings are not visible or searchable on the market. Only you and those
              that you share the link with can view this listing.
            </p>

            <div className="offer-button-div mt-30">
              <button className="green-button button-link" onClick={userAuctionTerms}>
                Continue Private Listing
              </button>
              <button
                className="delete-button button-link"
                onClick={() => {
                  setConfirmPrivateListing(false);
                  setPrivateListing(false);
                  setIsPrivate(false);
                }}
              >
                Cancel
              </button>
            </div>
            <p className="font-14 mt-10 text-white opcaity-5">This site is not associated with Valve Corp.</p>
          </div>
        </aside>
      )}
      <div className="sell-modal-area">
        <div className="modal-body inventory-sell-modal position-relative">
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>
          <div className="sell-product-image-div">
            <div className="row">
              <div className="col-lg-6 col">
                <div
                  className="sell-image position-relative"
                  style={{
                    borderBottom: `5px solid ${sellItem?.item?.r_color}`
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${sellItem.item.imageUrl}`}
                    alt={sellItem.item.name}
                    className="img-fluid"
                  />
                  <img src="/images/sell-modal/new-background-image.jpg" alt={sellItem.item.name} className="img-fluid sell-background" />
                </div>
              </div>
              <div className="col-lg-6 col">
                <div className="sell-product-right-div  box-background">
                  <div>
                    <h3 className="font-20 pb-5">{itemNameConverter(sellItem.item.fullname)}</h3>
                    <div>
                      <p className="font-14 pb-0">
                        {sellItem.item.category} - {sellItem.item.subCategory}
                      </p>
                    </div>
                  </div>

                  <div className="sell-product-info-div">
                    {sellItem.wear && (
                      <div className="sell-product-info">
                        <p className="opacity-white">Wear</p>
                        <p>{sellItem.wear}</p>
                      </div>
                    )}
                    {Number(sellItem.float_id) > 0 && (
                      <div className="sell-product-info">
                        <p className="opacity-white">Float</p>
                        <p>{sellItem.float_id && Number(sellItem.float_id).toFixed(5)}</p>
                      </div>
                    )}
                    {sellItem.item.rarity_name && (
                      <div className="sell-product-info">
                        <p className="opacity-white">Rarity</p>
                        <p>{sellItem.item.rarity_name}</p>
                      </div>
                    )}
                    {Number(sellItem.paintseed) > 0 && (
                      <div className="sell-product-info">
                        <p className="opacity-white">Pattern id</p>
                        <p>{sellItem.paintseed && sellItem.paintseed}</p>
                      </div>
                    )}
                    {Number(sellItem.paintindex) > 0 && (
                      <div className="sell-product-info">
                        <p className="opacity-white">Finish catalog</p>
                        <p>{sellItem.paintindex && sellItem.paintindex}</p>
                      </div>
                    )}
                    {sellItem.fade_id && (
                      <div className="sell-product-info">
                        <p className="opacity-white">Fade</p>
                        <p>{`${sellItem.fade_id && RoundedPrice(sellItem.fade_id)}%`}</p>
                      </div>
                    )}
                    {sellItem.phase && (
                      <div className="sell-product-info">
                        <p className="opacity-white">Phase</p>
                        <p>{sellItem.phase && sellItem.phase}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sell-item-info box-background">
            <div className="sell-item-select">
              <p className="font-20 pb-0">Selling an item:</p>
              <div className="grey-background ">
                <label className={`sell-label ${sellType === 'market' ? 'checked' : ''}`}>
                  Market
                  <input type="radio" name="sell_type" value="market" checked={sellType === 'market'} onChange={handleSellTypeChange} />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="grey-background ">
                <label className={`sell-label ${sellType === 'auction' ? 'checked' : ''}`}>
                  Auction
                  <input type="radio" name="sell_type" value="auction" checked={sellType === 'auction'} onChange={handleSellTypeChange} />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="input-outer-div">
                <div className="grey-background input-main-div">
                  <label className="input-lable">price</label>
                  <div className="position-relative prefix-div">
                    <p className="prefix">{defaultCurrency && defaultCurrency.symbol}</p>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="price"
                      placeholder="1.00"
                      value={mainPrice || ''}
                      onChange={handleInputChange}
                      onKeyDown={(e) => {
                        if (
                          (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                            e.key !== 'Backspace' &&
                            e.key !== 'Delete' &&
                            e.key !== 'ArrowLeft' &&
                            e.key !== 'ArrowRight' &&
                            e.key !== '.') || // Allow dot key
                          (e.key === '.' && mainPrice.includes('.')) // Ensure only one dot is entered
                        ) {
                          e.preventDefault();
                        }
                      }}
                      className={`${error ? 'error-msg' : ''}`}
                    />
                  </div>
                </div>
                {error.mainPrice && <p style={{ color: 'red', position: 'absolute' }}>{error.mainPrice}</p>}
              </div>
            </div>

            {sellType === 'auction' ? (
              <Auction
                setAcceptTerms={setAcceptTerms}
                auctionType={auctionType}
                setSelectedDay={setSelectedDay}
                selectedDay={selectedDay}
                setCurrentTime={setCurrentTime}
                currentTime={currentTime}
                setAuctionType={setAuctionType}
                setRunTime={setRunTime}
                currency={currency}
                showSecondPrice={showSecondPrice}
                defaultCurrency={defaultCurrency}
                convertedAmount={convertedAmount}
                buyEarningPrice={buyEarningPrice}
                buyListedPrice={buyListedPrice}
                error={error}
                // bargainPrice={bargainPrice}
                auctionBuyPrice={auctionBuyPrice}
                // hasBuyNow={hasBuyNow}
                // setHasBuyNow={setHasBuyNow}
                hasCheckOut={hasCheckOut}
                setHasCheckOut={setHasCheckOut}
                checkOutPrice={checkOutPrice}
                // handleBargainPriceChange={handleBargainPriceChange}
                average_price={sellItem.average_price}
                mainPrice={mainPrice}
              />
            ) : (
              <Market
                setAcceptTerms={setAcceptTerms}
                setIsPrivate={setIsPrivate}
                hasBuyNow={hasBuyNow}
                setHasBuyNow={setHasBuyNow}
                bargainPrice={bargainPrice}
                handleBargainPriceChange={handleBargainPriceChange}
                error={error}
                // currency={currency}
                // showSecondPrice={showSecondPrice}
                defaultCurrency={defaultCurrency}
                // convertedAmount={convertedAmount}
                // listedPrice={listedPrice}
                earning={earning}
                mainPrice={mainPrice}
                average_price={sellItem.average_price}
                isPrivate={isPrivate}
              />
            )}

            <button
              className="white-button w-100 mt-30"
              disabled={
                !acceptTerms ||
                error.bargainPrice ||
                error.checkOutPrice ||
                error.mainPrice ||
                mainPrice === null ||
                mainPrice === undefined ||
                mainPrice === '' ||
                mainPrice <= 0
              }
              onClick={() => userAuctionTerms()}
            >
              create listing
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};

SellModal.propTypes = {
  closeModal: PropTypes.func,
  sellItem: PropTypes.object,
  setWarningModal: PropTypes.func,
  setShowSellModal: PropTypes.func,
  getInventory: PropTypes.func,
  fetchData: PropTypes.func
};

import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setBearerToken } from 'reduxs/userSlice';

export const useApi = ({ dataGetter, autoRetrieve = false }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const apiCaller = useCallback(
    (queryParams, body) => {
      setLoading(true);
      return dataGetter(queryParams, body)
        .then((res) => {
          if (res.data) {
            setIsSuccess(Boolean(res.data));
          }
          if (res.data) {
            setData(res.data);
            return res;
          }
          return res;
        })
        .catch(async (err) => {
          setError(err.message);
          if (err?.response?.data.statusCode === 401) {
            dispatch(setBearerToken(''));
            setIsSuccess(false);
          }

          return {
            ...err,
            data: err
          };
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dataGetter, dispatch]
  );

  useEffect(() => {
    if (autoRetrieve) {
      apiCaller();
    }
  }, [apiCaller, autoRetrieve]);
  return {
    apiCaller,
    data,
    loading,
    error,
    isSuccess
  };
};

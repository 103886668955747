import React from 'react';
import { RoundedPrice } from 'lib';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const Market = ({
  setAcceptTerms,
  hasBuyNow,
  setHasBuyNow,
  setIsPrivate,
  // showSecondPrice,
  // currency,
  defaultCurrency,
  // convertedAmount,
  earning,
  // listedPrice,
  mainPrice,
  bargainPrice,
  error,
  handleBargainPriceChange,
  average_price,
  isPrivate
}) => {
  const commissionRate = useSelector((state) => state.user.commissionRate);
  return (
    <div className="sel-modal-row">
      <div className="row custome-row-gap">
        <div className="col-lg-6 col">
          <div className="sell-modal-left-div">
            {/* <div className="sell-modal-info">
              {showSecondPrice && (
                <>
                  <p>Your Approx Earnings.</p>
                  <p>
                    {currency?.symbol} {convertedAmount}
                  </p>
                </>
              )}
            </div> */}
            <div className="sell-modal-info">
              <div>
                Listed Price
                <img src="/images/sell-modal/question.svg" alt="zipit" className="img-fluid tooltip-image" />
                <div className="tooltiptext">
                  <p>The price the skin will be listed at in the market place.</p>
                </div>
              </div>
              <p>
                {defaultCurrency && defaultCurrency.symbol}
                {mainPrice}
              </p>
            </div>
            <div className="sell-modal-info position-relative">
              <div>
                Average Market Price
                <img src="/images/sell-modal/question.svg" alt="zipit" className="img-fluid tooltip-image" />
                <div className="tooltiptext">
                  <p>The average market price is based on the average price similar skins are listed at.</p>
                </div>
              </div>
              <p>
                {defaultCurrency && defaultCurrency.symbol}
                {average_price && RoundedPrice(average_price)}
              </p>
            </div>
            <div className="sell-modal-info">
              <div>
                Commission Rate
                <img src="/images/sell-modal/discount.svg" alt="zipit" className="img-fluid tooltip-image" />
                <div className="tooltiptext">
                  <p>The commission rate for items sold are {commissionRate && commissionRate + '%'} </p>
                </div>
              </div>
              <p>{commissionRate && commissionRate + '%'}</p>
            </div>
            <div className="sell-modal-info">
              <div>
                Your earnings
                <img src="/images/sell-modal/question.svg" alt="zipit" className="img-fluid tooltip-image" />
                <div className="tooltiptext">
                  <p>
                    The estimated payout if your item sells at the listed price. If the bargain offers are lower than the listed price, your
                    payout will change accordingly.{' '}
                  </p>
                </div>
              </div>
              <p>
                {defaultCurrency && defaultCurrency.symbol}
                {Number(earning)}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col">
          <div className="padding-left sell-modal-right-div">
            {mainPrice < 2 && <p className="opacity-white">Bargains are disabled for items under €2</p>}

            <div className="condition-label">
              <label className={`sell-label ${mainPrice < 2 ? 'sell-min-bargain-price' : ''}`}>
                Set custom minimum bargain price
                <input
                  type="checkbox"
                  name="custom_price"
                  checked={hasBuyNow}
                  onChange={(e) => setHasBuyNow(e.target.checked)}
                  disabled={mainPrice < 2}
                />
                <span className="checkmark l-0"></span>
                <img src="/images/sell-modal/question.svg" alt="zipit" className="img-fluid tooltip-image" />
                <div className="tooltiptext">
                  <p>
                    You can set a custom minimum bargain reserve price for your listing. We are not going to show it to potential buyers,
                    instead we will show the standard 20% of the sell price. The custom amount has to be more than 20% of the sell price.
                  </p>
                </div>
              </label>

              {hasBuyNow && (
                <>
                  <div className={`grey-background input-main-div mb-5 ${error?.bargainPrice ? 'invalid-input' : 'valid-input'}`}>
                    <label className="input-lable ">{defaultCurrency && defaultCurrency.symbol}</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="bargain"
                      // className={error.bargainPrice ? 'invalid-input' : 'valid-input'}
                      placeholder="0"
                      value={bargainPrice || ''}
                      onChange={handleBargainPriceChange}
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (
                          (!/[\d\t\n\r.]/.test(e.key) && // Allow digits, dot, tabs, newlines, and return keys
                            e.key !== 'Backspace' &&
                            e.key !== 'Delete' &&
                            e.key !== 'ArrowLeft' &&
                            e.key !== 'ArrowRight' &&
                            e.key !== '.') || // Allow dot key
                          (e.key === '.' && bargainPrice?.includes('.')) // Ensure only one dot is entered
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </>
              )}
              {error?.bargainPrice && <p style={{ color: 'red', paddingBottom: '5px' }}>{error?.bargainPrice}</p>}
            </div>
            <div className="condition-label">
              <label className="sell-label">
                Make listing private
                <input type="checkbox" name="" onChange={(e) => setIsPrivate(e.target.checked)} checked={isPrivate} />
                <span className="checkmark l-0"></span>
              </label>
            </div>
            <div className="condition-label">
              <label className="sell-label">
                I accept the
                <a href="/terms" rel="noreferrer" target="_blank" className="text-white text-underline">
                  terms
                </a>
                and confirm that I want to sell this item.
                <input type="checkbox" name="term" onChange={(e) => setAcceptTerms(e.target.checked)} />
                <span className="checkmark l-0"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Market.propTypes = {
  setAcceptTerms: PropTypes.any,
  setHasBuyNow: PropTypes.func,
  setIsPrivate: PropTypes.func,
  hasBuyNow: PropTypes.bool,
  // showSecondPrice: PropTypes.bool,
  // currency: PropTypes.object,
  defaultCurrency: PropTypes.object,
  // convertedAmount: PropTypes.any,
  earning: PropTypes.any,
  // listedPrice: PropTypes.any,
  mainPrice: PropTypes.any,
  bargainPrice: PropTypes.any,
  error: PropTypes.any,
  handleBargainPriceChange: PropTypes.func,
  average_price: PropTypes.any,
  isPrivate: PropTypes.bool
};

import React from 'react';
import PropTypes from 'prop-types';
import { convertCurrency } from 'lib';

export const Checkout = ({
  closeModal,
  acceptTerms,
  setAcceptTerms,
  item,
  defaultCurrency,
  showSecondPrice,
  tf2Currencies,
  acceptWarning,
  setAcceptWarning,
  onConfirmBuy,
  checkOutItemData
}) => {
  return (
    <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal">
      <div className="sell-modal-area">
        <div className="offer-popup position-relative">
          <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeModal}>
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>
          <p className="offer-heading">Confirm buy</p>
          <div className="alert-box">
            <p>Read this before you continue</p>
            <ul>
              <li>You can buy without balance</li>
              <li> Your buying is binding and can NOT be cancelled</li>
              <li> Failure to pay can result in a permanent ban</li>
            </ul>
            <label className="sell-label">
              I have read and understand
              <input type="checkbox" name="term" onChange={(e) => setAcceptWarning(e.target.checked)} />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="d-flex justify-content-between amount-offer-div">
            <p className="font-700">Confirm amount</p>
            {checkOutItemData && checkOutItemData.selltype === 'auction' ? (
              <div className="text-end">
                <p className="pb-5 font-700">
                  {defaultCurrency.symbol} {checkOutItemData && checkOutItemData.checkoutPrice}
                </p>
                {showSecondPrice && (
                  <p className="font-12  font-700">
                    -{tf2Currencies.symbol} {checkOutItemData && convertCurrency(checkOutItemData.checkoutPrice)}
                  </p>
                )}
              </div>
            ) : (
              <div className="text-end">
                <p className="pb-5 font-700">
                  {defaultCurrency.symbol} {checkOutItemData && checkOutItemData.startBid}
                </p>
                {showSecondPrice && (
                  <p className="font-12  font-700">
                    -{tf2Currencies.symbol} {checkOutItemData && convertCurrency(checkOutItemData.startBid)}
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="secure-text-div">
            <div className="d-flex gap-10">
              <img src="/images/market/shield.svg" alt="modal-close" className="img-fluid" />
              <div className="secure-text">
                <p className="font-700 pb-5">3DSecure is required for credit card payments.</p>
                <p className="font-12 opacity-white">What is 3DSecure really and how do I enable it?</p>
              </div>
            </div>
            <div className="secure-description mt-20">
              <p className="font-14 pb-0 font-700">
                3DS, or 3D Secure, is basically a two-factor authentication for your payments, much like logging in with Steam using the
                Steam Guard Authenticator. That means, when you pay, you have to confirm your payment, either in your banking app, through a
                text message sent to your phone, or whatever method your bank uses to confirm payments.
              </p>
            </div>
          </div>
          <label className="sell-label">
            I accept the
            <a href="/terms" rel="noreferrer" target="_blank" className="text-white text-underline">
              terms
            </a>
            and confirm that I want to sell this item.
            <input type="checkbox" name="term" onChange={(e) => setAcceptTerms(e.target.checked)} />
            <span className="checkmark l-0"></span>
          </label>
          <div className="offer-button-div">
            <button className="green-button" disabled={!acceptTerms || !acceptWarning} onClick={(e) => onConfirmBuy(checkOutItemData.m_id)}>
              Confirm
            </button>
            <button aria-label="Close Modal" aria-labelledby="close-modal" className="grey-button" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};

Checkout.propTypes = {
  closeModal: PropTypes.func,
  setAcceptTerms: PropTypes.func,
  acceptTerms: PropTypes.bool,
  item: PropTypes.object,
  defaultCurrency: PropTypes.object,
  showSecondPrice: PropTypes.bool,
  tf2Currencies: PropTypes.object,
  setAcceptWarning: PropTypes.func,
  acceptWarning: PropTypes.bool,
  onConfirmBuy: PropTypes.func,
  checkOutItemData: PropTypes.any
};

import { inventoryRoutes } from './apiRoutes';
import { axiosAuthInstance } from './auth';

export const inventory = (body) => axiosAuthInstance.post(inventoryRoutes.inventory, body);
export const refresh_inventory = (body) => axiosAuthInstance.post(inventoryRoutes.refresh_inventory, body);

export const inventoryCategory = () => axiosAuthInstance.get(inventoryRoutes.inventoryCategory);

export const steamApiKey = (body) => axiosAuthInstance.post(inventoryRoutes.steamApikey, body);

export const inventroy_sell = (body) => axiosAuthInstance.post(inventoryRoutes.inventroy_sell, body);

export const inventory_list_view = (queryParams) => axiosAuthInstance.get(inventoryRoutes.inventory_list_view, { params: queryParams });

export const remove_sell_inventory = (queryParams) => axiosAuthInstance.get(inventoryRoutes.remove_sell_inventory, { params: queryParams });

export const inventory_sell_price_update = (queryParams) => axiosAuthInstance.get(inventoryRoutes.sellpriceupdate, { params: queryParams });
export const update_price = () => axiosAuthInstance.get(inventoryRoutes.update_price);

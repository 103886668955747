import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';

export const Accordion = ({ title, items, isActive, onClick, handleToggle, activeChildIndex }) => {
  return (
    <>
      {items.length > 0 && (
        <div className="single-accordion-item">
          <div className={`accordion-item ${isActive ? 'active' : ''}`} onClick={onClick}>
            <div className="accordion-title position-relative">
              <h2 className="faq-main-category">{title}</h2>
            </div>
            {items.length > 0 && isActive && (
              <div className="main-single-accordion-div">
                {items.map((item, index) => {
                  return (
                    <div className="single-accordion-item-child" key={index}>
                      <div
                        className={`accordion-item-child ${activeChildIndex === index ? 'active-arrow' : ''}`}
                        onClick={(e) => handleToggle(e, index)}
                      >
                        <div className={`accordion-title-child position-relative ${activeChildIndex === index ? 'active-arrow' : ''}`}>
                          <h3 className="question-title">{item.title}</h3>
                        </div>
                        {activeChildIndex === index && <p className="accordion-content-child opacity-white">{item.description}</p>}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  index: PropTypes.number,
  isActive: PropTypes.any,
  activeChildIndex: PropTypes.any,
  onClick: PropTypes.func,
  handleToggle: PropTypes.func,
  items: PropTypes.any
};

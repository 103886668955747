import { notificationsRoutes } from './apiRoutes';
import { axiosAuthInstance } from './auth';

export const notification = (body) => axiosAuthInstance.post(notificationsRoutes.notifications, body);
export const getNotification = () => axiosAuthInstance.get(notificationsRoutes.getNotification);

export const notification_list = (queryParams) => axiosAuthInstance.get(notificationsRoutes.notificationList, { params: queryParams });

export const user_notification_seen = (body) => axiosAuthInstance.post(notificationsRoutes.user_notification_seen, body);
export const user_read_as_all_notification_seen = () => axiosAuthInstance.get(notificationsRoutes.mark_as_read_notification);

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { RoundedPrice, convertCurrency, itemNameConverter, wearNameConverter } from 'lib';
export const Shop = ({ item }) => {
  const navigate = useNavigate();

  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  return (
    <>
      <div className="main-single-market-product" id={item.m_id} onClick={() => navigate(`/market/${item.m_id}`)}>
        <div className="single-market-product">
          <div className="market-fade-info">
            <div className="font-14 pb-0 text-white">
              <span className="font-700" style={{ color: `${item.item.r_color}` }}>
                {wearNameConverter(item.wear)}
              </span>{' '}
              {''}
              {item && Number(item.float_id) !== 0 ? ` : ${item && Number(item.float_id).toFixed(4)}` : ''}
              {/* <span style={{ backgroundColor: `${item.item.r_color && item.item.r_color}` }}></span> */}
            </div>
            {item && Number(item.paintseed) !== 0 && (
              <div className="font-12 text-white font-700">
                P {''}/ {''}
                {item && item.paintseed}
              </div>
            )}
            {item && item.fade_id !== null && (
              <>
                <div className="font-12 opacity-white font-700 single-product-wear">
                  Fade
                  <span
                    style={{
                      width: '6px',
                      height: '6px',
                      borderRadius: '1px',
                      background: 'linear-gradient(45deg, #F7B500 12.5%, #B620E0 54.13%, #32C5FF 95.83%)'
                    }}
                  ></span>
                </div>
                <div className="font-12 text-white single-product-wear">{`${item && RoundedPrice(item.fade_id)}%`}</div>
              </>
            )}
          </div>

          <div className="single-product-image">
            <img
              src={`${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${item?.item?.imageUrl}`}
              alt="zipit"
              className="img-fluid similar-product-img"
            />
            <img src="/images/sell-modal/new-background-image.jpg" alt="Zipit" className="img-fluid sell-background" />
          </div>
          <div className="single-product-main-div">
            {item && Number(item.float_id) !== 0 && String(item.float_id).split('.')[1].substring(0, 2) === '00' && (
              <div className="product-float-id">
                <img src="/images/market/star-float.svg" alt="star-float" />
                Float
                {item && item.float_id && (
                  <div className="sticker-tooltip sticker-tooltip-top market-sticker-tooltip">
                    <p>low float</p>
                  </div>
                )}
              </div>
            )}
            <div className="single-product-name">
              <h3 className="pb-5 product-name">{item && itemNameConverter(item.item.fullname)}</h3>
              {/* <p>$10.00</p> */}
              <p>
                {currency ? currency.symbol : defaultCurrency && defaultCurrency?.symbol}
                {currency ? item && convertCurrency(item.startBid) : item && item.startBid}
              </p>
            </div>
            {item?.item?.stickers && item?.item?.stickers.length > 0 && (
              <div className="previous-sales-sticker product-sticker">
                {item.item.stickers.map((sticker, index) => (
                  <div className="single-sticker-div" key={index}>
                    <img src={sticker.value} alt="sticker" />
                    {sticker.key && (
                      <div className="sticker-tooltip sticker-tooltip-left">
                        <span>{sticker.key}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {item.item.r_color && (
              <div className="rarety-div ">
                <span style={{ backgroundColor: `${item.item.r_color && item.item.r_color}` }}></span>
                {item.item.rarity_name && (
                  <div className="sticker-tooltip sticker-tooltip-top market-sticker-tooltip">
                    <p>{item.item.rarity_name}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="responsive-view-button">
            <button className="view-button">view</button>
          </div>
        </div>
      </div>
    </>
  );
};

Shop.propTypes = {
  item: PropTypes.object
};

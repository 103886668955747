import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BulkSellTable } from './BulkSellTable';
import { useApi } from 'hooks/useApi';
import { inventory, inventroy_sell } from 'api';
import { BulkSellAdd } from './BulkSellAdd';
import { useMaterialReactTable } from 'material-react-table';
import _ from 'loadsh';
import './style.css';
import { useSelector } from 'react-redux';
import { calculateCommission, convertCurrency, itemNameConverter, wearNameConverter } from 'lib';
import { BulkSellModal } from './BulkSellModal';
import { useLocation } from 'react-router-dom';
import { MobileSellTable } from './mobileBulkSellTable';

export const BulkSellList = ({
  onBulkSellModalClose,
  bulkSellItem,
  setBulkSellItem,
  onRemoveItem,
  rowSelection,
  setRowSelection,
  setBulkSellModal,
  fetchInventoryData,
  setPrices,
  prices
}) => {
  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const showSecondPrice = currency && currency.code !== defaultCurrency.code;
  const commissionRate = useSelector((state) => state.user.commissionRate);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const categoryNameParam = params.get('view');

  const [sorting, setSorting] = useState([]);
  const [error, setError] = useState('');

  const [priceModal, setPriceModal] = useState(false);
  const [selectedSellItem, setSelectedSellItem] = useState();

  const [listedPrice, setListedPrice] = useState(0);
  const [earning, setEarning] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState(0);

  const [inventoryData, setInventoryData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [visible, setVisible] = useState();

  const [acceptTerms, setAcceptTerms] = useState();
  const tableContainerRef = useRef(null);

  const [addToItem, setAddToItem] = useState(false);

  const [selectedRowItem, setSelectedRowItem] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [changeSellType, setChangeSellType] = useState();
  const [Relisting, setRelisting] = useState(false);
  const [sellType, setSellType] = useState('auction');
  const [auctionType, setAuctionType] = useState('regular');
  const [runTime, setRunTime] = useState(1);

  const [listingTypeModal, setListingTypeModal] = useState(false);

  const [PriceError, setPriceError] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const mobileRef = useRef(null);
  const buttonRef = useRef(null);

  const bulkSellItemsArray = Array.isArray(bulkSellItem) ? bulkSellItem : [];

  const { apiCaller: getInventory } = useApi({
    dataGetter: inventory
  });

  const { apiCaller: inventroySellerAPICaller } = useApi({
    dataGetter: inventroy_sell
  });

  const pageSize = 50;

  const fetchData = useCallback(
    async (page, searchString, category) => {
      try {
        let params = {
          page
        };

        if (category !== null && category !== '' && category !== 'all') {
          params.cate = category;
        }

        if (searchString !== null && searchString !== '') {
          params.search = searchString;
        }

        params.limit = pageSize;
        const res = await getInventory(params);

        const newData = res.data.item.data.filter((item, index, self) => index === self.findIndex((i) => i.item_id === item.item_id));

        setInventoryData((prev) => {
          const updatedData = page === 1 ? newData : [...prev, ...newData];
          const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

          const newVisible = uniqueArray.length;
          setVisible(newVisible);
          return uniqueArray;
        });
        setRowCount(res.data.item.total);
      } catch (error) {
        console.error(error.message);
      }
    },
    [getInventory]
  );

  const ToggleEvent = () => {
    setAddToItem(!addToItem);
  };

  const onSearch = (searchString) => {
    setCurrentPage(1);
    setSearch(searchString);
  };

  const hendelRemove = () => {
    setShowOptions(false);

    const updatedBulkSellItem = bulkSellItem.filter((item) => !selectedRowItem[item.item_id]);

    const deleteRowSelection = { ...selectedRowItem };
    const updatedRowSelection = { ...rowSelection };

    bulkSellItem.forEach((item) => {
      if (!updatedBulkSellItem.some((updatedItem) => updatedItem.item_id === item.item_id)) {
        delete updatedRowSelection[item.item_id];
        delete deleteRowSelection[item.item_id];
      }
    });

    setBulkSellItem(updatedBulkSellItem);
    setRowSelection(updatedRowSelection);
    setSelectedRowItem(deleteRowSelection);
  };

  const onToggleRelisting = () => {
    setShowOptions(false);
    setRelisting(true);
  };

  const onChangeListingType = () => {
    setShowOptions(false);
    setListingTypeModal(true);
  };

  const ClearChanges = () => {
    setShowOptions(false);
    setSelectedRowItem({});
  };

  useEffect(() => {
    const selectedRowsObj = Object.values(selectedRowItem);
    const ListingType = selectedRowsObj.length <= 1;
    setChangeSellType(ListingType);
  }, [selectedRowItem]);

  const addPrice = (item) => {
    setSelectedSellItem(item);
    setPriceModal(true);
  };

  const showPrice = (itemId) => {
    return prices[itemId] || '0.00';
  };

  const showConvertedPrice = (itemId) => {
    const price = prices[itemId] || 0;
    return convertCurrency(price);
  };

  const onCloseModal = () => {
    const updatedPrices = { ...prices };
    delete updatedPrices[selectedSellItem.item_id];
    setPrices(updatedPrices);
    setPriceModal(false);
  };

  const onSellTypeSave = (e) => {
    e.preventDefault();
    setListingTypeModal(false);
    if (bulkSellItem.length > 0) {
      const sellObjectsArray = bulkSellItem.map((item) => {
        if (selectedRowItem[item.item_id]) {
          item.selltype = sellType;
          if (sellType === 'auction') {
            item.auctionType = auctionType;
            item.runtime = runTime;
          } else {
            delete item.auctionType;
          }
          item.relistOptions = { relistOptions: Relisting };
          if (prices[item.item_id]) {
            item.startBid = Number(prices[item.item_id]);
          }
          return item;
        } else {
          return item;
        }
      });

      setBulkSellItem(sellObjectsArray);
      setSelectedRowItem({});
    }
  };

  const onSavePrice = () => {
    const newPrice = prices[selectedSellItem.item_id];
    if (!newPrice) {
      setError('This Field is required');
      return;
    } else {
      setError('');
    }

    if (bulkSellItem.length > 0) {
      const sellObjectsArray = bulkSellItem.map((item) => {
        if (selectedSellItem.item_id === item.item_id) {
          if (prices[item.item_id]) {
            item.startBid = Number(newPrice);
          }
          return item;
        } else {
          return item;
        }
      });
      setBulkSellItem(sellObjectsArray);
    }
    setPriceModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const decimalRegex = /^\d{0,7}(\.\d{0,2})?$/;
    if (value < 1) {
      setError('The minimum price is 1€');
    } else if (value.startsWith('0')) {
      setError('The minimum price is 1€');
    } else if (!decimalRegex.test(value)) {
      setError('Price can have a maximum of 7 figures and 2 decimal places');
    } else {
      setError('');
    }
    setPrices({ ...prices, [name]: value });
    if (currency !== null && currency !== undefined) {
      const amount = convertCurrency(value);
      setConvertedAmount(amount);
    }
    const { listedAmount, earningAmount } = calculateCommission(value);
    setListedPrice(listedAmount);
    setEarning(earningAmount);
  };

  const buttonCheck = Object.keys(prices).length > 0 && Object.keys(prices).length === bulkSellItemsArray.length;

  useEffect(() => {
    if (buttonCheck) {
      setPriceError('');
    }
  }, [buttonCheck]);

  const handleBulkSell = () => {
    if (!buttonCheck) {
      setPriceError('the price text box has not been filled');
      return;
    }

    const totalItems = bulkSellItem.length;
    let completedRequests = 0;

    bulkSellItem.forEach((item) => {
      const sellObject = {
        item_id: item.item_id,
        selltype: item.selltype,
        startBid: item.startBid,
        relistOptions: {
          relistOptions: false
        },
        buyNowPrice: null,
        hasBuyNow: false,
        bundleId: false,
        isPrivate: false
      };

      if (item.selltype === 'auction') {
        sellObject.auctionType = item.auctionType;
        sellObject.runtime = item.runtime;
      }

      if (item.startBid) {
        inventroySellerAPICaller({ sellItemObject: JSON.stringify(sellObject) })
          .then((res) => {
            if (res.data.status === 'success') {
              document.body.classList.remove('modal-open');
              setBulkSellModal(false);
              setRowSelection({});
              completedRequests += 1;
              if (completedRequests === totalItems) {
                fetchInventoryData(categoryNameParam, 1, '', false);
              }
              // fetchInventoryData(categoryNameParam, 1, '', false);
            }
          })
          .catch((error) => {
            console.error('Error selling item:', sellObject.item_id, error);
            completedRequests += 1;
            if (completedRequests === totalItems) {
              fetchInventoryData(categoryNameParam, 1, '', false);
            }
          });
      }
    });
  };

  useEffect(() => {
    if (addToItem) {
      fetchData(currentPage, search, categoryNameParam);
    }
  }, [fetchData, currentPage, search, categoryNameParam, addToItem]);

  const fetchMoreOnBottomReached = (containerRefElement) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (scrollHeight - scrollTop - clientHeight + 50 <= 50) {
        if (visible < rowCount) {
          setCurrentPage(currentPage + 1);
        }
      }
    }
  };

  let columns = [];

  const onBulkSell = () => {
    const selectedRowModals = table.getSelectedRowModel();
    const selectedRows = selectedRowModals.flatRows.map((row) => {
      const item = bulkSellItem.find((item) => item?.item_id === row?.original?.item_id);
      return item ? item : { ...row.original, startBid: '', selltype: 'market' };
    });
    // Add new items to bulkSellItem
    const selectedIds = new Set(selectedRows.map((item) => item.item_id));
    // Filter out prices that don't match selectedRows item_ids
    const updatedPrices = Object.keys(prices)
      .filter((itemId) => selectedIds.has(Number(itemId)))
      .reduce((acc, itemId) => {
        acc[itemId] = prices[itemId];
        return acc;
      }, {});
    setBulkSellItem(selectedRows);
    setPrices(updatedPrices);
    document.body.classList.add('modal-open');
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(mobileRef.current.clientWidth <= 767);
    };
    const resizeObserver = new ResizeObserver(handleResize);
    if (mobileRef.current) {
      resizeObserver.observe(mobileRef.current);
    }
    handleResize(); // Initial check
    return () => {
      resizeObserver.disconnect();
    };
  }, [isMobile]);

  const RoundedPrice = (value) => {
    // Round the price to two decimal places
    const roundedPrice = Math.round(value * 100) / 100;
    return roundedPrice;
  };

  columns = useMemo(() => {
    const columnsArray = [
      {
        accessorKey: 'item.name',
        header: 'Item',
        size: 150,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          /* eslint-disable */
          const stickers = row.original?.item?.stickers;
          /* eslint-enable */
          return (
            /* eslint-disable */
            <div>
              <div className="goods-card-main-div position-relative">
                <div
                  className="goods-card-img"
                  style={{
                    backgroundImage: `url("${process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}${row?.original?.item?.imageUrl}")`
                  }}
                ></div>
                <div className="wear-name">
                  <p className="font-10 font-500">{row?.original?.wear && wearNameConverter(row?.original?.wear)}</p>
                  <p className="font-10 font-500">
                    {row?.original?.wear !== 'N/A' ? `/ ${Number(row?.original?.float_id).toFixed(4)} ` : ''}
                  </p>
                </div>

                <img src="/images/sell-modal/new-background-image.jpg" alt="sell-background" className="img-fluid sell-background" />
                <div
                  className="border"
                  style={{
                    borderBottom: `5px solid ${row?.original?.item?.r_color}`
                  }}
                ></div>
              </div>
              <div className="display-product-name">
                <div className="inventory-product-name">
                  <h3 className="productname">{row?.original?.item?.fullname && itemNameConverter(row?.original?.item?.fullname)}</h3>
                  {!isMobile ? (
                    <>
                      <div className="d-flex gap-5">
                        <p className="productname-desc">{row?.original?.item?.category}</p>*
                        <p className="productname-desc">{row?.original?.item?.subCategory}</p>
                      </div>
                      {stickers && stickers.length > 0 && (
                        <div className="previous-sales-sticker product-sticker mt-10">
                          {stickers.map((item, index) => {
                            return (
                              <div
                                className="product-display-sticker"
                                key={index}
                                // dangerouslySetInnerHTML={{ __html: item.value }}
                              >
                                <img src={item?.value} alt="sticker" title={item?.key} />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="d-flex gap-5">
                        <p className="productname-desc">{row?.original?.wear}</p>
                      </div>
                      <p className="font-16 pb-5">
                        {defaultCurrency && defaultCurrency.symbol}
                        {RoundedPrice(row?.original?.average_price)}
                      </p>
                      {/* {showSecondPrice && (
                        <p className="font-12">
                          Your Approx Earnings.{' '}
                          <span className="d-block font-14 mt-5 pb-0">
                            {currency?.symbol} {convertCurrency(row?.original?.average_price)}
                          </span>
                        </p>
                      )} */}
                    </>
                  )}
                </div>
              </div>
            </div>
            /* eslint-enable */
          );
        }
      }
    ];

    if (!isMobile) {
      // Push additional columns for desktop view
      columnsArray.push(
        {
          accessorKey: 'wear',
          header: 'Wear',
          size: 150
        },
        {
          // accessorKey: 'item.category',
          header: 'Price',
          size: 100,
          // eslint-disable-next-line
          Cell: ({ row }) => {
            return (
              /* eslint-disable */
              <>
                <p className="font-16 pb-5">
                  {defaultCurrency && defaultCurrency.symbol}
                  {RoundedPrice(row?.original?.average_price)}
                </p>
                {/* {showSecondPrice && (
                  <p className="font-12">
                    Your Approx Earnings.{' '}
                    <span className="d-block font-14 mt-5 pb-0">
                      {currency?.symbol} {convertCurrency(row?.original?.average_price)}
                    </span>
                  </p>
                )} */}
              </>
              /* eslint-enable */
            );
          }
        }
      );
    }

    return columnsArray;
  }, [isMobile, defaultCurrency, showSecondPrice, currency, convertCurrency]);

  const table = useMaterialReactTable({
    columns,
    data: inventoryData,
    // enableRowSelection: true,
    enableRowSelection: (row) => {
      /* eslint-disable */

      const marketStatus = row.original?.market_status;
      /* eslint-enable */
      return marketStatus !== '1';
    },
    getRowId: (row) => /* eslint-disable */ row?.item_id /* eslint-enable */,
    onRowSelectionChange: setRowSelection,
    // initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onSortingChange: setSorting,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight: 'calc(100vh - 360px)', overflow: 'auto' },
      onScroll: _.debounce((event) => fetchMoreOnBottomReached(event.target))
    },
    muiTablePaperProps: {
      sx: {
        borderRadius: '0',
        border: '1px solid rgb(255 255 255 / 20%) '
      }
    },
    muiSelectCheckboxProps: {
      className: 'zipit-selected-checkbox'
    },
    muiTableBodyProps: {
      sx: {
        '& tr td': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        },
        '& tr:not([data-selected="true"])': {
          backgroundColor: 'transparent'
        }
      }
    },
    mrtTheme: {
      baseBackgroundColor: '#0b051b',
      selectedRowBackgroundColor: '#ffffff1a'
    },

    muiTableHeadProps: {
      sx: {
        '& tr th': {
          borderBottom: '1px solid rgb(255 255 255 / 20%)'
        }
      }
    },

    muiCircularProgressProps: {
      color: 'secondary',
      thickness: 5,
      size: 75
    },

    rowCount,
    state: {
      // isLoading: true,
      //   isLoading: loading,
      rowSelection,
      sorting
    }
  });

  const regularObj = bulkSellItem.filter((item) => item.auctionType === 'regular').length;
  const lightingObj = bulkSellItem.filter((item) => item.auctionType === 'lighting').length;

  const handleOutsideClick = (event) => {
    if (!buttonRef.current?.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal sell-individual-main-modal" ref={mobileRef}>
        <div className="sell-modal-area">
          <button
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            className="modal-close"
            onClick={onBulkSellModalClose}
            // style={{ top: '-10px', right: '-10px', backgroundColor: 'hsl(0deg 0% 0% / 80%)' }}
          >
            <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
          </button>
          <div className="individual-sell-modal">
            <div className="individual-sell position-relative">
              <div>
                <div>
                  <div className="individual-sell-heading">
                    <div className="individual-sell-heading-div">
                      <div className="individual-inner-div">
                        <p className="offer-heading">{addToItem ? 'inventory' : 'Sell individually'}</p>
                        {!addToItem && <p className="sell-item-number">{bulkSellItemsArray.length}</p>}
                        <p>{bulkSellItemsArray.length} selected</p>

                        {/* {!changeSellType && (
                          <div className="individual-sell-modal-select">
                            <div className="d-flex gap-10 align-items-center custom-select-container">
                              <div className="selected-option currency-div" onClick={() => setShowOptions(!showOptions)}>
                                <div className="d-flex gap-10">{bulkSellItemsArray.length} selected</div>
                                <img src="/images/header/down-arrow.svg" alt="Zipit" className="img-fluid"></img>
                              </div>
                              <div className="">
                                <img src="/images/footer/info.svg" alt="Zipit" className="img-fluid"></img>
                              </div>
                              {showOptions && (
                                <ul className="options">
                                  <li className="option" onClick={hendelRemove}>
                                    Remove
                                  </li>
                                  <li
                                    className="option"
                                    onClick={() => {
                                      onChangeListingType();
                                    }}
                                  >
                                    Change Listing Type
                                  </li>
                                </ul>
                              )}
                            </div>
                            <button className="text-white button-link white-border-button" onClick={ClearChanges}>
                              Clear
                            </button>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <div className="bulk-sell-select-div d-flex">
                    {!changeSellType && !addToItem && (
                      <div className="individual-sell-modal-select">
                        <div className="d-flex gap-10 align-items-center custom-select-container" ref={buttonRef}>
                          <div className="selected-option currency-div" onClick={() => setShowOptions(!showOptions)}>
                            <div className="d-flex gap-10">{bulkSellItemsArray.length} selected</div>
                            <img src="/images/header/down-arrow.svg" alt="Zipit" className="img-fluid"></img>
                          </div>
                          {/* <div className="">
                            <img src="/images/footer/info.svg" alt="Zipit" className="img-fluid"></img>
                          </div> */}
                          {showOptions && (
                            <ul className="options">
                              <li className="option" onClick={hendelRemove}>
                                Remove
                              </li>
                              <li
                                className="option"
                                onClick={() => {
                                  onChangeListingType();
                                }}
                              >
                                Change Listing Type
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="bulk-sell-edit-div sell-modal-button">
                      {addToItem ? (
                        <div className="header-search position-relative">
                          <img
                            src="/images/header/header-search.svg"
                            alt="Zipit"
                            height={15}
                            width={15}
                            className="img-fluid search-icon"
                          />
                          <input
                            type="text"
                            placeholder="Search for an item"
                            className="ng-valid ng-dirty ng-touched"
                            onChange={(e) => onSearch(e.target.value)}
                          />
                          {/* {inputSearch && (
                                <button className="clear-search-button" onClick={clearSearch}>
                                  <img
                                    src="/images/common/whiteclose.svg"
                                    height={10}
                                    alt="Zipit"
                                    className="img-fluid"
                                  />
                                </button>
                              )} */}
                        </div>
                      ) : (
                        <div className=" mt-0">
                          <button onClick={() => ToggleEvent()} className="sell-edit-button button-link">
                            Edit
                          </button>
                        </div>
                      )}
                      {!changeSellType && !addToItem && (
                        <button className="text-white button-link sell-clear-button" onClick={ClearChanges}>
                          Clear
                        </button>
                      )}
                      {addToItem && (
                        // <div className="select-button-number">
                        <div className="offer-button-div mt-0">
                          <button
                            onClick={() => {
                              ToggleEvent();
                              onBulkSell();
                            }}
                            className="green-button button-link"
                          >
                            Done
                          </button>
                        </div>
                        // </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bulk-sell-table">
                {addToItem ? (
                  <BulkSellAdd table={table} />
                ) : (
                  <>
                    {isMobile ? (
                      <MobileSellTable
                        data={bulkSellItemsArray}
                        rowCount={bulkSellItemsArray?.length}
                        onRemoveItem={onRemoveItem}
                        tableContainerRef={tableContainerRef}
                        showSecondPrice={showSecondPrice}
                        defaultCurrency={defaultCurrency}
                        currency={currency}
                        selectedRowItem={selectedRowItem}
                        setSelectedRowItem={setSelectedRowItem}
                        auctionType={auctionType}
                        runTime={runTime}
                        onSavePrice={onSavePrice}
                        showPrice={showPrice}
                        showConvertedPrice={showConvertedPrice}
                        addPrice={addPrice}
                        handleInputChange={handleInputChange}
                        setSorting={setSorting}
                        sorting={sorting}
                        onCloseModal={onCloseModal}
                        convertedAmount={convertedAmount}
                        priceModal={priceModal}
                        selectedSellItem={selectedSellItem}
                        prices={prices}
                        error={error}
                        listedPrice={listedPrice}
                        earning={earning}
                        PriceError={PriceError}
                      />
                    ) : (
                      <BulkSellTable
                        data={bulkSellItemsArray}
                        rowCount={bulkSellItemsArray?.length}
                        onRemoveItem={onRemoveItem}
                        tableContainerRef={tableContainerRef}
                        showSecondPrice={showSecondPrice}
                        defaultCurrency={defaultCurrency}
                        currency={currency}
                        selectedRowItem={selectedRowItem}
                        setSelectedRowItem={setSelectedRowItem}
                        auctionType={auctionType}
                        runTime={runTime}
                        onSavePrice={onSavePrice}
                        showPrice={showPrice}
                        showConvertedPrice={showConvertedPrice}
                        addPrice={addPrice}
                        handleInputChange={handleInputChange}
                        setSorting={setSorting}
                        sorting={sorting}
                        onCloseModal={onCloseModal}
                        convertedAmount={convertedAmount}
                        priceModal={priceModal}
                        selectedSellItem={selectedSellItem}
                        prices={prices}
                        error={error}
                        listedPrice={listedPrice}
                        earning={earning}
                        PriceError={PriceError}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="sell-popup-text-div">
              <p className="font-20 font-700">Bulk</p>
              <div className="auction-number-main-div">
                <div>
                  {regularObj && regularObj > 0 ? (
                    <div className="auction-number-div">
                      <div className="auction-number">
                        <p className="font-12 font-700">{regularObj}</p>
                        <p className="font-12 font-700">X</p>
                        <p className="font-12 font-700">regular Auction</p>
                      </div>
                      <div className="commission-div">
                        <p className="font-12 font-700">{commissionRate && commissionRate + '%  Commission'}</p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {lightingObj && lightingObj > 0 ? (
                    <div className="auction-number-div">
                      <div className="auction-number">
                        <p className="font-12 font-700">{lightingObj}</p>
                        <p className="font-12 font-700">X</p>
                        <p className="font-12 font-700">lighting Auction</p>
                      </div>
                      <div className="commission-div">
                        <p className="font-12 font-700">{commissionRate && commissionRate + '%  Commission'}</p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="auction-number-div">
                    <div className="auction-number">
                      <p className="font-12 font-700">
                        {bulkSellItem.length > 0 && bulkSellItem.filter((item) => item.selltype === 'market').length}
                      </p>
                      <p className="font-12 font-700">X</p>
                      <p className="font-12 font-700">Fixed Price</p>
                    </div>
                    <div className="commission-div">
                      <p className="font-12 font-700">{commissionRate && commissionRate + '% Commission'}</p>
                    </div>
                  </div>
                  <p className="font-12 opacity-white">You are about to start {bulkSellItemsArray.length} listings</p>
                </div>
                <div>
                  <label className="sell-label">
                    I accept the
                    <a href="/" rel="noreferrer" target="_blank" className="text-white text-underline">
                      terms
                    </a>{' '}
                    and confirm that I want to sell these items.
                    <input name="terms" type="checkbox" onChange={(e) => setAcceptTerms(e.target.checked)} />
                    <span className="checkmark l-0"></span>
                  </label>
                </div>
              </div>
              <div className="offer-button-div">
                <button disabled={!acceptTerms} className="green-button" onClick={handleBulkSell}>
                  Start all listings
                </button>
              </div>
            </div>
          </div>
        </div>
      </aside>
      {listingTypeModal && (
        <BulkSellModal
          setListingTypeModal={setListingTypeModal}
          runTime={runTime}
          setRunTime={setRunTime}
          sellType={sellType}
          setSellType={setSellType}
          auctionType={auctionType}
          setAuctionType={setAuctionType}
          onSellTypeSave={onSellTypeSave}
          selectedRowItem={selectedRowItem}
        />
      )}
    </div>
  );
};

BulkSellList.propTypes = {
  onBulkSellModalClose: PropTypes.func,
  bulkSellItem: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setBulkSellItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  rowSelection: PropTypes.object,
  setRowSelection: PropTypes.func,
  setBulkSellModal: PropTypes.func,
  fetchInventoryData: PropTypes.func,
  isMobile: PropTypes.bool,
  setPrices: PropTypes.func,
  prices: PropTypes.object
};

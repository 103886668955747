import React, { useEffect, useState } from 'react';
import { page_template } from 'api';
import { useApi } from 'hooks/useApi';

export const About = () => {
  const [data, setData] = useState();
  const { apiCaller: onTermsDataGet } = useApi({
    dataGetter: page_template
  });

  const fetchData = () => {
    let params = {
      pages: 'About Zipit'
    };
    onTermsDataGet(params)
      .then((res) => {
        setData(res.data.item);
      })
      .catch((err) => console.error(err.message));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="about-main-div common-page-div">
        <div className="container-fluid">
          <div className="container">
            <div className="common-page-inner-div max-900 text-center">
              <div className="privacy-main-div">
                <div className="about-div">
                  <p className="main_heading">{data?.label ? data?.label : 'About Zipit'}</p>
                  <div className="static-page-content" dangerouslySetInnerHTML={{ __html: data?.content }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

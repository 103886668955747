import React, { useEffect, useState } from 'react';
import { ProductSkin } from 'components/SingleInventoryView';

import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from 'hooks/useApi';
import {
  add_following,
  axiosAuthInstance,
  check_api_key,
  get_similar_inventory,
  inventory_list_view,
  remove_sell_inventory,
  steam_pricehistory,
  steam_set_api_key,
  user_bid_add,
  buy_inventory
} from 'api';
import { Checkout, ConfirmBid, EditListing, Graph, LoginWithSteamModal, SimilarProduct, SteamApiKeyModal, UserBanModal } from 'components';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
export const SingleInventoryView = () => {
  document.title = 'Zipit - Inventory Skin';
  const { item_id } = useParams();
  const navigate = useNavigate();

  const userBan = useSelector((state) => state.user.userBlockDetails);
  const [isUserBanModalOpen, setIsUserBanModalOpen] = useState(false);

  const [confirmBuyModal, setConfirmBuyModal] = useState(false);
  const [editListingModal, setEditListingModal] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState();
  const [item, setItem] = useState();
  const [status, setStatus] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [similarProduct, setSimilarProduct] = useState([]);

  const [acceptWarning, setAcceptWarning] = useState(false);
  const [loginWithSteam, setLoginWithSteam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkOutItemData, setCheckOutItemData] = useState();

  //steam api key setter and getter hooks
  const [steamAPiKeyModal, setSteamApiKeyModal] = useState(false);
  const [apiKey, setApiKey] = useState({ api_key: '', tread_url: '' });
  const [errors, setErrors] = useState({});
  const [apiKeyErrorMessage, setApiKeyErrorMessage] = useState();

  const [successMes, setSuccessMes] = useState({});
  const [bidArray, setBidArray] = useState([]);
  const [confirmBidModal, setConfirmBidModal] = useState(false);

  const [bidItemData, setBidItemData] = useState();
  const [isLoadingBid, setIsLoadingBid] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);

  const [checkBidStatus, setCheckBidStatus] = useState(false);
  const [folloingCount, setFollowingCount] = useState(0);
  const [tradeCount, setTradeCount] = useState(0);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);

  const [priceHistory, setPriceHistory] = useState([]);
  const [graphDays, setGraphDays] = useState({ labels: 'Last 2 weeks', days: '15' });
  const [showOptions, setShowOptions] = useState(false);

  const [follow, setFollow] = useState([]);

  const Bearer_Token = Cookies.get('Bearer_Token');

  const tf2Currencies = JSON.parse(localStorage.getItem('tf2-currencies'));
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const showSecondPrice = tf2Currencies && tf2Currencies.code !== defaultCurrency.code;
  const currencies = showSecondPrice ? tf2Currencies.code : defaultCurrency.code;

  useEffect(() => {
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${Bearer_Token}`;
  }, [Bearer_Token]);

  const { apiCaller: single_skin } = useApi({
    dataGetter: inventory_list_view
  });

  const { apiCaller: onApiKeyCheck } = useApi({
    dataGetter: check_api_key
  });

  const { apiCaller: onSteamSetApiKey } = useApi({
    dataGetter: steam_set_api_key
  });

  const { apiCaller: onCheckOutItem } = useApi({
    dataGetter: buy_inventory
  });

  const { apiCaller: onSellItemDelete } = useApi({
    dataGetter: remove_sell_inventory
  });

  const { apiCaller: onGetSimilarInventory } = useApi({
    dataGetter: get_similar_inventory
  });

  const { apiCaller: onBidAddByUser } = useApi({
    dataGetter: user_bid_add
  });

  const { apiCaller: onFollowItem } = useApi({
    dataGetter: add_following
  });

  const { apiCaller: steamPriceHistory } = useApi({
    dataGetter: steam_pricehistory
  });

  const onCheckoutModal = (item) => {
    setCheckOutItemData(item);
    if (Bearer_Token === null) {
      document.body.classList.add('modal-open');
      setLoginWithSteam(true);
    } else {
      try {
        if (userBan.status === 'block') {
          setIsUserBanModalOpen(true);
        } else {
          setIsLoading(true);
          onApiKeyCheck().then((res) => {
            if (res.data.api_status === 1) {
              setTimeout(() => {
                setIsLoading(false);
                document.body.classList.add('modal-open');
                setConfirmBuyModal(true);
              }, 2000);
            } else {
              if (res.data.api_status === 0) {
                setTimeout(() => {
                  setIsLoading(false);
                  setApiKeyErrorMessage(res.data);
                  document.body.classList.add('modal-open');
                  setSteamApiKeyModal(true);
                }, 2000);
              }
            }
          });
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const onConfirmBuy = (market_id) => {
    onCheckOutItem({ market_id }).then((res) => {
      if (res.data.status === 'success') {
        setConfirmBuyModal(false);
        document.body.classList.remove('modal-open');
      }
    });
  };

  const fetchData = async (item_id) => {
    try {
      setIsLoadingProduct(true);
      const response = await single_skin({ item_id });

      if (response.data.status === 'success') {
        setItem(response.data.data);
        setFollowingCount(Number(response.data.following_count));
        setTradeCount(Number(response.data.trade_count));
        setBidArray(response.data.totalbid);
        const similerProductRes = await onGetSimilarInventory({
          category: response.data.data.category,
          market_id: item?.m_id
        });
        setSimilarProduct(similerProductRes.data.item);
        const similerProductArray = similerProductRes.data.item;
        const followItems = similerProductArray.map((product) => ({
          market_id: Number(product.m_id),
          status: product.following_status
        }));
        setFollow(followItems);
        const priceArray = await steamPriceHistory({
          hasbyname: response.data.data.item.fullname,
          days: graphDays.days,
          currency: currencies
        });
        setPriceHistory(priceArray.data.data);
        setStatus(true);
      } else {
        setStatus(false);
      }
      setTimeout(() => {
        setIsLoadingProduct(false);
      }, 1000);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData(item_id);
  }, [item_id]);

  const onDelete = (market_id) => {
    onSellItemDelete({ market_id }).then((res) => {
      if (res.data.status === 'success') {
        document.body.classList.remove('modal-open');
        setDeleteModal(false);
        setEditListingModal(false);
        navigate('/inventory');
      }
    });
  };

  const onEditListing = () => {
    document.body.classList.add('modal-open');
    setEditListingModal(true);
  };

  const closeModal = () => {
    document.body.classList.remove('modal-open');
    setConfirmBuyModal(false);
    setEditListingModal(false);
    setConfirmBidModal(false);
  };

  const deleteModalClose = () => {
    setDeleteModal(false);
  };

  const onCloseLoginModal = () => {
    document.body.classList.remove('modal-open');
    setLoginWithSteam(false);
  };

  const onCloseAPiKeyModal = () => {
    document.body.classList.remove('modal-open');
    setSteamApiKeyModal(false);
  };

  const validateValues = (apiKey) => {
    let errors = {};
    if ((apiKeyErrorMessage.status === 'error' || apiKeyErrorMessage.status === 'api-error') && apiKey.api_key === '') {
      errors.api_key = 'This field is required';
    }
    if ((apiKeyErrorMessage.status === 'error' || apiKeyErrorMessage.status === 'trade-error') && apiKey.tread_url === '') {
      errors.tread_url = 'This field is required';
    }
    return errors;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateValues(apiKey);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        setErrors({});
        const param = {};
        if (apiKey.api_key) {
          param.api_key = apiKey.api_key;
        }
        if (apiKey.tread_url) {
          param.tread_url = apiKey.tread_url;
        }
        const res = await onSteamSetApiKey(param);
        if (res.data.status === 'failed') {
          setErrors({ api_key: res.data.message });
        } else {
          setSteamApiKeyModal(false);
          if (checkBidStatus) {
            setConfirmBidModal(true);
          } else {
            setConfirmBuyModal(true);
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };
  const followHandle = (marketId) => {
    if (!Bearer_Token) {
      document.body.classList.add('modal-open');
      setLoginWithSteam(true);
    } else {
      onFollowItem({ market_id: marketId }).then((res) => {
        if (res.data.status === 'success') {
          if (res.data.follow_status === 'delete') {
            const updatedFollow = follow.map((f) => {
              if (Number(f.market_id) === Number(marketId)) {
                return { ...f, status: false };
              }
              return f;
            });
            setFollow(updatedFollow);
          } else {
            const updatedFollow = follow.map((f) => {
              if (Number(f.market_id) === Number(marketId)) {
                return { ...f, status: true };
              }
              return f;
            });
            setFollow(updatedFollow);
          }
        }
      });
    }
  };

  const onBidAdd = (itemData, indexNum) => {
    setBidItemData(itemData);
    setLoadingIndex(indexNum);
    if (!Bearer_Token) {
      document.body.classList.add('modal-open');
      setLoginWithSteam(true);
    } else {
      if (userBan.status === 'block') {
        setIsUserBanModalOpen(true);
      } else {
        setIsLoadingBid(true);
        onApiKeyCheck().then((res) => {
          setCheckBidStatus(true);
          if (res.data.api_status === 1) {
            setTimeout(() => {
              setIsLoadingBid(false);
              document.body.classList.add('modal-open');
              setConfirmBidModal(true);
            }, 1000);
          } else {
            if (res.data.api_status === 0) {
              setTimeout(() => {
                setIsLoadingBid(false);
                setApiKeyErrorMessage(res.data);
                document.body.classList.add('modal-open');
                setSteamApiKeyModal(true);
              }, 1000);
            }
          }
        });
      }
    }
  };

  const ConfirmBidHandle = (marketId, bid) => {
    const params = {
      market_id: marketId,
      bidPrice: bid
    };
    onBidAddByUser(params).then((res) => {
      if (res.data.status === 'success') {
        document.body.classList.remove('modal-open');
        setConfirmBidModal(false);
        fetchData(item_id);
        setSuccessMes({ message: res.data.massage, type: res.data.status });
      }
    });
  };

  const handleGraphChangeOnDays = async (daysObject) => {
    setGraphDays(daysObject);
    setShowOptions(false);
    const priceArray = await steamPriceHistory({
      hasbyname: item.item.fullname,
      day: daysObject.days,
      currency: currencies
    });
    setPriceHistory(priceArray?.data?.data);
  };

  const userBanModalClose = () => {
    document.body.classList.remove('modal-open');
    setIsUserBanModalOpen(false);
  };

  return (
    <div>
      {status ? (
        <>
          {loginWithSteam && <LoginWithSteamModal onClose={onCloseLoginModal} />}
          {steamAPiKeyModal && (
            <SteamApiKeyModal onSubmit={onSubmit} setApiKey={setApiKey} closeModal={onCloseAPiKeyModal} errors={errors} />
          )}

          {isUserBanModalOpen && (
            <UserBanModal setIsUserBanModalOpen={setIsUserBanModalOpen} userBan={userBan} userBanModalClose={userBanModalClose} />
          )}
          <ProductSkin
            onEditListing={onEditListing}
            item={item}
            bidArray={bidArray}
            folloingCount={folloingCount}
            tradeCount={tradeCount}
            isLoadingProduct={isLoadingProduct}
          />
          {confirmBidModal && (
            <ConfirmBid
              ConfirmBidHandle={ConfirmBidHandle}
              closeModal={closeModal}
              setAcceptTerms={setAcceptTerms}
              acceptTerms={acceptTerms}
              showSecondPrice={showSecondPrice}
              tf2Currencies={tf2Currencies}
              defaultCurrency={defaultCurrency}
              bidItemData={bidItemData}
              setBidItemData={setBidItemData}
            />
          )}
          {confirmBuyModal && (
            <Checkout
              closeModal={closeModal}
              setAcceptTerms={setAcceptTerms}
              acceptTerms={acceptTerms}
              item={checkOutItemData}
              showSecondPrice={showSecondPrice}
              tf2Currencies={tf2Currencies}
              defaultCurrency={defaultCurrency}
              acceptWarning={acceptWarning}
              setAcceptWarning={setAcceptWarning}
              onConfirmBuy={onConfirmBuy}
            />
          )}
          {editListingModal && (
            <EditListing
              closeModal={closeModal}
              deleteModalClose={deleteModalClose}
              item={item}
              setDeleteModal={setDeleteModal}
              deleteModal={deleteModal}
              onDelete={onDelete}
              setEditListingModal={setEditListingModal}
              fetchData={fetchData}
              errors={errors}
              id={item_id}
            />
          )}
          <Graph
            isLoadingProduct={isLoadingProduct}
            priceHistory={priceHistory}
            graphDays={graphDays}
            setShowOptions={setShowOptions}
            showOptions={showOptions}
            handleGraphChangeOnDays={handleGraphChangeOnDays}
          />
          <SimilarProduct
            similarProduct={similarProduct}
            defaultCurrency={defaultCurrency}
            onCheckoutModal={onCheckoutModal}
            isLoading={isLoading}
            onBidAdd={onBidAdd}
            loadingIndex={loadingIndex}
            isLoadingBid={isLoadingBid}
            followHandle={followHandle}
            follow={follow}
            successMes={successMes}
            isLoadingProduct={isLoadingProduct}
          />
        </>
      ) : (
        <>
          <div className="common-page-inner-div">
            <p>No have A Data</p>
          </div>
        </>
      )}
    </div>
  );
};

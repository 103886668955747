import { add_following, axiosAuthInstance, get_following_data, set_auction_timeprice } from 'api';
import { AlertModal, SkeletonLoader, WishlistIcon } from 'components';
import { useApi } from 'hooks/useApi';
import { itemNameConverter, wearNameConverter } from 'lib';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { SettingSideBar, CountdownTimer } from 'components';
import Cookies from 'js-cookie';

export const Following = () => {
  document.title = 'Zipit - Follow';
  const ref = useRef(null);
  const navigate = useNavigate();

  const [following, setFollowing] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [visible, setVisible] = useState();
  const [rowCount, setRowCount] = useState(0);
  const [newArray, setNewArray] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [AlertModalOpen, setAlertModalOpen] = useState(false);
  const [alertData, setAlertData] = useState();

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const [alertPrice, setAlertPrice] = useState();

  const defaultCurrency = JSON.parse(localStorage.getItem('default'));

  const steamToken = Cookies.get('Bearer_Token');

  useEffect(() => {
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${steamToken}`;
  }, [steamToken]);

  const { apiCaller: onGetFollowing } = useApi({
    dataGetter: get_following_data
  });

  const { apiCaller: onFollowItem } = useApi({
    dataGetter: add_following
  });

  const { apiCaller: onAuctionTimeAlert } = useApi({
    dataGetter: set_auction_timeprice
  });

  const fetchData = useCallback(
    async (page) => {
      try {
        setIsLoading(true);
        let params = {
          page
        };

        const res = await onGetFollowing(params);

        if (res.data.item.last_page > page) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        const newData = res.data.item.data.filter((item, index, self) => index === self.findIndex((i) => i.id === item.id));

        setFollowing((prev) => {
          const updatedData = page === 1 ? newData : [...prev, ...newData];
          const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

          const newVisible = uniqueArray.length;
          setVisible(newVisible);
          return uniqueArray;
        });
        setRowCount(res.data.item.total);
      } catch (error) {
        console.error(error.message);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    },
    [onGetFollowing]
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, currentPage]);

  const fetchMoreOnBottomReached = () => {
    if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
      if (visible < rowCount) {
        setCurrentPage(currentPage + 1);
        // fetchData(selectedCategory, currentPage + 1, search);
      }
    }
  };

  useEffect(() => {
    visible < rowCount && rowCount > 0
      ? window.addEventListener('scroll', fetchMoreOnBottomReached)
      : window.removeEventListener('scroll', fetchMoreOnBottomReached);
    // Attach scroll event listener when the component mounts
    window.addEventListener('scroll', fetchMoreOnBottomReached);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', fetchMoreOnBottomReached);
    };
  }, [visible]);

  const followHandle = (marketId) => {
    onFollowItem({ market_id: marketId }).then((res) => {
      if (res.data.status === 'success') {
        if (res.data.follow_status === 'delete') {
          toast.success('Entry removed from the following');
          const updatedFollow = following.filter((f) => Number(f.m_id) !== Number(marketId));
          setNewArray(updatedFollow);
        }
      }
    });
  };

  useEffect(() => {
    setFollowing(newArray);
    const newVisible = newArray.length;
    setVisible(newVisible);
  }, [newArray]);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setIsMobile(ref.current.clientWidth <= 767);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    handleResize(); // Initial check

    return () => {
      resizeObserver.disconnect();
    };
  }, [isMobile]);

  const handleAlert = (item) => {
    setAlertModalOpen(true);
    setAlertData(item);
    document.body.classList.add('modal-open');
  };

  const closeModal = () => {
    setAlertModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const onSaveAlterTime = () => {
    // Ensure days, hours, and minutes are integers
    const daysInt = Number.isInteger(days) ? days : 0;
    const hoursInt = Number.isInteger(hours) ? hours : 0;
    const minutesInt = Number.isInteger(minutes) ? minutes : 0;

    // Construct params object
    const params = {
      m_id: alertData?.m_id,
      alertprice: alertPrice || null,
      alertTimeLeft: {
        days: daysInt,
        hours: hoursInt,
        minutes: minutesInt
      }
    };

    // Call the onAuctionTimeAlert function
    onAuctionTimeAlert(params)
      .then((res) => {
        if (res.data.status === 'Success') {
          fetchData(1);
          closeModal();
          setDays(0);
          setHours(0);
          setMinutes(0);
        } else {
          toast.error(res.data.massage);
        }
      })
      .catch((error) => {
        console.error('Error saving alert time:', error.message);
      });
  };

  return (
    <div className="profile-page-main-div">
      <div className="container-fluid">
        <div className="container">
          <div className="profile-page-inner-div">
            <SettingSideBar />
            <div className={`following-table profile-page-common-div pb-60`} ref={ref}>
              <div className="profile-page-heading">
                <p>Following</p>
              </div>
              <div className="following-table-div">
                <div className={`product-display-table ${!isLoading && (!following || following.length === 0) ? 'no-following-data' : ''}`}>
                  {AlertModalOpen && (
                    <AlertModal
                      alertData={alertData}
                      closeModal={closeModal}
                      onSaveAlterTime={onSaveAlterTime}
                      setAlertPrice={setAlertPrice}
                      setDays={setDays}
                      setHours={setHours}
                      setMinutes={setMinutes}
                      days={days}
                      hours={hours}
                      minutes={minutes}
                      alertPrice={alertPrice}
                    />
                  )}
                  {!isLoading && (!following || following.length === 0) ? (
                    <div className="no-data">No data available</div>
                  ) : isMobile ? (
                    <>
                      <table className="responsive-table">
                        <tbody>
                          {isLoading
                            ? [...Array(10)].map((_, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="following-product">
                                      <SkeletonLoader
                                        width="99%"
                                        height={93}
                                        count={1}
                                        color="#0b051b"
                                        highlightColor="#242347"
                                        className="product-display-image"
                                      />
                                      <div className="display-product-name">
                                        <h3 className="font-700 mb-5 text-left">
                                          <SkeletonLoader width="100%" height={35} count={1} color="#0b051b" highlightColor="#242347" />
                                        </h3>
                                        <div className="following-skeleton-desc d-flex gap-10">
                                          <SkeletonLoader
                                            width="99%"
                                            height={25}
                                            count={1}
                                            color="#0b051b"
                                            highlightColor="#242347"
                                            className="display-product-name"
                                          />
                                          <SkeletonLoader
                                            width="99%"
                                            height={25}
                                            count={1}
                                            color="#0b051b"
                                            highlightColor="#242347"
                                            className="display-product-name"
                                          />
                                        </div>
                                      </div>
                                      <div className="wishlist-icon following-wishlist-skeleton">
                                        <SkeletonLoader width="99%" height={35} count={1} color="#0b051b" highlightColor="#242347" />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            : following &&
                              following.map((item, index) => {
                                const stickers = item.item.stickers;
                                const alertTimeLeft = JSON.parse(item.alertTimeLeft);
                                const days = item.runtime;
                                const daysCount = days !== null ? Number(days) : null;
                                const createdDate = item.cread_date;
                                return (
                                  <>
                                    <tr key={index} id={item?.m_id}>
                                      <td>
                                        {/* <Link to={`/market/${item.m_id}`}> */}
                                        <div className="following-product">
                                          <div
                                            className="product-display-image goods-card-main-div position-relative"
                                            onClick={() => navigate(`/market/${item.m_id}`)}
                                          >
                                            <img
                                              src={`
                                              ${item && process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}
                                              ${item && item.item.imageUrl}
                                            `}
                                              alt="zipit"
                                              className="img-fluid display-product-image"
                                            />
                                            <img
                                              src="/images/sell-modal/new-background-image.jpg"
                                              alt="sell-background"
                                              className="img-fluid sell-background"
                                            ></img>
                                            <p className="wear-name text-white">
                                              <span className="font-12">{item?.wear && wearNameConverter(item?.wear)}</span>
                                              <span className="font-10">
                                                {item?.wear !== 'N/A' ? `/ ${Number(item?.float_id).toFixed(4)} ` : ''}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="display-product-name text-left" onClick={() => navigate(`/market/${item.m_id}`)}>
                                            <h3 className="font-700 text-white product-name">
                                              {item.item && itemNameConverter(item.item.fullname)}
                                            </h3>
                                            <p className="d-flex gap-5 font-14 text-white following-product-desc">
                                              <span className="text-white">{item && item.category}</span>*
                                              <span className="text-white">{item.item.subCategory}</span>
                                            </p>
                                            <div className="product-sticker d-flex">
                                              {stickers.map((item, index) => {
                                                return (
                                                  <div
                                                    className="product-display-sticker"
                                                    key={index}
                                                    // dangerouslySetInnerHTML={{ __html: item.value }}
                                                  >
                                                    <div className="single-sticker-div">
                                                      <img src={item.value} alt="sticker" />
                                                      {item.key && (
                                                        <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                                          <span>{item.key}</span>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                            {daysCount !== null && (
                                              // <td>
                                              <CountdownTimer daysCount={daysCount} createdDate={createdDate} key={index} />
                                            )}
                                            {/* // </td> */}
                                          </div>

                                          <div className="following-alert">
                                            {item.selltype === 'auction' && (
                                              <div onClick={() => handleAlert(item)} className="alert-setting">
                                                <img src="/images/common/setting.svg" alt="setting" title="" />
                                              </div>
                                            )}
                                            <div className="wishlist-icon follow-active">
                                              <div className="tooltip-image ml-0" onClick={() => followHandle(item.m_id)}>
                                                <WishlistIcon />
                                              </div>
                                              <div className="tooltiptext">
                                                <p className="font-14 pb-0">unfollow</p>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="wishlist-icon follow-active">
                                        <div
                                          className="tooltip-image ml-0"
                                          onClick={() => followHandle(item.m_id)}
                                        >
                                          <WishlistIcon />
                                        </div>
                                        <div className="tooltiptext">
                                          <p className="font-14 pb-0">unfollow</p>
                                        </div>
                                      </div> */}
                                        </div>
                                        {/* </Link> */}
                                        {item.selltype === 'auction' && (
                                          <div className="mobile-alert">
                                            {item.selltype === 'auction' && (
                                              <>
                                                <div className="following-alert-text">
                                                  {alertTimeLeft ? (
                                                    <>
                                                      <p className="opacity-white pb-5">Alert when</p>
                                                      {item.alertprice && (
                                                        <p className="pb-0 font-14">
                                                          Price does not reach {defaultCurrency && defaultCurrency.symbol}
                                                          {item.alertprice}
                                                        </p>
                                                      )}
                                                      <p className="alert-time-left">
                                                        {alertTimeLeft.days !== 0 && <span>{alertTimeLeft.days + ' days'}</span>}
                                                        {alertTimeLeft.hours !== 0 && <span>{alertTimeLeft.hours + ' hours'}</span>}
                                                        {alertTimeLeft.minutes !== 0 && <span>{alertTimeLeft.minutes + ' minutes'}</span>}
                                                        left
                                                      </p>
                                                    </>
                                                  ) : (
                                                    <p className="opacity-white">No alerts set for this auction</p>
                                                  )}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Prices</th>
                            <th>Time left</th>
                            <th>Alert when</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading
                            ? [...Array(10)].map((_, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="following-product">
                                      <SkeletonLoader
                                        width="99%"
                                        height={93}
                                        count={1}
                                        color="#0b051b"
                                        highlightColor="#242347"
                                        className="product-display-image"
                                      />

                                      <div className="display-product-name">
                                        <div className="mb-10 ">
                                          <SkeletonLoader
                                            width="99%"
                                            height={20}
                                            count={1}
                                            color="#0b051b"
                                            highlightColor="#242347"
                                            className="display-product-name"
                                          />
                                        </div>
                                        <div className="following-skeleton-desc d-flex gap-10 mb-10">
                                          <SkeletonLoader
                                            width="99%"
                                            height={25}
                                            count={1}
                                            color="#0b051b"
                                            highlightColor="#242347"
                                            className="display-product-name"
                                          />
                                          <SkeletonLoader
                                            width="99%"
                                            height={25}
                                            count={1}
                                            color="#0b051b"
                                            highlightColor="#242347"
                                            className="display-product-name"
                                          />
                                        </div>
                                        <div className="skeleton-sticker">
                                          <SkeletonLoader width="99%" height={45} count={4} color="#0b051b" highlightColor="#242347" />
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <SkeletonLoader width="99%" height={30} count={1} color="#0b051b" highlightColor="#242347" />
                                  </td>
                                  <td>
                                    <SkeletonLoader width="99%" height={30} count={1} color="#0b051b" highlightColor="#242347" />
                                  </td>
                                  <td>
                                    <SkeletonLoader width="99%" height={30} count={1} color="#0b051b" highlightColor="#242347" />
                                  </td>
                                </tr>
                              ))
                            : following &&
                              following.map((item, index) => {
                                const stickers = item.item.stickers;
                                const days = item.runtime;
                                const daysCount = days !== null ? Number(days) : null;
                                const createdDate = item.cread_date;
                                const price =
                                  item.max_bid.length > 0
                                    ? Number(item?.max_bid[0]?.max_bidprice).toFixed(2)
                                    : Number(item.startBid).toFixed(2);

                                const alertTimeLeft = JSON.parse(item.alertTimeLeft);
                                // setAlertTimeLeft(alertTimeLeftData);

                                return (
                                  <tr key={index} id={item?.m_id}>
                                    <td>
                                      <Link to={`/market/${item.m_id}`}>
                                        <div className="following-product">
                                          <div className="product-display-image goods-card-main-div position-relative">
                                            <img
                                              src={`
                                                ${item && process.env.REACT_APP_STEAM_IMAGE_SHOW_URL}
                                                ${item && item.item.imageUrl}
                                              `}
                                              alt="zipit"
                                              className="img-fluid display-product-image"
                                            />
                                            <img
                                              src="/images/sell-modal/new-background-image.jpg"
                                              alt="sell-background"
                                              className="img-fluid sell-background"
                                            ></img>
                                            <p className="wear-name text-white">
                                              <span className="font-12">{item?.wear && wearNameConverter(item?.wear)}</span>
                                              <span className="font-10">
                                                {item?.wear !== 'N/A' ? `/ ${Number(item?.float_id).toFixed(4)} ` : ''}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="display-product-name">
                                            <h3 className="font-700 text-white product-name">
                                              {item.item && itemNameConverter(item.item.fullname)}
                                            </h3>
                                            <p className="d-flex gap-5 font-14 text-white">
                                              <span className="text-white">{item && item.category}</span>*
                                              <span className="text-white">{item.item.subCategory}</span>
                                            </p>
                                            {stickers && stickers.length > 0 && (
                                              <div className="product-sticker d-flex">
                                                {stickers.map((item, index) => {
                                                  return (
                                                    <div
                                                      className="product-display-sticker"
                                                      key={index}
                                                      // dangerouslySetInnerHTML={{ __html: item.value }}
                                                    >
                                                      <div className="single-sticker-div">
                                                        <img src={item.value} alt="sticker" />
                                                        {item.key && (
                                                          <div className="sticker-tooltip sticker-tooltip-top inventory-sticker-tooltip">
                                                            <span>{item.key}</span>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </Link>
                                    </td>
                                    <td>
                                      <Link to={`/market/${item.m_id}`}>
                                        <p className="font-16 text-white">
                                          {defaultCurrency && defaultCurrency.symbol} {price && price}
                                        </p>
                                      </Link>
                                    </td>
                                    {daysCount !== null ? (
                                      <td>
                                        <CountdownTimer daysCount={daysCount} createdDate={createdDate} key={index} />
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    <td>
                                      <div className="following-alert">
                                        {item.selltype === 'auction' && (
                                          <>
                                            <div className="following-alert-text">
                                              {alertTimeLeft ? (
                                                <>
                                                  <p className="opacity-white pb-5">Alert when</p>
                                                  {item.alertprice && (
                                                    <p className="pb-0 font-14">
                                                      Price does not reach {defaultCurrency && defaultCurrency.symbol}
                                                      {item.alertprice}
                                                    </p>
                                                  )}
                                                  <p className="alert-time-left">
                                                    {alertTimeLeft.days !== 0 && <span>{alertTimeLeft.days + ' days'}</span>}
                                                    {alertTimeLeft.hours !== 0 && <span>{alertTimeLeft.hours + ' hours'}</span>}
                                                    {alertTimeLeft.minutes !== 0 && <span>{alertTimeLeft.minutes + ' minutes'}</span>}
                                                    left
                                                  </p>
                                                </>
                                              ) : (
                                                <p className="opacity-white">No alerts set for this auction</p>
                                              )}
                                            </div>
                                          </>
                                        )}

                                        <div className="setting-icon-div">
                                          {item.selltype === 'auction' && (
                                            <div onClick={() => handleAlert(item)} className="alert-setting">
                                              <img src="/images/common/setting.svg" alt="setting" title="" />
                                            </div>
                                          )}

                                          <div className="wishlist-icon follow-active">
                                            <div className="tooltip-image ml-0" onClick={() => followHandle(item.m_id)}>
                                              <WishlistIcon />
                                            </div>
                                            <div className="tooltiptext">
                                              <p className="font-14 pb-0">unfollow</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Shop } from 'components/SellerShop';
import { useApi } from 'hooks/useApi';
import { seller_Shop } from 'api';
import { SettingSideBar, SkeletonLoader } from 'components';
import Cookies from 'js-cookie';

export const SellerShop = () => {
  document.title = 'Zipit - Seller Shop';

  const [shop, setShop] = useState([]);
  const [message, setMessage] = useState('');
  const [userDetails, setUserdetails] = useState(null);
  const [error, setError] = useState(null);
  const { hash_id } = useParams();

  const [rowCount, setRowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(true);
  const Bearer_Token = Cookies.get('Bearer_Token');

  const { apiCaller: onSellerShop } = useApi({
    dataGetter: seller_Shop
  });

  const skeletonItems = Array.from({ length: 10 });

  const fetchData = useCallback(
    async (page) => {
      try {
        setIsLoading(true);
        const queryParams = { page, hash_id };
        const res = await onSellerShop(queryParams);

        if (res.data.status === 'success') {
          setUserdetails(res.data.user_data);
          if (res.data.data === undefined) {
            setMessage(res.data.msg);
          } else {
            const newData = res.data.data.data.filter((item, index, self) => index === self.findIndex((i) => i.m_id === item.m_id));
            setShop((prev) => {
              const updatedData = page === 1 ? newData : [...prev, ...newData];
              const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));
              setVisible(uniqueArray.length);
              return uniqueArray;
            });
            setRowCount(Number(res.data.data.total));
          }
          setTimeout(() => {
            setIsLoading(false);
            setIsFetchingMore(false);
          }, 1000);
        } else {
          setError(res.data.msg);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while fetching data.');
      }
    },
    [onSellerShop, hash_id]
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, currentPage]);

  const fetchMoreOnBottomReached = useCallback(() => {
    if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
      if (visible < rowCount) {
        setCurrentPage((prev) => prev + 1);
        // setIsFetchingMore(true);
      }
    }
  }, [visible, rowCount]);

  useEffect(() => {
    if (visible < rowCount) {
      window.addEventListener('scroll', fetchMoreOnBottomReached);
    } else {
      window.removeEventListener('scroll', fetchMoreOnBottomReached);
    }
    return () => {
      window.removeEventListener('scroll', fetchMoreOnBottomReached);
    };
  }, [visible]);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className={Bearer_Token ? 'profile-page-main-div' : 'seller-shop-page'}>
      <div className="container-fluid">
        <div className="container">
          <div className="profile-page-inner-div">
            {Bearer_Token && <SettingSideBar />}
            <div className="shop-main-div profile-page-common-div">
              {error ? (
                <div className="no-similar-product">
                  <p className="opacity-white">{error ? error : 'This user has set their shop to private.'}</p>
                </div>
              ) : (
                <div className="inventory-inner-div position-relative">
                  <div className="profile-page-heading">
                    <p>My Shop</p>
                  </div>
                  <div className="user-details">
                    {userDetails ? (
                      <>
                        <div className="user-name-div">
                          <img src={`${userDetails?.steamdetails?.avatarfull}`} alt="user" />
                          <p className="font-20 font-700 pb-0">{userDetails?.personaname}</p>
                        </div>
                        <div className="user-detail-main-div">
                          <div className="user-detail-div">
                            <p>Steam Level</p>
                            <p>{userDetails?.steam_level}</p>
                          </div>
                        </div>
                        <div className="user-detail-main-div">
                          <div className="user-detail-div">
                            <p>Total trades</p>
                            <p>{userDetails?.total_trade}</p>
                          </div>
                          <div className="user-detail-div">
                            <p>Steam member since</p>
                            <p>{formatDate(userDetails?.steamdetails?.steam_createdtime)}</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <SkeletonLoader
                          height={60}
                          width="100%"
                          color="#0b051b"
                          highlightColor="#242347"
                          className="user-name-div"
                          borderRadius={0}
                        />
                        <SkeletonLoader
                          height={20}
                          width="100%"
                          count={2}
                          color="#0b051b"
                          highlightColor="#242347"
                          className="user-detail-div"
                          containerClassName="user-detail-main-div"
                          borderRadius={0}
                        />
                        <SkeletonLoader
                          height={20}
                          count={2}
                          width="100%"
                          color="#0b051b"
                          highlightColor="#242347"
                          className="user-detail-div"
                          containerClassName="user-detail-main-div"
                          borderRadius={0}
                        />
                      </>
                    )}
                  </div>

                  {shop.length > 0 ? (
                    <>
                      <div className="shop-heading">
                        <h3 className="font-20 text-uppercase pb-0">Shop</h3>
                      </div>
                      <div className="market-product-list shop-list resposive-product-list">
                        <div className="market-product-list">
                          <div className="market-product-row">
                            {isFetchingMore
                              ? skeletonItems.map((_, index) => (
                                  <div className="main-single-market-product" key={index}>
                                    <SkeletonLoader
                                      height={290}
                                      width="100%"
                                      color="#0b051b"
                                      highlightColor="#242347"
                                      className="single-market-product"
                                      borderRadius={0}
                                    />
                                  </div>
                                ))
                              : shop.map((item, index) => <Shop key={index} item={item} />)}

                            {Number(rowCount) !== 0 &&
                              isLoading &&
                              skeletonItems.map((_, index) => (
                                <div className="main-single-market-product" key={index}>
                                  <SkeletonLoader height={290} width="100%" color="#0b051b" highlightColor="#242347" borderRadius={0} />
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="no-similar-product">
                      <p className="opacity-white">{message && message}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

function MenuItem({ item, onCategory, selectedCategory, subSelectedCategory }) {
  const hasChildren = item.sub_categories && item.sub_categories.length > 0;
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const onSubMenuShow = () => {
    setSubMenuOpen(!subMenuOpen);
  };
  return (
    <>
      {hasChildren ? (
        <li className={`category-list ${selectedCategory === item.category ? 'active' : ''}`} onClick={onSubMenuShow}>
          <p className="category-name">
            {item.category && item.category} <img src="/images/common/right-arrow.svg" alt="right-aarow" />
          </p>

          <ul className={`list-options ${subMenuOpen ? ' show-submenu' : ''}`}>
            <li className="back-text" onClick={onSubMenuShow}>
              <img src="/images/common/left-arrow.svg" alt="left-aarow" /> Back
            </li>
            {item.sub_categories.map((subCategory, subIndex) => (
              <li
                className={`list-option ${subSelectedCategory === subCategory.subcategory ? 'active' : ''}`}
                key={subIndex}
                onClick={() => onCategory(item.category, subCategory.subcategory)}
              >
                {subCategory.img !== '' && (
                  <img src={subCategory.img} alt={subCategory.subcategory} className="img-fluid" title={subCategory.subcategory} />
                )}
                <span>{subCategory.subcategory && subCategory.subcategory}</span>
              </li>
            ))}
          </ul>
        </li>
      ) : (
        <li onClick={(e) => onCategory(item.category)} className={`category-list ${selectedCategory === item.category ? 'active' : ''}`}>
          <p> {item.category && item.category}</p>
        </li>
      )}
    </>
  );
}

MenuItem.propTypes = {
  item: PropTypes.object,
  onCategory: PropTypes.func,
  selectedCategory: PropTypes.string,
  subSelectedCategory: PropTypes.string
};

export const MobileTopFilter = ({
  categories,
  onCategory,
  selectedCategory,
  onSellType,
  sellType,
  subSelectedCategory,
  isMobileCateOpen,
  toggleCategoryFilter
}) => {
  return (
    <div className={`market-category-div ${isMobileCateOpen ? 'menu-show' : ''}`}>
      <div className="back-text" onClick={toggleCategoryFilter}>
        <img src="/images/common/left-arrow.svg" alt="left-aarow" /> Back
      </div>
      <div className="market-category-inner-div ">
        <ul>
          {categories &&
            categories.map((item, index) => (
              <MenuItem
                key={index}
                item={item}
                onCategory={onCategory}
                selectedCategory={selectedCategory}
                subSelectedCategory={subSelectedCategory}
              />
            ))}
        </ul>

        <ul className="auction-menu">
          <li onClick={() => onSellType('all')} className={`category-list ${sellType === 'all' ? 'active' : ''}`}>
            <img src={`/images/market/${sellType === 'all' ? 'active/list.svg' : 'deactive/list.svg'}`} alt="knife" className="gun-fluid" />
            <span>All items</span>
          </li>
          <li onClick={() => onSellType('market')} className={`category-list ${sellType === 'market' ? 'active' : ''}`}>
            <img
              src={`/images/market/${sellType === 'market' ? 'active/shopping-bag.svg' : 'deactive/shopping-bag.svg'}`}
              alt="knife"
              className="gun-fluid"
            />
            <span>Buy Now</span>
          </li>
          <li onClick={() => onSellType('auction')} className={`category-list ${sellType === 'auction' ? 'active' : ''}`}>
            <img
              src={`/images/market/${sellType === 'auction' ? 'active/auction.svg' : 'deactive/auction.svg'}`}
              alt="knife"
              className="gun-fluid"
            />
            <span>Auction</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

MobileTopFilter.propTypes = {
  categories: PropTypes.array,
  onCategory: PropTypes.func,
  toggleCategoryFilter: PropTypes.func,
  selectedCategory: PropTypes.string,
  sellType: PropTypes.string,
  onSellType: PropTypes.func,
  isMobileCateOpen: PropTypes.bool,
  subSelectedCategory: PropTypes.string
};

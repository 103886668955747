import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const SellerOfferAcceptModal = ({ onAcceptOffer, offerData, closeOfferModal }) => {
  const defaultCurrency = JSON.parse(localStorage.getItem('default'));
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  return (
    <div>
      <aside role="dialog" tabIndex="-1" aria-modal="true" className="edit-price-modal sell-model">
        <div className="sell-modal-area edit-listing-modal">
          <div className="position-relative steam-account-popup">
            <button aria-label="Close Modal" aria-labelledby="close-modal" className="modal-close" onClick={closeOfferModal}>
              <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
            </button>
            <p className="offer-heading">Accept</p>
            <div className="amount-offer-div border-bottom-0 pb-10">
              <div className="input-outer-div">
                <div className="grey-background input-main-div d-flex align-items-center gap-10">
                  <img src="/images/sell-modal/check.svg" alt="check" />
                  <p>
                    Accept {defaultCurrency && defaultCurrency.symbol}
                    {offerData && offerData.offer} Offer
                  </p>
                </div>
              </div>
            </div>
            <p className="pb-20 font-14">
              Accepting this offer will decline all other offers, the buyer will get 24 hours to pay for the item.
            </p>

            <div className="condition-label">
              <label className="sell-label">
                I accept the terms and confirm that i want to accept this offe
                <input type="checkbox" name="term" onChange={(e) => setIsAcceptTerms(e.target.checked)} />
                <span className="checkmark l-0"></span>
              </label>
            </div>
            <div className="offer-button-div">
              <button
                aria-label="Close Modal"
                aria-labelledby="close-modal"
                className="green-button"
                onClick={() => onAcceptOffer(offerData)}
                disabled={!isAcceptTerms}
              >
                Accept
              </button>
              <button aria-label="Close Modal" aria-labelledby="close-modal" className="grey-button" onClick={closeOfferModal}>
                cancel
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

SellerOfferAcceptModal.propTypes = {
  onAcceptOffer: PropTypes.func,
  offerData: PropTypes.any,
  OfferDecline: PropTypes.func,
  closeOfferModal: PropTypes.func
};

import { axiosAuthInstance, steamApiKey } from 'api';
import { SettingSideBar } from 'components';
import { useApi } from 'hooks/useApi';
import Cookies from 'js-cookie';
import { getCurrencyItems } from 'lib';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export const Setting = () => {
  document.title = 'Zipit - Settings';
  const userData = useSelector((state) => state.user.userData);
  const userBan = useSelector((state) => state.user.userBlockDetails);

  const [email, setEmail] = useState('');
  const [tradeUrl, setTradeUrl] = useState('');
  const [apiKey, setApiKey] = useState('');

  const [errors, setErrors] = useState({});
  const [shop, setShop] = useState({ d_shop: false, bargains_listing: false });
  const [shopUrl, setShopUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    const Bearer_Token = Cookies.get('Bearer_Token');
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${Bearer_Token}`;
  }, []);

  useEffect(() => {
    if (userData) {
      setEmail(userData.email || '');
      setTradeUrl(userData.tread_url || '');
      setApiKey(userData.api_key || '');
      setShopUrl(userData.hash_id || '');
      // setShop({
      //   d_shop: userData.d_shop === '1' ? true : false,
      //   bargains_listing: userData.bargains_listing === '1' ? true : false
      // });
      setShop({
        d_shop: userData.d_shop === '1' || userData.d_shop === true,
        bargains_listing: userData.bargains_listing === '1' || userData.bargains_listing === true
      });
    }
  }, [userData]);

  const { apiCaller: onSteamApiKey } = useApi({
    dataGetter: steamApiKey
  });

  const onSaveEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Email is required'
      }));
    } else if (!emailPattern.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Invalid email format'
      }));
    } else {
      setEmail(email);

      setErrors((prevErrors) => ({
        ...prevErrors,
        email: ''
      }));
      onSteamApiKey({ email }).then((res) => {
        if (res.data.status === 'success') {
          toast.success(res.data.massage);
        }
      });
    }
  };

  const onSaveTradeUrl = () => {
    // const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    const steamCommunityRegex = /^https?:\/\/(www\.)?steamcommunity\.com\/.*$/;
    // else if (!urlPattern.test(tradeUrl)) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     tradeUrl: 'Invalid URL format'
    //   }));
    // }

    if (!tradeUrl.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tradeUrl: 'Trade URL is required'
      }));
    } else if (!steamCommunityRegex.test(tradeUrl)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tradeUrl: 'Trade URL must be a steamcommunity.com URL'
      }));
    } else {
      setTradeUrl(tradeUrl);
      setErrors((prevErrors) => ({
        ...prevErrors,
        tradeUrl: ''
      }));
      onSteamApiKey({ tread_url: tradeUrl }).then((res) => {
        if (res.data.status === 'success') {
          toast.success(res.data.massage);
        } else {
          toast.error(res.data.massage);
          // setTradeUrl(tradeUrl);
        }
      });
    }
  };

  const onSaveApiKey = () => {
    if (!apiKey.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        apiKey: 'API Key is required'
      }));
    } else {
      setApiKey(apiKey);
      setErrors((prevErrors) => ({ ...prevErrors, apiKey: '' }));
      onSteamApiKey({ api_key: apiKey.trim() }).then((res) => {
        if (res.data.status === 'failed') {
          toast.error(res.data.massage);
        } else {
          toast.success(res.data.massage);
        }
      });
    }
  };

  const onMyshop = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    const value = checked ? 1 : 0;

    setShop({ ...shop, [name]: checked });

    onSteamApiKey({ [name]: value }).then((res) => {
      if (res.data.status === 'failed') {
        toast.error(res.data.massage);
      } else {
        toast.success(res.data.massage);
      }
    });
  };

  const onCopyShopUrl = (url, text) => {
    if (text) {
      navigator.clipboard
        .writeText(url + shopUrl)
        .then(() => {
          // console.log('Copied to clipboard:', shopUrl);
          setCopied(true);
          // You can also show a success message or perform any other action here
        })
        .catch((err) => {
          console.error('Failed to copy:', err);
          // You can also show an error message or perform any other action here
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }, [copied]);

  const currency = JSON.parse(localStorage.getItem('tf2-currencies'));

  const [selectedOption, setSelectedOption] = useState(currency || null);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOption] = useState([]);

  const [defaultCurrency, setDefaultCurrency] = useState();

  useEffect(() => {
    const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
    setSelectedOption(currency || { code: 'EUR', symbol: '€' });
    const defaultCurrency = JSON.parse(localStorage.getItem('default'));
    setDefaultCurrency(defaultCurrency);
  }, []);

  const fetchData = async () => {
    try {
      const productData = await getCurrencyItems();
      setOption(productData);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
    const currency = { ...option };
    delete currency.currency;
    localStorage.setItem('tf2-currencies', JSON.stringify(currency));
    window.location.reload();
  };

  const formattedReleaseDate = userBan.block_release_date
    ? moment.utc(userBan.block_release_date).local().format('DD/MM/yyyy h:mm:ss A')
    : '';
  return (
    <>
      <div className="profile-page-main-div">
        <div className="container-fluid">
          <div className="container">
            <div className="profile-page-inner-div">
              <SettingSideBar />
              <div className="setting-main-div profile-page-common-div setting-page">
                <div className="profile-page-heading">
                  <p>Setting</p>
                </div>
                <div className="setting-inner-div text-center pb-60">
                  <div className="setting-div">
                    {/* <p className="main_heading">Settings</p> */}
                    <div>
                      <div className="setting-text pt-30">
                        <div className="max-900">
                          <p className="font-26 text-center">KYC</p>
                          <div className="description">
                            <p className="opacity-white text-center">
                              KYC, or Know-Your-Customer, is a process required by law that allows our payment provider to confirm that you
                              are who you say you are. This is also both ours and your insurance that identities known for fraud, money
                              laundering, or other criminal activities arent allowed to sell items on our platform.
                            </p>
                          </div>
                          <p className="font-26 text-center mt-30">Please note:</p>
                          <p className="text-center color-red">**YOU MUST BE 18 YEARS OR OLDER TO COMPLETE KYC**</p>
                          <div className="button-link mt-30 green-background text-uppercase">Complete KYC</div>
                          <div className="process-div">
                            <p className="font-26 text-center mt-50">About the processing limit</p>
                            <div className="description">
                              <p className="opacity-white text-center">
                                If you have supplied all the required information from the start, you will automatically pass the checks
                                once you go above a certain threshold of total sales. If not, you will get a notification from us asking for
                                the needed documents.
                              </p>
                              <p className="opacity-white text-center mt-10">
                                If you have any questions regarding the KYC process, feel free to reach out to us on
                                <a href="mailto:support@Zipit.com" className="text-white text-underline ml-5">
                                  support@Zipit.com
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="setting-form">
                        <div className="max-900">
                          <div className="setting-form-inner-div">
                            <div className="row">
                              <div className="col-lg-6 col">
                                <div className="form-control">
                                  <label>Preferred display currency</label>
                                  <div className="footer-currency-div d-flex gap-10 align-items-center custom-select-container">
                                    <div className="selected-option currency-div" onClick={() => setShowOptions(!showOptions)}>
                                      <div className="d-flex gap-10">
                                        {selectedOption ? (
                                          <>
                                            <span className="currency-symbol">{selectedOption.symbol}</span>
                                            <span>{selectedOption.code}</span>
                                          </>
                                        ) : (
                                          <>
                                            <span className="currency-symbol">{defaultCurrency?.symbol}</span>
                                            <span>{defaultCurrency?.currency}</span>
                                          </>
                                        )}
                                      </div>
                                      <img src="/images/header/down-arrow.svg" alt="Zipit" className="img-fluid"></img>
                                    </div>
                                    <div className="">
                                      <img src="/images/footer/info.svg" alt="Zipit" className="img-fluid"></img>
                                    </div>
                                    {showOptions && (
                                      <ul className="options">
                                        {options.map((option) => (
                                          <li key={option.code} className="option" onClick={() => handleSelect(option)}>
                                            <span className="currency-symbol">{option.symbol}</span>
                                            <span className="currency-code">{option.currency}</span>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col">
                                <div className="form-control">
                                  <label>Contact E-mail</label>
                                  <input name="email" type="text" onChange={(e) => setEmail(e.target.value)} value={email} />
                                  <button className="text-white form-button" onClick={onSaveEmail}>
                                    Apply
                                  </button>
                                  {errors.email && <p className="error">{errors.email}</p>}
                                </div>
                              </div>
                              <div className="col-lg-6 col">
                                <div className="form-control">
                                  <label>Steam Trade URL</label>
                                  <div>
                                    <input name="trade_url" type="text" onChange={(e) => setTradeUrl(e.target.value)} value={tradeUrl} />
                                    <button className="text-white form-button" onClick={onSaveTradeUrl}>
                                      Apply
                                    </button>
                                  </div>
                                  <a
                                    href="https://steamcommunity.com/my/tradeoffers/privacy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-white mt-5 d-block text-underline"
                                  >
                                    Get your Steam Trade URL
                                    <img src="/images/common/link-icon.svg" alt="Zipit" height={13} width={13} className="img-fluid ml-5" />
                                  </a>
                                  {errors.tradeUrl && <p className="error">{errors.tradeUrl}</p>}
                                </div>
                              </div>
                              <div className="col-lg-6 col">
                                <div className="form-control mb-10">
                                  <label>Steam API Key</label>
                                  <div>
                                    <input
                                      name="apiKey"
                                      type="text"
                                      placeholder="Enter"
                                      onChange={(e) => setApiKey(e.target.value)}
                                      value={apiKey}
                                    />
                                    <button className="text-white form-button" onClick={onSaveApiKey}>
                                      Apply
                                    </button>
                                    {errors.apiKey && <p className="error">{errors.apiKey}</p>}
                                  </div>
                                  <a
                                    href="https://steamcommunity.com/dev/apikey"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-white mt-5 d-block text-underline"
                                  >
                                    Get your Steam API Key
                                    <img src="/images/common/link-icon.svg" alt="Zipit" height={13} width={13} className="img-fluid ml-5" />
                                  </a>
                                  <p className="opacity-white mt-5 ">You can use Zipit.com as the domain when registering</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="setting-form">
                        <div className="max-900">
                          <div className="setting-form-inner-div">
                            <p className="text-white text-left mb-10 mt-50">Ban Status</p>
                            <div className="website-div banned-status-div">
                              <div className="website-image-div">
                                <img src="/images/common/danger.svg" alt="Zipit" height={40} width={40} className="img-fluid ml-5" />
                              </div>
                              <div className="website-text-div ban-status-text">
                                <div className="discord-text text-left">
                                  <p className="font-20 pb-5 font-20 font-700">
                                    {userBan?.block_release_date ? `You have been banned for ${userBan?.block_hour} hours` : `ban lifted`}
                                  </p>
                                  <p className="font-12">
                                    <span className="yellow-text"> You have {5 - userBan?.block_count} strikes</span> left before your
                                    account will be permanently suspended.
                                  </p>
                                </div>
                                {userBan?.block_release_date && (
                                  <div className="discord-button text-left">
                                    <div>
                                      <p className="font-12">The suspension will automatically be lifted on</p>
                                      <p>
                                        <span className="yellow-text">{formattedReleaseDate} (CT) </span>
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="setting-checkbox-div">
                        <div className="setting-form-inner-div setting-form">
                          <div className="max-900">
                            <div className="row">
                              <div className="col-lg-6 col">
                                <div className="form-control">
                                  <label>Seller Shop Url</label>
                                  <div className="shop-url">
                                    <div>
                                      <div className="shop-inner-upl">
                                        {process.env.REACT_APP_SITE_URL}
                                        {shopUrl && shopUrl}
                                      </div>
                                    </div>
                                    <button
                                      className="text-white form-button"
                                      onClick={() => onCopyShopUrl(process.env.REACT_APP_SITE_URL, shopUrl)}
                                    >
                                      {copied ? 'Copied!' : 'Copy'}
                                    </button>
                                    {/* {copied && <span className="copied-message">Copied!</span>} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="setting-checkbox right-search-area">
                              <input name="d_shop" type="checkbox" checked={shop.d_shop} onChange={(e) => onMyshop(e)} />
                              <label>Display shop</label>
                            </div>
                          </div>
                        </div>
                        {/* <div className="delete-account-div">
                          <div className="max-900">
                            <div className="shop-inner-upl">
                              <p className="font-20 pb-0 font-700">Delete your account</p>
                              <div className="offer-button-div mt-0">
                                <button className="delete-button" onClick={() => setIsDeleteModalOpen(true)}>
                                  Delete Account
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="setting-checkbox right-search-area">
                      <input
                        name="bargains_listing"
                        type="checkbox"
                        checked={shop.bargains_listing}
                        onChange={(e) => onMyshop(e)}
                      />
                      <label>Bargains Listing</label>
                    </div> */}
                      </div>
                      {/* {isDeleteModalOpen && (
                        <aside role="dialog" tabIndex="-1" aria-modal="true" className="sell-modal">
                          <div className="position-relative steam-account-popup">
                            <button className="modal-close" onClick={() => setIsDeleteModalOpen(false)}>
                              <img src="/images/common/modal-close.svg" alt="modal-close" className="img-fluid" />
                            </button>
                            <p className="offer-heading text-left">Delete account</p>
                            <div className="mt-20 border-top">
                              <p className="opacity-white pt-20">
                                Are you sure you want to delete this listing? This action cannot be undone.
                              </p>
                            </div>
                            <div className="offer-button-div">
                              <button
                                aria-label="Close Modal"
                                aria-labelledby="close-modal"
                                className="delete-button"
                                // onClick={() => onDelete(item?.m_id)}
                              >
                                Delete
                              </button>
                              <button
                                aria-label="Close Modal"
                                aria-labelledby="close-modal"
                                className="grey-button"
                                onClick={() => setIsDeleteModalOpen(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </aside>
                      )} */}
                      {/* <div className="website-main-div mt-50">
                    <p className="opacity-white mb-10 mt-50">Websites & Apps</p>
                    <div className="website-div">
                      <div className="website-image-div">
                        <img
                          src="/images/common/discord.svg"
                          alt="Zipit"
                          height={40}
                          width={40}
                          className="img-fluid ml-5"
                        />
                      </div>
                      <div className="website-text-div">
                        <div className="discord-text">
                          <p className="font-20 pb-5">Discord</p>
                          <p className="opacity-white">
                            Link your account to enable Discord notifications
                          </p>
                        </div>
                        <div className="discord-button">
                          <button className="text-white form-button ml-0">
                            Connect to Discord
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

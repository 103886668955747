import React from 'react';
import { Link } from 'react-router-dom';

export const Nothing = () => {
  return (
    <>
      <div className="nothing-sell-div text-center">
        <div className="nothing-sell-image-div">
          <img src="/images/inventory/not-sell.svg" alt="zipit" className="img-fluid similar-product-img" />
        </div>
        <p className="font-26 text-white pb-5">You have nothing for sale.</p>
        <div className="description">
          <p>All you need to do is pick an item and a price</p>
        </div>
        <Link to="/inventory" className="button-link mt-20 green-background text-uppercase">
          sell Item
        </Link>
      </div>
    </>
  );
};

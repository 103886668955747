import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { setBearerToken } from 'reduxs/userSlice';
import {
  Bell,
  Button,
  DownArrow,
  LogoutIcon,
  Notification,
  Question,
  Setting,
  SkeletonLoader,
  SteamIcon,
  Store,
  UpArrow,
  Following,
  News,
  BellNotification,
  Deposit,
  Withdraw,
  Transactions,
  Shop,
  Support,
  UserUnBanPopup
} from 'components';
import { useApi } from 'hooks/useApi';
import { axiosAuthInstance, howItsWorkpopup, logout, newsletter } from 'api';

import './style.css';
import { HowItWork } from './HowItWork';
import { search_mrktet } from 'api/market';
import { AutoComplete } from './AutoComplete';
import { RoundedPrice, itemNameConverter } from 'lib';
import Cookies from 'js-cookie';
export const TopBar = ({ options }) => {
  const inputRef = useRef(null);
  const location = useLocation();
  const { pathname } = location;
  const { search } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const bellRef = useRef(null);
  const currencyRef = useRef(null);
  const searchRef = useRef(null);
  const closeModalRef = useRef(null);

  const urlSearchParams = new URLSearchParams(search);
  const notificationCount = useSelector((state) => state.buy.buyFilterCount);

  const [isMenuVisible, setMenuVisibility] = useState(false);

  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [inputSearch, setInputSearch] = useState('');

  const [howItWork, setHowItWork] = useState(false);
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);
  const [howItsWorkData, setHowItsWorkData] = useState();

  const [showBellNotification, setShowBellNotification] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  // const [options, setOption] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState();
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const [isUserUnBanModalOpen, setIsUserUnBanModalOpen] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // const [isSticky, setIsSticky] = useState(false);

  const steamToken = urlSearchParams.get('steam_token') || Cookies.get('Bearer_Token');

  const user_Data = useSelector((state) => state.user.userData);
  const userBan = useSelector((state) => state.user.userBlockDetails);
  const user = user_Data?.user;
  // const userBan = user_Data?.userBlockDetails;

  useEffect(() => {
    if (userBan?.status === 'unblock') {
      setIsUserUnBanModalOpen(true);
    }
  }, [userBan]);

  const loginTime = Cookies.get('login_time');

  /*---------------- Steam token set in Cookie ----------------*/
  if (steamToken !== undefined && steamToken !== null) {
    if (loginTime === undefined) {
      const expires = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      Cookies.set('login_time', new Date().toISOString(), { expires, secure: true, sameSite: 'strict' });
      Cookies.set('Bearer_Token', steamToken, { expires, secure: true, sameSite: 'strict' });
    }
  }

  /*---------------- api calls ----------------*/

  useEffect(() => {
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${steamToken}`;
  }, [steamToken]);

  const { apiCaller: onLogout } = useApi({
    dataGetter: logout
  });

  const { apiCaller: onSearchMrktet } = useApi({
    dataGetter: search_mrktet
  });

  const { data: newsletterData } = useApi({
    dataGetter: newsletter,
    autoRetrieve: true
  });

  const { apiCaller: onHowItsWork } = useApi({
    dataGetter: howItsWorkpopup
  });

  /*---------------- login with steam redirect url function ----------------*/

  const handleAuthSteam = () => {
    window.location.href = 'https://cms.zipit.absd.dk/auth';
  };

  /*---------------- logout code Start ----------------*/

  const handleLogout = useCallback(async () => {
    const steamId = Cookies.get('ustid');
    const param = {
      param: {
        steam_id: steamId
      }
    };
    const { data: res } = await onLogout(param);
    if (res.status === 'success') {
      navigate('/');
      dispatch(setBearerToken(null));
      Cookies.remove('Bearer_Token', { secure: true, sameSite: 'strict' });
      Cookies.remove('ustid', { secure: true, sameSite: 'strict' });
      Cookies.remove('ukey', { secure: true, sameSite: 'strict' });
      Cookies.remove('login_time', { secure: true, sameSite: 'strict' });
      axiosAuthInstance.defaults.headers.common.Authorization = `Bearer null`;
    }
  }, [dispatch, navigate, onLogout]);

  useEffect(() => {
    const checkForExpiration = () => {
      if (loginTime !== undefined) {
        const loginDate = new Date(loginTime);
        const currentDate = new Date();
        const timeDifference = currentDate - loginDate;
        const remainingTime = 24 * 60 * 60 * 1000 - timeDifference; // Remaining time in milliseconds

        // if (remainingTime <= 10 * 1000) {
        //   handleLogout();
        // }
        if (remainingTime <= 10 * 1000) {
          handleLogout();
        }
      }
    };

    checkForExpiration();
    const intervalId = setInterval(checkForExpiration, 1000); // Check every second
    return () => clearInterval(intervalId);
  }, [handleLogout, loginTime]);

  /*---------------- menu show or hide and notification show or hide code ----------------*/
  const handleOutsideClick = (event) => {
    if (!buttonRef.current?.contains(event.target)) {
      setMenuVisibility(false);
    }
    if (!bellRef.current?.contains(event.target)) {
      setShowBellNotification(false);
    }
    if (!currencyRef.current?.contains(event.target)) {
      setShowOptions(false);
    }
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowMobileSearch(false);
      setInputSearch('');
    }
    if (closeModalRef.current && !closeModalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisibility(!isMenuVisible);
    setShowBellNotification(false); // Hide bell notification on click in
  };

  /*---------------- modal open and close function ----------------*/

  const onHowItWork = () => {
    document.body.classList.add('modal-open');
    setHowItWork(true);
    const param = {
      status: 'Seller,Buyer'
    };
    onHowItsWork(param).then((res) => {
      setHowItsWorkData(res.data);
    });
  };

  const closeModal = () => {
    document.body.classList.remove('modal-open');
    setHowItWork(false);
  };

  /*----------------  Search bar code Start  ----------------*/

  const handleInputChange = (value) => {
    setInputSearch(value);
    onSearchMrktet({ search: value }).then((response) => {
      setAutoCompleteData(response.data.item);
    });
  };

  const handleKeyDown = (e, value) => {
    if (e.key === 'Enter') {
      if (value) {
        setInputSearch('');
        setShowMobileSearch(false);
        const queryParams = `/market?search=${encodeURIComponent(value)}`;
        navigate(queryParams);
      }
    }
  };

  const onSelecteditem = (selectedItem) => {
    if (selectedItem) {
      setInputSearch('');
      setShowMobileSearch(false);
      const name = itemNameConverter(selectedItem.item.fullname);
      const queryParams = `/market?cat=${selectedItem.category}&name=${name}`;
      navigate(queryParams);
    }
  };

  const clearSearch = () => {
    setInputSearch('');
  };

  /*---------------- News Letter modal show code ----------------*/

  useEffect(() => {
    const checkNewsletterPopup = async () => {
      const lastSeenTime = localStorage.getItem('LATEST_NEWS_SEEN_TIME');
      if (
        steamToken !== null &&
        newsletterData &&
        newsletterData !== null &&
        newsletterData.data.length !== 0 &&
        (!lastSeenTime || new Date(lastSeenTime).getTime() < new Date(newsletterData.data.created).getTime())
      ) {
        // Show newsletter popup after login
        setShowNewsletterPopup(true);
      }
    };
    checkNewsletterPopup();
  }, [steamToken, newsletterData]);

  const onCloseNewsModal = () => {
    setShowNewsletterPopup(false);
    localStorage.setItem('LATEST_NEWS_SEEN_TIME', newsletterData.data.created);
  };

  /*---------------- Bell Notification code ----------------*/

  const bellNotification = () => {
    setShowBellNotification(!showBellNotification);
    setMenuVisibility(false);
  };

  /*---------------- Currency change code ----------------*/

  useEffect(() => {
    const currency = JSON.parse(localStorage.getItem('tf2-currencies'));
    setSelectedOption(currency || { code: 'EUR', symbol: '€' });
    const defaultCurrency = JSON.parse(localStorage.getItem('default'));
    setDefaultCurrency(defaultCurrency);
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
    const currency = { ...option };
    delete currency.currency;
    localStorage.setItem('tf2-currencies', JSON.stringify(currency));
    window.location.reload();
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = screenWidth <= 767;
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY >= 5) {
  //       setIsSticky(true);
  //     } else {
  //       setIsSticky(false);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   // return () => {
  //   //   window.removeEventListener('scroll', handleScroll);
  //   // };
  // }, []);

  return (
    <>
      <header
      //  className={isSticky ? 'nav-sticky' : ''}
      >
        <div className="container-fluid">
          <div className="container">
            <div className="main-header">
              <div className="header-logo">
                <Link to={'/'}>
                  <img src="/images/header/new-zipit-logo.svg" alt="Zipit" className="img-fluid desktop-logo" />
                  {/* <img src="/images/header/new_mobile_logo.svg" alt="Zipit" className="img-fluid responsive-logo" /> */}
                </Link>
              </div>
              <div className="header-search position-relative">
                {isMobile ? (
                  <>
                    <div
                      className={`responsive-search ${showMobileSearch ? 'active' : ''}`}
                      onClick={() => {
                        setShowMobileSearch(!showMobileSearch);
                        setInputSearch('');
                      }}
                    >
                      {showMobileSearch ? (
                        <img src="/images/common/blackclose.svg" height={15} width={15} alt="Zipit" className="img-fluid search-icon" />
                      ) : (
                        <img src="/images/header/header-search.svg" alt="Zipit" height={15} width={15} className="img-fluid search-icon" />
                      )}
                    </div>
                    {showMobileSearch && (
                      <>
                        <div className="responsive-search-input">
                          <div className="search-icon-div">
                            <img
                              src="/images/header/blacksearchicon.svg"
                              alt="Zipit"
                              height={15}
                              width={15}
                              className="img-fluid search-icon"
                            />
                          </div>
                          <input
                            ref={inputRef}
                            type="text"
                            placeholder="Search for an item"
                            className="ng-valid ng-dirty ng-touched"
                            value={inputSearch}
                            onInput={(e) => handleInputChange(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, e.target.value)}
                            autoFocus
                          />
                        </div>
                        {inputSearch && (
                          <button className="clear-search-button" onClick={clearSearch}>
                            <img src="/images/common/whiteclose.svg" height={10} alt="Zipit" className="img-fluid" />
                          </button>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div className="header-search-inner-div">
                    <img src="/images/header/header-search.svg" alt="Zipit" height={15} width={15} className="img-fluid search-icon" />
                    <input
                      type="text"
                      placeholder="Search for an item"
                      className="ng-valid ng-dirty ng-touched"
                      value={inputSearch}
                      onInput={(e) => handleInputChange(e.target.value)}
                      onKeyDown={(e) => handleKeyDown(e, e.target.value)}
                    />
                    {inputSearch && (
                      <button className="clear-search-button" onClick={clearSearch}>
                        <img src="/images/common/whiteclose.svg" height={10} alt="Zipit" className="img-fluid" />
                      </button>
                    )}
                  </div>
                )}

                {inputSearch !== '' && (
                  <div className="search-auto-complete-outer-div" ref={searchRef}>
                    <div className="search-auto-complate">
                      <div className="">
                        {/* <h3 className="font-16 pb-5">Item</h3> */}
                        {/* <div className=""> */}
                        {autoCompleteData && autoCompleteData.length > 0 ? (
                          autoCompleteData?.map((item, index) => <AutoComplete item={item} key={index} onSelecteditem={onSelecteditem} />)
                        ) : (
                          <>
                            <div className="nosearch-item">
                              <img src="/images/header/empty-search.svg" alt="search" />
                              <p>Not Present</p>
                            </div>
                          </>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {steamToken === undefined || steamToken === null ? (
                <>
                  <div className="market-login" onClick={() => onHowItWork()}>
                    <Link to="#">
                      <Question />
                      How it works
                    </Link>
                  </div>

                  <div className={`market-login ${pathname === '/market' ? 'active' : ''}`}>
                    <Link to={'/market'}>
                      <Store />
                      Market
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className={`header-profile-div ${isMenuVisible ? 'active' : ''}`} onClick={toggleMenu} ref={buttonRef}>
                    <div className="header-profile">
                      <div className="header-profile-image">
                        {user && user.avatarmedium ? (
                          <img src={user.avatarmedium} alt="Zipit" className="img-fluid rounded-circle" />
                        ) : (
                          <img src="/images/common/profile.svg" height={32} width={32} alt="Zipit" className="img-fluid" />
                        )}

                        {user && user.personaname ? (
                          <>
                            <p>{user && user.personaname.slice(0, 8) + (user.personaname.length > 8 ? '...' : '')}</p>
                          </>
                        ) : (
                          <p>
                            <SkeletonLoader width="99%" height={18} color="#0b051b" highlightColor="#242347" />
                          </p>
                        )}
                      </div>
                      <p className="user-wallet">
                        {user_Data?.wallet && defaultCurrency && defaultCurrency?.symbol}{' '}
                        {user_Data.wallet && RoundedPrice(user_Data.wallet)}
                      </p>
                      {isMenuVisible ? <UpArrow /> : <DownArrow />}
                    </div>
                    <div className="profile-sub-menu">
                      <ul>
                        {isMenuVisible &&
                          (steamToken ? (
                            <>
                              <li className={pathname === '/deposit' ? 'active' : ''}>
                                <Link to={'/deposit'}>
                                  <Deposit />
                                  Deposit
                                </Link>
                              </li>
                              <li className={pathname === '/withdraw' ? 'active' : ''}>
                                <Link to={'/withdraw'}>
                                  <Withdraw />
                                  Withdraw
                                </Link>
                              </li>
                              <li className={pathname === '/transactions' ? 'active' : ''}>
                                <Link to={'/transactions'}>
                                  <Transactions />
                                  Transactions
                                </Link>
                              </li>
                              <li className={pathname === `/shop/${user_Data?.hash_id}` ? 'active' : ''}>
                                <Link to={`/shop/${user_Data?.hash_id}`}>
                                  <Shop />
                                  My Shop
                                </Link>
                              </li>
                              <li className={pathname === '/settings' ? 'active' : ''}>
                                <Link to={'/settings'}>
                                  <Setting />
                                  Setting
                                </Link>
                              </li>
                              <li className={pathname === '/notifications' ? 'active' : ''}>
                                <Link to={'/notifications'}>
                                  <Notification />
                                  Notifications
                                </Link>
                              </li>
                              <li className={pathname === '/following' ? 'active' : ''}>
                                <Link to={'/following'}>
                                  <Following />
                                  Following
                                </Link>
                              </li>
                              <li className={pathname === '/support' ? 'active' : ''}>
                                <Link to={'/support'}>
                                  <Support />
                                  Support
                                </Link>
                              </li>
                              {/* eslint-disable */}
                              <li onClick={handleLogout}>
                                <Link to="">
                                  <LogoutIcon />
                                  Logout
                                </Link>
                              </li>
                              {/* eslint-enable */}
                            </>
                          ) : (
                            ''
                          ))}
                      </ul>
                    </div>
                  </div>

                  <div className="header-notification" onClick={bellNotification} ref={bellRef}>
                    <div className="inner-notification">
                      <Bell />
                      {typeof notificationCount?.not_seen === 'number' && notificationCount?.not_seen !== 0 && (
                        <span className="notification-number">{notificationCount?.not_seen}</span>
                      )}
                    </div>
                    {showBellNotification && <BellNotification />}
                  </div>
                  {!isMobile && (
                    <div className={`header-currency ${showOptions ? 'active' : ''}`} ref={currencyRef}>
                      <div
                        className="selected-option currency-div gap-5 justify-content-between"
                        onClick={() => setShowOptions(!showOptions)}
                      >
                        <div className="d-flex gap-10">
                          {selectedOption ? (
                            <>
                              <span className="currency-symbol">{selectedOption.symbol}</span>
                              <span>{selectedOption.code}</span>
                              {/* </div> */}
                            </>
                          ) : (
                            <>
                              <span className="currency-symbol">{defaultCurrency?.symbol}</span>
                              <span>{defaultCurrency?.currency}</span>
                            </>
                          )}
                        </div>
                        {!showOptions ? <DownArrow /> : <UpArrow />}
                      </div>
                      {showOptions && (
                        <ul className="options">
                          {options.map((option) => (
                            <li key={option.code} className="option" onClick={() => handleSelect(option)}>
                              <span className="currency-symbol">{option.symbol}</span>
                              <span className="currency-code">{option.currency}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                </>
              )}

              {steamToken === null || steamToken === undefined ? (
                <Button handleClick={() => handleAuthSteam()} className="text-white login-steam">
                  <SteamIcon /> Log in with Steam
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </header>
      {howItWork && <HowItWork closeModalRef={closeModalRef} closeModal={closeModal} howItsWorkData={howItsWorkData} />}
      {showNewsletterPopup && (
        <News newsLatterData={newsletterData} onCloseNewsModal={onCloseNewsModal} showClass={showNewsletterPopup ? 'show' : ''} />
      )}
      {isUserUnBanModalOpen && <UserUnBanPopup setIsUserUnBanModalOpen={setIsUserUnBanModalOpen} />}
    </>
  );
};

TopBar.propTypes = {
  options: PropTypes.array
};

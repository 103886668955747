import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Product, Filter, SellModal, SearchBar, BulkSellList, WarningModal, MobileProduct } from 'components/Inventory';
import { setInventoryCategories } from 'reduxs/inventorySlice';
import { axiosAuthInstance, inventory, inventoryCategory, refresh_inventory, steamApiKey, update_price } from 'api';
import { useApi } from 'hooks/useApi';
import { toast } from 'react-toastify';
import { SteamApiKeyModal, UserBanModal } from 'components';
import Cookies from 'js-cookie';

export const Inventory = () => {
  document.title = 'Zipit - Inventory';
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userBan = useSelector((state) => state.user.userBlockDetails);
  const paramsToken = useLocation();
  const urlSearchParams = new URLSearchParams(paramsToken.search);
  const steamToken = urlSearchParams.get('steam_token');
  if (steamToken !== null) {
    localStorage.setItem('SteamLogin', true);
  }

  // user Ban
  const [isUserBanModalOpen, setIsUserBanModalOpen] = useState(false);

  // Mobile Menu State Start
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const mobileRef = useRef(null);

  // Api Key Modal state
  const [showPopup, setShowPopup] = useState(false);
  const [apiKey, setApiKey] = useState({ api_key: '' });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(true);
  const [inventoryTotalPrice, setInventoryTotalPrice] = useState(0);

  // inventory state
  const [inventoryData, setInventoryData] = useState([]);
  const [rowCount, setRowCount] = useState(10);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [visible, setVisible] = useState();
  const [pagination, setPagination] = useState({
    from: 0,
    to: 0
  });

  const pageSize = 50;

  // sell modal state
  const [showSellModal, setShowSellModal] = useState(false);
  const [sellItem, setSellItem] = useState();
  const [bulkSellItem, setBulkSellItem] = useState();
  const [bulkSellModal, setBulkSellModal] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [prices, setPrices] = useState({});
  // const [itemArray, setItemArray] = useState([]);

  // worning modal state
  const [warningModal, setWarningModal] = useState(false);
  const [AuctionTerms, setAuctionTerms] = useState();

  // Steam api Key code start

  const validateValues = (apiKey) => {
    let errors = {};
    if (apiKey.api_key === '') {
      errors.api_key = 'Steam Api key Required';
    }
    return errors;
  };
  const closeModal = () => {
    document.body.classList.remove('modal-open');
    setShowPopup(false);
    setShowSellModal(false);
  };

  const onBulkSellModalClose = () => {
    document.body.classList.remove('modal-open');
    setBulkSellModal(false);
    setRowSelection({});
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateValues(apiKey);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        setErrors({});
        const res = await onSteamApiKey(apiKey);
        if (res.data.status === 'failed') {
          toast.error(res.data.massage);
          setErrors({ api_key: res.data.message });
          setShowPopup(true);
        } else {
          fetchData();
          setShowPopup(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };
  // Steam api Key code end

  // api call in pass Bearer Token

  const Bearer_Token = Cookies.get('Bearer_Token');
  useEffect(() => {
    axiosAuthInstance.defaults.headers.common.Authorization = `Bearer ${Bearer_Token}`;
  }, [Bearer_Token]);

  // Api end points Start
  const { apiCaller: onSteamApiKey } = useApi({
    dataGetter: steamApiKey
  });

  const { data, apiCaller: onCategoryGet } = useApi({
    dataGetter: inventoryCategory,
    autoRetrieve: true
  });

  const { apiCaller: getInventory } = useApi({
    dataGetter: inventory
  });

  const { apiCaller: onUpdatePrice } = useApi({
    dataGetter: update_price
  });

  // Api end points End

  // Get categories data on mount and update Redux store with it.

  useEffect(() => {
    if (data && data?.data) {
      dispatch(setInventoryCategories(data?.data));
    }
  }, [data, dispatch]);

  const checkLogin = localStorage.getItem('SteamLogin');

  const Refresh_api = useCallback(
    async (page, isRefresh, i_url) => {
      try {
        if (checkLogin) {
          localStorage.removeItem('SteamLogin');
        }
        // document.body.classList.add('modal-open');
        let params = {
          page,
          i_url
        };

        if (isRefresh) {
          params.isRefresh = true;
        }
        const res = await refresh_inventory(params);
        if (res.data.status === 'failed') {
          setShowPopup(true);
        }
        if (res.data.next_url) {
          Refresh_api(page + 1, isRefresh, res.data.next_url);
        }
        if (res.data.next_url === '') {
          const price = await onUpdatePrice();
          if (price.data.status === 'success') {
            onCategoryGet().then((res) => {
              dispatch(setInventoryCategories(res.data?.data));
            });
            const res = await getInventory({ page: 1 });
            setInventoryTotalPrice(res.data.total_price);

            const newData = res.data.item.data.filter((item, index, self) => index === self.findIndex((i) => i.item_id === item.item_id));

            setInventoryData((prev) => {
              const updatedData = page === 1 ? newData : [...prev, ...newData];
              const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

              const newVisible = uniqueArray.length;
              setVisible(newVisible);
              return uniqueArray;
            });
            setPagination({ from: res.data.item.from, to: res.data.item.to });
            setRowCount(res.data.item.total);
            // document.body.classList.remove('modal-open');
            // setTimeout(() => {
            setIsLoading(false);
            setRefreshing(false);
            // }, 1000);
          }
        }
      } catch (error) {
        toast.error(error.message);
        localStorage.removeItem('SteamLogin');
        setTimeout(() => {
          setIsLoading(false);
          setRefreshing(false);
        }, 1000);
      }
    },
    [getInventory]
  );

  // on refresh button  click handle
  const onRefresh = () => {
    setRefreshing(true);
    setIsLoading(true);
    setCurrentPage(1);
    setSelectedCategory();
    setRowCount(10);
    const isRefresh = true;
    Refresh_api(currentPage, isRefresh);
  };

  useEffect(() => {
    if (checkLogin !== null) {
      onRefresh();
    }
  }, [checkLogin]);

  // Get Inventories data on mount and update Redux store with it.

  //  category on change function
  const onCategoryChange = (category) => {
    setSelectedCategory(category);

    setHasMore(true);
    let queryParams = `/inventory/`;
    if (category) {
      queryParams += `?view=${encodeURIComponent(category)}`;
    }
    if (search) {
      queryParams += `${category ? '&' : ''}search=${encodeURIComponent(search)}`;
    }
    const params = new URLSearchParams(location.search);
    if (params.get('view') !== category) {
      setIsLoading(true);
      setRowCount(10);
      setInventoryData([]);
      setCurrentPage(1);
      setPagination({});
    }

    navigate(queryParams);
  };

  // on resize , update the table layout and column widths

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(mobileRef.current.clientWidth <= 992);
    };
    const resizeObserver = new ResizeObserver(handleResize);
    if (mobileRef.current) {
      resizeObserver.observe(mobileRef.current);
    }
    handleResize(); // Initial check
    return () => {
      resizeObserver.disconnect();
    };
  }, [isMobile]);

  const toggleMenu = () => {
    if (!isMenuOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAllCategoryChange = () => {
    setRowCount(10);
    setInventoryData([]);
    setIsLoading(true);
    setPagination({});
    if (isMobile) {
      toggleMenu();
    }
    setSelectedCategory();
    setCurrentPage(1);
    setHasMore(true);
    const newSearchParams = new URLSearchParams(search);
    newSearchParams.delete('view');
    let queryParams = `/inventory/?`;
    if (search) {
      queryParams += `search=${encodeURIComponent(search)}`;
    }
    navigate(queryParams);
    fetchData();
  };

  //  on search function
  const onSearch = (searchString) => {
    setHasMore(true);
    setCurrentPage(1);
    setSearch(searchString);
    let queryParams = `/inventory/?`;
    if (selectedCategory) {
      queryParams += `view=${encodeURIComponent(selectedCategory)}`;
    } else {
      setSelectedCategory();
    }
    if (searchString) {
      queryParams += `${selectedCategory ? '&' : ''}search=${encodeURIComponent(searchString)}`;
    }
    navigate(queryParams);
  };

  // const onSearchDebounced = (event) => {
  //   setTimeout(() => {
  //     onSearch(event.target.value);
  //   }, 1000);
  // };
  const onSearchDebounced = (name) => {
    // setTimeout(() => {
    onSearch(name);
    // }, 1000);
  };

  // inventory  items fetching and pagination handling

  const fetchData = useCallback(
    async (category, page, searchString, isRefresh) => {
      try {
        setIsLoading(true);
        let params = {
          page
        };
        if (category !== null && category !== '' && category !== 'all') {
          params.cate = category;
        }
        if (searchString !== null && searchString !== '') {
          params.search = searchString;
        }
        params.limit = pageSize;

        const res = await getInventory(params);
        if (res.data.api_status === 0 && res.data.status === 'failed') {
          setShowPopup(true);
        } else {
          if (res.data.item.last_page > page) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }

          setInventoryTotalPrice(res.data.total_price);

          const newData = res.data.item.data.filter((item, index, self) => index === self.findIndex((i) => i.item_id === item.item_id));

          setInventoryData((prev) => {
            const updatedData = page === 1 ? newData : [...prev, ...newData];
            const uniqueArray = Array.from(new Set(updatedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

            const newVisible = uniqueArray.length;
            setVisible(newVisible);
            return uniqueArray;
          });
          setPagination({ from: res.data.item.from, to: res.data.item.to });
          setRowCount(res.data.item.total);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        // setTimeout(() => {
        setIsLoading(false);
        setRefreshing(false);
        // }, 1000);
      }
    },
    [getInventory]
  );
  // on page reload to fetch data using useEffect
  useEffect(() => {
    // Check if the current URL has query parameters
    const params = new URLSearchParams(location.search);
    if (!params.toString()) {
      if (!checkLogin) {
        // If no query parameters, proceed with the API call
        fetchData(selectedCategory, currentPage, search);
      }
    }
  }, [fetchData, location.search, currentPage, search, selectedCategory]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.size > 0) {
      if (!checkLogin) {
        const categoryNameParam = params.get('view');
        const searchParam = params.get('search');
        setSelectedCategory(categoryNameParam);
        setSearch(searchParam);
        fetchData(categoryNameParam, currentPage, searchParam);
      }
    }
  }, [currentPage, fetchData, location.search]);

  // on Scroll page number change function
  const [fetchThreshold, setFetchThreshold] = useState(700);

  const fetchMoreOnBottomReached = () => {
    const scrollPlus = isMobile ? 200 : 100;
    if (window.pageYOffset + window.innerHeight >= document.documentElement.scrollHeight - fetchThreshold) {
      if (visible < rowCount) {
        setCurrentPage(currentPage + 1);
        setFetchThreshold((prevThreshold) => prevThreshold + scrollPlus);
        setIsLoading(true);
      }
    }
  };
  useEffect(() => {
    visible < rowCount && rowCount > 0
      ? window.addEventListener('scroll', fetchMoreOnBottomReached)
      : window.removeEventListener('scroll', fetchMoreOnBottomReached);
    window.addEventListener('scroll', fetchMoreOnBottomReached);
    return () => {
      window.removeEventListener('scroll', fetchMoreOnBottomReached);
    };
  }, [visible]);

  // search value clear function
  const clearSearch = () => {
    setHasMore(true);
    setCurrentPage(1);
    setSearch('');

    let queryParams = `/inventory/?`;
    if (selectedCategory) {
      queryParams += `view=${encodeURIComponent(selectedCategory)}`;
    }
    navigate(queryParams);
  };

  // on sell click to open modal
  const OnSellModal = (item) => {
    document.body.classList.add('modal-open');
    if (userBan.status === 'block') {
      setIsUserBanModalOpen(true);
    } else {
      setShowSellModal(true);
      setSellItem(item);
    }
  };

  // show warning message before selling a skin
  const onwarrning = () => {
    document.body.classList.add('modal-open');
    // localStorage.setItem('USER_SHOW_CREATE_AUCTION_WARNING', true);
    Cookies.set('USER_SHOW_CREATE_AUCTION_WARNING', true, { expires: 364 });
    setWarningModal(false);
    if (userBan.status === 'block') {
      setIsUserBanModalOpen(true);
    } else {
      if (showSellModal === false) {
        setBulkSellModal(true);
        setBulkSellItem(bulkSellItem);
      }
    }
  };

  const onWarningModalClose = () => {
    document.body.classList.remove('modal-open');
    setWarningModal(false);
  };

  // remove selected skin on bulk sell
  const onRemoveItem = (itemToRemove) => {
    const updatedBulkSellItem = bulkSellItem.filter((item) => item.item_id !== itemToRemove.item_id);

    // Remove the item from rowSelection
    const updatedRowSelection = { ...rowSelection };
    delete updatedRowSelection[itemToRemove.item_id];

    // Remove the item from prices
    const updatedPrices = { ...prices };
    delete updatedPrices[itemToRemove.item_id];

    setBulkSellItem(updatedBulkSellItem);
    setRowSelection(updatedRowSelection);
    setPrices(updatedPrices);
  };

  const userBanModalClose = () => {
    document.body.classList.remove('modal-open');
    setIsUserBanModalOpen(false);
    setRowSelection({});
  };

  return (
    <>
      <div className="inventory-main-div position-relative inventory-display_div" ref={mobileRef}>
        <div className="container-fluid">
          <div className="container">
            <div className="inventory-inner-div position-relative">
              <div
                className={`inventory-main-filter d-flex inventory-page-table
                  ${Number(rowCount) === 0 || Number(rowCount) === null ? 'inventory-empty-div-main' : ''}`}
              >
                {showPopup && <SteamApiKeyModal closeModal={closeModal} onSubmit={onSubmit} setApiKey={setApiKey} errors={errors} />}
                {isUserBanModalOpen && (
                  <UserBanModal setIsUserBanModalOpen={setIsUserBanModalOpen} userBan={userBan} userBanModalClose={userBanModalClose} />
                )}

                {isMobile && (
                  <div>
                    <div onClick={toggleMenu} className="category-heading">
                      Filter
                      <img src="/images/common/right-arrow.svg" alt="right-arrow" />
                    </div>
                  </div>
                )}
                <Filter
                  onCategoryChange={onCategoryChange}
                  totalCountOfInventory={data && data?.total_count}
                  selectedCategory={selectedCategory}
                  handleAllCategoryChange={handleAllCategoryChange}
                  loading={refreshing}
                  toggleMenu={toggleMenu}
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                  isMobile={isMobile}
                />
                {isMobile ? (
                  <>
                    <MobileProduct
                      data={inventoryData}
                      rowCount={rowCount}
                      OnSellModal={OnSellModal}
                      loading={isLoading}
                      setWarningModal={setWarningModal}
                      setBulkSellItem={setBulkSellItem}
                      setBulkSellModal={setBulkSellModal}
                      setRowSelection={setRowSelection}
                      rowSelection={rowSelection}
                      setIsUserBanModalOpen={setIsUserBanModalOpen}
                      userBan={userBan}
                      currentPage={currentPage}
                      pagination={pagination}
                      refreshing={refreshing}
                      selectedCategory={selectedCategory}
                    />
                  </>
                ) : (
                  <>
                    <Product
                      data={inventoryData}
                      rowCount={rowCount}
                      OnSellModal={OnSellModal}
                      loading={isLoading}
                      setWarningModal={setWarningModal}
                      setBulkSellItem={setBulkSellItem}
                      setBulkSellModal={setBulkSellModal}
                      setRowSelection={setRowSelection}
                      rowSelection={rowSelection}
                      setIsUserBanModalOpen={setIsUserBanModalOpen}
                      userBan={userBan}
                      currentPage={currentPage}
                      pagination={pagination}
                      refreshing={refreshing}
                      selectedCategory={selectedCategory}
                    />
                  </>
                )}

                {showSellModal && (
                  <SellModal
                    closeModal={closeModal}
                    sellItem={sellItem}
                    setWarningModal={setWarningModal}
                    setShowSellModal={setShowSellModal}
                    getInventory={getInventory}
                    fetchData={fetchData}
                    setCurrentPage={setCurrentPage}
                  />
                )}

                {warningModal && (
                  <WarningModal
                    onWarningModalClose={onWarningModalClose}
                    setAuctionTerms={setAuctionTerms}
                    AuctionTerms={AuctionTerms}
                    setWarningModal={setWarningModal}
                    onwarrning={onwarrning}
                  />
                )}

                {bulkSellModal && (
                  <BulkSellList
                    bulkSellItem={bulkSellItem}
                    onBulkSellModalClose={onBulkSellModalClose}
                    setBulkSellItem={setBulkSellItem}
                    onRemoveItem={onRemoveItem}
                    setRowSelection={setRowSelection}
                    rowSelection={rowSelection}
                    setBulkSellModal={setBulkSellModal}
                    fetchInventoryData={fetchData}
                    isMobile={isMobile}
                    prices={prices}
                    setPrices={setPrices}
                  />
                )}
              </div>

              {/* {isMobile ? (
                <SearchBar
                  onRefresh={onRefresh}
                  clearSearch={clearSearch}
                  onSearchDebounced={onSearchDebounced}
                  search={search}
                />
              ) : ( */}
              <SearchBar
                onRefresh={onRefresh}
                clearSearch={clearSearch}
                onSearchDebounced={onSearchDebounced}
                search={search}
                refreshing={refreshing}
                inventoryTotalPrice={inventoryTotalPrice}
              />
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
